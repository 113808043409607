import type { Plane } from "../../plane/plane";
import type { SurfacePoint } from "../../plane/surfacePoint";
import {
  type Action,
  ActionType,
  type SelectPointConfig,
  type ActionUpdateInfo,
  type ActionResult,
} from "../action";
import type { Player } from "../player";

export class Mark implements Action {
  type: ActionType = ActionType.Mark;
  player: Player;
  plane: Plane;
  surfacePoints: Set<SurfacePoint> = new Set();
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 500,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.surfacePoints.add(surfacePoint);

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Mark (marks: ${this.surfacePoints.size})`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    for (const surfacePoint of this.surfacePoints) {
      const point = surfacePoint.getPosition();
      if (!point) {
        this.surfacePoints.delete(surfacePoint);
        continue;
      }
      // Render as red circle
      ctx.beginPath();
      ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
      ctx.fillStyle = "red";
      ctx.fill();
    }

    return true;
  }
}
