import type { Vector2D } from "./math/vector2D.ts";
import type { Transform } from "./math/transform.ts";
import type { Shape } from "./shapes/shape.ts";
import type { Body } from "./plane/body.ts";
import type { Color } from "./rendering/color.ts";
import type { SurfacePoint } from "./plane/surfacePoint.ts";

export interface Circle {
  x: number;
  y: number;
  radius: number;
}

export type SegmentInfo = {
  segment: Segment;
  index: number;
};

export type AABB = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export type RelativeTransformInfo = {
  guestToHost: Transform;
  hostToGuest: Transform;
  hostToGlobal: Transform;
};

export interface BodyState {
  position: [number, number, number];
  velocity: [number, number, number];
  positionLocked: boolean;
  rotationLocked: boolean;
}

export interface FullBodyState {
  x: number;
  y: number;
  rotation: number;
  sinRotation: number;
  cosRotation: number;
  velocityX: number;
  velocityY: number;
  angularVelocity: number;
  mass: number;
  momentOfInertia: number;
  positionLocked: boolean;
  rotationLocked: boolean;
  inSubplane: boolean;
}

export enum BodyType {
  PlayerBody,
  PlayerArm,
  Standard,
  Item,
  Enemy,
}

export enum SegmentType {
  LINE,
  ARC,
  CIRCLE,
}

export type SegmentContributions = {
  area: number;
  centroid: Vector2D;
  secondMomentOfArea: number;
};

export type CollisionContributions = {
  area: number;
  centroid: Vector2D;
  points?: Vector2D[];
};

export type SubtractionResult = {
  shape: Shape;
  position: [number, number, number];
};

export interface LineIntersection {
  point: Vector2D;
  t: number; // Parameter along the line (0-1)
  segment: Segment;
}

export enum StoredPointType {
  SURFACE_POINT,
  MEC_POINT,
}

export type StoredPoint = {
  type: StoredPointType;
  data: SurfacePoint | string;
  t: number;
};

export type PerimeterPoint = {
  position: Vector2D;
  segment: Segment;
  segmentIndex: number;
  tOrAngle: number;
};

export type PointOnBodySurface = {
  body: Body;
  globalPosition: Vector2D;
  perimeterPoint: PerimeterPoint;
};

export interface Joint {
  pointDestroy(point: SurfacePoint): void;
  pointTransfer(point: SurfacePoint, oldBody: Body, newBody: Body): void;
}

export interface Segment {
  type: SegmentType;
  storedPoints: StoredPoint[];
  getData(transform?: Transform): LineData | ArcData | CircleData;
  getNearPointData(
    transform?: Transform
  ): LineData | ArcNearPointData | CircleData;
  getAABB(): AABB;
  rayIntersectionCount(point: Vector2D): number;
  globalClone(transform: Transform, invert: boolean): Segment;
  globalPartialClone(
    transform: Transform,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): OpenSegment;
  clone(invert: boolean): Segment;
  partialClone(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): OpenSegment;
  translate(dx: number, dy: number): void;
  getAllContributions(): SegmentContributions;
  getCollisionContributions(transform?: Transform): CollisionContributions;
  getPartialCollisionContributions(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    transform?: Transform
  ): CollisionContributions;
  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    transform?: Transform
  ): LineIntersection[];
  getPointAtDistance(distance: number): Vector2D | number;
  getClosestPoint(point: Vector2D): {
    point: Vector2D;
    tOrAngle: number;
  };
  storePoint(storedPoint: StoredPoint): void;
  getStoredPoints(): StoredPoint[];
  clearStoredMecPoints(): void;
  getPointAtT(t: number): Vector2D | null;
}

export interface OpenSegment extends Segment {
  type: SegmentType.LINE | SegmentType.ARC;
  next: OpenSegment | null;
  previous: OpenSegment | null;
  start: Vector2D;
  end: Vector2D;
}

export type IntersectionShape = {
  shapes: {
    shape: Shape;
    centroid: Vector2D;
  }[];
  totalCentroid: Vector2D;
  normal: Vector2D;
  penetrationDistance: number;
};

export interface TetherConfig {
  maxLength: number; // Maximum allowed length between actuator objects
  restitution: number;
  baumgarteScale: number;
  slop: number;
}

export interface PIDParams {
  maxForce: number;
  kp: number;
  ki: number;
  kd: number;
  windupLimit: number;
}

export interface NetworkStateEntry {
  id: string;
  position: [number, number, number]; // [x, y, rotation]
  velocity: [number, number, number]; // [x, y, angular velocity]
}

export interface NetworkState {
  clientTimestamp: number;
  dt: number;
  entries: NetworkStateEntry[];
  used: boolean;
}

export interface Timer {
  id: number;
  callback: () => void;
  interval: number;
  nextTick: number;
  repeating: boolean;
}

export enum EnclosureType {
  Impossible,
  None,
  FirstBodyEncloses,
  SecondBodyEncloses,
}

export type Span = {
  onHost: boolean;
  onCircle: boolean;
  start: {
    segmentIndex: number;
    t: number;
    point: Vector2D;
  };
  end: {
    segmentIndex: number;
    t: number;
    point: Vector2D;
  };
};

export type SubtractionResultShape = {
  spans: (Span | Segment[])[];
  shapeProperties: ShapeProperties;
};

export type SerializableCutResult = {
  targetBodyId: number;
  resultBodies: {
    id: number;
    shape: SubtractionResultShape;
  }[];
};

export type ShapeProperties = {
  area: number;
  centroid: Vector2D;
  secondMomentOfArea: number;
  mec: {
    center: Vector2D;
    radius: number;
    mecPointIds: string[];
  };
};

export interface CollisionPair {
  body1Transform: Transform;
  body2Transform: Transform;
  relativeTransformInfo: RelativeTransformInfo;
  body1: Body;
  body2: Body;
  segmentPairs: [SegmentInfo, SegmentInfo][];
  intersectionPoints: [IntersectionPoint[], IntersectionPoint[]] | null;
  enclosureType: EnclosureType;
}

export interface CircleData {
  type: SegmentType.CIRCLE;
  center: Vector2D;
  radius: number;
  clockwise: boolean;
}

export interface ArcData {
  type: SegmentType.ARC;
  center: Vector2D;
  radius: number;
  startAngle: number;
  endAngle: number;
  centralAngle: number;
  clockwise: boolean;
}

export interface ArcNearPointData {
  type: SegmentType.ARC;
  center: Vector2D;
  radius: number;
  startAngle: number;
  endAngle: number;
  centralAngle: number;
  clockwise: boolean;
  start: Vector2D;
  end: Vector2D;
}

export interface LineData {
  type: SegmentType.LINE;
  start: Vector2D;
  end: Vector2D;
}

export type IntersectionPoint = {
  t: number;
  segmentIndex: number;
  entering: boolean;
  visited: boolean;
  point: Vector2D;
  onCircle: boolean;
  counterpart?: IntersectionPoint;
  previousIndex?: number;
  nextIndex?: number;
};

export interface ShapeMotionInfo {
  shape1: {
    vx: number;
    vy: number;
    angularVelocity: number; // in radians/second
  };
  shape2: {
    vx: number;
    vy: number;
    angularVelocity: number; // in radians/second
  };
}

export interface Material {
  density: number;
  restitution: number;
  staticFriction: number;
  dynamicFriction: number;
  color: Color;
  destructible: boolean;
}

export class CollisionPairKey {
  constructor(private body1: number, private body2: number) {
    // Always store pairs in sorted order to ensure consistent lookup
    if (body1 > body2) {
      [this.body1, this.body2] = [body2, body1];
    }
  }

  toString(): string {
    return `${this.body1},${this.body2}`;
  }
}

export interface CollisionInfo {
  body1: Body;
  body2: Body;
  pointOfContact: Vector2D;
  normal: Vector2D;
  penetrationDistance: number;
  intensity?: {
    impulseStrength: number; // Magnitude of the collision impulse
    impactSpeed: number; // Speed at which objects collided
    collisionEnergy: number; // Rough approximation of collision energy
  };
}

export interface IntersectionCollisionInfo extends CollisionInfo {
  intersectionPoints: [IntersectionPoint[], IntersectionPoint[]];
}

export interface EnclosureCollisionInfo extends CollisionInfo {
  enclosure: EnclosureType;
}

export type CollisionTrigger = (
  otherBody: Body,
  collision: CollisionInfo
) => void;

export type ShouldResolveCollisionCheck = (collision: CollisionInfo) => boolean;

export enum ButtonEvent {
  StartGrab,
  StopGrab,
  StartAction,
  StopAction,
  PreviousAction,
  NextAction,
  StartLeftThrust,
  StopLeftThrust,
  StartRightThrust,
  StopRightThrust,
  StartUpThrust,
  StopUpThrust,
  StartDownThrust,
  StopDownThrust,
}

export type ButtonMap = Map<string, ButtonEvent>;

export const DEFAULT_BUTTON_MAP: ButtonMap = new Map([
  ["leftMouse-down", ButtonEvent.StartGrab],
  ["leftMouse-up", ButtonEvent.StopGrab],
  ["shift-down", ButtonEvent.StartAction],
  ["shift-up", ButtonEvent.StopAction],
  ["rightMouse-down", ButtonEvent.PreviousAction],
  ["rightMouse-up", ButtonEvent.NextAction],
  ["q-down", ButtonEvent.PreviousAction],
  ["e-down", ButtonEvent.NextAction],
  [" -down", ButtonEvent.StartGrab],
  [" -up", ButtonEvent.StopGrab],
  ["w-down", ButtonEvent.StartUpThrust],
  ["w-up", ButtonEvent.StopUpThrust],
  ["a-down", ButtonEvent.StartLeftThrust],
  ["a-up", ButtonEvent.StopLeftThrust],
  ["d-down", ButtonEvent.StartRightThrust],
  ["d-up", ButtonEvent.StopRightThrust],
  ["s-down", ButtonEvent.StartDownThrust],
  ["s-up", ButtonEvent.StopDownThrust],
  ["rightTrigger-down", ButtonEvent.StartAction],
  ["rightTrigger-up", ButtonEvent.StopAction],
  ["leftTrigger-down", ButtonEvent.StartAction],
  ["leftTrigger-up", ButtonEvent.StopAction],
  ["gamepadA-down", ButtonEvent.StartAction],
  ["gamepadA-up", ButtonEvent.StopAction],
  ["dPadLeft-down", ButtonEvent.PreviousAction],
  ["dPadRight-down", ButtonEvent.NextAction],
]);

export enum ClientPacketType {
  Input,
  DataRequest,
  Join,
}

export enum DataRequestType {
  BodyData,
  PlayerData,
}

export enum InputPacketType {
  MovementControl,
  ButtonEvent,
}

export enum ServerPacketType {
  Welcome,
  StateUpdate,
}

export enum UpdateType {
  BodyData,
  PlayerData,
  CompositeData,
  RemovedBodyIds,
  RemovedCompositeIds,
  Cuts,
  BodyState,
  PlayerState,
}

export type PlayerEntityIds = {
  abdomenId: number;
  limbId: number;
  actuatorId: number;
  tetherId: number;
  thrusterId: number;
};

export type LightSource = {
  radius: number;
  glowRadius: number;
  brightnessStops: { position: number; opacity: number }[];
  colorStops: { position: number; opacity: number }[];
  color: Color;
};

export type BodyStateUpdate = {
  positionX: number;
  positionY: number;
  rotation: number;
  velocityX: number;
  velocityY: number;
  angularVelocity: number;
  positionLocked: boolean;
  rotationLocked: boolean;
  compositeInfo?: {
    id: number;
    localX: number;
    localY: number;
    localRotation: number;
  };
};

export type CompositeStateUpdate = {
  id: number;
  invMass: number;
  invMomentOfInertia: number;
  localCentroidX: number;
  localCentroidY: number;
  positionX: number;
  positionY: number;
  rotation: number;
  velocityX: number;
  velocityY: number;
  angularVelocity: number;
  positionLocked: boolean;
  rotationLocked: boolean;
};
