import type { Point } from "../rendering/noise";

export enum MarchingSquaresErrorType {
  NO_STARTING_EDGE = "NO_STARTING_EDGE",
  NO_CROSSING_POINT = "NO_CROSSING_POINT",
  PATH_NOT_CLOSED = "PATH_NOT_CLOSED",
}

export function getNoisePath({
  startPoint,
  gridSize,
  getNoiseValue,
  threshold,
  segmentLimit,
  initialCheckLimit,
}: {
  startPoint: Point;
  gridSize: number;
  getNoiseValue: (point: Point) => number;
  threshold: number;
  segmentLimit: number;
  initialCheckLimit: number;
}): {
  points: Point[];
  numChecksToFindStartingEdge: number;
  errorType?: MarchingSquaresErrorType;
} {
  // Step 1, find a pair of points on opposite sides of the threshold
  let currentPoint = startPoint;
  let currentNoiseValue = getNoiseValue(currentPoint);
  const startingAboveThreshold = currentNoiseValue > threshold;
  let nextPoint = {
    x: currentPoint.x + gridSize,
    y: currentPoint.y,
  };
  let nextNoiseValue = getNoiseValue(nextPoint);
  let nextAboveThreshold = nextNoiseValue > threshold;

  let numChecksToFindStartingEdge = 2;

  let foundEdge = false;
  for (let i = 0; i < initialCheckLimit; i++) {
    nextAboveThreshold = nextNoiseValue > threshold;

    if (startingAboveThreshold !== nextAboveThreshold) {
      foundEdge = true;
      break;
    }

    currentPoint = nextPoint;
    currentNoiseValue = nextNoiseValue;

    nextPoint = {
      x: currentPoint.x + gridSize,
      y: currentPoint.y,
    };
    nextNoiseValue = getNoiseValue(nextPoint);
    numChecksToFindStartingEdge++;
  }

  if (!foundEdge) {
    return {
      points: [],
      numChecksToFindStartingEdge,
      errorType: MarchingSquaresErrorType.NO_STARTING_EDGE,
    };
  }

  // Make the first edge
  const initialPoint1: NoisePoint = {
    x: currentPoint.x,
    y: currentPoint.y,
    noiseValue: currentNoiseValue,
    aboveThreshold: startingAboveThreshold,
  };

  let currentPoint1: NoisePoint = initialPoint1;
  let currentPoint2: NoisePoint = {
    x: nextPoint.x,
    y: nextPoint.y,
    noiseValue: nextNoiseValue,
    aboveThreshold: nextAboveThreshold,
  };

  // Start with top
  let currentSide = Side.TOP;

  const crossingPoints: Point[] = [];

  const firstCrossingPoint = evaluateGridEdge(
    currentPoint1,
    currentPoint2,
    getNoiseValue,
    threshold
  );
  if (!firstCrossingPoint) {
    return {
      points: [],
      numChecksToFindStartingEdge,
      errorType: MarchingSquaresErrorType.NO_CROSSING_POINT,
    };
  }

  crossingPoints.push({ x: 0, y: 0 });

  let closed = false;

  while (crossingPoints.length < segmentLimit) {
    const result = evaluateGridCell(
      currentPoint1,
      currentPoint2,
      currentSide,
      gridSize,
      getNoiseValue,
      threshold
    );

    if (!result) {
      return {
        points: [],
        numChecksToFindStartingEdge,
        errorType: MarchingSquaresErrorType.NO_CROSSING_POINT,
      };
    }

    const { point1, point2, crossingPoint, nextSide } = result;

    const recenteredCrossingPoint = {
      x: crossingPoint.x - firstCrossingPoint.x,
      y: crossingPoint.y - firstCrossingPoint.y,
    };

    // Check if the distance between the first crossing point and the last crossing point is less than epsilon
    if (crossingPoints.length > 2) {
      if (
        Math.abs(recenteredCrossingPoint.x) < 0.01 &&
        Math.abs(recenteredCrossingPoint.y) < 0.01
      ) {
        closed = true;
        break;
      }
    }

    crossingPoints.push(recenteredCrossingPoint);
    // Swap for next iteration
    currentPoint1 = point2;
    currentPoint2 = point1;
    currentSide = nextSide;

    // Check if we've looped back to the starting point

    // // We only need to check the bottom side because we start with the top side
    // if (currentSide !== Side.BOTTOM) {
    //   continue;
    // }

    // // Check if currentPoint2 (bottom left) is the same as initialPoint1 (top left)
    // if (
    //   Math.abs(currentPoint2.x - initialPoint1.x) < gridSize / 4 &&
    //   Math.abs(currentPoint2.y - initialPoint1.y) < gridSize / 4
    // ) {
    //   closed = true;
    //   break;
    // }
  }

  if (!closed) {
    return {
      points: [],
      numChecksToFindStartingEdge,
      errorType: MarchingSquaresErrorType.PATH_NOT_CLOSED,
    };
  }

  return {
    points: crossingPoints,
    numChecksToFindStartingEdge,
  };
}

enum Side {
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
}

type NoisePoint = {
  x: number;
  y: number;
  noiseValue?: number;
  aboveThreshold?: boolean;
};

function evaluateGridCell(
  initialPoint1: NoisePoint,
  initialPoint2: NoisePoint,
  side: Side,
  gridSize: number,
  getNoiseValue: (point: Point) => number,
  threshold: number
): {
  point1: NoisePoint;
  point2: NoisePoint;
  crossingPoint: Point;
  nextSide: Side;
} | null {
  switch (side) {
    case Side.LEFT:
      const leftResult = evaluateLeftEdge(
        initialPoint1,
        initialPoint2,
        getNoiseValue,
        threshold,
        gridSize
      );
      if (!leftResult) {
        return null;
      }
      return leftResult;
    case Side.RIGHT:
      const rightResult = evaluateRightEdge(
        initialPoint1,
        initialPoint2,
        getNoiseValue,
        threshold,
        gridSize
      );
      if (!rightResult) {
        return null;
      }
      return rightResult;
    case Side.TOP:
      const topResult = evaluateTopEdge(
        initialPoint1,
        initialPoint2,
        getNoiseValue,
        threshold,
        gridSize
      );
      if (!topResult) {
        return null;
      }
      return topResult;
    case Side.BOTTOM:
      const bottomResult = evaluateBottomEdge(
        initialPoint1,
        initialPoint2,
        getNoiseValue,
        threshold,
        gridSize
      );
      if (!bottomResult) {
        return null;
      }
      return bottomResult;
  }
}

function evaluateGridEdge(
  point1: NoisePoint,
  point2: NoisePoint,
  getNoiseValue: (point: Point) => number,
  threshold: number
): Point | null {
  if (point1.noiseValue === undefined) {
    point1.noiseValue = getNoiseValue(point1);
    point1.aboveThreshold = point1.noiseValue > threshold;
  }

  if (point2.noiseValue === undefined) {
    point2.noiseValue = getNoiseValue(point2);
    point2.aboveThreshold = point2.noiseValue > threshold;
  }

  if (point1.aboveThreshold === point2.aboveThreshold) {
    return null;
  }

  // Find the point between the endpoints where the threshold is crossed
  const t =
    (threshold - point1.noiseValue) / (point2.noiseValue - point1.noiseValue);
  const crossingPoint = {
    x: point1.x + t * (point2.x - point1.x),
    y: point1.y + t * (point2.y - point1.y),
  };

  return crossingPoint;
}

function evaluateLeftEdge(
  bottomLeftPoint: NoisePoint,
  topLeftPoint: NoisePoint,
  getNoiseValue: (point: Point) => number,
  threshold: number,
  gridSize: number
): {
  point1: NoisePoint;
  point2: NoisePoint;
  crossingPoint: Point;
  nextSide: Side;
} | null {
  const right = topLeftPoint.x + gridSize;

  // Check top
  const topRightPoint = {
    x: right,
    y: topLeftPoint.y,
  };

  const topResult = evaluateGridEdge(
    topLeftPoint,
    topRightPoint,
    getNoiseValue,
    threshold
  );

  if (topResult) {
    return {
      point1: topLeftPoint,
      point2: topRightPoint,
      crossingPoint: topResult,
      nextSide: Side.BOTTOM,
    };
  }

  // Check right
  const bottomRightPoint = {
    x: right,
    y: bottomLeftPoint.y,
  };

  const rightResult = evaluateGridEdge(
    bottomRightPoint,
    topRightPoint,
    getNoiseValue,
    threshold
  );

  if (rightResult) {
    return {
      point1: topRightPoint,
      point2: bottomRightPoint,
      crossingPoint: rightResult,
      nextSide: Side.LEFT,
    };
  }

  // Check bottom
  const bottomResult = evaluateGridEdge(
    bottomRightPoint,
    bottomLeftPoint,
    getNoiseValue,
    threshold
  );

  if (bottomResult) {
    return {
      point1: bottomRightPoint,
      point2: bottomLeftPoint,
      crossingPoint: bottomResult,
      nextSide: Side.TOP,
    };
  }

  return null;
}

function evaluateRightEdge(
  topRightPoint: NoisePoint,
  bottomRightPoint: NoisePoint,
  getNoiseValue: (point: Point) => number,
  threshold: number,
  gridSize: number
): {
  point1: NoisePoint;
  point2: NoisePoint;
  crossingPoint: Point;
  nextSide: Side;
} | null {
  const left = topRightPoint.x - gridSize;

  // Check left
  const bottomLeftPoint = {
    x: left,
    y: bottomRightPoint.y,
  };

  const bottomResult = evaluateGridEdge(
    bottomRightPoint,
    bottomLeftPoint,
    getNoiseValue,
    threshold
  );

  if (bottomResult) {
    return {
      point1: bottomRightPoint,
      point2: bottomLeftPoint,
      crossingPoint: bottomResult,
      nextSide: Side.TOP,
    };
  }

  const topLeftPoint = {
    x: left,
    y: topRightPoint.y,
  };

  const leftResult = evaluateGridEdge(
    bottomLeftPoint,
    topLeftPoint,
    getNoiseValue,
    threshold
  );

  if (leftResult) {
    return {
      point1: bottomLeftPoint,
      point2: topLeftPoint,
      crossingPoint: leftResult,
      nextSide: Side.RIGHT,
    };
  }

  const topResult = evaluateGridEdge(
    topRightPoint,
    topLeftPoint,
    getNoiseValue,
    threshold
  );

  if (topResult) {
    return {
      point1: topLeftPoint,
      point2: topRightPoint,
      crossingPoint: topResult,
      nextSide: Side.BOTTOM,
    };
  }

  return null;
}

function evaluateTopEdge(
  topLeftPoint: NoisePoint,
  topRightPoint: NoisePoint,
  getNoiseValue: (point: Point) => number,
  threshold: number,
  gridSize: number
): {
  point1: NoisePoint;
  point2: NoisePoint;
  crossingPoint: Point;
  nextSide: Side;
} | null {
  const bottom = topLeftPoint.y - gridSize;

  // Check right
  const bottomRightPoint = {
    x: topRightPoint.x,
    y: bottom,
  };

  const rightResult = evaluateGridEdge(
    topRightPoint,
    bottomRightPoint,
    getNoiseValue,
    threshold
  );

  if (rightResult) {
    return {
      point1: topRightPoint,
      point2: bottomRightPoint,
      crossingPoint: rightResult,
      nextSide: Side.LEFT,
    };
  }

  // Check bottom
  const bottomLeftPoint = {
    x: topLeftPoint.x,
    y: bottom,
  };

  const bottomResult = evaluateGridEdge(
    bottomLeftPoint,
    bottomRightPoint,
    getNoiseValue,
    threshold
  );

  if (bottomResult) {
    return {
      point1: bottomRightPoint,
      point2: bottomLeftPoint,
      crossingPoint: bottomResult,
      nextSide: Side.TOP,
    };
  }

  // Check left
  const leftResult = evaluateGridEdge(
    topLeftPoint,
    bottomLeftPoint,
    getNoiseValue,
    threshold
  );

  if (leftResult) {
    return {
      point1: bottomLeftPoint,
      point2: topLeftPoint,
      crossingPoint: leftResult,
      nextSide: Side.RIGHT,
    };
  }

  return null;
}

function evaluateBottomEdge(
  bottomRightPoint: NoisePoint,
  bottomLeftPoint: NoisePoint,
  getNoiseValue: (point: Point) => number,
  threshold: number,
  gridSize: number
): {
  point1: NoisePoint;
  point2: NoisePoint;
  crossingPoint: Point;
  nextSide: Side;
} | null {
  const top = bottomLeftPoint.y + gridSize;

  const topLeftPoint = {
    x: bottomLeftPoint.x,
    y: top,
  };

  // Check left
  const leftResult = evaluateGridEdge(
    bottomLeftPoint,
    topLeftPoint,
    getNoiseValue,
    threshold
  );

  if (leftResult) {
    return {
      point1: bottomLeftPoint,
      point2: topLeftPoint,
      crossingPoint: leftResult,
      nextSide: Side.RIGHT,
    };
  }

  // Check right
  const topRightPoint = {
    x: bottomRightPoint.x,
    y: top,
  };

  const rightResult = evaluateGridEdge(
    bottomRightPoint,
    topRightPoint,
    getNoiseValue,
    threshold
  );

  if (rightResult) {
    return {
      point1: topRightPoint,
      point2: bottomRightPoint,
      crossingPoint: rightResult,
      nextSide: Side.LEFT,
    };
  }

  const topResult = evaluateGridEdge(
    topLeftPoint,
    topRightPoint,
    getNoiseValue,
    threshold
  );

  if (topResult) {
    return {
      point1: topLeftPoint,
      point2: topRightPoint,
      crossingPoint: topResult,
      nextSide: Side.BOTTOM,
    };
  }

  return null;
}
