import type { Transform } from "../math/transform";
import { normalizeAngle } from "../math/utils";
import { Vector2D } from "../math/vector2D";
import {
  SegmentType,
  type ArcNearPointData,
  type CircleData,
  type LineData,
  type PerimeterPoint,
  type SegmentInfo,
} from "../models";

/**
 * Finds the closest points between pairs of segments, applying a relative transform to the first shape
 */
export function findClosestPointsBetweenSegmentPairs(
  segmentPairs: [SegmentInfo, SegmentInfo][],
  relativeTransform: Transform
): {
  closestPoints: {
    point1: PerimeterPoint;
    point2: PerimeterPoint;
  } | null;
  segmentCheckCount: number;
} {
  let minDistance = Infinity;
  let closestPoints = null;
  let segmentCheckCount = 0;

  for (const [segment1Info, segment2Info] of segmentPairs) {
    const segment1 = segment1Info.segment.getNearPointData(relativeTransform);
    const segment2 = segment2Info.segment.getNearPointData();

    segmentCheckCount++;

    let result = null;

    if (
      segment1.type === SegmentType.LINE &&
      segment2.type === SegmentType.LINE
    ) {
      result = findClosestPointsBetweenLineSegments(
        segment1 as LineData,
        segment2 as LineData
      );
    } else if (
      segment1.type === SegmentType.LINE &&
      segment2.type === SegmentType.ARC
    ) {
      result = findClosestPointsBetweenLineAndArcSegments(
        segment1 as LineData,
        segment2 as ArcNearPointData
      );
    } else if (
      segment1.type === SegmentType.ARC &&
      segment2.type === SegmentType.LINE
    ) {
      const flippedResult = findClosestPointsBetweenLineAndArcSegments(
        segment2 as LineData,
        segment1 as ArcNearPointData
      );
      if (flippedResult) {
        result = {
          point1: flippedResult.point2,
          t1: flippedResult.t2,
          point2: flippedResult.point1,
          t2: flippedResult.t1,
        };
      }
    } else if (
      segment1.type === SegmentType.ARC &&
      segment2.type === SegmentType.ARC
    ) {
      result = findClosestPointsBetweenArcSegments(
        segment1 as ArcNearPointData,
        segment2 as ArcNearPointData
      );
    } else if (
      segment1.type === SegmentType.LINE &&
      segment2.type === SegmentType.CIRCLE
    ) {
      result = findClosestPointsBetweenLineAndCircle(
        segment1 as LineData,
        segment2 as CircleData
      );
    } else if (
      segment1.type === SegmentType.CIRCLE &&
      segment2.type === SegmentType.LINE
    ) {
      const flippedResult = findClosestPointsBetweenLineAndCircle(
        segment2 as LineData,
        segment1 as CircleData
      );
      if (flippedResult) {
        result = {
          point1: flippedResult.point2,
          t1: flippedResult.t2,
          point2: flippedResult.point1,
          t2: flippedResult.t1,
        };
      }
    } else if (
      segment1.type === SegmentType.CIRCLE &&
      segment2.type === SegmentType.ARC
    ) {
      result = findClosestPointsBetweenCircleAndArcSegment(
        segment1 as CircleData,
        segment2 as ArcNearPointData
      );
    } else if (
      segment1.type === SegmentType.ARC &&
      segment2.type === SegmentType.CIRCLE
    ) {
      const flippedResult = findClosestPointsBetweenCircleAndArcSegment(
        segment2 as CircleData,
        segment1 as ArcNearPointData
      );
      if (flippedResult) {
        result = {
          point1: flippedResult.point2,
          t1: flippedResult.t2,
          point2: flippedResult.point1,
          t2: flippedResult.t1,
        };
      }
    } else if (
      segment1.type === SegmentType.CIRCLE &&
      segment2.type === SegmentType.CIRCLE
    ) {
      result = findClosestPointsBetweenCircles(
        segment1 as CircleData,
        segment2 as CircleData
      );
    }

    if (result) {
      const distance = result.point1.subtract(result.point2).length();
      if (distance < minDistance) {
        minDistance = distance;
        closestPoints = {
          point1: {
            position: result.point1,
            segment: segment1Info.segment,
            segmentIndex: segment1Info.index,
            tOrAngle: result.t1,
          },
          point2: {
            position: result.point2,
            segment: segment2Info.segment,
            segmentIndex: segment2Info.index,
            tOrAngle: result.t2,
          },
        };
      }
    }
  }

  if (closestPoints) {
    // Transform point1 to local space of segment1
    closestPoints.point1.position = relativeTransform.applyInverse(
      closestPoints.point1.position
    );
  }

  return {
    closestPoints,
    segmentCheckCount,
  };
}

/**
 * Finds the closest points between a line segment and an arc segment.
 */
export function findClosestPointsBetweenLineAndArcSegments(
  line: LineData,
  arc: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  if (arc.clockwise) {
    // Concave arc
    return findClosestPointsBetweenLineAndConcaveArcSegment(line, arc);
  } else {
    // Convex arc
    return findClosestPointsBetweenLineAndConvexArcSegment(line, arc);
  }
}

/**
 * Finds the closest points between a line segment and an arc segment.
 */
export function findClosestPointsBetweenLineAndConvexArcSegment(
  line: LineData,
  arc: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  // First find closest point on line (same as line/circle)
  const lineDir = line.end.subtract(line.start);
  const lineLength = lineDir.length();
  const normalizedLineDir = lineDir.scale(1 / lineLength);

  // Get vector from line start to arc center, flipping for clockwise arcs
  const v = arc.center.subtract(line.start);
  let t = normalizedLineDir.dot(v) / lineLength;
  t = Math.max(0, Math.min(1, t)); // Clamp to segment

  const closestPointOnLine = line.start.add(lineDir.scale(t));

  // Get direction from arc center to line point
  const centerToLine = closestPointOnLine.subtract(arc.center);
  const angle = normalizeAngle(Math.atan2(centerToLine.y, centerToLine.x));

  // Check if this angle is within arc's sweep
  if (angleIsWithinArc(angle, arc.startAngle, arc.endAngle, false)) {
    // Point on arc is always radius distance from center in direction of centerToLine
    const pointOnArc = arc.center.add(
      centerToLine.normalize().scale(arc.radius)
    );

    return {
      point1: closestPointOnLine,
      t1: t,
      point2: pointOnArc,
      t2: angleToTValueClamped(
        angle,
        arc.startAngle,
        arc.endAngle,
        arc.centralAngle,
        false
      ),
    };
  } else {
    // If the direct point isn't within the arc's sweep,
    // check the endpoints to find the closer one
    const startDist = arc.start.subtract(closestPointOnLine).length();
    const endDist = arc.end.subtract(closestPointOnLine).length();

    if (startDist <= endDist) {
      return {
        point1: closestPointOnLine,
        t1: t,
        point2: arc.start,
        t2: 0,
      };
    } else {
      return {
        point1: closestPointOnLine,
        t1: t,
        point2: arc.end,
        t2: 1,
      };
    }
  }
}

/**
 * Finds the closest points between a line segment and a concave (clockwise) arc segment.
 */
function findClosestPointsBetweenLineAndConcaveArcSegment(
  line: LineData,
  arc: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  let minDistance = Infinity;
  let bestResult = null;

  // Helper to check and update closest points
  const checkDistance = (
    point1: Vector2D,
    t1: number,
    point2: Vector2D,
    t2: number
  ) => {
    const distance = point1.subtract(point2).length();
    if (distance < minDistance) {
      minDistance = distance;
      bestResult = { point1, t1, point2, t2 };
    }
  };

  // Check line endpoints against arc
  [
    { point: line.start, t: 0 },
    { point: line.end, t: 1 },
  ].forEach(({ point: linePoint, t: lineT }) => {
    // Get angle from arc center to line endpoint
    const vecToPoint = linePoint.subtract(arc.center);
    const angle = normalizeAngle(Math.atan2(vecToPoint.y, vecToPoint.x));

    if (angleIsWithinArc(angle, arc.startAngle, arc.endAngle, true)) {
      // If angle is within arc's sweep, closest point is on the arc
      const pointOnArc = arc.center.add(
        vecToPoint.normalize().scale(arc.radius)
      );
      const arcT = angleToTValueClamped(
        angle,
        arc.startAngle,
        arc.endAngle,
        arc.centralAngle,
        true
      );
      checkDistance(linePoint, lineT, pointOnArc, arcT);
    } else {
      // If angle is outside sweep, check both endpoints of arc
      const distToStart = linePoint.subtract(arc.start).length();
      const distToEnd = linePoint.subtract(arc.end).length();

      if (distToStart <= distToEnd) {
        checkDistance(linePoint, lineT, arc.start, 0);
      } else {
        checkDistance(linePoint, lineT, arc.end, 1);
      }
    }
  });

  // Check arc endpoints against line
  [
    { point: arc.start, t: 0 },
    { point: arc.end, t: 1 },
  ].forEach(({ point: arcPoint, t: arcT }) => {
    const lineDir = line.end.subtract(line.start);
    const lineLength = lineDir.length();
    const normalizedLineDir = lineDir.scale(1 / lineLength);

    // Find closest point on line to this endpoint
    const v = arcPoint.subtract(line.start);
    let lineT = normalizedLineDir.dot(v) / lineLength;
    lineT = Math.max(0, Math.min(1, lineT)); // Clamp to segment

    const closestLinePoint = line.start.add(lineDir.scale(lineT));

    checkDistance(closestLinePoint, lineT, arcPoint, arcT);
  });

  return (
    bestResult || {
      point1: line.start,
      t1: 0,
      point2: arc.start,
      t2: 0,
    }
  );
}

export function findClosestPointsBetweenArcSegments(
  arc1: ArcNearPointData,
  arc2: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  // If arcs are concentric or coincident, handle as special case
  const centerToCenter = arc2.center.subtract(arc1.center);
  const centerDist = centerToCenter.length();
  if (centerDist < Number.EPSILON) {
    return {
      point1: arc1.start,
      t1: 0,
      point2: arc2.start,
      t2: 0,
    };
  }

  // Route to appropriate function based on concavity
  if (!arc1.clockwise && !arc2.clockwise) {
    // Both convex
    return findClosestPointsBetweenConvexArcSegments(arc1, arc2);
  } else if (arc1.clockwise && arc2.clockwise) {
    // Both concave
    return findClosestPointsBetweenConcaveArcSegments(arc1, arc2);
  } else {
    // Mixed concavity - ensure convex arc is always first parameter
    const convexArc = arc1.clockwise ? arc2 : arc1;
    const concaveArc = arc1.clockwise ? arc1 : arc2;
    const result = findClosestPointsBetweenMixedConcavityArcSegments(
      convexArc,
      concaveArc
    );

    // If we swapped the order, swap back the results
    if (arc1.clockwise) {
      return {
        point1: result.point2,
        t1: result.t2,
        point2: result.point1,
        t2: result.t1,
      };
    }
    return result;
  }
}

/**
 * Finds the closest points between two convex (counterclockwise) arc segments.
 */
export function findClosestPointsBetweenConvexArcSegments(
  arc1: ArcNearPointData,
  arc2: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  const centerToCenter = arc2.center.subtract(arc1.center);

  // Angles between centers
  const angle1 = normalizeAngle(Math.atan2(centerToCenter.y, centerToCenter.x));
  const angle2 = normalizeAngle(angle1 + Math.PI);

  // Check if angles fall within both arcs' sweeps
  if (
    angleIsWithinArc(angle1, arc1.startAngle, arc1.endAngle, false) &&
    angleIsWithinArc(angle2, arc2.startAngle, arc2.endAngle, false)
  ) {
    const point1 = arc1.center.add(
      centerToCenter.normalize().scale(arc1.radius)
    );
    const point2 = arc2.center.add(
      centerToCenter.normalize().scale(-arc2.radius)
    );

    return {
      point1,
      t1: angleToTValueClamped(
        angle1,
        arc1.startAngle,
        arc1.endAngle,
        arc1.centralAngle,
        false
      ),
      point2,
      t2: angleToTValueClamped(
        angle2,
        arc2.startAngle,
        arc2.endAngle,
        arc2.centralAngle,
        false
      ),
    };
  }

  return findClosestPointsByCheckingEndpoints(arc1, arc2);
}

/**
 * Finds the closest points between a convex and concave arc segment.
 * Note: First parameter is always the convex arc, second is always the concave arc.
 */
export function findClosestPointsBetweenMixedConcavityArcSegments(
  convexArc: ArcNearPointData,
  concaveArc: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  // Vector between centers (convex to concave)
  const centerToCenter = concaveArc.center.subtract(convexArc.center);
  const centerDist = centerToCenter.length();

  // If arcs are concentric or coincident
  if (centerDist < Number.EPSILON) {
    return {
      point1: convexArc.start,
      t1: 0,
      point2: concaveArc.start,
      t2: 0,
    };
  }

  // Calculate angles between centers
  const centerAngle = normalizeAngle(
    Math.atan2(centerToCenter.y, centerToCenter.x)
  );
  const oppositeAngle = normalizeAngle(centerAngle + Math.PI);

  // Check if angles fall within both arcs' sweeps
  if (
    angleIsWithinArc(
      oppositeAngle,
      convexArc.startAngle,
      convexArc.endAngle,
      false
    ) &&
    angleIsWithinArc(
      oppositeAngle,
      concaveArc.startAngle,
      concaveArc.endAngle,
      true
    )
  ) {
    // Calculate points on arcs
    const point1 = convexArc.center.add(
      centerToCenter.normalize().scale(-convexArc.radius)
    );
    const point2 = concaveArc.center.add(
      centerToCenter.normalize().scale(-concaveArc.radius)
    );

    return {
      point1,
      t1: angleToTValueClamped(
        oppositeAngle,
        convexArc.startAngle,
        convexArc.endAngle,
        convexArc.centralAngle,
        false
      ),
      point2,
      t2: angleToTValueClamped(
        oppositeAngle,
        concaveArc.startAngle,
        concaveArc.endAngle,
        concaveArc.centralAngle,
        true
      ),
    };
  }

  // If direct path doesn't work, check each endpoint of concave arc
  let minDistance = Infinity;
  let bestResult = null;

  // Helper to check distance between points and update if closer
  const checkDistance = (
    point1: Vector2D,
    t1: number,
    point2: Vector2D,
    t2: number
  ) => {
    const distance = point1.subtract(point2).length();
    if (distance < minDistance) {
      minDistance = distance;
      bestResult = { point1, t1, point2, t2 };
    }
  };

  // Check both endpoints of concave arc
  [
    { point: concaveArc.start, t: 0 },
    { point: concaveArc.end, t: 1 },
  ].forEach(({ point: concavePoint, t: concaveT }) => {
    // Get angle from convex center to concave endpoint
    const vecToPoint = concavePoint.subtract(convexArc.center);
    const angleToPoint = normalizeAngle(Math.atan2(vecToPoint.y, vecToPoint.x));

    if (
      angleIsWithinArc(
        angleToPoint,
        convexArc.startAngle,
        convexArc.endAngle,
        false
      )
    ) {
      // If angle is within convex arc's sweep, closest point is on the arc
      const convexPoint = convexArc.center.add(
        vecToPoint.normalize().scale(convexArc.radius)
      );
      const convexT = angleToTValueClamped(
        angleToPoint,
        convexArc.startAngle,
        convexArc.endAngle,
        convexArc.centralAngle,
        false
      );
      checkDistance(convexPoint, convexT, concavePoint, concaveT);
    } else {
      // If angle is outside sweep, check both endpoints of convex arc
      const distToStart = concavePoint.subtract(convexArc.start).length();
      const distToEnd = concavePoint.subtract(convexArc.end).length();

      if (distToStart <= distToEnd) {
        checkDistance(convexArc.start, 0, concavePoint, concaveT);
      } else {
        checkDistance(convexArc.end, 1, concavePoint, concaveT);
      }
    }
  });

  return (
    bestResult || {
      point1: convexArc.start,
      t1: 0,
      point2: concaveArc.start,
      t2: 0,
    }
  );
}

/**
 * Finds the closest points between two concave (clockwise) arc segments.
 * For concave arcs, we only need to check endpoints since the closest points
 * will always be at the endpoints.
 */
export function findClosestPointsBetweenConcaveArcSegments(
  arc1: ArcNearPointData,
  arc2: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  return findClosestPointsByCheckingEndpoints(arc1, arc2);
}

/**
 * Helper function to find closest points by checking all endpoint combinations
 */
function findClosestPointsByCheckingEndpoints(
  arc1: ArcNearPointData,
  arc2: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  let minDistance = Infinity;
  let bestResult = null;

  // Helper function to calculate distance and update best result
  const checkPoints = (
    point1: Vector2D,
    t1: number,
    point2: Vector2D,
    t2: number
  ) => {
    const distance = point1.subtract(point2).length();
    if (distance < minDistance) {
      minDistance = distance;
      bestResult = { point1, t1, point2, t2 };
    }
  };

  // Check all endpoint combinations
  checkPoints(arc1.start, 0, arc2.start, 0);
  checkPoints(arc1.start, 0, arc2.end, 1);
  checkPoints(arc1.end, 1, arc2.start, 0);
  checkPoints(arc1.end, 1, arc2.end, 1);

  return (
    bestResult || {
      point1: arc1.start,
      t1: 0,
      point2: arc2.start,
      t2: 0,
    }
  );
}

/**
 * Finds the closest points between two line segments.
 * Returns the points and their respective t parameters on each line segment.
 */
export function findClosestPointsBetweenLineSegments(
  seg1: LineData,
  seg2: LineData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  const d1 = seg1.end.subtract(seg1.start); // Direction vector of segment 1
  const d2 = seg2.end.subtract(seg2.start); // Direction vector of segment 2
  const r = seg1.start.subtract(seg2.start);

  const a = d1.dot(d1); // Squared length of segment 1
  const e = d2.dot(d2); // Squared length of segment 2
  const f = d2.dot(r);
  const c = d1.dot(r);
  const b = d1.dot(d2);

  const denom = a * e - b * b;

  // Calculate parameters of the closest points on the infinite lines
  let t1, t2;

  if (denom < Number.EPSILON) {
    // Lines are parallel, choose arbitrary point on first segment
    t1 = 0;
    t2 = f / e;
  } else {
    t1 = (b * f - c * e) / denom;
    t2 = (a * f - b * c) / denom;
  }

  // Clamp t1 and t2 to [0,1] to keep points on the segments
  t1 = Math.max(0, Math.min(1, t1));
  t2 = Math.max(0, Math.min(1, t2));

  // Recalculate t2 if t1 was clamped
  if (denom >= Number.EPSILON) {
    t2 = (b * t1 + f) / e;
    t2 = Math.max(0, Math.min(1, t2));
  }

  // Recalculate t1 if t2 was clamped
  if (denom >= Number.EPSILON) {
    t1 = (b * t2 - c) / a;
    t1 = Math.max(0, Math.min(1, t1));
  }

  // Calculate the actual points using the t parameters
  const point1 = seg1.start.add(d1.scale(t1));
  const point2 = seg2.start.add(d2.scale(t2));

  return { point1, t1, point2, t2 };
}

/**
 * Finds the closest points between a line segment and a circle.
 */
export function findClosestPointsBetweenLineAndCircle(
  line: LineData,
  circle: CircleData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  // Get line segment direction and points
  const lineDir = line.end.subtract(line.start);
  const lineLength = lineDir.length();
  const normalizedLineDir = lineDir.scale(1 / lineLength);

  // Get perpendicular distance from circle center to line
  const v = circle.center.subtract(line.start);
  let t = normalizedLineDir.dot(v) / lineLength;
  t = Math.max(0, Math.min(1, t)); // Clamp to segment

  // Closest point on line segment
  const closestPointOnLine = line.start.add(lineDir.scale(t));

  // Vector from circle center to closest point on line
  const centerToLine = closestPointOnLine.subtract(circle.center);
  const angle = Math.atan2(centerToLine.y, centerToLine.x);

  // Closest point on circle is along the same direction from center
  const pointOnCircle = circle.center.add(
    centerToLine.normalize().scale(circle.radius)
  );

  return {
    point1: closestPointOnLine,
    t1: t,
    point2: pointOnCircle,
    t2: angle, // For circles, t2 represents the angle in radians
  };
}

/**
 * Finds the closest points between two circles.
 */
export function findClosestPointsBetweenCircles(
  circle1: CircleData,
  circle2: CircleData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  // Vector between centers
  const centerToCenter = circle2.center.subtract(circle1.center);
  const centerDist = centerToCenter.length();

  // If circles are concentric or coincident
  if (centerDist < Number.EPSILON) {
    // Return arbitrary points on each circle at angle 0
    return {
      point1: new Vector2D(circle1.center.x + circle1.radius, circle1.center.y),
      t1: 0,
      point2: new Vector2D(circle2.center.x + circle2.radius, circle2.center.y),
      t2: 0,
    };
  }

  // Angle of line connecting centers
  const angle = Math.atan2(centerToCenter.y, centerToCenter.x);

  // Closest points lie along the line connecting the centers
  const point1 = circle1.center.add(
    centerToCenter.normalize().scale(circle1.radius)
  );
  const point2 = circle2.center.add(
    centerToCenter.normalize().scale(-circle2.radius)
  );

  return {
    point1,
    t1: angle,
    point2,
    t2: normalizeAngle(angle + Math.PI),
  };
}

/**
 * Finds the closest points between a circle segment and an arc segment.
 */
export function findClosestPointsBetweenCircleAndArcSegment(
  circle: CircleData,
  arc: ArcNearPointData
): { point1: Vector2D; t1: number; point2: Vector2D; t2: number } {
  // Vector between centers
  const centerToCenter = arc.center.subtract(circle.center);
  const centerDist = centerToCenter.length();

  // If circles are concentric or coincident
  if (centerDist < Number.EPSILON) {
    // Return start point of arc and corresponding point on circle
    return {
      point1: new Vector2D(circle.center.x + circle.radius, circle.center.y),
      t1: 0,
      point2: arc.start,
      t2: 0,
    };
  }

  // Angle of line connecting centers
  const centerAngle = Math.atan2(centerToCenter.y, centerToCenter.x);
  const oppositeAngle = normalizeAngle(centerAngle + Math.PI);

  // Check if the angle to the arc center is within the arc's sweep
  if (
    angleIsWithinArc(oppositeAngle, arc.startAngle, arc.endAngle, arc.clockwise)
  ) {
    // Determine if we need to flip the direction for the circle point
    // If arc is clockwise (concave) and circle is inside, we need to flip
    const circlePointDirection = arc.clockwise ? -1 : 1;

    const point1 = circle.center.add(
      centerToCenter.normalize().scale(circle.radius * circlePointDirection)
    );
    const point2 = arc.center.add(
      centerToCenter.normalize().scale(-arc.radius)
    );

    return {
      point1,
      t1: centerAngle,
      point2,
      t2: angleToTValueClamped(
        oppositeAngle,
        arc.startAngle,
        arc.endAngle,
        arc.centralAngle,
        arc.clockwise
      ),
    };
  }

  // If the direct line between centers doesn't work,
  // we need to check the endpoints of the arc
  const startDist = arc.start.subtract(circle.center).length();
  const endDist = arc.end.subtract(circle.center).length();

  if (startDist <= endDist) {
    const dirToStart = arc.start.subtract(circle.center).normalize();
    const pointOnCircle = circle.center.add(dirToStart.scale(circle.radius));
    return {
      point1: pointOnCircle,
      t1: Math.atan2(dirToStart.y, dirToStart.x),
      point2: arc.start,
      t2: 0,
    };
  } else {
    const dirToEnd = arc.end.subtract(circle.center).normalize();
    const pointOnCircle = circle.center.add(dirToEnd.scale(circle.radius));
    return {
      point1: pointOnCircle,
      t1: Math.atan2(dirToEnd.y, dirToEnd.x),
      point2: arc.end,
      t2: 1,
    };
  }
}

function angleIsWithinArc(
  angle: number,
  startAngle: number,
  endAngle: number,
  clockwise: boolean
): boolean {
  if (!clockwise) {
    if (startAngle < endAngle) {
      return angle >= startAngle && angle <= endAngle;
    } else {
      return angle >= startAngle || angle <= endAngle;
    }
  } else {
    if (startAngle > endAngle) {
      return angle <= startAngle && angle >= endAngle;
    } else {
      return angle <= startAngle || angle >= endAngle;
    }
  }
}

function angleToTValueClamped(
  angle: number,
  startAngle: number,
  endAngle: number,
  centralAngle: number,
  clockwise: boolean
): number {
  const unsignedCentralAngle = Math.abs(centralAngle);
  const normalizedAngle = normalizeAngle(angle);

  if (!clockwise) {
    if (startAngle < endAngle) {
      // Simple case: if angle is before start, return 0; if after end, return 1
      if (normalizedAngle < startAngle) return 0;
      if (normalizedAngle > endAngle) return 1;
      return (normalizedAngle - startAngle) / unsignedCentralAngle;
    } else {
      // Wrapping case
      if (normalizedAngle >= startAngle) {
        return (normalizedAngle - startAngle) / unsignedCentralAngle;
      }
      if (normalizedAngle <= endAngle) {
        return 1 - (endAngle - normalizedAngle) / unsignedCentralAngle;
      }
      // If in the gap, determine which end is closer
      const distToStart = Math.abs(
        normalizeAngle(normalizedAngle - startAngle)
      );
      const distToEnd = Math.abs(normalizeAngle(normalizedAngle - endAngle));
      return distToStart < distToEnd ? 0 : 1;
    }
  } else {
    if (startAngle > endAngle) {
      // Simple case: if angle is before end, return 1; if after start, return 0
      if (normalizedAngle > startAngle) return 0;
      if (normalizedAngle < endAngle) return 1;
      return (startAngle - normalizedAngle) / unsignedCentralAngle;
    } else {
      // Wrapping case
      if (normalizedAngle <= startAngle) {
        return (startAngle - normalizedAngle) / unsignedCentralAngle;
      }
      if (normalizedAngle >= endAngle) {
        return 1 - (normalizedAngle - endAngle) / unsignedCentralAngle;
      }
      // If in the gap, determine which end is closer
      const distToStart = Math.abs(
        normalizeAngle(normalizedAngle - startAngle)
      );
      const distToEnd = Math.abs(normalizeAngle(normalizedAngle - endAngle));
      return distToStart < distToEnd ? 0 : 1;
    }
  }
}
