import { Vector2D } from "../math/vector2D";
import {
  CompositeInfoOffset,
  CompositeStateOffset,
  CompositeStateStride,
} from "./bodyStore";

import { CompositeInfoStride } from "./bodyStore";
import { Body } from "./body";

export class Composite {
  id: number;
  index: number;
  stateStrideIndex: number;
  stateArray: Float32Array;
  infoStrideIndex: number;
  infoArray: Uint16Array;
  destroyed: boolean = false;
  bodies: Body[] = [];

  constructor(
    id: number,
    index: number,
    stateArray: Float32Array,
    infoArray: Uint16Array
  ) {
    this.id = id;
    this.index = index;
    this.stateArray = stateArray;
    this.infoArray = infoArray;
    this.stateStrideIndex = index * CompositeStateStride;
    this.infoStrideIndex = index * CompositeInfoStride;
  }

  getInvProperties(): {
    invMass: number;
    invInertia: number;
  } {
    return {
      invMass:
        this.stateArray[this.stateStrideIndex + CompositeStateOffset.InvMass],
      invInertia:
        this.stateArray[
          this.stateStrideIndex + CompositeStateOffset.InvMomentOfInertia
        ],
    };
  }

  getStateForCollision(pointOfContact: Vector2D): {
    r: Vector2D;
    v: Vector2D;
    invMass: number;
    invInertia: number;
  } {
    const velX =
      this.stateArray[this.stateStrideIndex + CompositeStateOffset.VelocityX];
    const velY =
      this.stateArray[this.stateStrideIndex + CompositeStateOffset.VelocityY];
    const angVel =
      this.stateArray[
        this.stateStrideIndex + CompositeStateOffset.AngularVelocity
      ];
    const posX =
      this.stateArray[this.stateStrideIndex + CompositeStateOffset.PositionX];
    const posY =
      this.stateArray[this.stateStrideIndex + CompositeStateOffset.PositionY];
    const r = new Vector2D(pointOfContact.x - posX, pointOfContact.y - posY);

    const v = new Vector2D(velX - r.y * angVel, velY + r.x * angVel);

    const invMass =
      this.stateArray[this.stateStrideIndex + CompositeStateOffset.InvMass];
    const invInertia =
      this.stateArray[
        this.stateStrideIndex + CompositeStateOffset.InvMomentOfInertia
      ];

    return { r, v, invMass, invInertia };
  }

  getStateForImpulse(): {
    position: Vector2D;
    rotation: number;
    velocity: Vector2D;
    angularVelocity: number;
    invMass: number;
    invInertia: number;
  } {
    return {
      position: new Vector2D(
        this.stateArray[this.stateStrideIndex + CompositeStateOffset.PositionX],
        this.stateArray[this.stateStrideIndex + CompositeStateOffset.PositionY]
      ),
      rotation:
        this.stateArray[this.stateStrideIndex + CompositeStateOffset.Rotation],
      velocity: new Vector2D(
        this.stateArray[this.stateStrideIndex + CompositeStateOffset.VelocityX],
        this.stateArray[this.stateStrideIndex + CompositeStateOffset.VelocityY]
      ),
      angularVelocity:
        this.stateArray[
          this.stateStrideIndex + CompositeStateOffset.AngularVelocity
        ],
      invMass:
        this.stateArray[this.stateStrideIndex + CompositeStateOffset.InvMass],
      invInertia:
        this.stateArray[
          this.stateStrideIndex + CompositeStateOffset.InvMomentOfInertia
        ],
    };
  }

  getCenter(): Vector2D {
    return new Vector2D(
      this.stateArray[this.stateStrideIndex + CompositeStateOffset.PositionX],
      this.stateArray[this.stateStrideIndex + CompositeStateOffset.PositionY]
    );
  }

  getProps(): {
    centroid: Vector2D;
    mass: number;
    momentOfInertia: number;
  } {
    return {
      centroid: new Vector2D(
        this.stateArray[
          this.stateStrideIndex + CompositeStateOffset.LocalCentroidX
        ],
        this.stateArray[
          this.stateStrideIndex + CompositeStateOffset.LocalCentroidY
        ]
      ),
      mass: this.stateArray[this.stateStrideIndex + CompositeStateOffset.Mass],
      momentOfInertia:
        this.stateArray[
          this.stateStrideIndex + CompositeStateOffset.MomentOfInertia
        ],
    };
  }

  addBody(body: Body): void {
    if (this.destroyed) {
      return;
    }

    if (body.composite) {
      console.warn("Body already in composite");
      return;
    }

    const compositeStateStride = this.stateStrideIndex;
    const compositeInfoStride = this.infoStrideIndex;

    // Get composite transform info
    const compositeOriginX =
      this.stateArray[compositeStateStride + CompositeStateOffset.OriginX];
    const compositeOriginY =
      this.stateArray[compositeStateStride + CompositeStateOffset.OriginY];
    const compositeRotation =
      this.stateArray[compositeStateStride + CompositeStateOffset.Rotation];
    const compositeCosRotation =
      this.stateArray[compositeStateStride + CompositeStateOffset.CosRotation];
    const compositeSinRotation =
      this.stateArray[compositeStateStride + CompositeStateOffset.SinRotation];

    const {
      x: bodyX,
      y: bodyY,
      velocityX: bodyVelocityX,
      velocityY: bodyVelocityY,
      angularVelocity: bodyAngularVelocity,
      rotation: bodyRotation,
      mass: bodyMass,
      momentOfInertia: bodyMomentOfInertia,
    } = body.getFullState();

    // Calculate body's position in composite local space
    const dx = bodyX - compositeOriginX;
    const dy = bodyY - compositeOriginY;
    const localX = dx * compositeCosRotation + dy * compositeSinRotation;
    const localY = -dx * compositeSinRotation + dy * compositeCosRotation;
    const localRotation = bodyRotation - compositeRotation;

    // Store local transform in body
    body.setCompositeInfo(
      this,
      localX,
      localY,
      localRotation,
      this.index,
      this.id
    );

    this.bodies.push(body);

    const oldMomentOfInertia =
      this.stateArray[
        compositeStateStride + CompositeStateOffset.MomentOfInertia
      ];

    const oldCompositeMass =
      this.stateArray[compositeStateStride + CompositeStateOffset.Mass];

    const oldVelocityX =
      this.stateArray[compositeStateStride + CompositeStateOffset.VelocityX];
    const oldVelocityY =
      this.stateArray[compositeStateStride + CompositeStateOffset.VelocityY];

    const oldPositionX =
      this.stateArray[compositeStateStride + CompositeStateOffset.PositionX];
    const oldPositionY =
      this.stateArray[compositeStateStride + CompositeStateOffset.PositionY];

    this.recalculateProperties();

    const newCompositeMass =
      this.stateArray[compositeStateStride + CompositeStateOffset.Mass];

    const newMomentOfInertia =
      this.stateArray[
        compositeStateStride + CompositeStateOffset.MomentOfInertia
      ];

    const newPositionX =
      this.stateArray[compositeStateStride + CompositeStateOffset.PositionX];
    const newPositionY =
      this.stateArray[compositeStateStride + CompositeStateOffset.PositionY];

    const newVelocityX =
      (oldCompositeMass * oldVelocityX + bodyMass * bodyVelocityX) /
      newCompositeMass;
    const newVelocityY =
      (oldCompositeMass * oldVelocityY + bodyMass * bodyVelocityY) /
      newCompositeMass;

    const oldAngularVelocity =
      this.stateArray[
        compositeStateStride + CompositeStateOffset.AngularVelocity
      ];
    const spinAngularMomentum =
      oldMomentOfInertia * oldAngularVelocity +
      bodyMomentOfInertia * bodyAngularVelocity;

    const compositeOrbitalMoment =
      oldCompositeMass *
      ((oldPositionX - newPositionX) * oldVelocityY -
        (oldPositionY - newPositionY) * oldVelocityX);
    const bodyOrbitalMoment =
      bodyMass *
      ((bodyX - newPositionX) * bodyVelocityY -
        (bodyY - newPositionY) * bodyVelocityX);

    const totalAngularMomentum =
      spinAngularMomentum + compositeOrbitalMoment + bodyOrbitalMoment;
    const newAngularVelocity = totalAngularMomentum / newMomentOfInertia;

    this.stateArray[compositeStateStride + CompositeStateOffset.VelocityX] =
      newVelocityX;
    this.stateArray[compositeStateStride + CompositeStateOffset.VelocityY] =
      newVelocityY;
    this.stateArray[
      compositeStateStride + CompositeStateOffset.AngularVelocity
    ] = newAngularVelocity;

    // Increment body count
    this.infoArray[compositeInfoStride + CompositeInfoOffset.NumBodies]++;
  }

  removeBody(body: Body): void {
    if (this.destroyed) {
      body.clearCompositeInfo();
      return;
    }

    if (body.composite !== this) {
      body.clearCompositeInfo();
      this.bodies = this.bodies.filter((b) => b !== body);
      return;
    }

    this.bodies = this.bodies.filter((b) => b !== body);

    const {
      velocityX: bodyVelocityX,
      velocityY: bodyVelocityY,
      mass: bodyMass,
    } = body.getFullState();

    const bodyPosition = body.getCompositePosition();

    if (!bodyPosition) {
      body.clearCompositeInfo();
      return;
    }

    const compositeStateStride = this.stateStrideIndex;

    const oldCompositeMass =
      this.stateArray[compositeStateStride + CompositeStateOffset.Mass];
    const newCompositeMass = oldCompositeMass - bodyMass;

    this.recalculateProperties();

    const oldVelocityX =
      this.stateArray[compositeStateStride + CompositeStateOffset.VelocityX];
    const oldVelocityY =
      this.stateArray[compositeStateStride + CompositeStateOffset.VelocityY];

    const newVelocityX =
      (oldCompositeMass * oldVelocityX - bodyMass * bodyVelocityX) /
      newCompositeMass;
    const newVelocityY =
      (oldCompositeMass * oldVelocityY - bodyMass * bodyVelocityY) /
      newCompositeMass;

    this.stateArray[compositeStateStride + CompositeStateOffset.VelocityX] =
      newVelocityX;
    this.stateArray[compositeStateStride + CompositeStateOffset.VelocityY] =
      newVelocityY;

    body.clearCompositeInfo();

    this.infoArray[this.infoStrideIndex + CompositeInfoOffset.NumBodies]--;
  }

  setPosition(position: Vector2D, rotation: number): void {
    // console.log("Setting composite position", position, rotation);
    const stateStride = this.stateStrideIndex;
    this.stateArray[stateStride + CompositeStateOffset.PositionX] = position.x;
    this.stateArray[stateStride + CompositeStateOffset.PositionY] = position.y;
    this.stateArray[stateStride + CompositeStateOffset.Rotation] = rotation;

    const cos = Math.cos(rotation);
    const sin = Math.sin(rotation);

    this.stateArray[stateStride + CompositeStateOffset.CosRotation] = cos;
    this.stateArray[stateStride + CompositeStateOffset.SinRotation] = sin;

    const localCentroidX =
      this.stateArray[stateStride + CompositeStateOffset.LocalCentroidX];
    const localCentroidY =
      this.stateArray[stateStride + CompositeStateOffset.LocalCentroidY];

    const originX =
      -localCentroidX * cos -
      -localCentroidY * sin +
      this.stateArray[stateStride + CompositeStateOffset.PositionX];
    const originY =
      -localCentroidX * sin +
      -localCentroidY * cos +
      this.stateArray[stateStride + CompositeStateOffset.PositionY];

    this.stateArray[stateStride + CompositeStateOffset.OriginX] = originX;
    this.stateArray[stateStride + CompositeStateOffset.OriginY] = originY;
  }

  getMass(): number {
    return this.stateArray[this.stateStrideIndex + CompositeStateOffset.Mass];
  }

  recalculateProperties(): void {
    // Initialize accumulators
    let totalMass = 0;
    let weightedCentroidSum = new Vector2D(0, 0);
    let totalMomentOfInertia = 0;

    for (const body of this.bodies) {
      const props = body.getPropsForCompositeRecalculation();
      if (!props) {
        continue;
      }

      const { mass, localPosition, momentOfInertia } = props;
      totalMass += mass;
      weightedCentroidSum = weightedCentroidSum.add(
        localPosition.multiply(mass)
      );

      totalMomentOfInertia +=
        momentOfInertia + mass * localPosition.lengthSquared();
    }

    // Calculate final centroid
    const finalCentroid = weightedCentroidSum.divide(totalMass);

    totalMomentOfInertia -= totalMass * finalCentroid.lengthSquared();

    const stateStride = this.stateStrideIndex;
    const infoStride = this.infoStrideIndex;

    this.stateArray[stateStride + CompositeStateOffset.Mass] = totalMass;
    this.stateArray[stateStride + CompositeStateOffset.InvMass] = this
      .infoArray[infoStride + CompositeInfoOffset.PositionLocked]
      ? 0
      : 1 / totalMass;
    this.stateArray[stateStride + CompositeStateOffset.MomentOfInertia] =
      totalMomentOfInertia;
    this.stateArray[stateStride + CompositeStateOffset.InvMomentOfInertia] =
      this.infoArray[infoStride + CompositeInfoOffset.RotationLocked]
        ? 0
        : 1 / totalMomentOfInertia;
    this.stateArray[stateStride + CompositeStateOffset.LocalCentroidX] =
      finalCentroid.x;
    this.stateArray[stateStride + CompositeStateOffset.LocalCentroidY] =
      finalCentroid.y;
    const compositeOriginX =
      this.stateArray[stateStride + CompositeStateOffset.OriginX];
    const compositeOriginY =
      this.stateArray[stateStride + CompositeStateOffset.OriginY];
    const compositeCosRotation =
      this.stateArray[stateStride + CompositeStateOffset.CosRotation];
    const compositeSinRotation =
      this.stateArray[stateStride + CompositeStateOffset.SinRotation];

    const newPositionX =
      compositeOriginX +
      finalCentroid.x * compositeCosRotation -
      finalCentroid.y * compositeSinRotation;
    const newPositionY =
      compositeOriginY +
      finalCentroid.x * compositeSinRotation +
      finalCentroid.y * compositeCosRotation;

    this.stateArray[stateStride + CompositeStateOffset.PositionX] =
      newPositionX;
    this.stateArray[stateStride + CompositeStateOffset.PositionY] =
      newPositionY;
  }

  applyImpulse(impulse: Vector2D, r?: Vector2D): void {
    if (this.destroyed) {
      return;
    }

    const compositeStateStride = this.stateStrideIndex;
    const compositeInfoStride = this.infoStrideIndex;

    const positionLocked =
      this.infoArray[
        compositeInfoStride + CompositeInfoOffset.PositionLocked
      ] === 1;
    const rotationLocked =
      this.infoArray[
        compositeInfoStride + CompositeInfoOffset.RotationLocked
      ] === 1;

    if (!positionLocked) {
      this.stateArray[compositeStateStride + CompositeStateOffset.VelocityX] +=
        impulse.x *
        this.stateArray[compositeStateStride + CompositeStateOffset.InvMass];
      this.stateArray[compositeStateStride + CompositeStateOffset.VelocityY] +=
        impulse.y *
        this.stateArray[compositeStateStride + CompositeStateOffset.InvMass];
    }
    if (r && !rotationLocked) {
      const rCrossImpulse = r.x * impulse.y - r.y * impulse.x;
      this.stateArray[
        compositeStateStride + CompositeStateOffset.AngularVelocity
      ] +=
        rCrossImpulse *
        this.stateArray[
          compositeStateStride + CompositeStateOffset.InvMomentOfInertia
        ];
    }
  }

  applyForce(force: Vector2D, r?: Vector2D) {
    const compositeStateStride = this.stateStrideIndex;
    const compositeInfoStride = this.infoStrideIndex;

    const positionLocked =
      this.infoArray[
        compositeInfoStride + CompositeInfoOffset.PositionLocked
      ] === 1;
    const rotationLocked =
      this.infoArray[
        compositeInfoStride + CompositeInfoOffset.RotationLocked
      ] === 1;

    // Skip if position locked or mass is infinite/zero
    if (!positionLocked) {
      const linearAcc = force.scale(
        this.stateArray[compositeStateStride + CompositeStateOffset.InvMass]
      );
      this.stateArray[
        compositeStateStride + CompositeStateOffset.AccelerationX
      ] += linearAcc.x;
      this.stateArray[
        compositeStateStride + CompositeStateOffset.AccelerationY
      ] += linearAcc.y;
    }

    // Skip if rotation locked or moment of inertia is invalid
    if (r && !rotationLocked) {
      const torque = r.x * force.y - r.y * force.x;
      this.stateArray[
        compositeStateStride + CompositeStateOffset.AngularAcceleration
      ] +=
        torque *
        this.stateArray[
          compositeStateStride + CompositeStateOffset.InvMomentOfInertia
        ];
    }
  }

  applyTorque(torque: number, r?: Vector2D) {
    const compositeStateStride = this.stateStrideIndex;
    const compositeInfoStride = this.infoStrideIndex;

    const rotationLocked =
      this.infoArray[
        compositeInfoStride + CompositeInfoOffset.RotationLocked
      ] === 1;
    const positionLocked =
      this.infoArray[
        compositeInfoStride + CompositeInfoOffset.PositionLocked
      ] === 1;

    // Skip if rotation locked or moment of inertia is invalid
    if (!rotationLocked) {
      this.stateArray[
        compositeStateStride + CompositeStateOffset.AngularAcceleration
      ] +=
        torque *
        this.stateArray[
          compositeStateStride + CompositeStateOffset.InvMomentOfInertia
        ];
    }

    // Skip if position locked or mass is infinite/zero
    if (r && !positionLocked) {
      const rLength = r.length();
      if (rLength > 0) {
        // Calculate force magnitude and direction
        const forceMagnitude = torque / rLength;
        // Force perpendicular to r: rotate (x,y) by 90 degrees to (-y,x)
        const forceX = (-r.y / rLength) * forceMagnitude;
        const forceY = (r.x / rLength) * forceMagnitude;

        // Apply the linear acceleration
        const linearAcc = new Vector2D(forceX, forceY).scale(
          this.stateArray[compositeStateStride + CompositeStateOffset.InvMass]
        );
        this.stateArray[
          compositeStateStride + CompositeStateOffset.AccelerationX
        ] += linearAcc.x;
        this.stateArray[
          compositeStateStride + CompositeStateOffset.AccelerationY
        ] += linearAcc.y;
      }
    }
  }

  applyPositionCorrection(correction: Vector2D): void {
    const compositeStateStride = this.stateStrideIndex;

    this.stateArray[compositeStateStride + CompositeStateOffset.PositionX] +=
      correction.x *
      this.stateArray[compositeStateStride + CompositeStateOffset.InvMass];
    this.stateArray[compositeStateStride + CompositeStateOffset.PositionY] +=
      correction.y *
      this.stateArray[compositeStateStride + CompositeStateOffset.InvMass];
  }

  applyAngleCorrection(correction: number): void {
    const compositeStateStride = this.stateStrideIndex;

    this.stateArray[compositeStateStride + CompositeStateOffset.Rotation] +=
      correction *
      this.stateArray[
        compositeStateStride + CompositeStateOffset.InvMomentOfInertia
      ];
  }
}
