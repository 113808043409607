import type { Transform } from "../../math/transform.ts";
import { Vector2D } from "../../math/vector2D.ts";
import {
  SegmentType,
  type LineIntersection,
  type SegmentContributions,
  type OpenSegment,
  type CollisionContributions,
  type LineData,
  type AABB,
  type StoredPoint,
  StoredPointType,
} from "../../models.ts";

export class LineSegment implements OpenSegment {
  type: SegmentType.LINE = SegmentType.LINE;
  next: OpenSegment | null = null;
  previous: OpenSegment | null = null;
  start: Vector2D;
  end: Vector2D;
  length: number;
  contributions: SegmentContributions | null = null;

  storedPoints: StoredPoint[] = [];

  constructor(start: Vector2D, end: Vector2D, cloning: boolean = false) {
    this.start = start;
    this.end = end;
    if (cloning) {
      this.length = 0;
    } else {
      this.length = this.end.subtract(this.start).magnitude();
    }
  }

  getData(transform?: Transform): LineData {
    if (!transform) {
      return {
        type: SegmentType.LINE,
        start: this.start,
        end: this.end,
      };
    }

    return {
      type: SegmentType.LINE,
      start: transform.apply(this.start),
      end: transform.apply(this.end),
    };
  }

  getNearPointData(transform?: Transform): LineData {
    return this.getData(transform);
  }

  getAABB(): AABB {
    return {
      left: Math.min(this.start.x, this.end.x),
      right: Math.max(this.start.x, this.end.x),
      top: Math.min(this.start.y, this.end.y),
      bottom: Math.max(this.start.y, this.end.y),
    };
  }

  rayIntersectionCount(point: Vector2D): number {
    const startY = this.start.y;
    const endY = this.end.y;
    const startX = this.start.x;
    const endX = this.end.x;

    // Check if the line segment is horizontal
    if (Math.abs(startY - endY) < Number.EPSILON) {
      return 0; // Horizontal lines don't count as intersections
    }

    // Check if the point's y is within the line segment's y range
    if (
      (point.y < startY && point.y < endY) ||
      (point.y > startY && point.y > endY)
    ) {
      return 0;
    }

    // Calculate the x-coordinate of the intersection point
    const t = (point.y - startY) / (endY - startY);
    const intersectionX = startX + t * (endX - startX);

    // Check if the intersection point is to the right of the test point
    if (intersectionX > point.x) {
      // Special handling for when the ray passes through a vertex
      if (Math.abs(point.y - startY) < Number.EPSILON) {
        return endY > startY ? 1 : 0;
      }
      if (Math.abs(point.y - endY) < Number.EPSILON) {
        return endY > startY ? 1 : 0;
      }
      return 1;
    }

    return 0;
  }

  globalClone(transform: Transform, invert: boolean): LineSegment {
    const p1 = transform.apply(this.start);
    const p2 = transform.apply(this.end);

    if (!invert) {
      const segment = new LineSegment(p1, p2, true);
      segment.length = this.length;
      segment.storedPoints = [...this.storedPoints];

      return segment;
    } else {
      const segment = new LineSegment(p2, p1, true);
      segment.length = this.length;
      segment.storedPoints = this.storedPoints.map((p) => ({
        ...p,
        t: 1 - p.t,
      }));

      return segment;
    }
  }

  globalPartialClone(
    transform: Transform,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): LineSegment {
    const [[t1, t2], [start, end]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const tDiff = t2 - t1;

    if (!invert) {
      const segment = new LineSegment(
        start ?? transform.apply(this.start),
        end ?? transform.apply(this.end),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: (p.t - t1) / tDiff,
        }));
      return segment;
    } else {
      const segment = new LineSegment(
        end ?? transform.apply(this.end),
        start ?? transform.apply(this.start),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: 1 - (p.t - t1) / tDiff,
        }));
      return segment;
    }
  }

  partialClone(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): LineSegment {
    const [[t1, t2], [start, end]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const tDiff = t2 - t1;

    if (!invert) {
      const segment = new LineSegment(
        start ?? this.start.clone(),
        end ?? this.end.clone(),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: (p.t - t1) / tDiff,
        }));
      return segment;
    } else {
      const segment = new LineSegment(
        end ?? this.end.clone(),
        start ?? this.start.clone(),
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: 1 - (p.t - t1) / tDiff,
        }));
      return segment;
    }
  }

  clone(invert: boolean): LineSegment {
    const p1 = this.start.clone();
    const p2 = this.end.clone();

    if (!invert) {
      const segment = new LineSegment(p1, p2, true);
      segment.length = this.length;
      segment.storedPoints = [...this.storedPoints];
      return segment;
    } else {
      const segment = new LineSegment(p2, p1, true);
      segment.length = this.length;
      segment.storedPoints = this.storedPoints.map((p) => ({
        ...p,
        t: 1 - p.t,
      }));
      return segment;
    }
  }

  translate(dx: number, dy: number) {
    this.start.x += dx;
    this.start.y += dy;
    this.end.x += dx;
    this.end.y += dy;
    this.contributions = null;
  }

  getAllContributions(): SegmentContributions {
    if (this.contributions) {
      return this.contributions;
    }

    const area = (this.start.x * this.end.y - this.end.x * this.start.y) / 2;
    const centroid = new Vector2D(
      (this.start.x + this.end.x) / 3,
      (this.start.y + this.end.y) / 3
    );

    // Second moment of area about origin
    const secondMomentAboutOrigin =
      (area / 6) *
      (this.start.x * this.start.x +
        this.start.y * this.start.y + // (x₁² + y₁²)
        (this.start.x * this.end.x + this.start.y * this.end.y) + // (x₁x₂ + y₁y₂)
        (this.end.x * this.end.x + this.end.y * this.end.y)); // (x₂² + y₂²)

    // Use parallel axis theorem to get moment about centroid
    const secondMomentOfArea =
      secondMomentAboutOrigin -
      area * (centroid.x * centroid.x + centroid.y * centroid.y);

    this.contributions = {
      area,
      centroid,
      secondMomentOfArea,
    };

    return this.contributions;
  }

  getCollisionContributions(transform?: Transform): CollisionContributions {
    const start = transform?.apply(this.start) ?? this.start;
    const end = transform?.apply(this.end) ?? this.end;

    const area = (start.x * end.y - end.x * start.y) / 2;
    const centroid = new Vector2D((start.x + end.x) / 3, (start.y + end.y) / 3);

    return {
      area,
      centroid,
      points: [start, end],
    };
  }

  getPartialCollisionContributions(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    transform?: Transform
  ): CollisionContributions {
    const [[t1, t2], [startPoint, endPoint]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const start =
      startPoint ?? transform?.apply(this.start) ?? this.start.clone();
    const end = endPoint ?? transform?.apply(this.end) ?? this.end.clone();

    const area = (start.x * end.y - end.x * start.y) / 2;
    const centroid = new Vector2D((start.x + end.x) / 3, (start.y + end.y) / 3);

    return {
      area,
      centroid,
      points: [start, end],
    };
  }

  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D
  ): LineIntersection[] {
    const start = this.start;
    const end = this.end;

    const denominator =
      (lineEnd.y - lineStart.y) * (end.x - start.x) -
      (lineEnd.x - lineStart.x) * (end.y - start.y);

    if (Math.abs(denominator) < 1e-10) {
      return []; // Lines are parallel
    }

    const ua =
      ((lineEnd.x - lineStart.x) * (start.y - lineStart.y) -
        (lineEnd.y - lineStart.y) * (start.x - lineStart.x)) /
      denominator;

    const ub =
      ((end.x - start.x) * (start.y - lineStart.y) -
        (end.y - start.y) * (start.x - lineStart.x)) /
      denominator;

    // Check if the intersection is within the line segment
    if (ua < 0 || ua > 1) {
      return [];
    }

    const point = new Vector2D(
      start.x + ua * (end.x - start.x),
      start.y + ua * (end.y - start.y)
    );

    if (!point.isValid()) {
      return [];
    }

    return [
      {
        point,
        t: ub,
        segment: this,
      },
    ];
  }

  getPointAtDistance(distance: number): Vector2D | number {
    // If the line has zero length, return the start point for distance 0
    // or the full distance as remaining for any other distance
    if (this.length === 0) {
      return distance === 0 ? this.start : distance;
    }

    // If distance is greater than line length, return remaining distance
    if (distance > this.length) {
      return distance - this.length;
    }

    // Calculate the point using linear interpolation
    const t = distance / this.length;
    return new Vector2D(
      this.start.x + t * (this.end.x - this.start.x),
      this.start.y + t * (this.end.y - this.start.y)
    );
  }

  getClosestPoint(point: Vector2D): {
    point: Vector2D;
    tOrAngle: number;
  } {
    const ab = this.end.subtract(this.start);
    const ap = point.subtract(this.start);

    const abDotAb = ab.dot(ab);
    if (abDotAb === 0) {
      // The segment is a point
      return {
        point: this.start.clone(),
        tOrAngle: 0,
      };
    }

    // Calculate the projection scalar 't'
    let t = ap.dot(ab) / abDotAb;

    // Clamp 't' to the [0, 1] range to stay within the segment
    t = Math.max(0, Math.min(1, t));

    // Calculate the closest point Q = A + t * AB
    const closestPoint = this.start.add(ab.scale(t));

    return {
      point: closestPoint,
      tOrAngle: t,
    };
  }

  storePoint(storedPoint: StoredPoint): void {
    storedPoint.t = Math.max(0, Math.min(1, storedPoint.t));

    this.storedPoints.push(storedPoint);
  }

  getPointAtT(t: number): Vector2D | null {
    if (t < 0 || t > 1) return null;

    return new Vector2D(
      this.start.x + t * (this.end.x - this.start.x),
      this.start.y + t * (this.end.y - this.start.y)
    );
  }

  getStoredPoints(): StoredPoint[] {
    return this.storedPoints;
  }

  clearStoredMecPoints(): void {
    this.storedPoints = this.storedPoints.filter(
      (point) => point.type !== StoredPointType.MEC_POINT
    );
  }
}
