import type { WelzlPoint } from "../../geometry/welzl.ts";
import type { Transform } from "../../math/transform.ts";
import { normalizeAngle, TWO_PI } from "../../math/utils.ts";
import { Vector2D } from "../../math/vector2D.ts";
import {
  type Segment,
  SegmentType,
  type LineIntersection,
  type SegmentContributions,
  type CollisionContributions,
  type CircleData,
  type AABB,
  type StoredPoint,
  StoredPointType,
} from "../../models.ts";
import { ArcSegment } from "./arcSegment.ts";

export class CircleSegment implements Segment {
  type: SegmentType = SegmentType.CIRCLE;
  center: Vector2D;
  radius: number;
  clockwise: boolean;
  area: number;
  compositeSecondMomentOfArea: number;
  storedPoints: StoredPoint[] = [];
  contributions: SegmentContributions | null = null;

  constructor(center: Vector2D, radius: number, clockwise: boolean = false) {
    this.center = center;
    this.radius = radius;
    this.clockwise = clockwise;
    this.area = clockwise
      ? -Math.PI * radius * radius
      : Math.PI * radius * radius;
    this.compositeSecondMomentOfArea =
      0.5 * this.area * this.radius * this.radius;
  }

  getData(transform?: Transform): CircleData {
    if (!transform) {
      return {
        type: SegmentType.CIRCLE,
        center: this.center,
        radius: this.radius,
        clockwise: this.clockwise,
      };
    }

    return {
      type: SegmentType.CIRCLE,
      center: transform.apply(this.center),
      radius: this.radius,
      clockwise: this.clockwise,
    };
  }

  getNearPointData(transform?: Transform): CircleData {
    return this.getData(transform);
  }

  getAABB(): AABB {
    return {
      left: this.center.x - this.radius,
      right: this.center.x + this.radius,
      top: this.center.y - this.radius,
      bottom: this.center.y + this.radius,
    };
  }

  calculateCentralAngleFromAngles(
    startAngle: number,
    endAngle: number,
    clockwise: boolean
  ): number {
    let centralAngle: number;
    if (!clockwise) {
      if (startAngle < endAngle) {
        centralAngle = endAngle - startAngle;
      } else {
        centralAngle = TWO_PI - startAngle + endAngle;
      }
    } else {
      if (startAngle > endAngle) {
        centralAngle = startAngle - endAngle;
      } else {
        centralAngle = TWO_PI - endAngle + startAngle;
      }
    }

    return normalizeAngle(centralAngle);
  }

  globalClone(
    transform: Transform,
    invert: boolean
  ): ArcSegment | CircleSegment {
    const center = transform.apply(this.center);

    if (!invert) {
      const segment = new CircleSegment(center, this.radius, this.clockwise);
      // Copy all points as-is
      segment.storedPoints = [...this.storedPoints];
      return segment;
    } else {
      const segment = new CircleSegment(center, this.radius, !this.clockwise);
      // Invert the angles for stored points
      segment.storedPoints = this.storedPoints.map((p) => ({
        ...p,
        t: normalizeAngle(TWO_PI - p.t),
      }));
      return segment;
    }
  }

  globalPartialClone(
    transform: Transform,
    tValues: [[number, number], [Vector2D, Vector2D]],
    invert: boolean
  ): ArcSegment {
    // For circles, t values are angles in radians
    const [[t1, t2], [start, end]] = tValues;

    if (t1 < 0 || t1 > TWO_PI || t2 < 0 || t2 > TWO_PI) {
      throw new Error(`t values must be between 0 and 2π: ${t1} ${t2}`);
    }

    if (start === null || end === null) {
      throw new Error("start and end must be defined on circle segments");
    }

    const center = transform.apply(this.center);

    if (!invert) {
      const centralAngle = this.calculateCentralAngleFromAngles(
        t1,
        t2,
        this.clockwise
      );

      const segment = new ArcSegment(
        start,
        end,
        {
          center,
          radius: this.radius,
          startAngle: t1,
          centralAngle: this.clockwise ? -centralAngle : centralAngle,
        },
        false
      );

      // Convert stored points from circle angles to arc t-values
      segment.storedPoints = this.storedPoints
        .filter((p) => {
          if (!this.clockwise) {
            // Check if angle is between t1 and t2
            return (
              this.calculateCentralAngleFromAngles(t1, p.t, false) <=
              centralAngle
            );
          } else {
            // Check if angle is between t2 and t1 going clockwise
            return (
              this.calculateCentralAngleFromAngles(p.t, t1, true) <=
              centralAngle
            );
          }
        })
        .map((p) => ({
          ...p,
          t: this.clockwise
            ? 1 -
              this.calculateCentralAngleFromAngles(t1, p.t, true) / centralAngle
            : this.calculateCentralAngleFromAngles(t1, p.t, false) /
              centralAngle,
        }));

      return segment;
    } else {
      const centralAngle = this.calculateCentralAngleFromAngles(
        t2,
        t1,
        !this.clockwise
      );

      const segment = new ArcSegment(
        end,
        start,
        {
          center,
          radius: this.radius,
          startAngle: t2,
          centralAngle: this.clockwise ? -centralAngle : centralAngle,
        },
        false
      );

      // Convert stored points from circle angles to arc t-values
      segment.storedPoints = this.storedPoints
        .filter((p) => {
          if (this.clockwise) {
            // Check if angle is between t1 and t2
            return (
              this.calculateCentralAngleFromAngles(t2, p.t, false) <=
              centralAngle
            );
          } else {
            // Check if angle is between t2 and t1 going clockwise
            return (
              this.calculateCentralAngleFromAngles(p.t, t2, true) <=
              centralAngle
            );
          }
        })
        .map((p) => ({
          ...p,
          t: !this.clockwise
            ? 1 -
              this.calculateCentralAngleFromAngles(t2, p.t, true) / centralAngle
            : this.calculateCentralAngleFromAngles(t2, p.t, false) /
              centralAngle,
        }));

      return segment;
    }
  }

  partialClone(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): ArcSegment {
    // For circles, t values are angles in radians
    const [[t1, t2], [start, end]] = tValues;

    if (t1 < 0 || t1 > TWO_PI || t2 < 0 || t2 > TWO_PI) {
      throw new Error(`t values must be between 0 and 2π: ${t1} ${t2}`);
    }

    if (start === null || end === null) {
      throw new Error("start and end must be defined on circle segments");
    }

    if (!invert) {
      const centralAngle = this.calculateCentralAngleFromAngles(
        t1,
        t2,
        this.clockwise
      );

      const segment = new ArcSegment(
        start,
        end,
        {
          center: this.center.clone(),
          radius: this.radius,
          startAngle: t1,
          centralAngle: this.clockwise ? -centralAngle : centralAngle,
        },
        false
      );

      // Convert stored points from circle angles to arc t-values
      segment.storedPoints = this.storedPoints
        .filter((p) => {
          if (!this.clockwise) {
            // Check if angle is between t1 and t2
            return (
              this.calculateCentralAngleFromAngles(t1, p.t, false) <=
              centralAngle
            );
          } else {
            // Check if angle is between t2 and t1 going clockwise
            return (
              this.calculateCentralAngleFromAngles(p.t, t1, true) <=
              centralAngle
            );
          }
        })
        .map((p) => ({
          ...p,
          t: this.clockwise
            ? 1 -
              this.calculateCentralAngleFromAngles(t1, p.t, true) / centralAngle
            : this.calculateCentralAngleFromAngles(t1, p.t, false) /
              centralAngle,
        }));

      return segment;
    } else {
      const centralAngle = this.calculateCentralAngleFromAngles(
        t2,
        t1,
        !this.clockwise
      );

      const segment = new ArcSegment(
        end,
        start,
        {
          center: this.center.clone(),
          radius: this.radius,
          startAngle: t2,
          centralAngle: this.clockwise ? -centralAngle : centralAngle,
        },
        false
      );

      // Convert stored points from circle angles to arc t-values
      segment.storedPoints = this.storedPoints
        .filter((p) => {
          if (this.clockwise) {
            // Check if angle is between t1 and t2
            return (
              this.calculateCentralAngleFromAngles(t2, p.t, false) <=
              centralAngle
            );
          } else {
            // Check if angle is between t2 and t1 going clockwise
            return (
              this.calculateCentralAngleFromAngles(p.t, t2, true) <=
              centralAngle
            );
          }
        })
        .map((p) => ({
          ...p,
          t: !this.clockwise
            ? 1 -
              this.calculateCentralAngleFromAngles(t2, p.t, true) / centralAngle
            : this.calculateCentralAngleFromAngles(t2, p.t, false) /
              centralAngle,
        }));

      return segment;
    }
  }

  clone(invert: boolean): CircleSegment {
    if (!invert) {
      return new CircleSegment(
        this.center.clone(),
        this.radius,
        this.clockwise
      );
    } else {
      return new CircleSegment(
        this.center.clone(),
        this.radius,
        !this.clockwise
      );
    }
  }

  translate(dx: number, dy: number): void {
    this.center.x += dx;
    this.center.y += dy;
  }

  getAllContributions(): SegmentContributions {
    return {
      area: this.area,
      centroid: this.center,
      secondMomentOfArea: this.compositeSecondMomentOfArea,
    };
  }

  getCollisionContributions(transform?: Transform): CollisionContributions {
    return {
      area: this.area,
      centroid: transform?.apply(this.center) ?? this.center,
    };
  }

  getPartialCollisionContributions(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    transform?: Transform
  ): CollisionContributions {
    const [[startAngle, endAngle], [start, end]] = tValues;
    if (
      startAngle < 0 ||
      startAngle > TWO_PI ||
      endAngle < 0 ||
      endAngle > TWO_PI
    ) {
      throw new Error(
        `t values must be between 0 and 2π: ${startAngle} ${endAngle}`
      );
    }

    if (start === null || end === null) {
      throw new Error("start and end must be defined on circle t values");
    }

    const centralAngle = this.calculateCentralAngleFromAngles(
      startAngle,
      endAngle,
      this.clockwise
    );

    const center = transform ? transform.apply(this.center) : this.center;

    const arcMidpoint = new Vector2D(
      center.x + this.radius * Math.cos(startAngle + centralAngle / 2),
      center.y + this.radius * Math.sin(startAngle + centralAngle / 2)
    );
    const angleTerm = centralAngle - Math.sin(centralAngle);
    const arcArea = (angleTerm * this.radius * this.radius) / 2;

    const centroidDistance =
      (4 * this.radius * Math.sin(centralAngle / 2) ** 3) / (3 * angleTerm);

    const arcCentroid = new Vector2D(
      center.x + (arcMidpoint.x - center.x) * (centroidDistance / this.radius),
      center.y + (arcMidpoint.y - center.y) * (centroidDistance / this.radius)
    );

    const baseArea = (start.x * end.y - end.x * start.y) / 2;
    const baseCentroid = new Vector2D(
      (start.x + end.x) / 3,
      (start.y + end.y) / 3
    );

    const totalArea = baseArea + arcArea;
    const totalCentroid = new Vector2D(
      (baseCentroid.x * baseArea + arcCentroid.x * arcArea) / totalArea,
      (baseCentroid.y * baseArea + arcCentroid.y * arcArea) / totalArea
    );

    return {
      area: totalArea,
      centroid: totalCentroid,
      points: [start, end, arcMidpoint],
    };
  }

  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D
  ): LineIntersection[] {
    const center = this.center;

    // Convert line to parametric form: p = p0 + t(p1-p0)
    const dx = lineEnd.x - lineStart.x;
    const dy = lineEnd.y - lineStart.y;

    // Solve quadratic equation for intersection
    const a = dx * dx + dy * dy;
    const b =
      2 * (dx * (lineStart.x - center.x) + dy * (lineStart.y - center.y));
    const c =
      center.x * center.x +
      center.y * center.y +
      lineStart.x * lineStart.x +
      lineStart.y * lineStart.y -
      2 * (center.x * lineStart.x + center.y * lineStart.y) -
      this.radius * this.radius;

    const discriminant = b * b - 4 * a * c;

    if (discriminant < 0) {
      return []; // No intersections
    }

    const intersections: LineIntersection[] = [];
    const sqrtDiscriminant = Math.sqrt(discriminant);

    const t1 = (-b + sqrtDiscriminant) / (2 * a);
    const t2 = (-b - sqrtDiscriminant) / (2 * a);

    const point1 = new Vector2D(lineStart.x + t1 * dx, lineStart.y + t1 * dy);
    const point2 = new Vector2D(lineStart.x + t2 * dx, lineStart.y + t2 * dy);

    if (!point1.isValid() || !point2.isValid()) {
      return [];
    }

    intersections.push({
      point: point1,
      t: t1,
      segment: this,
    });

    intersections.push({
      point: point2,
      t: t2,
      segment: this,
    });

    return intersections;
  }

  rayIntersectionCount(point: Vector2D): number {
    // Early exit if the ray is too far above or below the circle
    const dy = point.y - this.center.y;
    if (Math.abs(dy) > this.radius) {
      return 0;
    }

    // Calculate the x-coordinates of intersections using the circle equation
    const dx = Math.sqrt(this.radius * this.radius - dy * dy);
    const x1 = this.center.x - dx;
    const x2 = this.center.x + dx;

    let count = 0;

    if (x1 >= point.x) {
      count++;
    }
    if (x2 >= point.x) {
      count++;
    }

    return count;
  }

  getPointAtDistance(distance: number): Vector2D | number {
    // Handle zero radius
    if (this.radius === 0) {
      return distance === 0 ? this.center : distance;
    }

    // Circumference = 2πr
    const circumference = TWO_PI * this.radius;

    // If distance is greater than circumference, return remaining distance
    if (distance > circumference) {
      return distance - circumference;
    }

    // Convert distance to angle
    // If clockwise, we need to subtract the angle since we're moving backwards
    const angle = this.clockwise
      ? normalizeAngle(-distance / this.radius)
      : normalizeAngle(distance / this.radius);

    // Get point on circle at this angle
    return new Vector2D(
      this.center.x + this.radius * Math.cos(angle),
      this.center.y + this.radius * Math.sin(angle)
    );
  }

  getClosestPoint(point: Vector2D): {
    point: Vector2D;
    tOrAngle: number;
  } {
    // Vector from center to the point
    const cp = point.subtract(this.center);
    const distance = cp.length();

    if (distance === 0) {
      // The point coincides with the center; return a default point on the circumference
      return {
        point: new Vector2D(this.center.x + this.radius, this.center.y),
        tOrAngle: 0,
      };
    }

    // Normalize the vector cp and scale it by the radius to get the closest point on the circle
    const closestPoint = this.center.add(cp.scale(this.radius / distance));

    // Calculate the angle in radians
    const angle = normalizeAngle(
      Math.atan2(closestPoint.y - this.center.y, closestPoint.x - this.center.x)
    );

    return {
      point: closestPoint,
      tOrAngle: angle,
    };
  }

  storePoint(storedPoint: StoredPoint): void {
    // Clamp tOrAngle to the [0, 2π] range
    storedPoint.t = Math.max(0, Math.min(TWO_PI, storedPoint.t));

    this.storedPoints.push(storedPoint);
  }

  getPointAtT(t: number): Vector2D | null {
    if (t < 0 || t > TWO_PI) return null;

    return new Vector2D(
      this.center.x + this.radius * Math.cos(t),
      this.center.y + this.radius * Math.sin(t)
    );
  }

  getStoredPoints(): StoredPoint[] {
    return this.storedPoints;
  }

  clearStoredMecPoints(): void {
    this.storedPoints = this.storedPoints.filter(
      (point) => point.type !== StoredPointType.MEC_POINT
    );
  }
}
