import type { Weld } from "../../plane/components/weld";
import type { Plane } from "../../plane/plane";
import {
  type Action,
  ActionType,
  type ActionUpdateInfo,
  type ActionResult,
} from "../action";
import type { Player } from "../player";
import { Body } from "../../plane/body";

export class Grab implements Action {
  type: ActionType = ActionType.Grab;
  player: Player;
  plane: Plane;
  cooldown: number = 0;
  reach: number = 0;
  body: Body;
  grabTriggerId: string | null = null;
  weld: Weld | null = null;

  constructor(player: Player, body: Body) {
    this.player = player;
    this.plane = player.plane;
    this.body = body;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    if (this.grabTriggerId || this.weld) {
      return { success: true, ongoing: true };
    }

    this.grabTriggerId = this.body.addCollisionTrigger(
      (otherBody, collision) => {
        if (this.body.composite !== null) {
          return;
        }

        this.body.setRotationLocked(false);

        const result = this.plane.composeBodies(otherBody, this.body);
        if (result) {
          this.weld = result;
        }
      }
    );

    return { success: true, ongoing: true };
  }

  hold(info: ActionUpdateInfo): ActionResult {
    if (!this.weld) {
      return { success: false, ongoing: true };
    }

    if (this.weld.destroyed) {
      this.weld = null;
      if (this.grabTriggerId) {
        this.body.removeCollisionTrigger(this.grabTriggerId);
      }
      this.grabTriggerId = null;
      return { success: false, ongoing: true };
    }

    return { success: true, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    this.cancel();
  }

  cancel(): void {
    if (this.grabTriggerId) {
      this.body.removeCollisionTrigger(this.grabTriggerId);
    }

    this.grabTriggerId = null;

    if (this.weld) {
      this.weld.break();
    }

    this.weld = null;

    this.body.setRotationLocked(true);
  }

  getDisplayText(): string {
    return `Grab`;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    return true;
  }
}
