import { Vector2D } from "../math/vector2D.ts";
import { type NetworkState, type Timer } from "../models.ts";
import type { Circle, CollisionInfo, LightSource } from "../models.ts";
import { Actuator } from "./components/actuator.ts";
import { Body } from "./body.ts";
import type { Camera } from "../rendering/camera.ts";
import { getActualCollisions, resolveCollisions } from "./collisionPipeline.ts";
import { BodyStore } from "./bodyStore.ts";
import type { CentroidTether } from "./components/centroidTether.ts";
import type { Thruster } from "./components/thruster.ts";
import { SurfacePointStore } from "./surfacePointStore.ts";
import type { Spring } from "./components/spring.ts";
import type { Pin } from "./components/pin.ts";
import type { Tether } from "./components/tether.ts";
import { Weld } from "./components/weld.ts";
import { BodyQuadtree, circlesIntersect } from "./bodyQuadtree.ts";

export const SurfacePointDataStride = 10;
export enum SurfacePointOffset {
  PositionX = 0,
  PositionY = 1,
  LocalX = 2,
  LocalY = 3,
  LocalRX = 4,
  LocalRY = 5,
  VelocityX = 6,
  VelocityY = 7,
  RX = 8,
  RY = 9,
}

export const BodyDataStride = 30;
export enum BodyOffset {
  PositionX = 0,
  PositionY = 1,
  Rotation = 2,
  SinRotation = 3,
  CosRotation = 4,
  VelocityX = 5,
  VelocityY = 6,
  AngularVelocity = 7,
  AccelerationX = 8,
  AccelerationY = 9,
  AngularAcceleration = 10,
  LocalMecPositionX = 11,
  LocalMecPositionY = 12,
  MecPositionX = 13,
  MecPositionY = 14,
  MecRadius = 15,
  TransformOffsetX = 16,
  TransformOffsetY = 17,
  LocalCentroidX = 18,
  LocalCentroidY = 19,
  Mass = 20,
  InvMass = 21,
  MomentOfInertia = 22,
  InvMomentOfInertia = 23,
  CompositeLocalX = 24,
  CompositeLocalY = 25,
  CompositeLocalRotation = 26,
  QuadtreeNodeX = 27,
  QuadtreeNodeY = 28,
  QuadtreeNodeSize = 29,
}

export const BodyInfoStride = 6;
export enum BodyInfoOffset {
  PositionLocked = 0,
  RotationLocked = 1,
  CompositeIndex = 2,
  InSubplane = 3,
  QuadtreeCheckType = 4,
  Active = 5,
}

export class Plane {
  bodyStates: Float32Array;
  bodyInfo: Uint16Array;
  bodyIds: Uint32Array;
  bodyCompositeIds: Uint32Array;
  surfacePointStates: Float32Array;

  isNetworkedClient: boolean;

  worldSize: number;

  idToBody: Map<number, Body> = new Map();

  bodyStore: BodyStore;
  surfacePointStore: SurfacePointStore;

  actuators: Actuator[] = [];
  centroidTethers: CentroidTether[] = [];
  pins: Pin[] = [];
  tethers: Tether[] = [];

  thrusters: Thruster[] = [];
  springs: Spring[] = [];

  private timers: Map<number, Timer>;
  private nextTimerId: number;
  resolvedCollisions: CollisionInfo[] = [];
  startMetric: (name: string) => void;
  endMetric: (name: string) => void;
  recordNumericMetric: (name: string, value: number) => void;

  constructor({
    maxBodies,
    worldSize,
    isNetworkedClient,
    startMetric,
    endMetric,
    recordNumericMetric,
  }: {
    maxBodies: number;
    worldSize: number;
    isNetworkedClient: boolean;
    startMetric: (name: string) => void;
    endMetric: (name: string) => void;
    recordNumericMetric: (name: string, value: number) => void;
  }) {
    this.surfacePointStates = new Float32Array(2000 * SurfacePointDataStride);
    this.timers = new Map();
    this.nextTimerId = 0;
    this.startMetric = startMetric;
    this.endMetric = endMetric;
    this.recordNumericMetric = recordNumericMetric;
    this.bodyStates = new Float32Array(maxBodies * BodyDataStride);
    this.bodyInfo = new Uint16Array(maxBodies * BodyInfoStride);
    this.bodyIds = new Uint32Array(maxBodies);
    this.bodyCompositeIds = new Uint32Array(maxBodies);
    this.worldSize = worldSize;
    this.isNetworkedClient = isNetworkedClient;
    this.bodyStore = new BodyStore({
      bodyStates: this.bodyStates,
      bodyInfo: this.bodyInfo,
      bodyIds: this.bodyIds,
      bodyCompositeIds: this.bodyCompositeIds,
      idToBody: this.idToBody,
      worldSize,
      isNetworkedClient,
    });
    this.surfacePointStore = new SurfacePointStore({
      surfacePointStates: this.surfacePointStates,
    });
  }

  loadActuator(actuator: Actuator): number {
    this.actuators.push(actuator);
    this.bodyStore.addIgnoredCollisionPair(
      actuator.base.index,
      actuator.endEffector.index
    );
    return this.actuators.length - 1;
  }

  update(
    dt: number,
    now: number,
    shouldResolveCollisions: boolean = true
  ): void {
    this.updateTimers(now);

    this.startMetric("applyForceConstraints");
    this.applyForceConstraints(dt);
    this.endMetric("applyForceConstraints");

    this.startMetric("bodyUpdate");
    const needsQuadtreeUpdate = this.bodyStore.update(dt);
    this.endMetric("bodyUpdate");

    this.startMetric("quadtreeUpdate");
    this.bodyStore.updateQuadtree(needsQuadtreeUpdate);
    this.endMetric("quadtreeUpdate");

    this.startMetric("surfacePointUpdate");
    this.surfacePointStore.update();
    this.endMetric("surfacePointUpdate");

    const bodiesWithPositionCorrections: Set<Body> = new Set();
    const triggers: (() => void)[] = [];

    this.startMetric("queryQuadtree");
    const possiblePairs = this.bodyStore.quadtree.getPossiblePairs();
    this.endMetric("queryQuadtree");
    this.recordNumericMetric("numPossibleMecPairs", possiblePairs.length);

    this.startMetric("intersectMecs");
    const intersectingPairs = possiblePairs.filter((pair) => {
      const body1 = pair.a.body;
      const body2 = pair.b.body;
      const body1Composite = body1.composite;
      const body2Composite = body2.composite;

      // Skip if bodyStates share a composite
      if (body1Composite !== null && body1Composite === body2Composite) {
        return false;
      }

      const body1InSubplane =
        this.bodyInfo[body1.infoStrideIndex + BodyInfoOffset.InSubplane] === 1;
      const body2InSubplane =
        this.bodyInfo[body2.infoStrideIndex + BodyInfoOffset.InSubplane] === 1;

      if (body1InSubplane !== body2InSubplane) {
        return false;
      }

      const circleA = pair.a.body.getMec();
      const circleB = pair.b.body.getMec();
      return circlesIntersect(circleA, circleB);
    });
    this.endMetric("intersectMecs");

    this.recordNumericMetric(
      "numIntersectingMecPairs",
      intersectingPairs.length
    );

    this.startMetric("filterAndBuildCollisionPairs");
    const potentialCollisions =
      this.bodyStore.getPotentialCollisions(intersectingPairs);
    this.endMetric("filterAndBuildCollisionPairs");

    this.startMetric("narrowPhase");
    const actualCollisions = getActualCollisions(
      potentialCollisions,
      this.startMetric,
      this.endMetric,
      this.recordNumericMetric
    );
    this.endMetric("narrowPhase");

    // Resolve collisions
    if (shouldResolveCollisions) {
      this.startMetric("resolveCollisions");
      this.resolvedCollisions = resolveCollisions(
        actualCollisions,
        bodiesWithPositionCorrections,
        triggers
      );
      this.endMetric("resolveCollisions");
    }

    this.startMetric("applyImpulseConstraints");
    this.applyImpulseConstraints(dt, bodiesWithPositionCorrections);
    this.endMetric("applyImpulseConstraints");

    // Correct positions
    for (const body of bodiesWithPositionCorrections) {
      body.applyPositionCorrections();
      body.applyAngleCorrections();
    }

    // Trigger callbacks
    for (const trigger of triggers) {
      trigger();
    }
  }

  initialBodyDistribution(maxIterations: number): void {
    let lastNumCollisions = 0;
    let numIterations = 0;
    for (let i = 0; i < maxIterations; i++) {
      numIterations++;
      this.bodyStore.quadtree = new BodyQuadtree(this.bodyStore.worldSize);

      for (const body of this.bodyStore.bodies) {
        if (body) {
          this.bodyStore.quadtree.insert({
            index: body.index,
            body,
            circle: body.getMec(),
          });
        }
      }

      const possiblePairs = this.bodyStore.quadtree.getPossiblePairs();

      const intersectingPairs = possiblePairs.filter((pair) => {
        const body1 = pair.a.body;
        const body2 = pair.b.body;
        const body1Composite = body1.composite;
        const body2Composite = body2.composite;

        // Skip if bodyStates share a composite
        if (body1Composite !== null && body1Composite === body2Composite) {
          return false;
        }

        const body1InSubplane =
          this.bodyInfo[body1.infoStrideIndex + BodyInfoOffset.InSubplane] ===
          1;
        const body2InSubplane =
          this.bodyInfo[body2.infoStrideIndex + BodyInfoOffset.InSubplane] ===
          1;

        if (body1InSubplane !== body2InSubplane) {
          return false;
        }

        const circleA = pair.a.body.getMec();
        const circleB = pair.b.body.getMec();
        return circlesIntersect(circleA, circleB);
      });

      const potentialCollisions =
        this.bodyStore.getPotentialCollisions(intersectingPairs);

      const actualCollisions = getActualCollisions(
        potentialCollisions,
        this.startMetric,
        this.endMetric,
        this.recordNumericMetric
      );
      lastNumCollisions = actualCollisions.length;

      // If no collisions, we're done
      if (actualCollisions.length === 0) {
        console.log("No collisions found after", i, "iterations");
        break;
      }

      // Process collisions by moving smaller bodies
      for (const collision of actualCollisions) {
        const body1 = collision.body1;
        const body2 = collision.body2;
        let body1Mass: number;
        if (body1.composite) {
          body1Mass = body1.composite.getMass();
        } else {
          body1Mass = body1.getMass();
        }
        let body2Mass: number;
        if (body2.composite) {
          body2Mass = body2.composite.getMass();
        } else {
          body2Mass = body2.getMass();
        }
        const smallerBody = body1Mass < body2Mass ? body1 : body2;

        // Move the smaller body to a random place in the world bounds
        const worldBounds = this.bodyStore.worldBounds;
        const randomX =
          Math.random() * (worldBounds.right - worldBounds.left) +
          worldBounds.left;
        const randomY =
          Math.random() * (worldBounds.bottom - worldBounds.top) +
          worldBounds.top;
        const randomRotation = Math.random() * 2 * Math.PI;

        if (smallerBody.composite) {
          smallerBody.composite.setPosition(
            new Vector2D(randomX, randomY),
            randomRotation
          );
        } else {
          smallerBody.setPosition(randomX, randomY, randomRotation);
        }
      }

      this.bodyStore.updateBodyInCompositePositions();
    }

    console.log(
      "Number of collisions after",
      numIterations,
      "iterations:",
      lastNumCollisions
    );
  }

  getAllBodies(): Body[] {
    const bodies: Body[] = [];
    for (const body of this.bodyStore.bodies) {
      if (body) {
        bodies.push(body);
      }
    }
    return bodies;
  }

  applyForceConstraints(dt: number): void {
    for (const thruster of this.thrusters) {
      thruster.apply(dt);
    }
    for (const spring of this.springs) {
      spring.apply(dt);
    }
    for (const actuator of this.actuators) {
      actuator.apply(dt);
    }
  }

  applyImpulseConstraints(
    dt: number,
    bodiesWithPositionCorrections: Set<Body>
  ): void {
    for (const tether of this.centroidTethers) {
      const correctedBodies = tether.apply();
      for (const body of correctedBodies) {
        bodiesWithPositionCorrections.add(body);
      }
    }
    for (const pin of this.pins) {
      const correctedBodies = pin.apply();
      for (const body of correctedBodies) {
        bodiesWithPositionCorrections.add(body);
      }
    }
    for (const tether of this.tethers) {
      const correctedBodies = tether.apply();
      for (const body of correctedBodies) {
        bodiesWithPositionCorrections.add(body);
      }
    }
  }

  updateTimers(now: number): void {
    const currentTime = now;
    for (const timer of this.timers.values()) {
      if (currentTime >= timer.nextTick) {
        timer.callback();
        if (timer.repeating) {
          timer.nextTick = currentTime + timer.interval;
        } else {
          this.timers.delete(timer.id);
        }
      }
    }
  }

  addTimer(
    callback: () => void,
    delay: number,
    repeating: boolean = false
  ): number {
    const id = this.nextTimerId++;
    this.timers.set(id, {
      id,
      callback,
      interval: delay,
      nextTick: performance.now() + delay,
      repeating,
    });
    return id;
  }

  removeTimer(id: number): void {
    this.timers.delete(id);
  }

  getRenderInfo(
    camera: Camera,
    margin: number = 0
  ): {
    mainPlaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
    subplaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
    lightSources: {
      light: LightSource;
      position: Vector2D;
    }[];
    actuators: Actuator[];
  } {
    const { mainPlaneBodies, subplaneBodies } =
      this.bodyStore.getBodiesVisibleToCamera(camera, margin);

    const lightSources: {
      light: LightSource;
      position: Vector2D;
    }[] = [];

    for (const body of mainPlaneBodies) {
      if (body.body.light) {
        lightSources.push({
          light: body.body.light,
          position: new Vector2D(body.mec.x, body.mec.y),
        });
      }
    }

    const actuators: Actuator[] = [];

    for (const actuator of this.actuators) {
      if (
        mainPlaneBodies.some(
          (body) =>
            body.body === actuator.base || body.body === actuator.endEffector
        )
      ) {
        actuators.push(actuator);
      }
    }

    return {
      mainPlaneBodies,
      subplaneBodies,
      lightSources,
      actuators,
    };
  }

  composeBodies(body1: Body, body2: Body): Weld | null {
    if (this.isNetworkedClient) {
      return null;
    }

    if (!body1.isLoaded() || !body2.isLoaded()) {
      return null;
    }

    const marginForClosestPointCheck = 100;

    const closestPoints = this.bodyStore.getClosestPointsBetweenBodies(
      body1,
      body2,
      marginForClosestPointCheck
    );

    if (!closestPoints) {
      console.error("No closest points found");
      return null;
    }

    const point1 = this.surfacePointStore.createSurfacePoint(
      closestPoints.point1
    );
    const point2 = this.surfacePointStore.createSurfacePoint(
      closestPoints.point2
    );

    if (!point1 || !point2) {
      return null;
    }

    const composite1 = body1.composite;
    const composite2 = body2.composite;

    // Case 1 & 2: Handle cases where at least one shape isn't in a composite
    if (!composite1 || !composite2 || composite1 === composite2) {
      if (!composite1 && !composite2) {
        const newComposite = this.bodyStore.addComposite(body1);

        if (newComposite === null) {
          return null;
        }

        newComposite.addBody(body2);
        return new Weld(point1, point2, newComposite);
      } else if (composite1 && !composite2) {
        composite1.addBody(body2);
        return new Weld(point1, point2, composite1);
      } else if (!composite1 && composite2) {
        composite2.addBody(body1);
        return new Weld(point2, point1, composite2);
      } else {
        throw new Error("Should not happen");
      }
    }

    console.error("Tried to merge composites, not implemented");

    return null;

    // // Case 3: Handle merging two composites
    // // Put all the indices of composite2 into an array for later
    // const composite2Indices = Array.from(composite2.bodies.values()).map(
    //   (b) => b.body.index
    // );

    // composite1.mergeComposite(composite2);
    // this.bodyStore.compositeBodies = this.bodyStore.compositeBodies.filter(
    //   (c) => c !== composite2
    // );
    // for (const index of composite2Indices) {
    //   const body = this.bodyStore.getBody(index);
    //   if (body) {
    //     body.composite = composite1;
    //   }
    // }

    // return new Weld(point1, point2, composite1);
  }
}
