import { PlanarClient } from "./planar/planarClient.ts";
import { generateVariety } from "./planar/planeInitUtil.ts";

export function setupLocalPlanar(canvas: HTMLCanvasElement): PlanarClient {
  const worldSize = 200000;

  const planar = new PlanarClient({
    canvas,
    worldSize,
  });

  planar.resolveCollisions = true;

  generateVariety(planar, {
    boundarySize: worldSize,
    smallCount: 1000,
    mediumCount: 0,
    largeCount: 0,
    asteroidCount: 8,
    noiseShapeCount: 100,
  });
  planar.plane.initialBodyDistribution(1000);

  planar.substeps = 2;

  // planar.makeEnemyBlob(80, new Vector2D(-10000, 10000), 1000);
  // planar.makeEnemyBlob(80, new Vector2D(10000, 10000), 1000);
  // planar.makeEnemyBlob(80, new Vector2D(-10000, -10000), 1000);

  return planar;
}
