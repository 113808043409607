import { Plane } from "../plane/plane.ts";
import { Player } from "./player.ts";
import { Body } from "../plane/body.ts";
import type { Camera } from "../rendering/camera.ts";
import { Spring } from "../plane/components/spring.ts";
import { Vector2D } from "../math/vector2D.ts";
import { Enemy } from "./enemy.ts";
import type { BodyState, PlayerEntityIds } from "../models.ts";

interface PerformanceMetric {
  name: string;
  lastTime: number;
  samples: number[];
  maxSamples: number;
  type: "time" | "numeric";
}

const METRIC_SAMPLE_SIZE = 60; // Store last 60 frames for averaging

const METRIC_NAMES = [
  "actualFrameTime",
  "frameTime",
  "planeUpdate",
  "substep",
  "bodyUpdate",
  "surfacePointUpdate",
  "quadtreeUpdate",
  "applyForceConstraints",
  "queryQuadtree",
  "intersectMecs",
  "filterAndBuildCollisionPairs",
  "narrowPhase",
  "findSegmentPairs",
  "intersectSegments",
  "checkEnclosure",
  "calculateIntersectionCollisionInfo",
  "calculateEnclosureCollisionInfo",
  "resolveCollisions",
  "applyImpulseConstraints",
  "render",
] as const;

const NUMERIC_METRIC_NAMES = [
  "numIntersectingMecPairs",
  "numPossibleMecPairs",
  "numIntersectionCollisions",
  "numPossibleEnclosureCollisions",
  "numSubsteps",
  "numEnclosureImpossibleCollisions",
  "numSegmentPairs",
] as const;

export abstract class PlanarBase {
  id: string;
  plane: Plane;

  bodies: Map<number, Body> = new Map();
  players: Map<string, Player> = new Map();

  enemies: Enemy[] = [];

  metrics: Map<string, PerformanceMetric> = new Map();

  constructor({
    id,
    maxBodies,
    worldSize,
    isNetworkedClient,
  }: {
    id: string;
    maxBodies: number;
    worldSize: number;
    isNetworkedClient: boolean;
  }) {
    this.id = id;
    this.plane = new Plane({
      maxBodies,
      worldSize,
      isNetworkedClient,
      startMetric: this.startMetric,
      endMetric: this.endMetric,
      recordNumericMetric: this.recordNumericMetric,
    });
    this.initializeMetrics([...METRIC_NAMES]);
    this.initializeMetrics([...NUMERIC_METRIC_NAMES], "numeric");
  }

  private initializeMetrics(
    metricNames: string[],
    type: "time" | "numeric" = "time"
  ): void {
    metricNames.forEach((name) => {
      this.metrics.set(name, {
        name,
        lastTime: 0,
        samples: [],
        maxSamples: METRIC_SAMPLE_SIZE,
        type,
      });
    });
  }

  startMetric = (name: string) => {
    const metric = this.metrics.get(name);
    if (metric) {
      metric.lastTime = performance.now();
    }
  };

  endMetric = (name: string, startTime?: number): void => {
    const metric = this.metrics.get(name);
    if (metric) {
      const duration = performance.now() - (startTime ?? metric.lastTime);
      metric.samples.push(duration);
      if (metric.samples.length > metric.maxSamples) {
        metric.samples.shift();
      }
    }
  };

  recordNumericMetric = (name: string, value: number): void => {
    const metric = this.metrics.get(name);
    if (metric && metric.type === "numeric") {
      metric.samples.push(value);
      if (metric.samples.length > metric.maxSamples) {
        metric.samples.shift();
      }
    }
  };

  getMetricsReport(camera: Camera): string {
    let report = "";

    // Add FPS at the top of the report
    const fps = this.calculateFPS();
    report += `Max FPS: ${fps.toFixed(1)}\n`;

    // Add camera position
    report += `Camera: (${camera.x.toFixed(1)}, ${camera.y.toFixed(1)})\n`;
    report += `Zoom: ${camera.zoom.toFixed(2)}x\n`;

    // Add shape counts
    report += `Number of Bodies: ${this.plane.bodyStore.count}\n`;

    // Add existing metrics
    this.metrics.forEach((metric) => {
      if (metric.samples.length > 0) {
        const avg =
          metric.samples.reduce((a, b) => a + b, 0) / metric.samples.length;
        report += `${metric.name}: ${avg.toFixed(2)}${
          metric.type === "time" ? "ms" : ""
        }\n`;
      }
    });
    return report;
  }

  private calculateFPS(): number {
    const frameTimeMetric = this.metrics.get("frameTime");
    if (!frameTimeMetric || frameTimeMetric.samples.length === 0) {
      return 0;
    }

    // Calculate average frame time from recent samples
    const avgFrameTime =
      frameTimeMetric.samples.reduce((a, b) => a + b, 0) /
      frameTimeMetric.samples.length;
    return 1000 / avgFrameTime; // Convert ms/frame to frames/second
  }

  unloadBody(id: number): void {
    this.plane.bodyStore.unloadBody(id);
  }

  deleteBody(id: number): void {
    this.plane.bodyStore.unloadBody(id);
    this.bodies.delete(id);
  }

  loadBody({ body, state }: { body: Body; state: BodyState }): void {
    const currentId = this.plane.idToBody.get(body.id);
    if (currentId !== undefined) {
      console.error("Body already loaded", body.id);
      return;
    }

    this.plane.bodyStore.loadBody({ body, state });
    return;
  }

  addBody = (body: Body) => {
    if (this.bodies.has(body.id)) {
      return;
    }

    this.bodies.set(body.id, body);
  };

  addNewPlayer(playerId: string, entityIds: PlayerEntityIds): Player {
    const player = new Player(playerId, this.plane, entityIds);

    this.players.set(player.id, player);

    this.addBody(player.abdomen);
    this.addBody(player.limb);
    this.loadBody({
      body: player.abdomen,
      state: {
        position: [0, 0, 0],
        velocity: [10, 10, 0.001],
        positionLocked: false,
        rotationLocked: false,
      },
    });
    this.loadBody({
      body: player.limb,
      state: {
        position: [0, 0, 0],
        velocity: [10, 10, 0.001],
        positionLocked: false,
        rotationLocked: true,
      },
    });

    this.plane.loadActuator(player.actuator);
    this.plane.centroidTethers.push(player.tether);
    this.plane.thrusters.push(player.thruster);

    return player;
  }

  makeEnemyBlob(size: number, center: Vector2D, radius: number): void {
    const enemiesInBlob = this.makeEnemies(size, center, radius);
    this.linkUpEnemies(enemiesInBlob);
  }

  makeEnemies(count: number, center: Vector2D, radius: number): Enemy[] {
    const enemies: Enemy[] = [];
    for (let i = 0; i < count; i++) {
      const enemy = new Enemy(this.plane);
      enemies.push(enemy);

      const { body, thruster } = enemy.getEntities();

      this.plane.bodyStore.loadBody({
        body,
        state: {
          position: [
            center.x + Math.random() * radius - radius / 2,
            center.y + Math.random() * radius - radius / 2,
            0,
          ],
          velocity: [0, 0, 0],
          positionLocked: false,
          rotationLocked: false,
        },
      });
      this.plane.thrusters.push(thruster);
      this.enemies.push(enemy);
    }

    return enemies;
  }

  linkUpEnemies(enemies: Enemy[]): void {
    for (const enemy of enemies) {
      const enemy1Position = enemy.body.getCenter();
      for (let i = 0; i < 2; i++) {
        // Get another random enemy
        const randomEnemy = enemies[Math.floor(Math.random() * enemies.length)];

        const enemy2Position = randomEnemy.body.getCenter();

        const surfacePoint1 = this.plane.surfacePointStore.createSurfacePoint({
          body: enemy.body,
          globalPosition: enemy1Position,
          perimeterPoint: {
            position: new Vector2D(0, 0),
            segment: enemy.body.shape.segments[0],
            segmentIndex: 0,
            tOrAngle: 0,
          },
        });

        const surfacePoint2 = this.plane.surfacePointStore.createSurfacePoint({
          body: randomEnemy.body,
          globalPosition: enemy2Position,
          perimeterPoint: {
            position: new Vector2D(0, 0),
            segment: randomEnemy.body.shape.segments[0],
            segmentIndex: 0,
            tOrAngle: 0,
          },
        });

        if (!surfacePoint1 || !surfacePoint2) {
          continue;
        }

        const spring = new Spring(
          surfacePoint1,
          surfacePoint2,
          surfacePoint1.getPosition()!.distanceTo(surfacePoint2.getPosition()!),
          20
        );

        this.plane.springs.push(spring);
      }
    }
  }
}
