import PartySocket from "partysocket";
import { ServerPacketType } from "./models";
import { BufferReader } from "./planar/bufferUtil";
import { PlanarClient } from "./planar/planarClient";

const timeoutMs = 10000;

export async function setupNetworkedPlanar(
  canvas: HTMLCanvasElement,
  room: string,
  partykit: boolean
): Promise<PlanarClient> {
  let socket: PartySocket;

  // if (partykit) {
  socket = new PartySocket({
    host: `${window.location.hostname}:${window.location.port}`,
    room: room,
    startClosed: true,
  });
  // } else {
  //   socket = new PartySocket({
  //     protocol: "ws",
  //     host: "localhost:8000",
  //     startClosed: true,
  //   });
  // }

  socket.binaryType = "arraybuffer";

  const initPromise = new Promise<PlanarClient>((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error("Planar initialization timeout"));
    }, timeoutMs);

    socket.onmessage = (event: MessageEvent) => {
      try {
        const data = event.data;

        if (typeof data === "string") {
          return;
        }

        const reader = new BufferReader(data);
        const messageType = reader.readUint8();

        if (messageType !== ServerPacketType.Welcome) {
          return;
        }

        const playerId = reader.readString();
        const entityIds = reader.readPlayerEntityIds();
        const worldSize = reader.readFloat32();
        const playerIds = {
          id: playerId,
          entityIds,
        };

        const sendToServer = (message: ArrayBuffer) => {
          socket.send(message);
        };

        const planar = new PlanarClient({
          canvas,
          worldSize,
          localPlayerIds: playerIds,
          sendToServer,
        });

        clearTimeout(timeout);
        resolve(planar);
      } catch (error) {
        clearTimeout(timeout);
        reject(error);
      }
    };

    socket.onerror = (error) => {
      clearTimeout(timeout);
      reject(error);
    };

    socket.onclose = () => {
      clearTimeout(timeout);
      reject(new Error("Connection closed during initialization"));
    };
  });

  socket.reconnect();

  const planar = await initPromise;

  socket.onmessage = (event: MessageEvent) => {
    planar.handleMessage(event.data);
  };

  socket.onerror = (error) => {
    console.error("Socket error:", error);
  };

  socket.onclose = () => {
    console.error("Socket connection closed");
  };

  return planar;
}
