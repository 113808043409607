import type { Transform } from "../../math/transform.ts";
import { normalizeAngle, TWO_PI } from "../../math/utils.ts";
import { Vector2D } from "../../math/vector2D.ts";
import {
  SegmentType,
  StoredPointType,
  type AABB,
  type ArcData,
  type ArcNearPointData,
  type CollisionContributions,
  type LineIntersection,
  type OpenSegment,
  type SegmentContributions,
  type StoredPoint,
} from "../../models.ts";
import type { WelzlPoint } from "../../geometry/welzl.ts";

export class ArcSegment implements OpenSegment {
  type: SegmentType.ARC = SegmentType.ARC;
  next: OpenSegment | null = null;
  previous: OpenSegment | null = null;
  start: Vector2D;
  end: Vector2D;
  length: number;
  sagitta: number;
  contributions: SegmentContributions | null = null;

  center: Vector2D;
  startAngle: number;
  endAngle: number;
  arcMidpoint: Vector2D;

  centralAngle: number;
  radius: number;
  clockwise: boolean;

  circleSegmentArea: number;
  circleSegmentCentroid: Vector2D;
  circleSegmentSecondMomentOfArea: number;

  aabb: AABB | null = null;

  storedPoints: StoredPoint[] = [];

  constructor(
    start: Vector2D,
    end: Vector2D,
    sagOrCircle:
      | number
      | {
          center: Vector2D;
          radius: number;
          startAngle: number;
          centralAngle: number;
        },
    cloning: boolean = false
  ) {
    this.start = start;
    this.end = end;
    if (!cloning) {
      const localDisplacement = end.subtract(start);
      this.length = localDisplacement.magnitude();
      if (typeof sagOrCircle === "number") {
        this.sagitta = sagOrCircle;
        const signedRadius =
          sagOrCircle / 2 + (this.length * this.length) / (8 * sagOrCircle);

        const perpX = -localDisplacement.y / this.length;
        const perpY = localDisplacement.x / this.length;

        this.arcMidpoint = new Vector2D(
          (start.x + end.x) / 2 - perpX * sagOrCircle,
          (start.y + end.y) / 2 - perpY * sagOrCircle
        );

        this.center = new Vector2D(
          this.arcMidpoint.x + perpX * signedRadius,
          this.arcMidpoint.y + perpY * signedRadius
        );

        this.radius = Math.abs(signedRadius);

        this.startAngle = normalizeAngle(
          Math.atan2(this.start.y - this.center.y, this.start.x - this.center.x)
        );

        const unsignedCentralAngle =
          Math.abs(sagOrCircle) > this.radius
            ? TWO_PI - 2 * Math.asin(this.length / (2 * this.radius))
            : 2 * Math.asin(this.length / (2 * this.radius));

        this.centralAngle =
          sagOrCircle < 0 ? -unsignedCentralAngle : unsignedCentralAngle;

        this.endAngle = normalizeAngle(this.startAngle + this.centralAngle);
        this.clockwise = this.centralAngle < 0;
      } else {
        this.center = sagOrCircle.center;
        this.radius = sagOrCircle.radius;
        this.startAngle = sagOrCircle.startAngle;
        this.centralAngle = sagOrCircle.centralAngle;
        this.endAngle = normalizeAngle(this.startAngle + this.centralAngle);
        this.clockwise = this.centralAngle < 0;
        this.arcMidpoint = new Vector2D(
          this.center.x +
            this.radius * Math.cos(this.startAngle + this.centralAngle / 2),
          this.center.y +
            this.radius * Math.sin(this.startAngle + this.centralAngle / 2)
        );
        this.sagitta = this.clockwise
          ? -this.radius * (1 - Math.cos(this.centralAngle / 2))
          : this.radius * (1 - Math.cos(this.centralAngle / 2));
      }
      const angleTerm = this.centralAngle - Math.sin(this.centralAngle);
      this.circleSegmentArea = (angleTerm * this.radius * this.radius) / 2;

      const centroidDistance =
        (4 * this.radius * Math.sin(this.centralAngle / 2) ** 3) /
        (3 * angleTerm);

      this.circleSegmentCentroid = new Vector2D(
        this.center.x +
          (this.arcMidpoint.x - this.center.x) *
            (centroidDistance / this.radius),
        this.center.y +
          (this.arcMidpoint.y - this.center.y) *
            (centroidDistance / this.radius)
      );
      this.circleSegmentSecondMomentOfArea =
        this.calculateCompositeSecondMomentOfArea();
    } else {
      if (typeof sagOrCircle === "number") {
        throw new Error("Cannot clone an arc segment with a sagitta");
      }
      this.center = sagOrCircle.center;
      this.radius = sagOrCircle.radius;
      this.startAngle = sagOrCircle.startAngle;
      this.centralAngle = sagOrCircle.centralAngle;
      this.endAngle = normalizeAngle(this.startAngle + this.centralAngle);
      this.clockwise = this.centralAngle < 0;
      this.sagitta = this.clockwise
        ? -this.radius * (1 - Math.cos(this.centralAngle / 2))
        : this.radius * (1 - Math.cos(this.centralAngle / 2));

      this.length = 0;
      this.arcMidpoint = new Vector2D(0, 0);
      this.circleSegmentArea = 0;
      this.circleSegmentCentroid = new Vector2D(0, 0);
      this.circleSegmentSecondMomentOfArea = 0;
    }
  }

  getData(transform?: Transform): ArcData {
    if (!transform) {
      return {
        type: SegmentType.ARC,
        center: this.center,
        radius: this.radius,
        startAngle: this.startAngle,
        endAngle: this.endAngle,
        centralAngle: this.centralAngle,
        clockwise: this.clockwise,
      };
    }

    return {
      type: SegmentType.ARC,
      center: transform.apply(this.center),
      radius: this.radius,
      startAngle: normalizeAngle(this.startAngle + transform.angle),
      endAngle: normalizeAngle(this.endAngle + transform.angle),
      centralAngle: this.centralAngle,
      clockwise: this.clockwise,
    };
  }

  getNearPointData(transform?: Transform): ArcNearPointData {
    if (!transform) {
      return {
        type: SegmentType.ARC,
        center: this.center,
        radius: this.radius,
        startAngle: this.startAngle,
        endAngle: this.endAngle,
        centralAngle: this.centralAngle,
        clockwise: this.clockwise,
        start: this.start,
        end: this.end,
      };
    }
    return {
      type: SegmentType.ARC,
      center: transform.apply(this.center),
      radius: this.radius,
      startAngle: normalizeAngle(this.startAngle + transform.angle),
      endAngle: normalizeAngle(this.endAngle + transform.angle),
      centralAngle: this.centralAngle,
      clockwise: this.clockwise,
      start: transform.apply(this.start),
      end: transform.apply(this.end),
    };
  }

  getAABB(): AABB {
    if (this.aabb) {
      return this.aabb;
    }

    // Initialize bounds with start and end points
    let left = Math.min(this.start.x, this.end.x);
    let right = Math.max(this.start.x, this.end.x);
    let top = Math.min(this.start.y, this.end.y);
    let bottom = Math.max(this.start.y, this.end.y);

    // Check each cardinal direction (right, top, left, bottom)
    const cardinalAngles = [0, Math.PI / 2, Math.PI, (3 * Math.PI) / 2];

    for (const angle of cardinalAngles) {
      if (this.angleIsWithinArc(angle, this.startAngle, this.endAngle)) {
        const x = this.center.x + this.radius * Math.cos(angle);
        const y = this.center.y + this.radius * Math.sin(angle);

        left = Math.min(left, x);
        right = Math.max(right, x);
        top = Math.min(top, y);
        bottom = Math.max(bottom, y);
      }
    }

    this.aabb = { left, right, top, bottom };
    return this.aabb;
  }

  globalClone(transform: Transform, invert: boolean): ArcSegment {
    const start = transform.apply(this.start);
    const end = transform.apply(this.end);
    const center = transform.apply(this.center);
    const circleSegmentCentroid = transform.apply(this.circleSegmentCentroid);
    const startAngle = normalizeAngle(this.startAngle + transform.angle);
    const endAngle = normalizeAngle(startAngle + this.centralAngle);
    const arcMidpoint = transform.apply(this.arcMidpoint);

    if (!invert) {
      const segment = new ArcSegment(
        start,
        end,
        {
          center,
          radius: this.radius,
          startAngle,
          centralAngle: this.centralAngle,
        },
        true
      );
      segment.length = this.length;
      segment.circleSegmentArea = this.circleSegmentArea;
      segment.circleSegmentCentroid = circleSegmentCentroid;
      segment.circleSegmentSecondMomentOfArea =
        this.circleSegmentSecondMomentOfArea;
      segment.arcMidpoint = arcMidpoint;
      segment.sagitta = this.sagitta;
      segment.storedPoints = [...this.storedPoints];
      return segment;
    } else {
      const segment = new ArcSegment(
        end,
        start,
        {
          center,
          radius: this.radius,
          startAngle: endAngle,
          centralAngle: -this.centralAngle,
        },
        true
      );
      segment.length = this.length;
      segment.circleSegmentArea = -this.circleSegmentArea;
      segment.circleSegmentCentroid = circleSegmentCentroid;
      segment.circleSegmentSecondMomentOfArea =
        -this.circleSegmentSecondMomentOfArea;
      segment.arcMidpoint = arcMidpoint;
      segment.sagitta = -this.sagitta;
      segment.storedPoints = this.storedPoints.map((p) => ({
        ...p,
        t: 1 - p.t,
      }));
      return segment;
    }
  }

  globalPartialClone(
    transform: Transform,
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): ArcSegment {
    const [[t1, t2], [startPoint, endPoint]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const start = transform.apply(this.start);
    const end = transform.apply(this.end);
    const center = transform.apply(this.center);
    const startAngle = normalizeAngle(this.startAngle + transform.angle);

    const tDiff = t2 - t1;

    if (!invert) {
      const segment = new ArcSegment(
        startPoint ?? start,
        endPoint ?? end,
        {
          center,
          radius: this.radius,
          startAngle: normalizeAngle(startAngle + t1 * this.centralAngle),
          centralAngle: this.centralAngle * tDiff,
        },
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: (p.t - t1) / tDiff,
        }));
      return segment;
    } else {
      const segment = new ArcSegment(
        endPoint ?? end,
        startPoint ?? start,
        {
          center,
          radius: this.radius,
          startAngle: normalizeAngle(startAngle + t2 * this.centralAngle),
          centralAngle: -this.centralAngle * tDiff,
        },
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: 1 - (p.t - t1) / tDiff,
        }));
      return segment;
    }
  }

  partialClone(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    invert: boolean
  ): ArcSegment {
    const [[t1, t2], [start, end]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const tDiff = t2 - t1;

    if (!invert) {
      const segment = new ArcSegment(
        start ?? this.start.clone(),
        end ?? this.end.clone(),
        {
          center: this.center.clone(),
          radius: this.radius,
          startAngle: normalizeAngle(this.startAngle + t1 * this.centralAngle),
          centralAngle: this.centralAngle * tDiff,
        },
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: (p.t - t1) / tDiff,
        }));
      return segment;
    } else {
      const segment = new ArcSegment(
        end ?? this.end.clone(),
        start ?? this.start.clone(),
        {
          center: this.center.clone(),
          radius: this.radius,
          startAngle: normalizeAngle(this.startAngle + t2 * this.centralAngle),
          centralAngle: -this.centralAngle * tDiff,
        },
        false
      );
      segment.storedPoints = this.storedPoints
        .filter((p) => p.t >= t1 && p.t <= t2)
        .map((p) => ({
          ...p,
          t: 1 - (p.t - t1) / tDiff,
        }));
      return segment;
    }
  }

  clone(invert: boolean): ArcSegment {
    if (!invert) {
      const segment = new ArcSegment(
        this.start.clone(),
        this.end.clone(),
        {
          center: this.center.clone(),
          radius: this.radius,
          startAngle: this.startAngle,
          centralAngle: this.centralAngle,
        },
        true
      );
      segment.length = this.length;
      segment.circleSegmentArea = this.circleSegmentArea;
      segment.circleSegmentCentroid = this.circleSegmentCentroid.clone();
      segment.circleSegmentSecondMomentOfArea =
        this.circleSegmentSecondMomentOfArea;
      segment.arcMidpoint = this.arcMidpoint.clone();
      segment.storedPoints = [...this.storedPoints];

      return segment;
    } else {
      const segment = new ArcSegment(
        this.end.clone(),
        this.start.clone(),
        {
          center: this.center.clone(),
          radius: this.radius,
          startAngle: this.endAngle,
          centralAngle: -this.centralAngle,
        },
        true
      );
      segment.length = this.length;
      segment.circleSegmentArea = -this.circleSegmentArea;
      segment.circleSegmentCentroid = this.circleSegmentCentroid.clone();
      segment.circleSegmentSecondMomentOfArea =
        -this.circleSegmentSecondMomentOfArea;
      segment.arcMidpoint = this.arcMidpoint.clone();
      segment.storedPoints = [...this.storedPoints];
      return segment;
    }
  }

  rayIntersectionCount(point: Vector2D): number {
    const center = this.center;

    // Early exit if the ray is too far above or below the circle
    const dy = point.y - center.y;
    if (Math.abs(dy) > this.radius) {
      return 0;
    }

    // Calculate the x-coordinates of intersections using the circle equation
    const dx = Math.sqrt(this.radius * this.radius - dy * dy);
    const x1 = center.x - dx;
    const x2 = center.x + dx;

    const angles: number[] = [];

    if (x1 >= point.x) {
      const angle = Math.atan2(dy, x1 - center.x);
      angles.push(normalizeAngle(angle));
    }
    if (x2 >= point.x) {
      const angle = Math.atan2(dy, x2 - center.x);
      angles.push(normalizeAngle(angle));
    }

    if (angles.length === 0) {
      return 0;
    }

    // Count regular intersections
    let count = 0;
    for (const angle of angles) {
      if (this.angleIsWithinArc(angle, this.startAngle, this.endAngle)) {
        count++;
      }
    }
    return count;
  }

  angleIsWithinArc(
    angle: number,
    startAngle: number,
    endAngle: number
  ): boolean {
    if (!this.clockwise) {
      if (startAngle < endAngle) {
        return angle >= startAngle && angle <= endAngle;
      } else {
        return angle >= startAngle || angle <= endAngle;
      }
    } else {
      if (startAngle > endAngle) {
        return angle <= startAngle && angle >= endAngle;
      } else {
        return angle <= startAngle || angle >= endAngle;
      }
    }
  }

  angleToTValue(
    angle: number,
    startAngle: number,
    endAngle: number,
    centralAngle: number
  ): number | null {
    const unsignedCentralAngle = Math.abs(centralAngle);
    if (centralAngle > 0) {
      if (startAngle < endAngle) {
        if (angle >= startAngle && angle <= endAngle) {
          return (angle - startAngle) / unsignedCentralAngle;
        }
        return null;
      } else {
        if (angle >= startAngle && angle <= TWO_PI) {
          return (angle - startAngle) / unsignedCentralAngle;
        }
        if (angle <= endAngle && angle >= 0) {
          return 1 - (endAngle - angle) / unsignedCentralAngle;
        }
        return null;
      }
    } else {
      if (startAngle > endAngle) {
        if (angle <= startAngle && angle >= endAngle) {
          return (startAngle - angle) / unsignedCentralAngle;
        }
        return null;
      } else {
        if (angle <= startAngle && angle >= 0) {
          return (startAngle - angle) / unsignedCentralAngle;
        }
        if (angle >= endAngle && angle <= TWO_PI) {
          return 1 - (angle - endAngle) / unsignedCentralAngle;
        }
        return null;
      }
    }
  }

  angleToTValueClamped(
    angle: number,
    startAngle: number,
    endAngle: number,
    centralAngle: number
  ): number {
    const unsignedCentralAngle = Math.abs(centralAngle);
    const normalizedAngle = normalizeAngle(angle);

    if (!this.clockwise) {
      if (startAngle < endAngle) {
        // Simple case: if angle is before start, return 0; if after end, return 1
        if (normalizedAngle < startAngle) return 0;
        if (normalizedAngle > endAngle) return 1;
        return (normalizedAngle - startAngle) / unsignedCentralAngle;
      } else {
        // Wrapping case
        if (normalizedAngle >= startAngle) {
          return (normalizedAngle - startAngle) / unsignedCentralAngle;
        }
        if (normalizedAngle <= endAngle) {
          return 1 - (endAngle - normalizedAngle) / unsignedCentralAngle;
        }
        // If in the gap, determine which end is closer
        const distToStart = Math.abs(
          normalizeAngle(normalizedAngle - startAngle)
        );
        const distToEnd = Math.abs(normalizeAngle(normalizedAngle - endAngle));
        return distToStart < distToEnd ? 0 : 1;
      }
    } else {
      if (startAngle > endAngle) {
        // Simple case: if angle is before end, return 1; if after start, return 0
        if (normalizedAngle > startAngle) return 0;
        if (normalizedAngle < endAngle) return 1;
        return (startAngle - normalizedAngle) / unsignedCentralAngle;
      } else {
        // Wrapping case
        if (normalizedAngle <= startAngle) {
          return (startAngle - normalizedAngle) / unsignedCentralAngle;
        }
        if (normalizedAngle >= endAngle) {
          return 1 - (normalizedAngle - endAngle) / unsignedCentralAngle;
        }
        // If in the gap, determine which end is closer
        const distToStart = Math.abs(
          normalizeAngle(normalizedAngle - startAngle)
        );
        const distToEnd = Math.abs(normalizeAngle(normalizedAngle - endAngle));
        return distToStart < distToEnd ? 0 : 1;
      }
    }
  }

  translate(dx: number, dy: number) {
    this.start.x += dx;
    this.start.y += dy;
    this.end.x += dx;
    this.end.y += dy;
    this.center.x += dx;
    this.center.y += dy;
    this.circleSegmentCentroid.x += dx;
    this.circleSegmentCentroid.y += dy;
    this.arcMidpoint.x += dx;
    this.arcMidpoint.y += dy;

    if (this.aabb) {
      this.aabb.left += dx;
      this.aabb.right += dx;
      this.aabb.top += dy;
      this.aabb.bottom += dy;
    }

    this.contributions = null;
  }

  getAllContributions(): SegmentContributions {
    if (this.contributions) {
      return this.contributions;
    }

    const baseArea =
      (this.start.x * this.end.y - this.end.x * this.start.y) / 2;
    const baseCentroid = new Vector2D(
      (this.start.x + this.end.x) / 3,
      (this.start.y + this.end.y) / 3
    );

    // Second moment of area about origin
    const baseSecondMomentAboutOrigin =
      (baseArea / 6) *
      (this.start.x * this.start.x +
        this.start.y * this.start.y + // (x₁² + y₁²)
        (this.start.x * this.end.x + this.start.y * this.end.y) + // (x₁x₂ + y₁y₂)
        (this.end.x * this.end.x + this.end.y * this.end.y)); // (x₂² + y₂²)

    // Use parallel axis theorem to get moment about centroid
    const baseSecondMomentOfArea =
      baseSecondMomentAboutOrigin -
      baseArea *
        (baseCentroid.x * baseCentroid.x + baseCentroid.y * baseCentroid.y);

    const arcArea = this.circleSegmentArea;
    const arcCentroid = this.circleSegmentCentroid;
    // About arc centroid
    const arcSecondMomentOfArea = this.circleSegmentSecondMomentOfArea;

    const totalArea = baseArea + arcArea;
    const totalCentroid = new Vector2D(
      (baseCentroid.x * baseArea + arcCentroid.x * arcArea) / totalArea,
      (baseCentroid.y * baseArea + arcCentroid.y * arcArea) / totalArea
    );

    const totalSecondMomentOfArea =
      // Start with base triangle moment about its centroid
      baseSecondMomentOfArea +
      // Shift base triangle moment to total centroid using parallel axis theorem
      baseArea * totalCentroid.subtract(baseCentroid).lengthSquared() +
      // Add arc segment moment about its centroid
      arcSecondMomentOfArea +
      // Shift arc segment moment to total centroid using parallel axis theorem
      arcArea * totalCentroid.subtract(arcCentroid).lengthSquared();

    this.contributions = {
      area: totalArea,
      centroid: totalCentroid,
      secondMomentOfArea: totalSecondMomentOfArea,
    };

    return this.contributions;
  }

  getCollisionContributions(transform?: Transform): CollisionContributions {
    const start = transform?.apply(this.start) ?? this.start;
    const end = transform?.apply(this.end) ?? this.end;
    const arcCentroid =
      transform?.apply(this.circleSegmentCentroid) ??
      this.circleSegmentCentroid;
    const arcMidpoint = transform?.apply(this.arcMidpoint) ?? this.arcMidpoint;

    const baseArea = (start.x * end.y - end.x * start.y) / 2;
    const baseCentroid = new Vector2D(
      (start.x + end.x) / 3,
      (start.y + end.y) / 3
    );

    const arcArea = this.circleSegmentArea;

    const totalArea = baseArea + arcArea;
    const totalCentroid = new Vector2D(
      (baseCentroid.x * baseArea + arcCentroid.x * arcArea) / totalArea,
      (baseCentroid.y * baseArea + arcCentroid.y * arcArea) / totalArea
    );

    return {
      area: totalArea,
      centroid: totalCentroid,
      points: [start, end, arcMidpoint],
    };
  }

  getPartialCollisionContributions(
    tValues: [[number, number], [Vector2D | null, Vector2D | null]],
    transform?: Transform
  ): CollisionContributions {
    const [[t1, t2], [startPoint, endPoint]] = tValues;
    if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
      throw new Error(`t values must be between 0 and 1: ${t1} ${t2}`);
    }

    if (t1 > t2) {
      throw new Error("t1 must be less than t2");
    }

    const start =
      startPoint ?? transform?.apply(this.start) ?? this.start.clone();
    const end = endPoint ?? transform?.apply(this.end) ?? this.end.clone();

    const center = transform ? transform.apply(this.center) : this.center;
    const startAngle = transform
      ? normalizeAngle(
          transform.angle + this.startAngle + t1 * this.centralAngle
        )
      : normalizeAngle(this.startAngle + t1 * this.centralAngle);
    const centralAngle = this.centralAngle * (t2 - t1);

    const arcMidpoint = new Vector2D(
      center.x + this.radius * Math.cos(startAngle + centralAngle / 2),
      center.y + this.radius * Math.sin(startAngle + centralAngle / 2)
    );
    const angleTerm = centralAngle - Math.sin(centralAngle);
    const arcArea = (angleTerm * this.radius * this.radius) / 2;

    const centroidDistance =
      (4 * this.radius * Math.sin(centralAngle / 2) ** 3) / (3 * angleTerm);

    const arcCentroid = new Vector2D(
      center.x + (arcMidpoint.x - center.x) * (centroidDistance / this.radius),
      center.y + (arcMidpoint.y - center.y) * (centroidDistance / this.radius)
    );

    const baseArea = (start.x * end.y - end.x * start.y) / 2;
    const baseCentroid = new Vector2D(
      (start.x + end.x) / 3,
      (start.y + end.y) / 3
    );

    const totalArea = baseArea + arcArea;
    const totalCentroid = new Vector2D(
      (baseCentroid.x * baseArea + arcCentroid.x * arcArea) / totalArea,
      (baseCentroid.y * baseArea + arcCentroid.y * arcArea) / totalArea
    );

    return {
      area: totalArea,
      centroid: totalCentroid,
      points: [start, end, arcMidpoint],
    };
  }

  calculateCompositeSecondMomentOfArea(): number {
    // Calculate moment of inertia for the circular segment about circle center
    const theta = Math.abs(this.centralAngle);
    const sinTheta = Math.sin(theta);
    const sinHalfTheta = Math.sin(theta / 2);

    // Using the formula for Iz about circle center
    const segmentMomentAboutCenter =
      (this.radius ** 4 / 4) *
      (theta - sinTheta + (2 / 3) * sinTheta * sinHalfTheta * sinHalfTheta);

    // Get the vector from circle center to arc centroid
    const centerToCentroid = this.circleSegmentCentroid.subtract(this.center);

    // Apply parallel axis theorem to shift to arc centroid
    const segmentMomentAboutCentroid =
      segmentMomentAboutCenter -
      Math.abs(this.circleSegmentArea) * centerToCentroid.lengthSquared();

    // If the central angle is negative, the moment should be negative
    return this.centralAngle < 0
      ? -segmentMomentAboutCentroid
      : segmentMomentAboutCentroid;
  }

  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D
  ): LineIntersection[] {
    const center = this.center;
    const startAngle = this.startAngle;
    const endAngle = this.endAngle;

    // First find circle intersections
    const dx = lineEnd.x - lineStart.x;
    const dy = lineEnd.y - lineStart.y;

    const a = dx * dx + dy * dy;
    const b =
      2 * (dx * (lineStart.x - center.x) + dy * (lineStart.y - center.y));
    const c =
      center.x * center.x +
      center.y * center.y +
      lineStart.x * lineStart.x +
      lineStart.y * lineStart.y -
      2 * (center.x * lineStart.x + center.y * lineStart.y) -
      this.radius * this.radius;

    const discriminant = b * b - 4 * a * c;

    if (discriminant < 0) {
      return []; // No intersections
    }

    const intersections: LineIntersection[] = [];
    const sqrtDiscriminant = Math.sqrt(discriminant);

    const t1 = (-b + sqrtDiscriminant) / (2 * a);
    const t2 = (-b - sqrtDiscriminant) / (2 * a);

    // Check each intersection point to see if it lies on the arc
    const point1 = new Vector2D(lineStart.x + t1 * dx, lineStart.y + t1 * dy);
    if (!point1.isValid()) {
      return [];
    }
    const angle1 = normalizeAngle(
      Math.atan2(point1.y - center.y, point1.x - center.x)
    );

    if (this.angleIsWithinArc(angle1, startAngle, endAngle)) {
      intersections.push({
        point: point1,
        t: t1,
        segment: this,
      });
    }

    const point2 = new Vector2D(lineStart.x + t2 * dx, lineStart.y + t2 * dy);
    if (!point2.isValid()) {
      return [];
    }
    const angle2 = normalizeAngle(
      Math.atan2(point2.y - center.y, point2.x - center.x)
    );

    if (this.angleIsWithinArc(angle2, startAngle, endAngle)) {
      intersections.push({
        point: point2,
        t: t2,
        segment: this,
      });
    }

    return intersections;
  }

  getPointAtDistance(distance: number): Vector2D | number {
    // Handle zero radius
    if (this.radius === 0) {
      return distance === 0 ? this.center : distance;
    }

    // Arc length = radius * central angle
    const arcLength = Math.abs(this.radius * this.centralAngle);

    // If distance is greater than arc length, return remaining distance
    if (distance > arcLength) {
      return distance - arcLength;
    }

    // Convert distance to angle
    // If clockwise, we need to subtract the angle since we're moving backwards
    const angleFromStart = this.clockwise
      ? -(distance / this.radius)
      : distance / this.radius;

    // Calculate the actual angle
    const angle = normalizeAngle(this.startAngle + angleFromStart);

    // Get point on arc at this angle
    return new Vector2D(
      this.center.x + this.radius * Math.cos(angle),
      this.center.y + this.radius * Math.sin(angle)
    );
  }

  getClosestPoint(point: Vector2D): {
    point: Vector2D;
    tOrAngle: number;
  } {
    // Vector from center to the point
    const cp = point.subtract(this.center);
    const distance = cp.length();

    if (distance === 0) {
      // The point coincides with the center; return a default point on the circumference
      return {
        point: new Vector2D(this.center.x + this.radius, this.center.y),
        tOrAngle: 0,
      };
    }

    // Normalize the vector cp and scale it by the radius to get the projected point Q'
    const qPrime = this.center.add(cp.scale(this.radius / distance));

    // Calculate the angle of Q' relative to the center
    let angleQPrime = Math.atan2(
      qPrime.y - this.center.y,
      qPrime.x - this.center.x
    );
    angleQPrime = normalizeAngle(angleQPrime);

    // Determine if Q' lies within the arc
    const isQPrimeOnArc = this.isAngleWithinArc(angleQPrime);

    if (isQPrimeOnArc) {
      // Q' is within the arc
      return {
        point: qPrime,
        tOrAngle: this.angleToTValueClamped(
          angleQPrime,
          this.startAngle,
          this.endAngle,
          this.centralAngle
        ),
      };
    } else {
      // Q' is outside the arc; return the closer endpoint to P
      const distanceToStart = point.subtract(this.start).length();
      const distanceToEnd = point.subtract(this.end).length();

      return distanceToStart < distanceToEnd
        ? {
            point: this.start.clone(),
            tOrAngle: 0,
          }
        : {
            point: this.end.clone(),
            tOrAngle: 1,
          };
    }
  }

  storePoint(storedPoint: StoredPoint): void {
    this.storedPoints.push(storedPoint);
  }

  /**
   * Checks if a given angle lies within the arc's angular bounds.
   *
   * @param {number} angle - The angle to check, in radians.
   * @returns {boolean} - True if the angle is within the arc; otherwise, false.
   */
  private isAngleWithinArc(angle: number): boolean {
    const start = normalizeAngle(this.startAngle);
    const end = normalizeAngle(this.endAngle);
    const target = normalizeAngle(angle);

    if (!this.clockwise) {
      if (start < end) {
        return target >= start && target <= end;
      } else {
        return target >= start || target <= end;
      }
    } else {
      if (start > end) {
        return target <= start && target >= end;
      } else {
        return target <= start || target >= end;
      }
    }
  }

  getPointAtT(t: number): Vector2D | null {
    if (t < 0 || t > 1) return null;

    const angle = this.startAngle + t * this.centralAngle;
    return new Vector2D(
      this.center.x + this.radius * Math.cos(angle),
      this.center.y + this.radius * Math.sin(angle)
    );
  }

  getStoredPoints(): StoredPoint[] {
    return this.storedPoints;
  }

  clearStoredMecPoints(): void {
    this.storedPoints = this.storedPoints.filter(
      (point) => point.type !== StoredPointType.MEC_POINT
    );
  }

  getMecPoints(): WelzlPoint[] {
    // Constants for MEC point generation
    const MIN_RADIUS_RATIO = 1.0;
    const MAX_RADIUS_RATIO = 1.05;
    const MIN_POINTS = 3; // Minimum number of internal points
    const MAX_POINTS = 12; // Maximum number of internal points

    // Calculate scaling factor based on central angle (0 to 1)
    const scaleFactor = Math.abs(this.centralAngle) / TWO_PI;

    // Scale both radius ratio and number of points
    const radiusRatio =
      MIN_RADIUS_RATIO + scaleFactor * (MAX_RADIUS_RATIO - MIN_RADIUS_RATIO);
    const numPoints = Math.round(
      MIN_POINTS + scaleFactor * (MAX_POINTS - MIN_POINTS)
    );

    // Calculate the scaled radius
    const mecRadius = this.radius * radiusRatio;

    // Create array to store points
    const points: WelzlPoint[] = [];

    // Calculate angle step size
    // Now we divide by (numPoints + 1) to create numPoints internal divisions
    const angleStep = this.centralAngle / (numPoints + 1);

    // Generate points along the arc, starting after start and ending before end
    for (let i = 1; i <= numPoints; i++) {
      const angle = this.startAngle + i * angleStep;
      points.push({
        x: this.center.x + mecRadius * Math.cos(angle),
        y: this.center.y + mecRadius * Math.sin(angle),
        segment: this,
        t: i / numPoints,
      });
    }

    return points;
  }
}
