import type { SurfacePoint } from "../surfacePoint.ts";
import type { Joint } from "../../models.ts";
import type { Body } from "../body.ts";
export class Spring implements Joint {
  pointA: SurfacePoint;
  pointB: SurfacePoint;
  private restLength: number;
  private springConstant: number;
  private destroyed: boolean = false;

  constructor(
    pointA: SurfacePoint,
    pointB: SurfacePoint,
    restLength: number,
    springConstant: number
  ) {
    this.pointA = pointA;
    this.pointB = pointB;
    // Calculate initial distance between points as rest length
    this.restLength = restLength;
    this.springConstant = springConstant;

    this.pointA.joint = this;
    this.pointB.joint = this;
  }

  apply(dt: number): void {
    if (this.destroyed) {
      return;
    }

    const pointA = this.pointA.getPosition();
    const pointB = this.pointB.getPosition();

    if (!pointA || !pointB) {
      return;
    }

    // Calculate current displacement vector
    const displacement = pointB.subtract(pointA);
    const currentLength = displacement.magnitude();

    // Skip if points are in the same position to avoid division by zero
    if (currentLength === 0) return;

    // Calculate spring force magnitude using Hooke's Law
    const stretch = currentLength - this.restLength;
    const forceMagnitude = this.springConstant * stretch;

    // Calculate unit vector for force direction
    const direction = displacement.scale(1 / currentLength);

    // // Calculate damping force based on relative velocity
    // const relativeVelocity = this.pointB.velocity.subtract(
    //   this.pointA.velocity
    // );
    // const dampingForce = relativeVelocity.scale(this.damping);

    // // // Combine spring and damping forces
    // const totalForce = direction.scale(forceMagnitude).add(dampingForce);

    const totalForce = direction.scale(forceMagnitude);

    // Apply equal and opposite forces to both points
    this.pointA.applyForce(totalForce);
    this.pointB.applyForce(totalForce.scale(-1));
  }

  pointDestroy(point: SurfacePoint): void {
    this.destroyed = true;
  }

  pointTransfer(point: SurfacePoint, oldBody: Body, newBody: Body): void {}

  render(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    const pos1 = this.pointA.getPosition();
    const pos2 = this.pointB.getPosition();

    if (pos1 === null || pos2 === null) {
      return;
    }

    ctx.strokeStyle = "red";
    ctx.lineWidth = 10;
    ctx.beginPath();
    ctx.moveTo(pos1.x, pos1.y);
    ctx.lineTo(pos2.x, pos2.y);
    ctx.stroke();
  }
}
