import type { Vector2D } from "../../math/vector2D";
import type { Plane } from "../../plane/plane";
import type { SurfacePoint } from "../../plane/surfacePoint";
import { VertexShapeBuilder } from "../../shapes/builders/vertexShapeBuilder";
import {
  type Action,
  ActionType,
  type SelectPointConfig,
  type ActionUpdateInfo,
  type ActionResult,
} from "../action";
import type { Player } from "../player";
import { Body } from "../../plane/body";

export class Cut implements Action {
  type: ActionType = ActionType.Cut;
  plane: Plane;
  player: Player;
  cooldown: number = 0;
  cutRadius: number;
  cutCenter: SurfacePoint | null = null;
  cutDuration: number;
  selectPointConfig: SelectPointConfig = {
    reach: 250,
    includeAbdomen: false,
    eligibilityCheck: (body: Body) => body.material.destructible,
  };

  constructor({
    player,
    cutRadius,
    cutDuration,
  }: {
    player: Player;
    cutRadius: number;
    cutDuration: number;
  }) {
    this.player = player;
    this.plane = player.plane;
    this.cutRadius = cutRadius;
    this.cutDuration = cutDuration;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.cutCenter = surfacePoint;

    return { success: true, ongoing: true };
  }

  hold(info: ActionUpdateInfo): ActionResult {
    if (!this.cutCenter) return { success: false, ongoing: false };

    const cutCenterInfo = this.cutCenter.getPositionAndBody();

    if (!cutCenterInfo) {
      this.cutCenter = null;
      return { success: false, ongoing: false };
    }

    // Check if the point is still in reach
    if (
      cutCenterInfo.position.distanceTo(info.limbPosition) >
      this.selectPointConfig.reach
    ) {
      this.cutCenter.destroy();
      this.cutCenter = null;
      return { success: false, ongoing: false };
    }

    // If the cut duration has passed, perform the cut
    if (info.holdTime > this.cutDuration) {
      this.cutCenter.destroy();

      const cutShape = VertexShapeBuilder.circle(this.cutRadius);

      this.plane.bodyStore.cut(
        cutCenterInfo.body,
        cutShape,
        cutCenterInfo.position
      );

      this.cutCenter = null;
      return { success: true, ongoing: false };
    }

    return { success: true, ongoing: true };
  }

  cancel(): void {
    if (!this.cutCenter) return;
    this.cutCenter.destroy();
    this.cutCenter = null;
  }

  release(info: ActionUpdateInfo): void {
    this.cancel();
  }

  getDisplayText(): string {
    return `Cut (radius: ${this.cutRadius})`;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    let renderProgress = false;
    let cutLocation: {
      position: Vector2D;
      body: Body;
    } | null = null;

    if (this.cutCenter) {
      cutLocation = this.cutCenter.getPositionAndBody();
      renderProgress = true;
    } else if (info.selectPoint) {
      cutLocation = {
        body: info.selectPoint.pointOnSurface.body,
        position: info.selectPoint.pointOnSurface.globalPosition,
      };
    }

    if (!cutLocation) {
      return true;
    }

    const path = cutLocation.body.shape.getPath();
    const transform = cutLocation.body.getTransform();

    ctx.save();

    ctx.translate(transform.x, transform.y);
    ctx.rotate(transform.angle);
    ctx.clip(path);

    ctx.rotate(-transform.angle);
    ctx.translate(-transform.x, -transform.y);

    if (renderProgress) {
      // Draw the progress circle if we're currently cutting
      const progress = info.holdTime / this.cutDuration;
      const currentRadius = Math.min(progress, 1) * this.cutRadius;

      if (currentRadius > 0) {
        ctx.beginPath();
        ctx.arc(
          cutLocation.position.x,
          cutLocation.position.y,
          currentRadius,
          0,
          Math.PI * 2
        );
        ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
        ctx.fill();
      }
    }

    // Draw the cutting circle outline
    ctx.beginPath();
    ctx.arc(
      cutLocation.position.x,
      cutLocation.position.y,
      this.cutRadius,
      0,
      Math.PI * 2
    );
    ctx.strokeStyle = "white";
    ctx.lineWidth = 10;
    ctx.stroke();

    ctx.restore();

    return false;
  }
}
