import type { Transform } from "../math/transform.ts";
import { normalizeAngle, TWO_PI } from "../math/utils.ts";
import { Vector2D } from "../math/vector2D.ts";
import {
  EnclosureType,
  SegmentType,
  type ArcData,
  type Circle,
  type CircleData,
  type IntersectionPoint,
  type LineData,
  type SegmentInfo,
} from "../models.ts";
import { Shape } from "../shapes/shape.ts";

const EPSILON = 1e-10;

function isValidT(t: number): boolean {
  if (!Number.isNaN(t) && Number.isFinite(t)) {
    if (Math.abs(t) < EPSILON) return false;
    if (Math.abs(t - 1) < EPSILON) return false;
    return true;
  }
  return false;
}

function isValidAngle(angle: number): boolean {
  if (!Number.isNaN(angle) && Number.isFinite(angle)) {
    // Check if angle is too close to 0 or 2π
    if (Math.abs(angle) < EPSILON) return false;
    if (Math.abs(angle - 2 * Math.PI) < EPSILON) return false;
    return true;
  }
  return false;
}

export function findShapeEnclosure(
  shape1: Shape,
  shape2: Shape,
  transform1: Transform,
  transform2: Transform
): EnclosureType {
  if (shape1.area > shape2.area) {
    const perimeterPoint = shape2.getPointOnPerimeter(transform2);

    if (shape1.containsPoint(perimeterPoint, transform1)) {
      return EnclosureType.FirstBodyEncloses;
    }
    return EnclosureType.None;
  } else {
    const perimeterPoint = shape1.getPointOnPerimeter(transform1);

    if (shape2.containsPoint(perimeterPoint, transform2)) {
      return EnclosureType.SecondBodyEncloses;
    }
    return EnclosureType.None;
  }
}

export function findShapeIntersections(
  segmentPairs: [SegmentInfo, SegmentInfo][],
  relativeTransform: Transform
): {
  intersections: [IntersectionPoint[], IntersectionPoint[]] | null;
  segmentCheckCount: number;
  segmentIntersectionCount: number;
} {
  const intersections1: IntersectionPoint[] = [];
  const intersections2: IntersectionPoint[] = [];

  let segmentCheckCount = 0;

  for (const [segment1Info, segment2Info] of segmentPairs) {
    const segment1 = segment1Info.segment.getData(relativeTransform);
    const segmentIndex1 = segment1Info.index;
    const segment2 = segment2Info.segment.getData();
    const segmentIndex2 = segment2Info.index;

    segmentCheckCount++;

    if (
      segment1.type === SegmentType.LINE &&
      segment2.type === SegmentType.LINE
    ) {
      findLineIntersectionPoints(
        segment1 as LineData,
        segment2 as LineData,
        segmentIndex1,
        segmentIndex2,
        intersections1,
        intersections2
      );
    } else if (
      segment1.type === SegmentType.LINE &&
      segment2.type === SegmentType.ARC
    ) {
      findArcLineIntersectionPoints(
        segment2 as ArcData,
        segment1 as LineData,
        segmentIndex2,
        segmentIndex1,
        intersections2,
        intersections1
      );
    } else if (
      segment1.type === SegmentType.ARC &&
      segment2.type === SegmentType.LINE
    ) {
      findArcLineIntersectionPoints(
        segment1 as ArcData,
        segment2 as LineData,
        segmentIndex1,
        segmentIndex2,
        intersections1,
        intersections2
      );
    } else if (
      segment1.type === SegmentType.ARC &&
      segment2.type === SegmentType.ARC
    ) {
      findArcIntersectionPoints(
        segment1 as ArcData,
        segment2 as ArcData,
        segmentIndex1,
        segmentIndex2,
        intersections1,
        intersections2
      );
    } else if (
      segment1.type === SegmentType.ARC &&
      segment2.type === SegmentType.CIRCLE
    ) {
      findCircleArcIntersectionPoints(
        segment2 as CircleData,
        segment1 as ArcData,
        segmentIndex2,
        segmentIndex1,
        intersections2,
        intersections1
      );
    } else if (
      segment1.type === SegmentType.CIRCLE &&
      segment2.type === SegmentType.ARC
    ) {
      findCircleArcIntersectionPoints(
        segment1 as CircleData,
        segment2 as ArcData,
        segmentIndex1,
        segmentIndex2,
        intersections1,
        intersections2
      );
    } else if (
      segment1.type === SegmentType.CIRCLE &&
      segment2.type === SegmentType.CIRCLE
    ) {
      findCircleIntersectionsAndEnclosure(
        segment1 as CircleData,
        segment2 as CircleData,
        segmentIndex1,
        segmentIndex2,
        intersections1,
        intersections2
      );
    } else if (
      segment1.type === SegmentType.CIRCLE &&
      segment2.type === SegmentType.LINE
    ) {
      findCircleLineIntersectionPoints(
        segment1 as CircleData,
        segment2 as LineData,
        segmentIndex1,
        segmentIndex2,
        intersections1,
        intersections2
      );
    } else if (
      segment1.type === SegmentType.LINE &&
      segment2.type === SegmentType.CIRCLE
    ) {
      findCircleLineIntersectionPoints(
        segment2 as CircleData,
        segment1 as LineData,
        segmentIndex2,
        segmentIndex1,
        intersections2,
        intersections1
      );
    }
  }

  if (intersections1.length !== intersections2.length) {
    throw new Error(
      `Intersections1 and intersections2 have different lengths: ${intersections1.length} ${intersections2.length}`
    );
  }

  if (intersections1.length % 2 !== 0) {
    throw new Error(`Odd number of intersections: ${intersections1.length}`);
  }

  if (intersections1.length > 0 || intersections2.length > 0) {
    intersections1.sort((a, b) => {
      return a.segmentIndex - b.segmentIndex || a.t - b.t;
    });
    intersections2.sort((a, b) => {
      return a.segmentIndex - b.segmentIndex || a.t - b.t;
    });

    linkIntersections(intersections1);
    linkIntersections(intersections2);

    return {
      intersections: [intersections1, intersections2],
      segmentCheckCount,
      segmentIntersectionCount: intersections1.length,
    };
  }

  return {
    intersections: null,
    segmentCheckCount,
    segmentIntersectionCount: 0,
  };
}

function linkIntersections(intersections: IntersectionPoint[]): void {
  if (intersections.length === 0) return;

  // Link all points in sequence
  for (let i = 0; i < intersections.length; i++) {
    const current = intersections[i];
    const isLastElement = i === intersections.length - 1;

    // Link to next (wrapping around to start if at end)
    current.nextIndex = isLastElement ? 0 : i + 1;
    // Link to previous (wrapping to end if at start)
    intersections[current.nextIndex].previousIndex = i;
  }
}

function findCircleIntersectionsAndEnclosure(
  circle1: CircleData,
  circle2: CircleData,
  segmentIndex1: number,
  segmentIndex2: number,
  intersections1: IntersectionPoint[],
  intersections2: IntersectionPoint[]
): boolean {
  // Calculate distance between centers
  const dx = circle2.center.x - circle1.center.x;
  const dy = circle2.center.y - circle1.center.y;
  const d = Math.sqrt(dx * dx + dy * dy);

  // Check for coincident circles (same center and radius)
  if (d === 0 && Math.abs(circle1.radius - circle2.radius) < EPSILON) {
    throw new Error("Coincident circles");
  }

  // Check for enclosure cases
  if (d <= Math.abs(circle1.radius - circle2.radius)) {
    return false;
  }

  // Check for no intersection cases
  if (
    d > circle1.radius + circle2.radius ||
    (d === 0 && circle1.radius === circle2.radius)
  ) {
    return false;
  }

  // Calculate intersection points
  const a =
    (circle1.radius * circle1.radius -
      circle2.radius * circle2.radius +
      d * d) /
    (2 * d);
  const h = Math.sqrt(circle1.radius * circle1.radius - a * a);

  // Calculate point P2 - Keep using circle's center as reference
  const p2x = circle1.center.x + (a * dx) / d;
  const p2y = circle1.center.y + (a * dy) / d;

  // Calculate intersection points - Adjust signs
  const intersectionX1 = p2x + (h * dy) / d;
  const intersectionY1 = p2y - (h * dx) / d;
  const intersectionX2 = p2x - (h * dy) / d;
  const intersectionY2 = p2y + (h * dx) / d;

  // Convert intersection points to angles for both circles
  const point1 = new Vector2D(intersectionX1, intersectionY1);
  const point2 = new Vector2D(intersectionX2, intersectionY2);

  const angle1Circle1 = normalizeAngle(
    Math.atan2(point1.y - circle1.center.y, point1.x - circle1.center.x)
  );
  const angle1Circle2 = normalizeAngle(
    Math.atan2(point1.y - circle2.center.y, point1.x - circle2.center.x)
  );
  const angle2Circle1 = normalizeAngle(
    Math.atan2(point2.y - circle1.center.y, point2.x - circle1.center.x)
  );
  const angle2Circle2 = normalizeAngle(
    Math.atan2(point2.y - circle2.center.y, point2.x - circle2.center.x)
  );

  // Determine entering/leaving for each intersection
  // At point1
  const tangent1 = new Vector2D(
    -Math.sin(angle1Circle1),
    Math.cos(angle1Circle1)
  );
  const toCenter2 = circle2.center.subtract(point1);
  let firstShapeEntering = tangent1.dot(toCenter2) > 0 ? true : false;
  if (circle1.clockwise !== circle2.clockwise) {
    firstShapeEntering = !firstShapeEntering;
  }

  if (!isValidAngle(angle1Circle1)) {
    return false;
  }

  if (!isValidAngle(angle1Circle2)) {
    return false;
  }

  if (!isValidAngle(angle2Circle1)) {
    return false;
  }

  if (!isValidAngle(angle2Circle2)) {
    return false;
  }

  // Add first intersection
  const intersection1Circle1: IntersectionPoint = {
    // Negative if the circle is clockwise
    t: circle1.clockwise ? -angle1Circle1 : angle1Circle1,
    entering: firstShapeEntering,
    segmentIndex: segmentIndex1,
    visited: false,
    point: point1,
    onCircle: true,
  };

  const intersection1Circle2: IntersectionPoint = {
    t: circle2.clockwise ? -angle1Circle2 : angle1Circle2,
    entering: !firstShapeEntering,
    segmentIndex: segmentIndex2,
    visited: false,
    point: point1,
    counterpart: intersection1Circle1,
    onCircle: true,
  };

  intersection1Circle1.counterpart = intersection1Circle2;

  const intersection2Circle1: IntersectionPoint = {
    t: circle1.clockwise ? -angle2Circle1 : angle2Circle1,
    entering: !firstShapeEntering,
    segmentIndex: segmentIndex1,
    visited: false,
    point: point2,
    onCircle: true,
  };

  const intersection2Circle2: IntersectionPoint = {
    t: circle2.clockwise ? -angle2Circle2 : angle2Circle2,
    entering: firstShapeEntering,
    segmentIndex: segmentIndex2,
    visited: false,
    point: point2,
    counterpart: intersection2Circle1,
    onCircle: true,
  };

  intersection2Circle1.counterpart = intersection2Circle2;

  intersections1.push(intersection1Circle1);
  intersections1.push(intersection2Circle1);
  intersections2.push(intersection1Circle2);
  intersections2.push(intersection2Circle2);

  return true;
}

function findCircleLineIntersectionPoints(
  circle: CircleData,
  line: LineData,
  circleSegmentIndex: number,
  lineSegmentIndex: number,
  circleIntersections: IntersectionPoint[],
  lineIntersections: IntersectionPoint[]
): boolean {
  // Calculate line direction vector
  const dx = line.end.x - line.start.x;
  const dy = line.end.y - line.start.y;

  // Calculate coefficients for quadratic equation at² + bt + c = 0
  const a = dx * dx + dy * dy;
  const b =
    2 *
    (dx * (line.start.x - circle.center.x) +
      dy * (line.start.y - circle.center.y));
  const c =
    circle.center.x * circle.center.x +
    circle.center.y * circle.center.y +
    line.start.x * line.start.x +
    line.start.y * line.start.y -
    2 * (circle.center.x * line.start.x + circle.center.y * line.start.y) -
    circle.radius * circle.radius;

  // Calculate discriminant
  const discriminant = b * b - 4 * a * c;

  // No real solutions if discriminant is negative
  if (discriminant < 0) return false;

  // Calculate t values
  const sqrtDiscriminant = Math.sqrt(discriminant);
  const t1 = (-b - sqrtDiscriminant) / (2 * a);
  const t2 = (-b + sqrtDiscriminant) / (2 * a);

  let foundIntersection = false;

  // Check each t value
  [t1, t2].forEach((t) => {
    // Only consider intersections along the line segment
    if (t >= 0 && t <= 1) {
      if (!isValidT(t)) {
        return;
      }

      foundIntersection = true;
      // Calculate intersection point
      const x = line.start.x + t * dx;
      const y = line.start.y + t * dy;

      // Determine if entering or exiting
      // Vector from circle center to intersection point
      const toCenterX = circle.center.x - x;
      const toCenterY = circle.center.y - y;

      let firstShapeEntering =
        dx * toCenterX + dy * toCenterY > 0 ? false : true;
      // Flip entering status if circle is clockwise
      if (circle.clockwise) {
        firstShapeEntering = !firstShapeEntering;
      }

      // Calculate angle for circle's t-value
      const angleOnCircle = normalizeAngle(
        Math.atan2(y - circle.center.y, x - circle.center.x)
      );

      if (!isValidAngle(angleOnCircle)) {
        return false;
      }

      const intersectionCircle: IntersectionPoint = {
        t: circle.clockwise ? -angleOnCircle : angleOnCircle,
        entering: firstShapeEntering,
        segmentIndex: circleSegmentIndex,
        visited: false,
        point: new Vector2D(x, y),
        onCircle: true,
      };

      const intersectionLine: IntersectionPoint = {
        t: t,
        entering: !firstShapeEntering,
        segmentIndex: lineSegmentIndex,
        visited: false,
        point: new Vector2D(x, y),
        counterpart: intersectionCircle,
        onCircle: false,
      };

      intersectionCircle.counterpart = intersectionLine;

      circleIntersections.push(intersectionCircle);
      lineIntersections.push(intersectionLine);
    }
  });

  return foundIntersection;
}

function findCircleArcIntersectionPoints(
  circle: CircleData,
  arc: ArcData,
  circleSegmentIndex: number,
  arcSegmentIndex: number,
  circleIntersections: IntersectionPoint[],
  arcIntersections: IntersectionPoint[]
): boolean {
  // Calculate distance between centers
  const dx = arc.center.x - circle.center.x;
  const dy = arc.center.y - circle.center.y;
  const d = Math.sqrt(dx * dx + dy * dy);

  // Check for coincident circles (same center and radius)
  if (d === 0 && Math.abs(circle.radius - arc.radius) < EPSILON) {
    throw new Error("Coincident circles");
  }

  // Check for enclosure cases
  if (d <= Math.abs(circle.radius - arc.radius)) {
    return false;
  }

  // Check for no intersection cases
  if (d > circle.radius + arc.radius) {
    return false;
  }

  // Calculate intersection points - MODIFIED to use circle as reference
  const a =
    (circle.radius * circle.radius - arc.radius * arc.radius + d * d) / (2 * d);
  const h = Math.sqrt(circle.radius * circle.radius - a * a);

  // Calculate point P2 - MODIFIED to use circle's center as reference
  const p2x = circle.center.x + (a * dx) / d;
  const p2y = circle.center.y + (a * dy) / d;

  // Calculate intersection points - MODIFIED signs to match new reference point
  const intersectionX1 = p2x - (h * dy) / d;
  const intersectionY1 = p2y + (h * dx) / d;
  const intersectionX2 = p2x + (h * dy) / d;
  const intersectionY2 = p2y - (h * dx) / d;

  let foundIntersection = false;

  // Process each intersection point
  [
    [intersectionX1, intersectionY1],
    [intersectionX2, intersectionY2],
  ].forEach(([x, y]) => {
    // Calculate angles for both shapes
    const angleOnArc = normalizeAngle(
      Math.atan2(y - arc.center.y, x - arc.center.x)
    );
    const angleOnCircle = normalizeAngle(
      Math.atan2(y - circle.center.y, x - circle.center.x)
    );

    // Convert arc angle to t-value
    const arcT = angleToTValue(
      angleOnArc,
      arc.startAngle,
      arc.endAngle,
      arc.centralAngle,
      arc.clockwise
    );

    // Only process if point lies on arc segment
    if (arcT !== null) {
      if (!isValidT(arcT)) {
        return false;
      }

      if (!isValidAngle(angleOnCircle)) {
        return false;
      }

      foundIntersection = true;
      // Calculate entering/leaving
      // Tangent vector at intersection point on arc
      const tangentX = -Math.sin(angleOnArc);
      const tangentY = Math.cos(angleOnArc);

      // Vector from intersection to circle center
      const toCircleX = circle.center.x - x;
      const toCircleY = circle.center.y - y;

      let firstShapeEntering =
        tangentX * toCircleX + tangentY * toCircleY > 0 ? false : true;
      // Flip entering status based on circle and arc direction
      if (circle.clockwise !== arc.clockwise) {
        firstShapeEntering = !firstShapeEntering;
      }

      const intersectionCircle: IntersectionPoint = {
        t: circle.clockwise ? -angleOnCircle : angleOnCircle,
        entering: firstShapeEntering,
        segmentIndex: circleSegmentIndex,
        visited: false,
        point: new Vector2D(x, y),
        onCircle: true,
      };

      const intersectionArc: IntersectionPoint = {
        t: arcT,
        entering: !firstShapeEntering,
        segmentIndex: arcSegmentIndex,
        visited: false,
        point: new Vector2D(x, y),
        counterpart: intersectionCircle,
        onCircle: false,
      };

      intersectionCircle.counterpart = intersectionArc;

      circleIntersections.push(intersectionCircle);
      arcIntersections.push(intersectionArc);
    }
  });

  return foundIntersection;
}

function findArcIntersectionPoints(
  arc1: ArcData,
  arc2: ArcData,
  arc1Index: number,
  arc2Index: number,
  intersections1: IntersectionPoint[],
  intersections2: IntersectionPoint[]
): boolean {
  // Calculate distance between centers
  const dx = arc2.center.x - arc1.center.x;
  const dy = arc2.center.y - arc1.center.y;
  const d = Math.sqrt(dx * dx + dy * dy);

  // Check for coincident arcs (same center and radius)
  if (d === 0 && Math.abs(arc1.radius - arc2.radius) < EPSILON) {
    return false;
  }

  // Check for enclosure cases
  if (d <= Math.abs(arc1.radius - arc2.radius)) {
    return false;
  }

  // Check for no intersection cases
  if (d > arc1.radius + arc2.radius) {
    return false;
  }

  // Calculate intersection points
  const a =
    (arc1.radius * arc1.radius - arc2.radius * arc2.radius + d * d) / (2 * d);
  const h = Math.sqrt(arc1.radius * arc1.radius - a * a);

  // Calculate point P2
  const p2x = arc1.center.x + (a * dx) / d;
  const p2y = arc1.center.y + (a * dy) / d;

  // Calculate intersection points
  const intersectionX1 = p2x + (h * dy) / d;
  const intersectionY1 = p2y - (h * dx) / d;
  const intersectionX2 = p2x - (h * dy) / d;
  const intersectionY2 = p2y + (h * dx) / d;

  let foundIntersection = false;

  // Process each intersection point
  [
    [intersectionX1, intersectionY1],
    [intersectionX2, intersectionY2],
  ].forEach(([x, y]) => {
    // Calculate angles for both arcs
    const angleOnArc1 = normalizeAngle(
      Math.atan2(y - arc1.center.y, x - arc1.center.x)
    );
    const angleOnArc2 = normalizeAngle(
      Math.atan2(y - arc2.center.y, x - arc2.center.x)
    );

    // Convert angles to t-values
    const arc1T = angleToTValue(
      angleOnArc1,
      arc1.startAngle,
      arc1.endAngle,
      arc1.centralAngle,
      arc1.clockwise
    );

    const arc2T = angleToTValue(
      angleOnArc2,
      arc2.startAngle,
      arc2.endAngle,
      arc2.centralAngle,
      arc2.clockwise
    );

    // Only process if point lies on both arc segments
    if (arc1T !== null && arc2T !== null) {
      if (!isValidT(arc1T)) {
        return false;
      }

      if (!isValidT(arc2T)) {
        return false;
      }

      foundIntersection = true;
      // Calculate entering/leaving
      // Tangent vector at intersection point on arc1
      const tangent1X = -Math.sin(angleOnArc1);
      const tangent1Y = Math.cos(angleOnArc1);

      // Vector from intersection to arc2 center
      const toArc2X = arc2.center.x - x;
      const toArc2Y = arc2.center.y - y;

      // Dot product determines entering/leaving
      let firstShapeEntering =
        tangent1X * toArc2X + tangent1Y * toArc2Y > 0 ? false : true;

      if (arc1.clockwise !== arc2.clockwise) {
        firstShapeEntering = !firstShapeEntering;
      }

      const intersectionArc1: IntersectionPoint = {
        t: arc1T,
        entering: !firstShapeEntering,
        segmentIndex: arc1Index,
        visited: false,
        point: new Vector2D(x, y),
        onCircle: false,
      };

      const intersectionArc2: IntersectionPoint = {
        t: arc2T,
        entering: firstShapeEntering,
        segmentIndex: arc2Index,
        visited: false,
        point: new Vector2D(x, y),
        counterpart: intersectionArc1,
        onCircle: false,
      };

      intersectionArc1.counterpart = intersectionArc2;

      intersections1.push(intersectionArc1);
      intersections2.push(intersectionArc2);
    }
  });

  return foundIntersection;
}

function findArcLineIntersectionPoints(
  arc: ArcData,
  line: LineData,
  arcSegmentIndex: number,
  lineSegmentIndex: number,
  arcIntersections: IntersectionPoint[],
  lineIntersections: IntersectionPoint[]
): boolean {
  // Calculate line direction vector
  const dx = line.end.x - line.start.x;
  const dy = line.end.y - line.start.y;

  // Calculate coefficients for quadratic equation at² + bt + c = 0
  const a = dx * dx + dy * dy;
  const b =
    2 *
    (dx * (line.start.x - arc.center.x) + dy * (line.start.y - arc.center.y));
  const c =
    arc.center.x * arc.center.x +
    arc.center.y * arc.center.y +
    line.start.x * line.start.x +
    line.start.y * line.start.y -
    2 * (arc.center.x * line.start.x + arc.center.y * line.start.y) -
    arc.radius * arc.radius;

  // Calculate discriminant
  const discriminant = b * b - 4 * a * c;

  // No real solutions if discriminant is negative
  if (discriminant < 0) return false;

  // Calculate t values
  const sqrtDiscriminant = Math.sqrt(discriminant);
  const t1 = (-b - sqrtDiscriminant) / (2 * a);
  const t2 = (-b + sqrtDiscriminant) / (2 * a);

  let foundIntersection = false;
  // Check each t value
  [t1, t2].forEach((t) => {
    if (!isValidT(t)) {
      return;
    }
    // Only consider intersections along the line segment
    if (t >= 0 && t <= 1) {
      // Calculate intersection point
      const x = line.start.x + t * dx;
      const y = line.start.y + t * dy;

      // Determine if entering or exiting
      // Vector from circle center to intersection point
      const toCenterX = arc.center.x - x;
      const toCenterY = arc.center.y - y;

      let firstShapeEntering =
        dx * toCenterX + dy * toCenterY > 0 ? false : true;
      // Flip entering status if circle is clockwise
      if (arc.clockwise) {
        firstShapeEntering = !firstShapeEntering;
      }

      // Calculate angle for arc's t-value
      const angleOnArc = normalizeAngle(
        Math.atan2(y - arc.center.y, x - arc.center.x)
      );

      // Convert angle to t-value on arc
      const arcT = angleToTValue(
        angleOnArc,
        arc.startAngle,
        arc.endAngle,
        arc.centralAngle,
        arc.clockwise
      );

      // If arcT is null, the intersection point isn't on the arc segment
      if (arcT !== null) {
        foundIntersection = true;
        const intersectionArc: IntersectionPoint = {
          t: arcT,
          entering: firstShapeEntering,
          segmentIndex: arcSegmentIndex,
          visited: false,
          point: new Vector2D(x, y),
          onCircle: false,
        };

        const intersectionLine: IntersectionPoint = {
          t: t,
          entering: !firstShapeEntering,
          segmentIndex: lineSegmentIndex,
          visited: false,
          point: new Vector2D(x, y),
          counterpart: intersectionArc,
          onCircle: false,
        };

        intersectionArc.counterpart = intersectionLine;

        arcIntersections.push(intersectionArc);
        lineIntersections.push(intersectionLine);
      }
    }
  });

  return foundIntersection;
}

function findLineIntersectionPoints(
  line1: LineData,
  line2: LineData,
  line1Index: number,
  line2Index: number,
  intersections1: IntersectionPoint[],
  intersections2: IntersectionPoint[]
): boolean {
  // Calculate direction vectors
  const dx1 = line1.end.x - line1.start.x;
  const dy1 = line1.end.y - line1.start.y;
  const dx2 = line2.end.x - line2.start.x;
  const dy2 = line2.end.y - line2.start.y;

  // Calculate denominator
  const denominator = dx1 * dy2 - dy1 * dx2;

  // Calculate differences between start points
  const dx3 = line2.start.x - line1.start.x;
  const dy3 = line2.start.y - line1.start.y;

  // Calculate parametric coordinates of intersection
  const t = (dx3 * dy2 - dy3 * dx2) / denominator;
  const s = (dx3 * dy1 - dy3 * dx1) / denominator;

  if (!isValidT(t)) {
    return false;
  }

  if (!isValidT(s)) {
    return false;
  }

  let foundIntersection = false;

  // Check if intersection is within both line segments
  if (t >= 0 && t <= 1 && s >= 0 && s <= 1) {
    foundIntersection = true;
    // Determine entering/leaving
    const cross = dx1 * dy2 - dy1 * dx2;
    const firstShapeEntering = cross > 0 ? false : true;

    // Calculate point
    const point = new Vector2D(
      line1.start.x + t * dx1,
      line1.start.y + t * dy1
    );

    const intersection1: IntersectionPoint = {
      t: t,
      entering: firstShapeEntering,
      segmentIndex: line1Index,
      visited: false,
      point,
      onCircle: false,
    };

    const intersection2: IntersectionPoint = {
      t: s,
      entering: !firstShapeEntering,
      segmentIndex: line2Index,
      visited: false,
      point,
      counterpart: intersection1,
      onCircle: false,
    };

    intersection1.counterpart = intersection2;

    intersections1.push(intersection1);
    intersections2.push(intersection2);
  }

  return foundIntersection;
}

function angleToTValue(
  angle: number,
  startAngle: number,
  endAngle: number,
  centralAngle: number,
  clockwise: boolean
): number | null {
  const unsignedCentralAngle = Math.abs(centralAngle);
  if (!clockwise) {
    if (startAngle < endAngle) {
      if (angle >= startAngle && angle <= endAngle) {
        return (angle - startAngle) / unsignedCentralAngle;
      }
      return null;
    } else {
      if (angle >= startAngle && angle <= TWO_PI) {
        return (angle - startAngle) / unsignedCentralAngle;
      }
      if (angle <= endAngle && angle >= 0) {
        return 1 - (endAngle - angle) / unsignedCentralAngle;
      }
      return null;
    }
  } else {
    if (startAngle > endAngle) {
      if (angle <= startAngle && angle >= endAngle) {
        return (startAngle - angle) / unsignedCentralAngle;
      }
      return null;
    } else {
      if (angle <= startAngle && angle >= 0) {
        return (startAngle - angle) / unsignedCentralAngle;
      }
      if (angle >= endAngle && angle <= TWO_PI) {
        return 1 - (angle - endAngle) / unsignedCentralAngle;
      }
      return null;
    }
  }
}
