import { Vector2D } from "../math/vector2D.ts";
import { Shape } from "../shapes/shape.ts";
import { VertexShapeBuilder } from "../shapes/builders/vertexShapeBuilder.ts";
import {
  BodyType,
  type AABB,
  type BodyState,
  type LightSource,
  type Material,
} from "../models.ts";
import { Body } from "../plane/body.ts";
import {
  createFractalNoise2D,
  type FractalNoiseParams,
  type Point,
} from "../rendering/noise.ts";
import { getNoisePaths, type Path } from "../shapes/marchingSquares.ts";
import type { PlanarBase } from "./planarBase.ts";
import { Transform } from "../math/transform.ts";
import { getRandomMaterialColor, Color } from "../rendering/color.ts";
import { getNoisePath } from "../shapes/marchingSquaresSingle.ts";
import { randomUint32 } from "../math/utils.ts";

export type VarietyGenerationParams = {
  boundarySize: number;
  smallCount: number;
  mediumCount: number;
  largeCount: number;
  noiseShapeCount: number;
  asteroidCount: number;
  planeId?: string;
};

export function getMaterial(): Material {
  return {
    density: 1,
    restitution: 0.4,
    staticFriction: 0.6,
    dynamicFriction: 0.4,
    color: getRandomMaterialColor(),
    destructible: true,
  };
}

/**
 * Generates a random number between min and max.
 * @param min - Minimum value
 * @param max - Maximum value
 * @returns A random number between min and max
 */
function getRandomNumber(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

/**
 * Generates a random point.
 * @param size - The size of the grid
 * @returns A Point with random x and y values
 */
function generateRandomPoint(size: number): Point {
  const x = getRandomNumber(-size, size);
  const y = getRandomNumber(-size, size);
  return { x, y };
}

// Add this helper at the top of the file
function getSecureRandom(): number {
  // Use crypto.getRandomValues which is available in both environments
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] / (0xffffffff + 1); // Convert to [0, 1) range
}

function getRandomPointInCircle(radius: number): [number, number] {
  const angle = getSecureRandom() * Math.PI * 2;
  const distance = getSecureRandom() * radius;
  return [Math.cos(angle) * distance, Math.sin(angle) * distance];
}

function getRandomPointInAABB(aabb: AABB): [number, number] {
  const { left, top, right, bottom } = aabb;

  return [
    getSecureRandom() * (right - left) + left,
    getSecureRandom() * (bottom - top) + top,
  ];
}

function getRandomPointInSquare(size: number): [number, number] {
  return [
    getSecureRandom() * size - size / 2,
    getSecureRandom() * size - size / 2,
  ];
}

interface RandomShapeParams {
  count: number;
  boundarySize: number;
  minSize: number;
  maxSize: number;
}

interface RandomCapsuleParams extends RandomShapeParams {
  minSagitta: number;
  maxSagitta: number;
}

interface RandomPolygonParams extends RandomShapeParams {
  minSides: number;
  maxSides: number;
  lights: boolean;
}

interface RandomVelocityParams {
  minLinearVelocity: number;
  maxLinearVelocity: number;
  minAngularVelocity: number;
  maxAngularVelocity: number;
}

function getRandomVelocity(params: RandomVelocityParams): {
  velocity: Vector2D;
  angularVelocity: number;
} {
  const angle = getSecureRandom() * Math.PI * 2;
  const speed =
    getSecureRandom() * (params.maxLinearVelocity - params.minLinearVelocity) +
    params.minLinearVelocity;
  const angularVelocity =
    getSecureRandom() *
      (params.maxAngularVelocity - params.minAngularVelocity) +
    params.minAngularVelocity;
  return {
    velocity: new Vector2D(Math.cos(angle) * speed, Math.sin(angle) * speed),
    angularVelocity,
  };
}

function generateRandomRectangles(
  params: RandomShapeParams & RandomVelocityParams
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];
  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInSquare(boundarySize);
    const width = getSecureRandom() * (maxSize - minSize) + minSize;
    const height = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: VertexShapeBuilder.rectangle({ width, height }),
        material: getMaterial(),
        type: BodyType.Standard,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }
  return bodies;
}

function generateRandomCapsules(
  params: RandomCapsuleParams & RandomVelocityParams
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize, minSagitta, maxSagitta } =
    params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInSquare(boundarySize);
    const width = getSecureRandom() * (maxSize - minSize) + minSize;
    const height = getSecureRandom() * (maxSize - minSize) + minSize;
    const widthSagittaFactor =
      getSecureRandom() * (maxSagitta - minSagitta) + minSagitta;
    const heightSagittaFactor =
      getSecureRandom() * (maxSagitta - minSagitta) + minSagitta;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    if (
      (widthSagittaFactor < 0 &&
        Math.abs(widthSagittaFactor) * 2 * width > height) ||
      (heightSagittaFactor < 0 &&
        Math.abs(heightSagittaFactor) * 2 * height > width)
    ) {
      continue;
    }

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: VertexShapeBuilder.capsule({
          width,
          height,
          widthSagittaFactor,
          heightSagittaFactor,
        }),
        material: getMaterial(),
        type: BodyType.Standard,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }
  return bodies;
}

function generateRandomPolygons(
  params: RandomPolygonParams & RandomVelocityParams
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize, minSides, maxSides } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  let light: LightSource | undefined = undefined;

  if (params.lights) {
    light = {
      radius: 500,
      glowRadius: 150,
      brightnessStops: [
        { position: 0, opacity: 1 },
        { position: 1, opacity: 0 },
      ],
      colorStops: [
        { position: 0, opacity: 0.4 },
        { position: 1, opacity: 0 },
      ],
      color: Color.fromHex("#e3bb64"),
    };
  }

  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInSquare(boundarySize);
    const numSides =
      Math.floor(getSecureRandom() * (maxSides - minSides + 1)) + minSides;
    const radius = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: VertexShapeBuilder.regularPolygon(radius, numSides),
        material: getMaterial(),
        type: BodyType.Standard,
        light,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }
  return bodies;
}

function generateRandomCircles(
  params: RandomShapeParams & RandomVelocityParams
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundarySize, minSize, maxSize } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];
  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInSquare(boundarySize);
    const radius = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: VertexShapeBuilder.circle(radius),
        material: getMaterial(),
        type: BodyType.Standard,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }
  return bodies;
}

// function generateItems(
//   boundaryRadius: number,
//   number: number,
//   nextId: () => string
// ): {
//   body: Body;
//   state: BodyState;
// }[] {
//   const bodies: {
//     body: Body;
//     state: BodyState;
//   }[] = [];
//   for (let i = 0; i < number; i++) {
//     const [x, y] = getRandomPointInCircle(boundaryRadius);

//     const itemBody = new Body({
//       id: nextId(),
//       shape: VertexShapeBuilder.circle(100),
//       material: {
//         density: 1,
//         restitution: 0.4,
//         staticFriction: 0.6,
//         dynamicFriction: 0.4,
//         color: Color.fromName("blue"),
//         destructible: false,
//       },
//       type: BodyType.Item,
//       positionLocked: false,
//       rotationLocked: false,
//       inSubplane: false,
//       light: {
//         radius: 200,
//         glowRadius: 200,
//         brightnessStops: [
//           { position: 0, opacity: 0.5 },
//           { position: 1, opacity: 0 },
//         ],
//         colorStops: [
//           { position: 0, opacity: 0.3 },
//           { position: 1, opacity: 0 },
//         ],
//         color: Color.fromHex("#0000ff"),
//       },
//     });

//     itemBody.item = new Shooter({
//       cooldown: getSecureRandom() * 1000,
//       shots: Math.floor(getSecureRandom() * 20),
//     });

//     bodies.push({
//       body: itemBody,
//       state: {
//         position: [x, y, 0],
//         velocity: [1, 1, 0],
//       },
//     });
//   }
//   return bodies;
// }

interface SizeRangeParams {
  count: number;
  minSize: number;
  maxSize: number;
  maxLinearVelocity: number;
  maxAngularVelocity: number;
}

function generateShapesForSizeRange(
  planar: PlanarBase,
  params: SizeRangeParams,
  boundarySize: number,
  lights: boolean = false
): void {
  const shapeCount = Math.floor(params.count / 3);
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  // Rectangles
  bodies.push(
    ...generateRandomRectangles({
      count: shapeCount,
      boundarySize,
      minSize: params.minSize,
      maxSize: params.maxSize,
      minLinearVelocity: 0,
      maxLinearVelocity: params.maxLinearVelocity,
      minAngularVelocity: 0,
      maxAngularVelocity: params.maxAngularVelocity,
    })
  );

  // Capsules
  bodies.push(
    ...generateRandomCapsules({
      count: shapeCount,
      boundarySize,
      minSize: params.minSize,
      maxSize: params.maxSize,
      minSagitta: -0.3,
      maxSagitta: 1.0,
      minLinearVelocity: 0,
      maxLinearVelocity: params.maxLinearVelocity,
      minAngularVelocity: 0,
      maxAngularVelocity: params.maxAngularVelocity,
    })
  );

  // Polygons
  bodies.push(
    ...generateRandomPolygons({
      count: shapeCount,
      boundarySize,
      minSize: params.minSize,
      maxSize: params.maxSize,
      minSides: 3,
      maxSides: 20,
      minLinearVelocity: 0,
      maxLinearVelocity: params.maxLinearVelocity,
      minAngularVelocity: 0,
      maxAngularVelocity: params.maxAngularVelocity,
      lights,
    })
  );

  // Circles
  bodies.push(
    ...generateRandomCircles({
      count: shapeCount,
      boundarySize,
      minSize: params.minSize,
      maxSize: params.maxSize,
      minLinearVelocity: 0,
      maxLinearVelocity: params.maxLinearVelocity,
      minAngularVelocity: 0,
      maxAngularVelocity: params.maxAngularVelocity,
    })
  );

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);
  }
}

export function generateVarietyWithBoundary({
  planar,
  boundarySize,
  small,
  medium,
  large,
  noiseShapeCount,
  asteroidCount,
}: {
  planar: PlanarBase;
  boundarySize: number;
  small: SizeRangeParams;
  medium: SizeRangeParams;
  large: SizeRangeParams;
  noiseShapeCount: number;
  asteroidCount: number;
}): void {
  // Generate shapes for each size range
  generateShapesForSizeRange(planar, small, boundarySize, true);
  generateShapesForSizeRange(planar, medium, boundarySize);
  generateShapesForSizeRange(planar, large, boundarySize);

  generateNoiseShapesNew({
    planar,
    gridSize: 100,
    noiseParams: {
      baseScale: 0.0001,
      lacunarity: 2.5,
      octaves: 6,
      persistence: 0.5,
    },
    boundarySize,
    count: noiseShapeCount,
    threshold: -0.5,
    velParams: {
      minLinearVelocity: 1,
      maxLinearVelocity: 10,
      minAngularVelocity: 0,
      maxAngularVelocity: 0.01,
    },
  });

  for (let i = 0; i < asteroidCount; i++) {
    generateCutNoiseShape({
      planar,
      position: [0, 0],
      baseNoiseParams: {
        baseScale: 0.000001,
        lacunarity: 2.5,
        octaves: 6,
        persistence: 0.5,
      },
      cutNoiseParams: {
        baseScale: 0.00001,
        lacunarity: 2.5,
        octaves: 6,
        persistence: 0.5,
      },
      cutSizeFactorRange: [0.01, 0.15],
      cutPercentThreshold: 0.5,
    });
  }
}

export function generateVariety(
  planar: PlanarBase,
  params: VarietyGenerationParams
): void {
  const {
    boundarySize,
    smallCount,
    mediumCount,
    largeCount,
    noiseShapeCount,
    asteroidCount,
  } = params;

  generateVarietyWithBoundary({
    planar: planar,
    boundarySize,
    small: {
      count: smallCount,
      minSize: 50,
      maxSize: 100,
      maxLinearVelocity: 1000,
      maxAngularVelocity: 1,
    },
    medium: {
      count: mediumCount,
      minSize: 1000,
      maxSize: 2000,
      maxLinearVelocity: 500,
      maxAngularVelocity: 0.01,
    },
    large: {
      count: largeCount,
      minSize: 2000,
      maxSize: 5000,
      maxLinearVelocity: 10,
      maxAngularVelocity: 0.01,
    },
    noiseShapeCount,
    asteroidCount,
  });
}

export function generateCutShape(
  planar: PlanarBase,
  position: [number, number],
  baseRadius: number,
  numCuts: number,
  cutSizeFactorRange: [number, number],
  nextId: () => string
): void {
  const subplane = VertexShapeBuilder.circle(baseRadius);
  const base = VertexShapeBuilder.circle(baseRadius);

  let resultShapes: Shape[] = [base];

  for (let i = 0; i < numCuts; i++) {
    const [x, y] = getRandomPointInCircle(baseRadius);
    const cutSizeFactor =
      getSecureRandom() * (cutSizeFactorRange[1] - cutSizeFactorRange[0]) +
      cutSizeFactorRange[0];
    const cut = VertexShapeBuilder.circle(
      getSecureRandom() * baseRadius * cutSizeFactor
    );
    // const angle = getSecureRandom() * Math.PI * 2;

    const newResults: Shape[] = [];

    for (const shape of resultShapes) {
      const transformInfo = Transform.getRelativeTransformInfo({
        guest: new Transform(x, y, 0, 0, 1),
        host: new Transform(0, 0, 0, 0, 1),
      });

      const results = shape.subtract(cut, transformInfo);
      if (!results || results.resultShapes.length === 0) {
        // If subtraction fails or returns empty, keep the original shape
        newResults.push(shape);
      } else {
        newResults.push(...results.resultShapes.map((r) => r.shape));
      }
    }

    if (newResults.length === 0) {
      console.warn(
        `Cut ${i} eliminated all shapes, reverting to previous state`
      );
      continue;
    }

    resultShapes = newResults;
  }

  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  const material = getMaterial();

  for (let i = 0; i < resultShapes.length; i++) {
    if (resultShapes[i].area < 200) {
      continue;
    }

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: resultShapes[i],
        material,
        type: BodyType.Standard,
        inSubplane: false,
      }),
      state: {
        position: [
          resultShapes[i].centroid.x + position[0],
          resultShapes[i].centroid.y + position[1],
          0,
        ],
        velocity: [10, 10, 0.001],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }

  const subplaneMaterial = getMaterial();
  subplaneMaterial.color = Color.fromName("darkgray");

  const subplaneBody = new Body({
    id: randomUint32(),
    shape: subplane,
    material: subplaneMaterial,
    type: BodyType.Standard,
    inSubplane: true,
  });

  planar.addBody(subplaneBody);
  planar.loadBody({
    body: subplaneBody,
    state: {
      position: [position[0], position[1], 0],
      velocity: [0, 0, 0],
      positionLocked: false,
      rotationLocked: false,
    },
  });

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);

    // If the area of the body is above a threshold, add it to the composite
    if (body.body.shape.area > 100000) {
      planar.plane.composeBodies(subplaneBody, body.body);
    }
  }
}

export function generateCutShapes({
  planar,
  minRadius,
  maxRadius,
  minCuts,
  maxCuts,
  cutSizeFactorRange,
  count,
  boundarySize,
}: {
  planar: PlanarBase;
  minRadius: number;
  maxRadius: number;
  minCuts: number;
  maxCuts: number;
  cutSizeFactorRange: [number, number];
  count: number;
  boundarySize: number;
}): void {
  let id = 0;

  const nextId = () => {
    return `cut-circle-piece-${id++}`;
  };

  for (let i = 0; i < count; i++) {
    const radius = getSecureRandom() * (maxRadius - minRadius) + minRadius;
    const cuts =
      Math.floor(getSecureRandom() * (maxCuts - minCuts + 1)) + minCuts;
    const position = getRandomPointInSquare(boundarySize);
    generateCutShape(
      planar,
      position,
      radius,
      cuts,
      cutSizeFactorRange,
      nextId
    );
  }
}

export function generateNoiseShapes({
  planar,
  gridSize,
  spaceSize,
  noiseParams,
  threshold,
  limit,
  nextId,
  boundarySize,
  velParams,
}: {
  planar: PlanarBase;
  gridSize: number;
  spaceSize: number;
  noiseParams: FractalNoiseParams;
  threshold: number;
  limit: number;
  nextId: () => string;
  boundarySize: number;
  velParams: RandomVelocityParams;
}): void {
  let paths = getNoisePaths({
    gridSize,
    height: spaceSize,
    width: spaceSize,
    noiseParams,
    threshold,
    limit,
  });

  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  for (const path of paths) {
    let pathShape = VertexShapeBuilder.fromPath(path);

    if (pathShape.area < 0) {
      pathShape = pathShape.inverse();
    }

    pathShape.recenter();
    const [x, y] = getRandomPointInSquare(boundarySize);
    const { velocity, angularVelocity } = getRandomVelocity(velParams);

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: pathShape,
        material: getMaterial(),
        type: BodyType.Standard,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);
  }
}

export function generateNoiseShapesNew({
  planar,
  gridSize,
  noiseParams,
  threshold,
  count,
  boundarySize,
  velParams,
}: {
  planar: PlanarBase;
  gridSize: number;
  noiseParams: FractalNoiseParams;
  threshold: number;
  count: number;
  boundarySize: number;
  velParams: RandomVelocityParams;
}): void {
  const getNoiseValue = createFractalNoise2D({ params: noiseParams });

  const paths: Path[] = [];

  for (let i = 0; i < count; i++) {
    // Get random start point
    const startPoint: Point = generateRandomPoint(10000000);

    const result = getNoisePath({
      gridSize,
      startPoint,
      getNoiseValue,
      threshold,
      segmentLimit: 1000,
      initialCheckLimit: 1000,
    });

    if (!result.errorType && result.points.length > 0) {
      paths.push({
        points: result.points,
        isClosed: true,
      });
    }
  }

  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  for (const path of paths) {
    let pathShape = VertexShapeBuilder.fromPath(path);

    if (pathShape.area < 0) {
      pathShape = pathShape.inverse();
    }

    pathShape.recenter();
    const [x, y] = getRandomPointInSquare(boundarySize);
    const { velocity, angularVelocity } = getRandomVelocity(velParams);

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: pathShape,
        material: getMaterial(),
        type: BodyType.Standard,
        inSubplane: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);
  }
}

export function generateNoiseShape({
  gridSize,
  getNoiseValue,
  threshold,
  retryLimit,
  minArea,
  maxArea,
}: {
  gridSize: number;
  getNoiseValue: (point: Point) => number;
  threshold: number;
  retryLimit: number;
  minArea: number;
  maxArea: number;
}): Shape | null {
  let shape: Shape | null = null;

  for (let i = 0; i < retryLimit; i++) {
    // Get random start point
    const startPoint: Point = generateRandomPoint(10000000);

    const result = getNoisePath({
      gridSize,
      startPoint,
      getNoiseValue,
      threshold,
      segmentLimit: 10000,
      initialCheckLimit: 10000,
    });

    if (result.errorType !== undefined || result.points.length === 0) {
      // console.log(
      //   "Failed to generate noise shape: ",
      //   result.errorType,
      //   result.points.length
      // );
      continue;
    }

    shape = VertexShapeBuilder.fromPath({
      points: result.points,
      isClosed: true,
    });

    if (Math.abs(shape.area) < minArea || Math.abs(shape.area) > maxArea) {
      continue;
    }

    if (shape.area < 0) {
      shape = shape.inverse();
    }

    shape.recenter();
    break;
  }

  return shape;
}

export function generateCutNoiseShape({
  planar,
  position,
  cutSizeFactorRange,
  baseNoiseParams,
  cutNoiseParams,
  cutPercentThreshold,
}: {
  planar: PlanarBase;
  position: [number, number];
  cutSizeFactorRange: [number, number];
  baseNoiseParams: FractalNoiseParams;
  cutNoiseParams: FractalNoiseParams;
  cutPercentThreshold: number;
}): void {
  const baseShapeNoise = createFractalNoise2D({ params: baseNoiseParams });

  const subplane = generateNoiseShape({
    gridSize: 500,
    getNoiseValue: baseShapeNoise,
    threshold: 0.5,
    retryLimit: 1000,
    minArea: 100000000,
    maxArea: 2000000000,
  });

  if (!subplane) {
    return;
  }

  const base = subplane.clone();

  const baseAABB = base.getSegmentTree().bounds;

  let resultShapes: Shape[] = [base];

  const cutNoise = createFractalNoise2D({ params: cutNoiseParams });

  const originalArea = base.area;

  while (true) {
    // Calculate current total area before making cuts
    let currentArea = resultShapes.reduce((sum, shape) => sum + shape.area, 0);

    // Check if we've cut enough
    if (currentArea <= originalArea * cutPercentThreshold) {
      break;
    }

    const [x, y] = getRandomPointInAABB(baseAABB);
    const cut = generateNoiseShape({
      gridSize: 500,
      getNoiseValue: cutNoise,
      threshold: 0.5,
      retryLimit: 1000,
      minArea: 1000000,
      maxArea: 100000000,
    });

    if (!cut) {
      continue;
    }

    const newResults: Shape[] = [];

    for (const shape of resultShapes) {
      const transformInfo = Transform.getRelativeTransformInfo({
        guest: new Transform(x, y, 0, 0, 1),
        host: new Transform(0, 0, 0, 0, 1),
      });

      const results = shape.subtract(cut, transformInfo);
      if (!results || results.resultShapes.length === 0) {
        newResults.push(shape);
      } else {
        newResults.push(...results.resultShapes.map((r) => r.shape));
      }
    }

    if (newResults.length === 0) {
      console.warn("Cut eliminated all shapes, reverting to previous state");
      continue;
    }

    resultShapes = newResults;
  }

  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  const material = getMaterial();

  for (let i = 0; i < resultShapes.length; i++) {
    if (resultShapes[i].area < 200) {
      continue;
    }

    bodies.push({
      body: new Body({
        id: randomUint32(),
        shape: resultShapes[i],
        material,
        type: BodyType.Standard,
        inSubplane: false,
      }),
      state: {
        position: [
          resultShapes[i].centroid.x + position[0],
          resultShapes[i].centroid.y + position[1],
          0,
        ],
        velocity: [10, 10, 0.001],
        positionLocked: false,
        rotationLocked: false,
      },
    });
  }

  const subplaneMaterial = getMaterial();
  subplaneMaterial.color = Color.fromName("darkgray");

  const subplaneBody = new Body({
    id: randomUint32(),
    shape: subplane,
    material: subplaneMaterial,
    type: BodyType.Standard,
    inSubplane: true,
  });

  planar.addBody(subplaneBody);
  planar.loadBody({
    body: subplaneBody,
    state: {
      position: [position[0], position[1], 0],
      velocity: [0, 0, 0],
      positionLocked: false,
      rotationLocked: false,
    },
  });

  for (const body of bodies) {
    planar.addBody(body.body);
    planar.loadBody(body);

    // If the area of the body is above a threshold, add it to the composite
    // if (body.body.shape.area > 1000) {
    planar.plane.composeBodies(subplaneBody, body.body);
    // }
  }
}
