import type { Transform } from "../math/transform";
import { Vector2D } from "../math/vector2D";
import {
  StoredPointType,
  type IntersectionPoint,
  type Segment,
  type SubtractionResultShape,
  type Span,
  type StoredPoint,
} from "../models";
import type { SurfacePoint } from "../plane/surfacePoint";
import { Shape } from "../shapes/shape";

export function getIntersectionSpans({
  intersections,
  union = false,
}: {
  intersections: [IntersectionPoint[], IntersectionPoint[]];
  union: boolean;
}): Span[][] {
  let currentIntersection: IntersectionPoint;
  let onHost = false;
  let currentIntersections = intersections[0];
  let nextIntersection: IntersectionPoint;
  let count = 0;
  let forwards = true;
  let forwardsSpans: Span[] = [];
  let backwardsSpans: Span[] = [];
  const spanShapes: Span[][] = [];

  currentIntersection = currentIntersections[0];

  while (true) {
    count++;

    if (count > 100) {
      throw new Error("Infinite loop");
    }

    currentIntersection.visited = true;

    forwards = union
      ? !currentIntersection.entering
      : currentIntersection.entering;

    if (forwards) {
      nextIntersection = currentIntersections[currentIntersection.nextIndex!];
    } else {
      nextIntersection =
        currentIntersections[currentIntersection.previousIndex!];
    }

    if (nextIntersection === currentIntersection) {
      break;
    }

    if (currentIntersection.onCircle) {
      if (forwards) {
        forwardsSpans.push({
          onHost,
          onCircle: true,
          start: {
            segmentIndex: currentIntersection.segmentIndex,
            t: Math.abs(currentIntersection.t),
            point: currentIntersection.point.clone(),
          },
          end: {
            segmentIndex: nextIntersection.segmentIndex,
            t: Math.abs(nextIntersection.t),
            point: nextIntersection.point.clone(),
          },
        });
      } else {
        backwardsSpans.push({
          onHost,
          onCircle: true,
          start: {
            segmentIndex: nextIntersection.segmentIndex,
            t: Math.abs(nextIntersection.t),
            point: nextIntersection.point.clone(),
          },
          end: {
            segmentIndex: currentIntersection.segmentIndex,
            t: Math.abs(currentIntersection.t),
            point: currentIntersection.point.clone(),
          },
        });
      }
    } else {
      if (forwards) {
        forwardsSpans.push({
          onHost,
          onCircle: false,
          start: {
            segmentIndex: currentIntersection.segmentIndex,
            t: currentIntersection.t,
            point: currentIntersection.point.clone(),
          },
          end: {
            segmentIndex: nextIntersection.segmentIndex,
            t: nextIntersection.t,
            point: nextIntersection.point.clone(),
          },
        });
      } else {
        backwardsSpans.push({
          onHost,
          onCircle: false,
          start: {
            segmentIndex: nextIntersection.segmentIndex,
            t: nextIntersection.t,
            point: nextIntersection.point.clone(),
          },
          end: {
            segmentIndex: currentIntersection.segmentIndex,
            t: currentIntersection.t,
            point: currentIntersection.point.clone(),
          },
        });
      }
    }

    // Get the counterpart of the next intersection
    const counterpart = nextIntersection.counterpart;
    nextIntersection.visited = true;
    currentIntersections =
      currentIntersections === intersections[0]
        ? intersections[1]
        : intersections[0];
    onHost = currentIntersections === intersections[1];

    // If the counterpart is visited, try to find another unvisited intersection
    if (counterpart?.visited) {
      spanShapes.push(forwardsSpans.concat(backwardsSpans.reverse()));

      currentIntersection = currentIntersections.find(
        (intersection) => !intersection.visited
      )!;

      // If there are no more unvisited intersections, we're done
      if (currentIntersection === undefined) {
        break;
      }

      forwardsSpans = [];
      backwardsSpans = [];
    } else {
      // Set the current intersection to the counterpart
      currentIntersection = counterpart!;
    }
  }

  return spanShapes;
}

export function buildShapesFromSpans(
  guestShape: Shape,
  hostShape: Shape,
  spanShapes: Span[][],
  guestToHost: Transform
): {
  shape: Shape;
  subtractionResultShape: SubtractionResultShape;
  surfacePoints: SurfacePoint[];
}[] {
  const shapes: {
    shape: Shape;
    subtractionResultShape: SubtractionResultShape;
    surfacePoints: SurfacePoint[];
  }[] = [];

  for (const spanShape of spanShapes) {
    const segments: Segment[] = [];
    const spanCutShape: (Span | Segment[])[] = [];
    const storedPoints: StoredPoint[] = [];
    const accumulators = {
      area: 0,
      weightedCentroidX: 0,
      weightedCentroidY: 0,
      secondMomentOfArea: 0,
    };
    for (const span of spanShape) {
      const shape = span.onHost ? hostShape : guestShape;
      if (span.onHost) {
        shape.addSegmentsInSpan(span, segments, storedPoints, accumulators);
        spanCutShape.push(span);
      } else {
        const numSegmentsAdded = shape.addSegmentsInSpan(
          span,
          segments,
          storedPoints,
          accumulators,
          guestToHost
        );

        // Get the segments that were added
        const segmentsAdded = segments.slice(-numSegmentsAdded);
        spanCutShape.push(segmentsAdded);
      }
    }

    const finalCentroid = new Vector2D(
      accumulators.weightedCentroidX / accumulators.area,
      accumulators.weightedCentroidY / accumulators.area
    );

    const finalCentroidLengthSquared =
      finalCentroid.x * finalCentroid.x + finalCentroid.y * finalCentroid.y;

    const finalSecondMomentOfArea =
      accumulators.secondMomentOfArea -
      accumulators.area * finalCentroidLengthSquared;

    const surfacePoints: SurfacePoint[] = [];
    const mecPointIds: string[] = [];
    for (const storedPoint of storedPoints) {
      switch (storedPoint.type) {
        case StoredPointType.SURFACE_POINT:
          surfacePoints.push(storedPoint.data as SurfacePoint);
          break;
        case StoredPointType.MEC_POINT:
          mecPointIds.push(storedPoint.data as string);
          break;
      }
    }

    let mec:
      | {
          center: Vector2D;
          radius: number;
          mecPointIds: string[];
        }
      | undefined = undefined;

    const hostMec = hostShape.mec;

    if (hostMec.mecPointIds.length > 3) {
      console.error("Host MEC has more than 3 points");
    }
    // if (mecPointIds.length > 3) {
    //   console.error("Result MEC has more than 3 points");
    // }

    if (
      hostMec.mecPointIds.length !== 0 &&
      mecPointIds.length === hostMec.mecPointIds.length
    ) {
      let useOldMec = true;

      // Check that all mecPointIds are present in the hostMec
      for (const mecPointId of mecPointIds) {
        if (!hostMec.mecPointIds.includes(mecPointId)) {
          // console.error("Mec point not found in host MEC");
          useOldMec = false;
          break;
        }
      }

      if (useOldMec) {
        mec = {
          center: hostMec.center,
          radius: hostMec.radius,
          mecPointIds,
        };
      }
    }

    const shapeProperties = {
      area: accumulators.area,
      centroid: finalCentroid,
      secondMomentOfArea: finalSecondMomentOfArea,
      mec,
    };

    const shape = new Shape(segments, shapeProperties);

    const fullShapeProperties = {
      ...shapeProperties,
      mec: shape.mec,
    };

    shapes.push({
      shape,
      subtractionResultShape: {
        spans: spanCutShape,
        shapeProperties: fullShapeProperties,
      },
      surfacePoints,
    });
  }

  return shapes;
}
