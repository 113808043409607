import { normalizeAngle } from "../../math/utils.ts";
import { TWO_PI } from "../../math/utils.ts";
import type { Vector2D } from "../../math/vector2D.ts";
import type { Joint } from "../../models.ts";
import type { Body } from "../body.ts";
import type { SurfacePoint } from "../surfacePoint.ts";

export type PinConfig = {
  desiredLength: number;
  restitution: number;
  baumgarteScale: number;
  slop: number;
};

export class Pin implements Joint {
  private surfacePoint1: SurfacePoint;
  private surfacePoint2: SurfacePoint;
  private desiredAngle1: number;
  private desiredAngle2: number;
  private desiredLength: number;
  private restitution: number;
  private baumgarteScale: number;
  private slop: number;
  private destroyed: boolean = false;
  constructor(
    surfacePoint1: SurfacePoint,
    surfacePoint2: SurfacePoint,
    config: PinConfig
  ) {
    this.surfacePoint1 = surfacePoint1;
    this.surfacePoint2 = surfacePoint2;

    const angles = this.getAngles();
    this.desiredAngle1 = angles.angle1;
    this.desiredAngle2 = angles.angle2;

    this.desiredLength = config.desiredLength;
    this.restitution = config.restitution;
    this.baumgarteScale = config.baumgarteScale;
    this.slop = config.slop;

    this.surfacePoint1.joint = this;
    this.surfacePoint2.joint = this;
  }

  getAngles(): { angle1: number; angle2: number } {
    const body1 = this.surfacePoint1.getBody();
    const body2 = this.surfacePoint2.getBody();
    const pos1 = this.surfacePoint1.getPosition();
    const pos2 = this.surfacePoint2.getPosition();
    const localPos1 = this.surfacePoint1.getLocalPosition();
    const localPos2 = this.surfacePoint2.getLocalPosition();

    if (
      body1 === null ||
      body2 === null ||
      pos1 === null ||
      pos2 === null ||
      localPos1 === null ||
      localPos2 === null
    ) {
      return { angle1: 0, angle2: 0 };
    }

    const transform1 = body1.getTransform();
    const transform2 = body2.getTransform();

    const pos1inLocal2 = transform2.applyInverse(pos1);
    const pos2inLocal1 = transform1.applyInverse(pos2);

    const angle1 = normalizeAngle(localPos1.angleTo(pos2inLocal1));
    const angle2 = normalizeAngle(localPos2.angleTo(pos1inLocal2));

    return { angle1, angle2 };
  }

  getAngleDifferences(): {
    angleDifference1: number;
    angleDifference2: number;
  } {
    const { angle1, angle2 } = this.getAngles();
    return {
      angleDifference1: minorAngleBetweenAngles(angle1, this.desiredAngle1),
      angleDifference2: minorAngleBetweenAngles(angle2, this.desiredAngle2),
    };
  }

  apply(): Body[] {
    if (this.destroyed) {
      return [];
    }

    const impulseInfo1 = this.surfacePoint1.getImpulseInfo();
    const impulseInfo2 = this.surfacePoint2.getImpulseInfo();

    if (impulseInfo1 === null || impulseInfo2 === null) {
      return [];
    }

    const {
      position: pos1,
      velocity: vel1,
      r: r1,
      invMass: invMass1,
      invInertia: invInertia1,
    } = impulseInfo1;
    const {
      position: pos2,
      velocity: vel2,
      r: r2,
      invMass: invMass2,
      invInertia: invInertia2,
    } = impulseInfo2;

    const correctedBodies: Body[] = [];

    // Skip if both objects have infinite mass
    if (invMass1 === 0 && invMass2 === 0) return [];

    // Calculate current length and direction
    const displacement = pos2.subtract(pos1);
    const currentLength = displacement.magnitude();

    const direction = displacement.scale(1 / currentLength);

    const relativeVelocity = vel2.subtract(vel1);

    // Project relative velocity onto constraint direction
    const normalVelocity = relativeVelocity.dot(direction);

    // Calculate r cross n terms for the denominator
    const r1CrossN = r1.x * direction.y - r1.y * direction.x;
    const r2CrossN = r2.x * direction.y - r2.y * direction.x;

    // Calculate denominator including angular terms
    const denominator =
      invMass1 +
      invMass2 +
      r1CrossN * r1CrossN * invInertia1 +
      r2CrossN * r2CrossN * invInertia2;

    if (denominator === 0) return [];

    if (denominator === 0) {
      return correctedBodies;
    }

    // Calculate impulse scalar
    const j = (-(1 + this.restitution) * normalVelocity) / denominator;

    // Apply impulses
    const impulse = direction.multiply(j);

    if (invMass1 > 0) {
      this.surfacePoint1.applyImpulse(impulse.multiply(-1));
    }
    if (invMass2 > 0) {
      this.surfacePoint2.applyImpulse(impulse);
    }

    // Position Correction
    const penetration = currentLength - this.desiredLength;
    const correctionMagnitude =
      Math.max(penetration - this.slop, 0) * this.baumgarteScale;
    const correction = direction.multiply(correctionMagnitude / denominator);

    if (invMass1 > 0) {
      this.surfacePoint1.addPositionCorrection(correction.multiply(invMass1));
      correctedBodies.push(this.surfacePoint1.getBody()!);
    }
    if (invMass2 > 0) {
      this.surfacePoint2.addPositionCorrection(correction.multiply(-invMass2));
      correctedBodies.push(this.surfacePoint2.getBody()!);
    }

    return correctedBodies;
  }

  pointDestroy(point: SurfacePoint): void {
    this.destroyed = true;
  }

  pointTransfer(point: SurfacePoint, oldBody: Body, newBody: Body): void {}

  render(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  ): void {
    const pos1 = this.surfacePoint1.getPosition();
    const pos2 = this.surfacePoint2.getPosition();
    const transform1 = this.surfacePoint1.getBody()?.getTransform();
    const transform2 = this.surfacePoint2.getBody()?.getTransform();

    if (
      pos1 === null ||
      pos2 === null ||
      transform1 === undefined ||
      transform2 === undefined
    ) {
      return;
    }

    // Draw the pin connection
    ctx.strokeStyle = "red";
    ctx.lineWidth = 10;
    ctx.beginPath();
    ctx.moveTo(pos1.x, pos1.y);
    ctx.lineTo(pos2.x, pos2.y);
    ctx.stroke();

    const originalAngle1 = transform1.angle + this.desiredAngle1;
    const originalAngle2 = transform2.angle + this.desiredAngle2;

    // Render current angles
    const { angle1, angle2 } = this.getAngles();

    const currentAngle1 = transform1.angle + angle1;
    const currentAngle2 = transform2.angle + angle2;

    // Find difference between original and current angles
    const angleDifference1 = minorAngleBetweenAngles(
      currentAngle1,
      originalAngle1
    );
    const angleDifference2 = minorAngleBetweenAngles(
      currentAngle2,
      originalAngle2
    );

    renderAngleVector(
      ctx,
      originalAngle1,
      pos1,
      100 * Math.abs(angleDifference1),
      "green"
    );
    renderAngleVector(
      ctx,
      originalAngle2,
      pos2,
      100 * Math.abs(angleDifference2),
      "green"
    );
  }
}

function renderAngleVector(
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  angle: number,
  pos: Vector2D,
  vectorLength: number,
  color: string
): void {
  const end = {
    x: pos.x + Math.cos(angle) * vectorLength,
    y: pos.y + Math.sin(angle) * vectorLength,
  };

  ctx.lineWidth = 10;
  ctx.strokeStyle = color;

  ctx.beginPath();
  ctx.moveTo(pos.x, pos.y);
  ctx.lineTo(end.x, end.y);
  ctx.stroke();
}

function minorAngleBetweenAngles(angle1: number, angle2: number): number {
  // Determine if the difference is major or minor
  const difference = angle2 - angle1;
  if (difference > Math.PI) {
    return difference - TWO_PI;
  } else if (difference < -Math.PI) {
    return difference + TWO_PI;
  }
  return difference;
}
