import { Vector2D } from "../math/vector2D";
import {
  BodyQuadtree,
  QuadtreeCheckType,
  type BodyQuadtreeEntry,
  type BodyQuadtreePair,
} from "./bodyQuadtree";
import { Composite } from "./composite";
import { Body } from "./body";
import {
  CollisionPairKey,
  type AABB,
  EnclosureType,
  type BodyState,
  type Circle,
  type CollisionPair,
  type IntersectionPoint,
  type PointOnBodySurface,
  type BodyStateUpdate,
  type SerializableCutResult,
  type CompositeStateUpdate,
} from "../models";
import type { Shape } from "../shapes/shape";
import {
  BodyDataStride,
  BodyInfoOffset,
  BodyInfoStride,
  BodyOffset,
} from "./plane";
import type { Camera } from "../rendering/camera";
import { findShapeIntersections } from "../geometry/intersection";
import { Transform } from "../math/transform";
import { intersectSegmentTrees } from "../shapes/intersectSegmentTrees";
import { findClosestPointsBetweenSegmentPairs } from "../geometry/nearPoints";
import { randomUint32 } from "../math/utils";

export const GLOBAL_MASS_SCALE = 0.0001;

export const CompositeStateStride = 19;
export enum CompositeStateOffset {
  PositionX = 0,
  PositionY = 1,
  Rotation = 2,
  SinRotation = 3,
  CosRotation = 4,
  VelocityX = 5,
  VelocityY = 6,
  AngularVelocity = 7,
  AccelerationX = 8,
  AccelerationY = 9,
  AngularAcceleration = 10,
  OriginX = 11,
  OriginY = 12,
  LocalCentroidX = 13,
  LocalCentroidY = 14,
  Mass = 15,
  InvMass = 16,
  MomentOfInertia = 17,
  InvMomentOfInertia = 18,
}

export const CompositeInfoStride = 5;
export enum CompositeInfoOffset {
  PositionLocked = 0,
  RotationLocked = 1,
  NumBodies = 2,
  NumPositionLocked = 3,
  NumRotationLocked = 4,
}

export const NO_COMPOSITE = 0xffff; // 65535 - indicates no composite

const MAX_COMPOSITES = 500;

export class BodyStore {
  count: number = 0;
  bodyStates: Float32Array;
  bodyInfo: Uint16Array;
  bodyCompositeIds: Uint32Array;
  bodyIds: Uint32Array;
  idToBody: Map<number, Body>;
  compositeStates: Float32Array;
  compositeInfo: Uint16Array;
  compositeIds: Uint32Array;
  idToComposite: Map<number, Composite> = new Map();
  nextIndex: number = 0;
  bodies: (Body | undefined)[] = [];
  freeIndices: number[] = [];
  composites: (Composite | undefined)[] = [];
  freeCompositeIndices: number[] = [];
  nextCompositeIndex: number = 0;
  compositeCount: number = 0;
  private ignoredCollisionPairs: Set<string> = new Set();
  gravity: number = 0;
  velocityDamping: number = 1;
  angularVelocityDamping: number = 1;
  worldSize: number;
  worldBounds: AABB;
  quadtree: BodyQuadtree;
  addedBody: (body: Body) => void = () => {};
  removedBody: (bodyId: number) => void = () => {};
  madeCut: (cutResult: SerializableCutResult) => void = () => {};
  removedComposite: (compositeId: number) => void = () => {};
  isNetworkedClient: boolean;

  private worldBoundsRestitution: number = 0.8;

  constructor({
    bodyStates,
    bodyInfo,
    bodyIds,
    bodyCompositeIds,
    idToBody,
    worldSize,
    isNetworkedClient,
  }: {
    bodyStates: Float32Array;
    bodyInfo: Uint16Array;
    bodyIds: Uint32Array;
    bodyCompositeIds: Uint32Array;
    idToBody: Map<number, Body>;
    worldSize: number;
    isNetworkedClient: boolean;
  }) {
    this.bodyStates = bodyStates;
    this.bodyInfo = bodyInfo;
    this.bodyIds = bodyIds;
    this.bodyCompositeIds = bodyCompositeIds;
    this.idToBody = idToBody;
    this.worldSize = worldSize;
    this.isNetworkedClient = isNetworkedClient;
    this.worldBounds = {
      left: -worldSize / 2,
      right: worldSize / 2,
      top: worldSize / 2,
      bottom: -worldSize / 2,
    };

    this.quadtree = new BodyQuadtree(worldSize);
    this.compositeStates = new Float32Array(
      MAX_COMPOSITES * CompositeStateStride
    );
    this.compositeInfo = new Uint16Array(MAX_COMPOSITES * CompositeInfoStride);
    this.compositeIds = new Uint32Array(MAX_COMPOSITES);
  }

  getBody(index: number): Body | undefined {
    return this.bodies[index];
  }

  loadBody({
    body,
    state,
    fromCut = false,
  }: {
    body: Body;
    state: BodyState;
    fromCut?: boolean;
  }): number {
    // Use a free index if available, otherwise use the current count
    const index =
      this.freeIndices.length > 0 ? this.freeIndices.pop()! : this.nextIndex++;

    body.index = index;
    body.stateStrideIndex = index * BodyDataStride;
    body.stateArray = this.bodyStates;
    body.infoStrideIndex = index * BodyInfoStride;
    body.compositeIdArray = this.bodyCompositeIds;
    body.infoArray = this.bodyInfo;

    this.bodies[index] = body;
    this.bodyStates[body.stateStrideIndex + BodyOffset.PositionX] =
      state.position[0];
    this.bodyStates[body.stateStrideIndex + BodyOffset.PositionY] =
      state.position[1];
    this.bodyStates[body.stateStrideIndex + BodyOffset.Rotation] =
      state.position[2];
    const cos = Math.cos(state.position[2]);
    const sin = Math.sin(state.position[2]);
    this.bodyStates[body.stateStrideIndex + BodyOffset.CosRotation] = cos;
    this.bodyStates[body.stateStrideIndex + BodyOffset.SinRotation] = sin;
    this.bodyStates[body.stateStrideIndex + BodyOffset.VelocityX] =
      state.velocity[0];
    this.bodyStates[body.stateStrideIndex + BodyOffset.VelocityY] =
      state.velocity[1];
    this.bodyStates[body.stateStrideIndex + BodyOffset.AngularVelocity] =
      state.velocity[2];
    this.bodyStates[body.stateStrideIndex + BodyOffset.AccelerationX] = 0;
    this.bodyStates[body.stateStrideIndex + BodyOffset.AccelerationY] = 0;
    this.bodyStates[body.stateStrideIndex + BodyOffset.AngularAcceleration] = 0;

    const shape = body.shape;

    const centroid = shape.centroid;
    const mecCenter = shape.mec.center;

    this.bodyStates[body.stateStrideIndex + BodyOffset.LocalCentroidX] =
      centroid.x;
    this.bodyStates[body.stateStrideIndex + BodyOffset.LocalCentroidY] =
      centroid.y;

    this.bodyStates[body.stateStrideIndex + BodyOffset.LocalMecPositionX] =
      mecCenter.x;
    this.bodyStates[body.stateStrideIndex + BodyOffset.LocalMecPositionY] =
      mecCenter.y;

    const transformOffsetX =
      -centroid.x * cos - -centroid.y * sin + state.position[0];
    const transformOffsetY =
      -centroid.x * sin + -centroid.y * cos + state.position[1];

    this.bodyStates[body.stateStrideIndex + BodyOffset.TransformOffsetX] =
      transformOffsetX;
    this.bodyStates[body.stateStrideIndex + BodyOffset.TransformOffsetY] =
      transformOffsetY;

    const mecX = mecCenter.x * cos - mecCenter.y * sin + transformOffsetX;
    const mecY = mecCenter.x * sin + mecCenter.y * cos + transformOffsetY;
    const mecRadius = body.shape.mec.radius;

    this.bodyStates[body.stateStrideIndex + BodyOffset.MecPositionX] = mecX;
    this.bodyStates[body.stateStrideIndex + BodyOffset.MecPositionY] = mecY;
    this.bodyStates[body.stateStrideIndex + BodyOffset.MecRadius] = mecRadius;

    const densityFactor = body.material.density * GLOBAL_MASS_SCALE;
    const mass = shape.area * densityFactor;
    const momentOfInertia = shape.secondMomentOfArea * densityFactor;
    const positionLocked = state.positionLocked;
    const rotationLocked = state.rotationLocked;

    this.bodyStates[body.stateStrideIndex + BodyOffset.Mass] = mass;
    this.bodyStates[body.stateStrideIndex + BodyOffset.InvMass] = positionLocked
      ? 0
      : 1 / mass;
    this.bodyStates[body.stateStrideIndex + BodyOffset.MomentOfInertia] =
      momentOfInertia;
    this.bodyStates[body.stateStrideIndex + BodyOffset.InvMomentOfInertia] =
      rotationLocked ? 0 : 1 / momentOfInertia;

    this.bodyInfo[body.infoStrideIndex + BodyInfoOffset.PositionLocked] =
      positionLocked ? 1 : 0;
    this.bodyInfo[body.infoStrideIndex + BodyInfoOffset.RotationLocked] =
      rotationLocked ? 1 : 0;
    this.bodyInfo[body.infoStrideIndex + BodyInfoOffset.CompositeIndex] =
      NO_COMPOSITE;
    this.bodyCompositeIds[index] = 0;
    this.bodyInfo[body.infoStrideIndex + BodyInfoOffset.InSubplane] =
      body.inSubplane ? 1 : 0;
    this.bodyInfo[body.infoStrideIndex + BodyInfoOffset.Active] = 1;

    this.bodyIds[index] = body.id;
    this.idToBody.set(body.id, body);

    const mec = {
      x: mecX,
      y: mecY,
      radius: mecRadius,
    };

    this.quadtree.insert({
      index,
      body,
      circle: mec,
    });

    if (!fromCut) {
      this.addedBody(body);
    }

    this.count++;

    return index;
  }

  unloadBody(id: number, fromCut = false): void {
    const index = this.idToBody.get(id)?.index;
    if (index === undefined) {
      console.error(`Body ${id} not found`);
      return;
    }

    if (index < 0 || index >= this.nextIndex) {
      // console.warn(`Invalid body index: ${index}`);
      return;
    }

    const body = this.bodies[index];

    if (!body) {
      console.error("Body not found");
      return;
    }

    // Clear the body data in the Float32Array
    const stateStride = index * BodyDataStride;
    for (let i = 0; i < BodyDataStride; i++) {
      this.bodyStates[stateStride + i] = 0;
    }

    const infoStride = index * BodyInfoStride;
    for (let i = 0; i < BodyInfoStride; i++) {
      this.bodyInfo[infoStride + i] = 0;
    }

    this.bodyIds[index] = 0;
    this.idToBody.delete(body.id);

    body.index = -1;
    body.stateStrideIndex = -1;
    body.stateArray = null;
    body.infoStrideIndex = -1;
    body.infoArray = null;

    this.bodies[index] = undefined;

    // Add the index to the free list
    this.freeIndices.push(index);

    // Remove any ignored collision pairs involving this body
    this.cleanupIgnoredCollisionPairs(index);
    body.removeFromQuadtree();

    if (!fromCut) {
      this.removedBody(body.id);
    }

    this.count--;
  }

  activateBody(body: Body): void {
    if (!body.isLoaded()) {
      return;
    }

    if (body.isActive()) {
      return;
    }

    body.activate();

    body.removeFromQuadtree();

    this.quadtree.insert({
      index: body.index,
      body,
      circle: body.getMec(),
    });
  }

  deactivateBody(body: Body): void {
    if (!body.isLoaded()) {
      return;
    }

    body.deactivate();

    body.removeFromQuadtree();
  }

  addCompositeFromRemote(state: CompositeStateUpdate): void {
    const index =
      this.freeCompositeIndices.length > 0
        ? this.freeCompositeIndices.pop()!
        : this.nextCompositeIndex++;

    const composite = new Composite(
      state.id,
      index,
      this.compositeStates,
      this.compositeInfo
    );

    const stateStride = index * CompositeStateStride;
    const infoStride = index * CompositeInfoStride;

    this.compositeIds[index] = state.id;
    this.compositeStates[stateStride + CompositeStateOffset.InvMass] =
      state.invMass;
    this.compositeStates[
      stateStride + CompositeStateOffset.InvMomentOfInertia
    ] = state.invMomentOfInertia;
    this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidX] =
      state.localCentroidX;
    this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidY] =
      state.localCentroidY;
    this.compositeStates[stateStride + CompositeStateOffset.PositionX] =
      state.positionX;
    this.compositeStates[stateStride + CompositeStateOffset.PositionY] =
      state.positionY;
    this.compositeStates[stateStride + CompositeStateOffset.Rotation] =
      state.rotation;
    this.compositeStates[stateStride + CompositeStateOffset.VelocityX] =
      state.velocityX;
    this.compositeStates[stateStride + CompositeStateOffset.VelocityY] =
      state.velocityY;
    this.compositeStates[stateStride + CompositeStateOffset.AngularVelocity] =
      state.angularVelocity;
    this.compositeInfo[infoStride + CompositeInfoOffset.PositionLocked] =
      state.positionLocked ? 1 : 0;
    this.compositeInfo[infoStride + CompositeInfoOffset.RotationLocked] =
      state.rotationLocked ? 1 : 0;

    this.composites[index] = composite;
    this.idToComposite.set(state.id, composite);
    this.compositeCount++;
  }

  addComposite(initialBody: Body): Composite | null {
    const inComposite =
      this.bodyInfo[
        initialBody.infoStrideIndex + BodyInfoOffset.CompositeIndex
      ] !== NO_COMPOSITE;

    if (inComposite) {
      console.error("Body already in composite");
      return null;
    }

    const index =
      this.freeCompositeIndices.length > 0
        ? this.freeCompositeIndices.pop()!
        : this.nextCompositeIndex++;

    const compositeId = randomUint32();

    const stateStride = index * CompositeStateStride;
    const infoStride = index * CompositeInfoStride;

    const composite = new Composite(
      compositeId,
      index,
      this.compositeStates,
      this.compositeInfo
    );
    composite.bodies.push(initialBody);

    this.composites[index] = composite;

    initialBody.composite = composite;

    const {
      x,
      y,
      rotation,
      sinRotation,
      cosRotation,
      velocityX,
      velocityY,
      angularVelocity,
      mass,
      momentOfInertia,
      positionLocked,
      rotationLocked,
    } = initialBody.getFullState();

    this.compositeStates[stateStride + CompositeStateOffset.PositionX] = x;
    this.compositeStates[stateStride + CompositeStateOffset.PositionY] = y;
    this.compositeStates[stateStride + CompositeStateOffset.Rotation] =
      rotation;
    this.compositeStates[stateStride + CompositeStateOffset.CosRotation] =
      cosRotation;
    this.compositeStates[stateStride + CompositeStateOffset.SinRotation] =
      sinRotation;
    this.compositeStates[stateStride + CompositeStateOffset.VelocityX] =
      velocityX;
    this.compositeStates[stateStride + CompositeStateOffset.VelocityY] =
      velocityY;
    this.compositeStates[stateStride + CompositeStateOffset.AngularVelocity] =
      angularVelocity;
    this.compositeStates[stateStride + CompositeStateOffset.AccelerationX] = 0;
    this.compositeStates[stateStride + CompositeStateOffset.AccelerationY] = 0;
    this.compositeStates[
      stateStride + CompositeStateOffset.AngularAcceleration
    ] = 0;

    this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidX] = 0;
    this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidY] = 0;

    this.compositeStates[stateStride + CompositeStateOffset.OriginX] = x;
    this.compositeStates[stateStride + CompositeStateOffset.OriginY] = y;

    this.compositeStates[stateStride + CompositeStateOffset.Mass] = mass;
    this.compositeStates[stateStride + CompositeStateOffset.InvMass] =
      positionLocked ? 0 : 1 / mass;
    this.compositeStates[stateStride + CompositeStateOffset.MomentOfInertia] =
      momentOfInertia;
    this.compositeStates[
      stateStride + CompositeStateOffset.InvMomentOfInertia
    ] = rotationLocked ? 0 : 1 / momentOfInertia;

    this.compositeIds[index] = compositeId;
    this.compositeInfo[infoStride + CompositeInfoOffset.PositionLocked] =
      positionLocked ? 1 : 0;
    this.compositeInfo[infoStride + CompositeInfoOffset.RotationLocked] =
      rotationLocked ? 1 : 0;
    this.compositeInfo[infoStride + CompositeInfoOffset.NumBodies] = 1;

    this.bodyInfo[initialBody.infoStrideIndex + BodyInfoOffset.CompositeIndex] =
      index;
    this.bodyCompositeIds[initialBody.index] = compositeId;

    this.idToComposite.set(compositeId, composite);

    this.compositeCount++;

    return composite;
  }

  removeComposite(compositeIndex: number): void {
    const composite = this.composites[compositeIndex];
    if (!composite) {
      console.error("Composite not found");
      return;
    }

    composite.destroyed = true;

    this.idToComposite.delete(composite.id);

    this.removedComposite(composite.id);

    // Clear the body data in the Float32Array
    const stateStride = composite.stateStrideIndex;
    for (let i = 0; i < CompositeStateStride; i++) {
      this.compositeStates[stateStride + i] = 0;
    }

    const infoStride = composite.infoStrideIndex;
    for (let i = 0; i < CompositeInfoStride; i++) {
      this.compositeInfo[infoStride + i] = 0;
    }

    this.composites[composite.index] = undefined;

    this.compositeIds[composite.index] = 0;

    // Add the index to the free list
    this.freeCompositeIndices.push(composite.index);

    this.compositeCount--;
  }

  update(dt: number): {
    bodyIndex: number;
    mec: Circle;
    updateType: QuadtreeCheckType;
  }[] {
    this.updateComposites(dt);
    return this.updateBodies(dt);
  }

  applyStateUpdate(bodyStates: Map<number, BodyStateUpdate>): void {
    for (let i = 0; i < this.nextIndex; i++) {
      const bodyId = this.bodyIds[i];

      if (bodyId === 0) {
        continue;
      }

      const update = bodyStates.get(bodyId);
      if (!update) {
        // Deactivate body
        this.bodyInfo[i * BodyInfoStride + BodyInfoOffset.Active] = 0;
        continue;
      }

      this.bodyStates[i * BodyDataStride + BodyOffset.PositionX] =
        update.positionX;
      this.bodyStates[i * BodyDataStride + BodyOffset.PositionY] =
        update.positionY;
      this.bodyStates[i * BodyDataStride + BodyOffset.Rotation] =
        update.rotation;
      this.bodyStates[i * BodyDataStride + BodyOffset.VelocityX] =
        update.velocityX;
      this.bodyStates[i * BodyDataStride + BodyOffset.VelocityY] =
        update.velocityY;
      this.bodyStates[i * BodyDataStride + BodyOffset.AngularVelocity] =
        update.angularVelocity;
      this.bodyInfo[i * BodyInfoStride + BodyInfoOffset.PositionLocked] =
        update.positionLocked ? 1 : 0;
      this.bodyInfo[i * BodyInfoStride + BodyInfoOffset.RotationLocked] =
        update.rotationLocked ? 1 : 0;
      this.bodyInfo[i * BodyInfoStride + BodyInfoOffset.Active] = 1;

      bodyStates.delete(bodyId);

      if (update.compositeInfo) {
        // Check if the id matches
        if (this.bodyCompositeIds[i] !== update.compositeInfo.id) {
          const body = this.bodies[i];
          if (!body) {
            console.error("Body not found");
            continue;
          }

          const composite = this.idToComposite.get(update.compositeInfo.id);
          if (!composite) {
            console.error("Composite not found:", update.compositeInfo.id);
            continue;
          }

          body.setCompositeInfo(
            composite,
            update.compositeInfo.localX,
            update.compositeInfo.localY,
            update.compositeInfo.localRotation,
            composite.index,
            composite.id
          );
        } else {
          this.bodyStates[i * BodyDataStride + BodyOffset.CompositeLocalX] =
            update.compositeInfo.localX;
          this.bodyStates[i * BodyDataStride + BodyOffset.CompositeLocalY] =
            update.compositeInfo.localY;
          this.bodyStates[
            i * BodyDataStride + BodyOffset.CompositeLocalRotation
          ] = update.compositeInfo.localRotation;
        }
      } else {
        // Check if the body is currently in a composite
        const bodyCompositeId = this.bodyCompositeIds[i];
        if (bodyCompositeId !== 0) {
          const body = this.bodies[i];
          if (!body) {
            console.error("Body not found");
            continue;
          }

          body.clearCompositeInfo();
        }
      }
    }
  }

  applyCompositeStateUpdate(compositeStates: CompositeStateUpdate[]): void {
    for (const state of compositeStates) {
      const composite = this.idToComposite.get(state.id);
      if (!composite) {
        this.addCompositeFromRemote(state);
        continue;
      }

      const index = composite.index;
      const stateStride = index * CompositeStateStride;
      const infoStride = index * CompositeInfoStride;

      this.compositeStates[stateStride + CompositeStateOffset.InvMass] =
        state.invMass;
      this.compositeStates[
        stateStride + CompositeStateOffset.InvMomentOfInertia
      ] = state.invMomentOfInertia;
      this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidX] =
        state.localCentroidX;
      this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidY] =
        state.localCentroidY;
      this.compositeStates[stateStride + CompositeStateOffset.PositionX] =
        state.positionX;
      this.compositeStates[stateStride + CompositeStateOffset.PositionY] =
        state.positionY;
      this.compositeStates[stateStride + CompositeStateOffset.Rotation] =
        state.rotation;
      this.compositeStates[stateStride + CompositeStateOffset.VelocityX] =
        state.velocityX;
      this.compositeStates[stateStride + CompositeStateOffset.VelocityY] =
        state.velocityY;
      this.compositeStates[stateStride + CompositeStateOffset.AngularVelocity] =
        state.angularVelocity;
      this.compositeInfo[infoStride + CompositeInfoOffset.PositionLocked] =
        state.positionLocked ? 1 : 0;
      this.compositeInfo[infoStride + CompositeInfoOffset.RotationLocked] =
        state.rotationLocked ? 1 : 0;
    }
  }

  updateBodies(dt: number): {
    bodyIndex: number;
    mec: Circle;
    updateType: QuadtreeCheckType;
  }[] {
    const needsQuadtreeUpdate: {
      bodyIndex: number;
      mec: Circle;
      updateType: QuadtreeCheckType;
    }[] = [];

    // Update velocities and positions with dt
    for (let i = 0; i < this.nextIndex; i++) {
      const stateStride = i * BodyDataStride;
      const infoStride = i * BodyInfoStride;

      const compositeIndex =
        this.bodyInfo[infoStride + BodyInfoOffset.CompositeIndex];

      const isActive = this.bodyInfo[infoStride + BodyInfoOffset.Active] === 1;

      if (!isActive) {
        continue;
      }

      let updatedFromComposite = false;

      if (compositeIndex !== NO_COMPOSITE) {
        updatedFromComposite = this.updateBodyInComposite(
          i,
          stateStride,
          compositeIndex
        );
      }

      if (!updatedFromComposite) {
        const positionLocked =
          this.bodyInfo[infoStride + BodyInfoOffset.PositionLocked] === 1;
        const rotationLocked =
          this.bodyInfo[infoStride + BodyInfoOffset.RotationLocked] === 1;

        if (!positionLocked) {
          const factor = this.velocityDamping * dt;
          let velocityX =
            this.bodyStates[stateStride + BodyOffset.VelocityX] +
            this.bodyStates[stateStride + BodyOffset.AccelerationX] * factor;
          let velocityY =
            this.bodyStates[stateStride + BodyOffset.VelocityY] +
            this.bodyStates[stateStride + BodyOffset.AccelerationY] * factor;
          let positionX =
            this.bodyStates[stateStride + BodyOffset.PositionX] +
            velocityX * dt;
          let positionY =
            this.bodyStates[stateStride + BodyOffset.PositionY] +
            velocityY * dt;

          // Handle world bounds collisions with restitution
          if (positionX < this.worldBounds.left) {
            positionX = this.worldBounds.left;
            velocityX = Math.abs(velocityX) * this.worldBoundsRestitution;
          } else if (positionX > this.worldBounds.right) {
            positionX = this.worldBounds.right;
            velocityX = -Math.abs(velocityX) * this.worldBoundsRestitution;
          }

          if (positionY < this.worldBounds.bottom) {
            positionY = this.worldBounds.bottom;
            velocityY = Math.abs(velocityY) * this.worldBoundsRestitution;
          } else if (positionY > this.worldBounds.top) {
            positionY = this.worldBounds.top;
            velocityY = -Math.abs(velocityY) * this.worldBoundsRestitution;
          }

          this.bodyStates[stateStride + BodyOffset.PositionX] = positionX;
          this.bodyStates[stateStride + BodyOffset.PositionY] = positionY;
          this.bodyStates[stateStride + BodyOffset.VelocityX] = velocityX;
          this.bodyStates[stateStride + BodyOffset.VelocityY] = velocityY;
        } else {
          this.bodyStates[stateStride + BodyOffset.VelocityX] += 0;
          this.bodyStates[stateStride + BodyOffset.VelocityY] += 0;
        }

        if (!rotationLocked) {
          const factor = this.angularVelocityDamping * dt;
          this.bodyStates[stateStride + BodyOffset.AngularVelocity] +=
            this.bodyStates[stateStride + BodyOffset.AngularAcceleration] *
            factor;
          this.bodyStates[stateStride + BodyOffset.Rotation] +=
            this.bodyStates[stateStride + BodyOffset.AngularVelocity] * dt;
          this.bodyStates[stateStride + BodyOffset.CosRotation] = Math.cos(
            this.bodyStates[stateStride + BodyOffset.Rotation]
          );
          this.bodyStates[stateStride + BodyOffset.SinRotation] = Math.sin(
            this.bodyStates[stateStride + BodyOffset.Rotation]
          );
        } else {
          this.bodyStates[stateStride + BodyOffset.AngularVelocity] = 0;
        }
      }
      const cos = this.bodyStates[stateStride + BodyOffset.CosRotation];
      const sin = this.bodyStates[stateStride + BodyOffset.SinRotation];

      const localCentroidX =
        this.bodyStates[stateStride + BodyOffset.LocalCentroidX];
      const localCentroidY =
        this.bodyStates[stateStride + BodyOffset.LocalCentroidY];

      const localMecX =
        this.bodyStates[stateStride + BodyOffset.LocalMecPositionX];
      const localMecY =
        this.bodyStates[stateStride + BodyOffset.LocalMecPositionY];

      const transformOffsetX =
        -localCentroidX * cos -
        -localCentroidY * sin +
        this.bodyStates[stateStride + BodyOffset.PositionX];
      const transformOffsetY =
        -localCentroidX * sin +
        -localCentroidY * cos +
        this.bodyStates[stateStride + BodyOffset.PositionY];

      this.bodyStates[stateStride + BodyOffset.TransformOffsetX] =
        transformOffsetX;
      this.bodyStates[stateStride + BodyOffset.TransformOffsetY] =
        transformOffsetY;

      const mecX = localMecX * cos - localMecY * sin + transformOffsetX;
      const mecY = localMecX * sin + localMecY * cos + transformOffsetY;
      const mecRadius = this.bodyStates[stateStride + BodyOffset.MecRadius];

      // Update MEC position with proper rotation
      this.bodyStates[stateStride + BodyOffset.MecPositionX] = mecX;
      this.bodyStates[stateStride + BodyOffset.MecPositionY] = mecY;

      this.bodyStates[stateStride + BodyOffset.AccelerationX] = 0;
      this.bodyStates[stateStride + BodyOffset.AccelerationY] = this.gravity;
      this.bodyStates[stateStride + BodyOffset.AngularAcceleration] = 0;

      const quadtreeX = this.bodyStates[stateStride + BodyOffset.QuadtreeNodeX];
      const quadtreeY = this.bodyStates[stateStride + BodyOffset.QuadtreeNodeY];
      const quadtreeSize =
        this.bodyStates[stateStride + BodyOffset.QuadtreeNodeSize];

      const checkType =
        this.bodyInfo[infoStride + BodyInfoOffset.QuadtreeCheckType];

      const updateType = this.checkForQuadtreeUpdate({
        mecX,
        mecY,
        mecRadius,
        quadtreeX,
        quadtreeY,
        quadtreeSize,
        checkType,
      });

      if (updateType !== null) {
        needsQuadtreeUpdate.push({
          bodyIndex: i,
          mec: { x: mecX, y: mecY, radius: mecRadius },
          updateType,
        });
      }
    }

    return needsQuadtreeUpdate;
  }

  updateBodyInCompositePositions(): void {
    // Update velocities and positions with dt
    for (let i = 0; i < this.nextIndex; i++) {
      const stateStride = i * BodyDataStride;
      const infoStride = i * BodyInfoStride;

      const compositeIndex =
        this.bodyInfo[infoStride + BodyInfoOffset.CompositeIndex];

      if (compositeIndex === NO_COMPOSITE) {
        continue;
      }

      this.updateBodyInComposite(i, stateStride, compositeIndex);

      const cos = this.bodyStates[stateStride + BodyOffset.CosRotation];
      const sin = this.bodyStates[stateStride + BodyOffset.SinRotation];

      const localCentroidX =
        this.bodyStates[stateStride + BodyOffset.LocalCentroidX];
      const localCentroidY =
        this.bodyStates[stateStride + BodyOffset.LocalCentroidY];

      const localMecX =
        this.bodyStates[stateStride + BodyOffset.LocalMecPositionX];
      const localMecY =
        this.bodyStates[stateStride + BodyOffset.LocalMecPositionY];

      const transformOffsetX =
        -localCentroidX * cos -
        -localCentroidY * sin +
        this.bodyStates[stateStride + BodyOffset.PositionX];
      const transformOffsetY =
        -localCentroidX * sin +
        -localCentroidY * cos +
        this.bodyStates[stateStride + BodyOffset.PositionY];

      this.bodyStates[stateStride + BodyOffset.TransformOffsetX] =
        transformOffsetX;
      this.bodyStates[stateStride + BodyOffset.TransformOffsetY] =
        transformOffsetY;

      const mecX = localMecX * cos - localMecY * sin + transformOffsetX;
      const mecY = localMecX * sin + localMecY * cos + transformOffsetY;

      // Update MEC position with proper rotation
      this.bodyStates[stateStride + BodyOffset.MecPositionX] = mecX;
      this.bodyStates[stateStride + BodyOffset.MecPositionY] = mecY;

      this.bodyStates[stateStride + BodyOffset.AccelerationX] = 0;
      this.bodyStates[stateStride + BodyOffset.AccelerationY] = this.gravity;
      this.bodyStates[stateStride + BodyOffset.AngularAcceleration] = 0;
    }
  }

  updateQuadtree(
    updates: {
      bodyIndex: number;
      mec: Circle;
      updateType: QuadtreeCheckType;
    }[]
  ): void {
    for (const update of updates) {
      const body = this.bodies[update.bodyIndex];
      if (!body) {
        continue;
      }
      body.updateQuadtree(update.mec, update.updateType);
    }
  }

  checkForQuadtreeUpdate({
    mecX,
    mecY,
    mecRadius,
    quadtreeX,
    quadtreeY,
    quadtreeSize,
    checkType,
  }: {
    mecX: number;
    mecY: number;
    mecRadius: number;
    quadtreeX: number;
    quadtreeY: number;
    quadtreeSize: number;
    checkType: QuadtreeCheckType;
  }): QuadtreeCheckType | null {
    const minX = mecX - mecRadius;
    const maxX = mecX + mecRadius;
    const minY = mecY - mecRadius;
    const maxY = mecY + mecRadius;

    // Check if it extends past left edge
    if (minX < quadtreeX - quadtreeSize) {
      return QuadtreeCheckType.IntersectedBounds;
    }

    // Check if it extends past right edge
    if (maxX > quadtreeX + quadtreeSize) {
      return QuadtreeCheckType.IntersectedBounds;
    }

    // Check if it extends past bottom edge
    if (maxY > quadtreeY + quadtreeSize) {
      return QuadtreeCheckType.IntersectedBounds;
    }

    // Check if it extends past top edge
    if (minY < quadtreeY - quadtreeSize) {
      return QuadtreeCheckType.IntersectedBounds;
    }

    switch (checkType) {
      case QuadtreeCheckType.StoppedIntersectingVerticalMidline:
        // Check if we're not intersecting the vertical midline
        if (minX > quadtreeX || maxX < quadtreeX) {
          return QuadtreeCheckType.StoppedIntersectingVerticalMidline;
        }
        return QuadtreeCheckType.UpdateMec;
      case QuadtreeCheckType.StoppedIntersectingHorizontalMidline:
        // Check if we're not intersecting the horizontal midline
        if (minY > quadtreeY || maxY < quadtreeY) {
          return QuadtreeCheckType.StoppedIntersectingHorizontalMidline;
        }
        return QuadtreeCheckType.UpdateMec;
      case QuadtreeCheckType.StoppedIntersectingEitherMidline:
        // Check if we're not intersecting the vertical or horizontal midline
        if (
          minX > quadtreeX ||
          maxX < quadtreeX ||
          minY > quadtreeY ||
          maxY < quadtreeY
        ) {
          return QuadtreeCheckType.StoppedIntersectingEitherMidline;
        }
        return QuadtreeCheckType.UpdateMec;
      default:
        break;
    }

    return null;
  }

  updateBodyInComposite(
    bodyIndex: number,
    bodyStateStride: number,
    compositeIndex: number
  ): boolean {
    const compositeId = this.compositeIds[compositeIndex];
    if (compositeId !== this.bodyCompositeIds[bodyIndex]) {
      const body = this.bodies[bodyIndex];
      if (body) {
        body.clearCompositeInfo();
      }
      return false;
    }

    const compositeStateStride = compositeIndex * CompositeStateStride;

    const compositeOriginX =
      this.compositeStates[compositeStateStride + CompositeStateOffset.OriginX];
    const compositeOriginY =
      this.compositeStates[compositeStateStride + CompositeStateOffset.OriginY];

    const compositeRotation =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.Rotation
      ];

    const compositeCosRotation =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.CosRotation
      ];
    const compositeSinRotation =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.SinRotation
      ];

    const compositeX =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.PositionX
      ];
    const compositeY =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.PositionY
      ];
    const compositeVelocityX =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.VelocityX
      ];
    const compositeVelocityY =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.VelocityY
      ];
    const compositeAngularVelocity =
      this.compositeStates[
        compositeStateStride + CompositeStateOffset.AngularVelocity
      ];

    const bodyLocalX =
      this.bodyStates[bodyStateStride + BodyOffset.CompositeLocalX];
    const bodyLocalY =
      this.bodyStates[bodyStateStride + BodyOffset.CompositeLocalY];
    const bodyLocalRotation =
      this.bodyStates[bodyStateStride + BodyOffset.CompositeLocalRotation];

    const newBodyX =
      compositeOriginX +
      bodyLocalX * compositeCosRotation -
      bodyLocalY * compositeSinRotation;
    const newBodyY =
      compositeOriginY +
      bodyLocalX * compositeSinRotation +
      bodyLocalY * compositeCosRotation;

    this.bodyStates[bodyStateStride + BodyOffset.PositionX] = newBodyX;
    this.bodyStates[bodyStateStride + BodyOffset.PositionY] = newBodyY;

    const rotation = bodyLocalRotation + compositeRotation;
    this.bodyStates[bodyStateStride + BodyOffset.Rotation] = rotation;
    this.bodyStates[bodyStateStride + BodyOffset.CosRotation] =
      Math.cos(rotation);
    this.bodyStates[bodyStateStride + BodyOffset.SinRotation] =
      Math.sin(rotation);

    // Calculate r vector from composite center to body position
    const rx = newBodyX - compositeX;
    const ry = newBodyY - compositeY;

    // Calculate orbital velocity (-ω × r)
    const orbitalVelocityX = -compositeAngularVelocity * ry;
    const orbitalVelocityY = compositeAngularVelocity * rx;

    // Set body velocity as sum of composite velocity and orbital velocity
    this.bodyStates[bodyStateStride + BodyOffset.VelocityX] =
      compositeVelocityX + orbitalVelocityX;
    this.bodyStates[bodyStateStride + BodyOffset.VelocityY] =
      compositeVelocityY + orbitalVelocityY;

    // Set body angular velocity to match composite
    this.bodyStates[bodyStateStride + BodyOffset.AngularVelocity] =
      compositeAngularVelocity;

    return true;
  }

  // updateQuadtree(): void {
  //   this.quadtree = new BodyQuadtree(this.worldSize);

  //   // Insert all MECs into quadtree
  //   for (let i = 0; i < this.nextIndex; i++) {
  //     const mecX =
  //       this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionX];
  //     const mecY =
  //       this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionY];

  //     const body = this.bodies[i];
  //     if (!body) {
  //       continue;
  //     }

  //     this.quadtree.insert({
  //       body,
  //       circle: {
  //         x: mecX,
  //         y: mecY,
  //         radius: this.bodyStates[i * BodyDataStride + BodyOffset.MecRadius],
  //       },
  //       index: i,
  //     });
  //   }
  // }

  // Add this helper method to clean up collision pairs
  private cleanupIgnoredCollisionPairs(bodyIndex: number): void {
    // Create a new Set to store pairs we want to keep
    const updatedPairs = new Set<string>();

    // Only keep pairs that don't involve the removed body
    for (const pair of this.ignoredCollisionPairs) {
      const [body1, body2] = pair.split(",").map(Number);
      if (body1 !== bodyIndex && body2 !== bodyIndex) {
        updatedPairs.add(pair);
      }
    }

    this.ignoredCollisionPairs = updatedPairs;
  }

  updateComposites(dt: number): void {
    // Update velocities and positions with dt
    for (let i = 0; i < this.nextCompositeIndex; i++) {
      const stateStride = i * CompositeStateStride;
      const infoStride = i * CompositeInfoStride;

      const compositeId = this.compositeIds[i];

      if (compositeId === 0) {
        continue;
      }

      if (!this.isNetworkedClient) {
        const numBodies =
          this.compositeInfo[infoStride + CompositeInfoOffset.NumBodies];

        if (numBodies <= 1) {
          this.removeComposite(i);
          continue;
        }
      }

      const positionLocked =
        this.compositeInfo[infoStride + CompositeInfoOffset.PositionLocked] ===
        1;
      const rotationLocked =
        this.compositeInfo[infoStride + CompositeInfoOffset.RotationLocked] ===
        1;

      if (!positionLocked) {
        const factor = this.velocityDamping * dt;
        let velocityX =
          this.compositeStates[stateStride + CompositeStateOffset.VelocityX] +
          this.compositeStates[
            stateStride + CompositeStateOffset.AccelerationX
          ] *
            factor;
        let velocityY =
          this.compositeStates[stateStride + CompositeStateOffset.VelocityY] +
          this.compositeStates[
            stateStride + CompositeStateOffset.AccelerationY
          ] *
            factor;
        let positionX =
          this.compositeStates[stateStride + CompositeStateOffset.PositionX] +
          velocityX * dt;
        let positionY =
          this.compositeStates[stateStride + CompositeStateOffset.PositionY] +
          velocityY * dt;

        // Handle world bounds collisions with restitution
        if (positionX < this.worldBounds.left) {
          positionX = this.worldBounds.left;
          velocityX = Math.abs(velocityX) * this.worldBoundsRestitution;
        } else if (positionX > this.worldBounds.right) {
          positionX = this.worldBounds.right;
          velocityX = -Math.abs(velocityX) * this.worldBoundsRestitution;
        }

        if (positionY < this.worldBounds.bottom) {
          positionY = this.worldBounds.bottom;
          velocityY = Math.abs(velocityY) * this.worldBoundsRestitution;
        } else if (positionY > this.worldBounds.top) {
          positionY = this.worldBounds.top;
          velocityY = -Math.abs(velocityY) * this.worldBoundsRestitution;
        }

        this.compositeStates[stateStride + CompositeStateOffset.PositionX] =
          positionX;
        this.compositeStates[stateStride + CompositeStateOffset.PositionY] =
          positionY;
        this.compositeStates[stateStride + CompositeStateOffset.VelocityX] =
          velocityX;
        this.compositeStates[stateStride + CompositeStateOffset.VelocityY] =
          velocityY;
      } else {
        this.compositeStates[stateStride + CompositeStateOffset.VelocityX] += 0;
        this.compositeStates[stateStride + CompositeStateOffset.VelocityY] += 0;
      }

      if (!rotationLocked) {
        const factor = this.angularVelocityDamping * dt;
        this.compositeStates[
          stateStride + CompositeStateOffset.AngularVelocity
        ] +=
          this.compositeStates[
            stateStride + CompositeStateOffset.AngularAcceleration
          ] * factor;
        this.compositeStates[stateStride + CompositeStateOffset.Rotation] +=
          this.compositeStates[
            stateStride + CompositeStateOffset.AngularVelocity
          ] * dt;
        this.compositeStates[stateStride + CompositeStateOffset.CosRotation] =
          Math.cos(
            this.compositeStates[stateStride + CompositeStateOffset.Rotation]
          );
        this.compositeStates[stateStride + CompositeStateOffset.SinRotation] =
          Math.sin(
            this.compositeStates[stateStride + CompositeStateOffset.Rotation]
          );
      } else {
        this.compositeStates[
          stateStride + CompositeStateOffset.AngularVelocity
        ] = 0;
      }
      const cos =
        this.compositeStates[stateStride + CompositeStateOffset.CosRotation];
      const sin =
        this.compositeStates[stateStride + CompositeStateOffset.SinRotation];

      const localCentroidX =
        this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidX];
      const localCentroidY =
        this.compositeStates[stateStride + CompositeStateOffset.LocalCentroidY];

      const originX =
        -localCentroidX * cos -
        -localCentroidY * sin +
        this.compositeStates[stateStride + CompositeStateOffset.PositionX];
      const originY =
        -localCentroidX * sin +
        -localCentroidY * cos +
        this.compositeStates[stateStride + CompositeStateOffset.PositionY];

      this.compositeStates[stateStride + CompositeStateOffset.OriginX] =
        originX;
      this.compositeStates[stateStride + CompositeStateOffset.OriginY] =
        originY;

      this.compositeStates[
        stateStride + CompositeStateOffset.AccelerationX
      ] = 0;
      this.compositeStates[stateStride + CompositeStateOffset.AccelerationY] =
        this.gravity;
      this.compositeStates[
        stateStride + CompositeStateOffset.AngularAcceleration
      ] = 0;
    }
  }

  cut(target: Body, cutter: Shape, cutterPosition: Vector2D): Body[] {
    if (this.isNetworkedClient) {
      return [];
    }

    if (!target.material.destructible) {
      return [];
    }

    const cutResult = target.cut(cutter, cutterPosition);

    if (cutResult === null) {
      return [];
    }

    if (cutResult.resultBodies.length === 0) {
      return [];
    }

    for (const { body, state, surfacePoints } of cutResult.resultBodies) {
      this.loadBody({ body, state, fromCut: true });

      for (const surfacePoint of surfacePoints) {
        surfacePoint.transfer(body);

        body.addSurfacePoint(surfacePoint);
      }

      // body.textureInfo = target.getTextureInfo();
    }

    for (const surfacePoint of cutResult.destroyedSurfacePoints) {
      surfacePoint.destroy();
    }

    this.unloadBody(target.id, true);

    this.madeCut(cutResult.serializableCutResult);

    return cutResult.resultBodies.map((b) => b.body);
  }

  cutFromSerialized(cut: SerializableCutResult): Body[] | null {
    const target = this.idToBody.get(cut.targetBodyId);
    if (!target) {
      return null;
    }

    const cutResult = target.cutFromSerialized(cut);
    if (cutResult === null) {
      return null;
    }

    for (const { body, state, surfacePoints } of cutResult.resultBodies) {
      this.loadBody({ body, state, fromCut: true });

      for (const surfacePoint of surfacePoints) {
        surfacePoint.transfer(body);

        body.addSurfacePoint(surfacePoint);
      }

      // body.textureInfo = target.getTextureInfo();
    }

    for (const surfacePoint of cutResult.destroyedSurfacePoints) {
      surfacePoint.destroy();
    }

    return cutResult.resultBodies.map((b) => b.body);
  }

  explosion(position: Vector2D, radius: number, energy: number): void {
    // Get all bodies intersecting the radius
    const intersectingBodies = this.quadtree.queryCircle({
      x: position.x,
      y: position.y,
      radius,
    });

    // Constants for explosion behavior
    const minDistance = radius * 0.1; // Prevent infinite forces at epicenter
    const falloffExponent = 2; // Square law falloff

    for (const body of intersectingBodies) {
      const direction = body.body.getCenter().subtract(position);
      const distance = Math.max(direction.length(), minDistance);

      // Calculate force that falls off with square of distance
      const normalizedDistance = distance / radius;
      const forceMagnitude =
        energy * Math.pow(1 / normalizedDistance, falloffExponent);

      // Apply a maximum force cap if needed
      const clampedForce = Math.min(forceMagnitude, energy * 10);

      // Only apply force if it's significant enough
      if (clampedForce > 0.01) {
        const impulse = direction.normalize().multiply(clampedForce);
        body.body.applyImpulse(impulse);
      }
    }
  }

  // rayCast(numRays: number, position: Vector2D, radius: number): RayHit[] {
  //   const queryCircle: Circle = {
  //     x: position.x,
  //     y: position.y,
  //     radius,
  //   };

  //   // Query the quadtree for all bodies within the radius
  //   const intersectingBodies = this.quadtree.queryCircle(queryCircle);

  //   for (const body of intersectingBodies) {
  //     // Query the body for possible segments
  //     const segments = body.body.queryCircle(queryCircle);
  //   }
  // }

  getClosestPointsBetweenBodies(
    body1: Body,
    body2: Body,
    margin: number
  ): {
    point1: PointOnBodySurface;
    point2: PointOnBodySurface;
  } | null {
    const body1Transform = body1.getTransform();
    const body2Transform = body2.getTransform();

    // Find candidate segment pairs
    const relativeTransformInfo = Transform.getRelativeTransformInfo({
      guest: body1Transform,
      host: body2Transform,
    });

    const segmentPairs = intersectSegmentTrees(
      body1.shape.getSegmentTree(),
      body2.shape.getSegmentTree(),
      relativeTransformInfo.guestToHost,
      relativeTransformInfo.hostToGuest,
      margin
    );

    if (segmentPairs === null) {
      return null;
    }

    const result = findClosestPointsBetweenSegmentPairs(
      segmentPairs,
      relativeTransformInfo.guestToHost
    );

    if (!result.closestPoints) {
      return null;
    }

    const finalResult: {
      point1: PointOnBodySurface;
      point2: PointOnBodySurface;
    } = {
      point1: {
        body: body1,
        globalPosition: body1Transform.apply(
          result.closestPoints.point1.position
        ),
        perimeterPoint: result.closestPoints.point1,
      },
      point2: {
        body: body2,
        globalPosition: body2Transform.apply(
          result.closestPoints.point2.position
        ),
        perimeterPoint: result.closestPoints.point2,
      },
    };

    return finalResult;
  }

  addIgnoredCollisionPair(body1: number, body2: number): void {
    const key = new CollisionPairKey(body1, body2).toString();
    this.ignoredCollisionPairs.add(key);
  }

  removeIgnoredCollisionPair(body1: number, body2: number): void {
    const key = new CollisionPairKey(body1, body2).toString();
    this.ignoredCollisionPairs.delete(key);
  }

  shouldCheckCollision = (body1: number, body2: number): boolean => {
    const key = new CollisionPairKey(body1, body2).toString();
    return !this.ignoredCollisionPairs.has(key);
  };

  bodiesIntersect(body1: Body, body2: Body) {
    // Step 1, mecs
    const quadtreeQueryResult = this.quadtree.queryCircle(body1.getMec());

    if (quadtreeQueryResult === undefined) {
      return false;
    }

    let found = false;
    quadtreeQueryResult.forEach((entry: BodyQuadtreeEntry) => {
      if (entry.body.index === body2.index) {
        found = true;
      }
    });

    if (!found) {
      return false;
    }

    const body1Transform = body1.getTransform();
    const body2Transform = body2.getTransform();

    const segmentPairs = intersectSegmentTrees(
      body1.shape.getSegmentTree(),
      body2.shape.getSegmentTree(),
      Transform.getRelativeTransformInfo({
        guest: body1Transform,
        host: body2Transform,
      }).guestToHost,
      Transform.getRelativeTransformInfo({
        guest: body2Transform,
        host: body1Transform,
      }).guestToHost
    );

    if (segmentPairs === null) {
      return false;
    }

    let result: {
      intersections: [IntersectionPoint[], IntersectionPoint[]] | null;
      segmentCheckCount: number;
      segmentIntersectionCount: number;
    } | null = null;

    try {
      // Get intersection points
      result = findShapeIntersections(
        segmentPairs,
        Transform.getRelativeTransformInfo({
          guest: body1Transform,
          host: body2Transform,
        }).guestToHost
      );
    } catch (e) {
      return false;
    }

    if (result.intersections === null) {
      return false;
    }

    return true;
  }

  getBodiesVisibleToCamera(
    camera: Camera,
    margin: number = 0
  ): {
    mainPlaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
    subplaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[];
  } {
    const viewBounds = camera.getWorldBounds(margin);
    const screenViewSize = Math.min(camera.width, camera.height);
    const minFullDetailRadius = screenViewSize * camera.fullDetailThreshold;
    const minLODRadius = screenViewSize * camera.lodThreshold;

    const mainPlaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[] = [];

    const subplaneBodies: {
      body: Body;
      mec: Circle;
      detailed: boolean;
    }[] = [];

    // Check every body
    for (let i = 0; i < this.nextIndex; i++) {
      const body = this.getBody(i);
      if (!body) continue;

      const isActive =
        this.bodyInfo[i * BodyInfoStride + BodyInfoOffset.Active] === 1;

      if (!isActive) {
        continue;
      }

      const mec = {
        x: this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionX],
        y: this.bodyStates[i * BodyDataStride + BodyOffset.MecPositionY],
        radius: this.bodyStates[i * BodyDataStride + BodyOffset.MecRadius],
        index: i,
      };

      // Check if circle intersects with view bounds
      const circleInView =
        mec.x + mec.radius >= viewBounds.left &&
        mec.x - mec.radius <= viewBounds.right &&
        mec.y + mec.radius >= viewBounds.top &&
        mec.y - mec.radius <= viewBounds.bottom;

      if (circleInView) {
        const screenRadius = mec.radius * camera.zoom;
        let detailed = false;

        if (screenRadius >= minFullDetailRadius) {
          detailed = true;
        } else if (screenRadius >= minLODRadius) {
          detailed = false;
        }

        const inSubplane =
          this.bodyInfo[i * BodyInfoStride + BodyInfoOffset.InSubplane] === 1;

        if (inSubplane) {
          subplaneBodies.push({
            body,
            mec,
            detailed,
          });
        } else {
          mainPlaneBodies.push({
            body,
            mec,
            detailed,
          });
        }
      }
    }

    return {
      mainPlaneBodies,
      subplaneBodies,
    };
  }

  getClosestPointOnBody(
    queryCenter: Vector2D,
    queryRadius: number,
    ignoreBodies: Body[] = [],
    eligibilityCheck: (body: Body) => boolean = () => true,
    includeSubplane: boolean = false
  ): {
    distance: number;
    pointOnSurface: PointOnBodySurface;
  } | null {
    // Step 1: Define the search circle in global coordinates
    const searchCircle: Circle = {
      x: queryCenter.x,
      y: queryCenter.y,
      radius: queryRadius,
    };

    // Step 2: Query the quadtree for potential bodies within the search circle
    const results = this.quadtree.queryCircle(searchCircle);

    // If no bodies are found, return early
    if (results.length === 0) {
      return null;
    }

    let closestResult: {
      distance: number;
      pointOnSurface: PointOnBodySurface;
    } | null = null;
    let minDistance = queryRadius;

    // Step 3: Iterate through each candidate body
    for (const { body } of results) {
      if (ignoreBodies.includes(body)) {
        continue;
      }

      const inSubplane =
        this.bodyInfo[body.infoStrideIndex + BodyInfoOffset.InSubplane] === 1;

      if (!includeSubplane && inSubplane) {
        continue;
      }

      if (!eligibilityCheck(body)) continue;

      // Step 4: Get the closest point on the body to the global point
      const closestOnBody = body.getClosestPerimeterPoint(
        queryCenter,
        queryRadius
      );

      // Skip if no valid closest point is found within the query radius
      if (!closestOnBody) continue;

      // If this distance is smaller than the current minimum, update closestPoint
      if (closestOnBody.distance < minDistance) {
        minDistance = closestOnBody.distance;
        closestResult = {
          distance: closestOnBody.distance,
          pointOnSurface: {
            body,
            globalPosition: closestOnBody.globalPosition,
            perimeterPoint: closestOnBody.perimeterPoint,
          },
        };
        // Early termination if the closest possible point is found
        if (closestOnBody.distance === 0) {
          break;
        }
      }
    }

    return closestResult;
  }

  getPotentialCollisions(
    intersectingPairs: BodyQuadtreePair[]
  ): CollisionPair[] {
    const potentialCollisions: CollisionPair[] = [];

    for (const pair of intersectingPairs) {
      const body1 = pair.a.body;
      const body2 = pair.b.body;

      // Skip if this pair is in the ignored list
      if (!this.shouldCheckCollision(pair.a.index, pair.b.index)) {
        continue;
      }

      const body1Transform = body1.getTransform();
      const body2Transform = body2.getTransform();

      potentialCollisions.push({
        body1: body1,
        body2: body2,
        body1Transform: body1Transform,
        body2Transform: body2Transform,
        relativeTransformInfo: Transform.getRelativeTransformInfo({
          guest: body1Transform,
          host: body2Transform,
        }),
        segmentPairs: [],
        intersectionPoints: null,
        enclosureType: EnclosureType.None,
      });
    }

    return potentialCollisions;
  }
}
