import type { Transform } from "../math/transform";
import { Vector2D } from "../math/vector2D";
import {
  type ShapeMotionInfo,
  type IntersectionShape,
  EnclosureType,
} from "../models";
import type { Shape } from "../shapes/shape";

export function buildEnclosureShapes({
  shapes,
  transforms,
  motionInfo,
  union,
  enclosureType,
  buildShapes,
}: {
  shapes: [Shape, Shape];
  transforms: [Transform, Transform];
  union: boolean;
  enclosureType: EnclosureType;
  buildShapes: boolean;
  motionInfo?: ShapeMotionInfo;
}): IntersectionShape | undefined {
  if (enclosureType === EnclosureType.None) {
    return undefined;
  }

  // Handle static cases (no motion) or union operations
  if (!motionInfo || union) {
    return handleStaticEnclosure(
      shapes,
      transforms,
      union,
      enclosureType === EnclosureType.FirstBodyEncloses,
      buildShapes
    );
  }

  // Handle dynamic cases with motion
  return handleDynamicEnclosure(
    shapes,
    transforms,
    motionInfo,
    enclosureType === EnclosureType.FirstBodyEncloses,
    buildShapes
  );
}

function createShapeResult(
  shape: Shape,
  transform: Transform,
  buildShapes: boolean,
  penetrationVector = new Vector2D(0, 0)
): IntersectionShape {
  const centroid = shape.getCentroid(transform);
  return {
    shapes: buildShapes ? [{ shape: shape.clone(), centroid }] : [],
    totalCentroid: centroid,
    normal:
      penetrationVector.length() > 0
        ? penetrationVector.normalize()
        : new Vector2D(0, 0),
    penetrationDistance: penetrationVector.length(),
  };
}

function handleStaticEnclosure(
  shapes: [Shape, Shape],
  transforms: [Transform, Transform],
  union: boolean,
  firstShapeEncloses: boolean,
  buildShapes: boolean
): IntersectionShape {
  // Shape1 encloses shape2
  if (firstShapeEncloses) {
    return createShapeResult(
      union ? shapes[0] : shapes[1],
      union ? transforms[0] : transforms[1],
      buildShapes
    );
  }

  // Shape2 encloses shape1
  return createShapeResult(
    union ? shapes[1] : shapes[0],
    union ? transforms[1] : transforms[0],
    buildShapes
  );
}

function handleDynamicEnclosure(
  shapes: [Shape, Shape],
  transforms: [Transform, Transform],
  motionInfo: ShapeMotionInfo,
  firstShapeEncloses: boolean,
  buildShapes: boolean
): IntersectionShape {
  // Determine which shape is enclosed vs enclosing
  const { enclosed, enclosing, enclosedTransform, enclosingTransform } =
    determineShapeRoles(shapes, transforms, firstShapeEncloses);

  // Calculate velocities and motion
  const relativeMotion = calculateRelativeMotion(
    enclosedTransform,
    enclosingTransform,
    motionInfo,
    firstShapeEncloses
  );

  // Calculate penetration
  const penetrationInfo = calculatePenetration(
    enclosed,
    enclosing,
    enclosedTransform,
    enclosingTransform,
    relativeMotion,
    shapes[0] === enclosing
  );

  return createShapeResult(
    enclosed,
    enclosedTransform,
    buildShapes,
    penetrationInfo.penetrationVector
  );
}

function determineShapeRoles(
  shapes: [Shape, Shape],
  transforms: [Transform, Transform],
  firstShapeEncloses: boolean
) {
  return {
    enclosed: firstShapeEncloses ? shapes[1] : shapes[0],
    enclosing: firstShapeEncloses ? shapes[0] : shapes[1],
    enclosedTransform: firstShapeEncloses ? transforms[1] : transforms[0],
    enclosingTransform: firstShapeEncloses ? transforms[0] : transforms[1],
  };
}

function calculateRelativeMotion(
  enclosedTransform: Transform,
  enclosingTransform: Transform,
  motionInfo: ShapeMotionInfo,
  firstShapeEncloses: boolean
) {
  const enclosedCenter = new Vector2D(enclosedTransform.x, enclosedTransform.y);
  const enclosedVelocityInfo = firstShapeEncloses
    ? motionInfo.shape2
    : motionInfo.shape1;
  const enclosingVelocityInfo = firstShapeEncloses
    ? motionInfo.shape1
    : motionInfo.shape2;

  // Calculate velocities at collision point
  const relativeVelocity = calculateRelativeVelocity(
    enclosedCenter,
    enclosedTransform,
    enclosingTransform,
    enclosedVelocityInfo,
    enclosingVelocityInfo
  );

  return {
    velocity: relativeVelocity,
    center: enclosedCenter,
  };
}

function calculateRelativeVelocity(
  enclosedCenter: Vector2D,
  enclosedTransform: Transform,
  enclosingTransform: Transform,
  enclosedVelocityInfo: { vx: number; vy: number; angularVelocity: number },
  enclosingVelocityInfo: { vx: number; vy: number; angularVelocity: number }
): Vector2D {
  const MINIMUM_VELOCITY = 0.1;

  const v1 = calculatePointVelocity(
    enclosedCenter,
    enclosingTransform,
    enclosingVelocityInfo
  );

  const v2 = calculatePointVelocity(
    enclosedCenter,
    enclosedTransform,
    enclosedVelocityInfo
  );

  const relativeVelocity = v2.subtract(v1);

  // Handle near-zero velocity case
  if (relativeVelocity.lengthSquared() < MINIMUM_VELOCITY * MINIMUM_VELOCITY) {
    const randomAngle = Math.random() * Math.PI * 2;
    return new Vector2D(Math.cos(randomAngle), Math.sin(randomAngle));
  }

  return relativeVelocity.normalize();
}

function calculatePointVelocity(
  point: Vector2D,
  transform: Transform,
  velocityInfo: { vx: number; vy: number; angularVelocity: number }
): Vector2D {
  const r = point.subtract(new Vector2D(transform.x, transform.y));
  const tangentialVelocity = new Vector2D(
    -velocityInfo.angularVelocity * r.y,
    velocityInfo.angularVelocity * r.x
  );
  return new Vector2D(velocityInfo.vx, velocityInfo.vy).add(tangentialVelocity);
}

function calculatePenetration(
  enclosed: Shape,
  enclosing: Shape,
  enclosedTransform: Transform,
  enclosingTransform: Transform,
  motion: { velocity: Vector2D; center: Vector2D },
  isEnclosingFirst: boolean
) {
  const lineStart = motion.center;
  const lineEnd = lineStart.subtract(motion.velocity.scale(100000000));

  const intersectionsOnEnclosed = enclosed.getLineIntersections(
    lineStart,
    lineEnd,
    enclosedTransform
  );
  const intersectionsOnEnclosing = enclosing.getLineIntersections(
    lineStart,
    lineEnd,
    enclosingTransform
  );

  const firstOnEnclosed = intersectionsOnEnclosed[0];
  if (!firstOnEnclosed) {
    return { penetrationVector: motion.velocity };
  }

  const firstOnEnclosing = intersectionsOnEnclosing.find(
    (intersection) => intersection.t > firstOnEnclosed.t
  );
  if (!firstOnEnclosing) {
    return { penetrationVector: motion.velocity };
  }

  return {
    penetrationVector: isEnclosingFirst
      ? firstOnEnclosed.point.subtract(firstOnEnclosing.point)
      : firstOnEnclosing.point.subtract(firstOnEnclosed.point),
  };
}
