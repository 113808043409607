import { nanoid } from "nanoid";
import { Vector2D } from "../math/vector2D";
import type {
  AABB,
  BodyState,
  BodyType,
  Circle,
  CollisionInfo,
  CollisionTrigger,
  FullBodyState,
  LightSource,
  Material,
  PerimeterPoint,
  Segment,
  SegmentInfo,
  SerializableCutResult,
  ShouldResolveCollisionCheck,
  SubtractionResultShape,
} from "../models";
import { Shape } from "../shapes/shape";
import type { Composite } from "./composite";
import { BodyInfoOffset, BodyOffset } from "./plane";
import { Transform } from "../math/transform";
import { SurfacePoint } from "./surfacePoint";
import { NO_COMPOSITE } from "./bodyStore";
import { QuadtreeCheckType, type BodyQuadtreeNode } from "./bodyQuadtree";
import { randomUint32 } from "../math/utils";

export const DEFAULT_SHADOW_CONFIG = {
  baseRadius: 3000,
  stops: [
    { position: 0, opacity: 1 },
    { position: 0.1, opacity: 0.4 },
    { position: 0.25, opacity: 0.2 },
    { position: 0.5, opacity: 0.1 },
    { position: 1, opacity: 0 },
  ],
};
export const DEFAULT_SHADOW_POINT_DISTANCE = 400;

export class Body {
  id: number;
  index: number = -1;
  stateStrideIndex: number = -1;
  infoStrideIndex: number = -1;
  stateArray: Float32Array | null = null;
  infoArray: Uint16Array | null = null;
  compositeIdArray: Uint32Array | null = null;

  inSubplane: boolean = false;

  quadtreeNode: BodyQuadtreeNode | null = null;

  type: BodyType;

  collisions: CollisionInfo[] = [];
  positionCorrections: Vector2D[] = [];
  angleCorrections: number[] = [];

  shape: Shape;
  material: Material;

  composite: Composite | null = null;

  shouldResolveCollisionChecks: Map<
    string,
    ShouldResolveCollisionCheck
  > | null = null;
  collisionTriggers: Map<string, CollisionTrigger> | null = null;

  light: LightSource | null = null;

  // textureInfo: {
  //   texture: ImageBitmap;
  //   textureScale: number;
  //   originalBounds: AABB;
  // } | null = null;

  constructor({
    id,
    shape,
    material,
    type,
    inSubplane,
    light,
  }: {
    id: number;
    shape: Shape;
    material: Material;
    type: BodyType;
    inSubplane: boolean;
    light?: LightSource;
  }) {
    this.id = id;
    this.shape = shape;
    this.material = material;
    this.type = type;
    this.inSubplane = inSubplane;
    this.light = light ?? null;
  }

  isLoaded(): boolean {
    return this.stateArray !== null;
  }

  isActive(): boolean {
    if (!this.infoArray) {
      return false;
    }
    return this.infoArray[this.infoStrideIndex + BodyInfoOffset.Active] === 1;
  }

  activate(): void {
    if (!this.infoArray) {
      return;
    }
    this.infoArray[this.infoStrideIndex + BodyInfoOffset.Active] = 1;
  }

  deactivate(): void {
    if (!this.infoArray) {
      return;
    }
    this.infoArray[this.infoStrideIndex + BodyInfoOffset.Active] = 0;
  }

  getTransform(): Transform {
    if (!this.stateArray) {
      return new Transform(0, 0, 0, 0, 0);
    }

    return new Transform(
      this.stateArray[this.stateStrideIndex + BodyOffset.TransformOffsetX],
      this.stateArray[this.stateStrideIndex + BodyOffset.TransformOffsetY],
      this.stateArray[this.stateStrideIndex + BodyOffset.Rotation],
      this.stateArray[this.stateStrideIndex + BodyOffset.SinRotation],
      this.stateArray[this.stateStrideIndex + BodyOffset.CosRotation]
    );
  }

  getPosition(): [number, number, number] {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionX],
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionY],
      this.stateArray[this.stateStrideIndex + BodyOffset.Rotation],
    ];
  }

  getVelocity(): [number, number, number] {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX],
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY],
      this.stateArray[this.stateStrideIndex + BodyOffset.AngularVelocity],
    ];
  }

  getVelocityVector(): Vector2D {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return new Vector2D(0, 0);
    }
    return new Vector2D(
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX],
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY]
    );
  }

  getAcceleration(): [number, number, number] {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.stateStrideIndex + BodyOffset.AccelerationX],
      this.stateArray[this.stateStrideIndex + BodyOffset.AccelerationY],
      this.stateArray[this.stateStrideIndex + BodyOffset.AngularAcceleration],
    ];
  }

  getMec(): Circle {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return {
        x: 0,
        y: 0,
        radius: 0,
      };
    }
    return {
      x: this.stateArray[this.stateStrideIndex + BodyOffset.MecPositionX],
      y: this.stateArray[this.stateStrideIndex + BodyOffset.MecPositionY],
      radius: this.stateArray[this.stateStrideIndex + BodyOffset.MecRadius],
    };
  }

  getCompositeIndex(): number {
    if (!this.infoArray) {
      return NO_COMPOSITE;
    }
    return this.infoArray[this.infoStrideIndex + BodyInfoOffset.CompositeIndex];
  }

  getLocalAABB(): AABB {
    return this.shape.getSegmentTree().bounds;
  }

  getGlobalAABB(depth: number): AABB {
    return this.shape
      .getSegmentTree()
      .getGlobalBoundsAtDepth(depth, this.getTransform());
  }

  getMass(): number {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return 0;
    }

    return this.stateArray[this.stateStrideIndex + BodyOffset.Mass];
  }

  getProps(): {
    centroid: Vector2D;
    mass: number;
    momentOfInertia: number;
  } {
    if (!this.stateArray) {
      return {
        centroid: new Vector2D(0, 0),
        mass: 0,
        momentOfInertia: 0,
      };
    }
    return {
      centroid: new Vector2D(
        this.stateArray[this.stateStrideIndex + BodyOffset.LocalCentroidX],
        this.stateArray[this.stateStrideIndex + BodyOffset.LocalCentroidY]
      ),
      mass: this.stateArray[this.stateStrideIndex + BodyOffset.Mass],
      momentOfInertia:
        this.stateArray[this.stateStrideIndex + BodyOffset.MomentOfInertia],
    };
  }

  getPropsForCompositeRecalculation(): {
    localPosition: Vector2D;
    mass: number;
    momentOfInertia: number;
  } | null {
    if (!this.stateArray || !this.composite) {
      return null;
    }

    return {
      localPosition: new Vector2D(
        this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalX],
        this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalY]
      ),
      mass: this.stateArray[this.stateStrideIndex + BodyOffset.Mass],
      momentOfInertia:
        this.stateArray[this.stateStrideIndex + BodyOffset.MomentOfInertia],
    };
  }

  getInvProperties(): {
    invMass: number;
    invInertia: number;
  } {
    if (!this.stateArray) {
      return {
        invMass: 0,
        invInertia: 0,
      };
    }
    if (this.composite) {
      return this.composite.getInvProperties();
    }
    return {
      invMass: this.stateArray[this.stateStrideIndex + BodyOffset.InvMass],
      invInertia:
        this.stateArray[this.stateStrideIndex + BodyOffset.InvMomentOfInertia],
    };
  }

  getStateForCollision(pointOfContact: Vector2D): {
    r: Vector2D;
    v: Vector2D;
    invMass: number;
    invInertia: number;
  } {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return {
        r: new Vector2D(0, 0),
        v: new Vector2D(0, 0),
        invMass: 0,
        invInertia: 0,
      };
    }

    if (this.composite) {
      return this.composite.getStateForCollision(pointOfContact);
    }

    const posX = this.stateArray[this.stateStrideIndex + BodyOffset.PositionX];
    const posY = this.stateArray[this.stateStrideIndex + BodyOffset.PositionY];
    const r = new Vector2D(pointOfContact.x - posX, pointOfContact.y - posY);

    const linVelX =
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX];
    const linVelY =
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY];
    const angVel =
      this.stateArray[this.stateStrideIndex + BodyOffset.AngularVelocity];

    const v = new Vector2D(linVelX - r.y * angVel, linVelY + r.x * angVel);

    const invMass = this.stateArray[this.stateStrideIndex + BodyOffset.InvMass];
    const invInertia =
      this.stateArray[this.stateStrideIndex + BodyOffset.InvMomentOfInertia];

    return { r, v, invMass, invInertia };
  }

  getStateForImpulse(): {
    position: Vector2D;
    rotation: number;
    velocity: Vector2D;
    angularVelocity: number;
    invMass: number;
    invInertia: number;
  } {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return {
        position: new Vector2D(0, 0),
        rotation: 0,
        velocity: new Vector2D(0, 0),
        angularVelocity: 0,
        invMass: 0,
        invInertia: 0,
      };
    }

    if (this.composite) {
      return this.composite.getStateForImpulse();
    }

    const position = new Vector2D(
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionX],
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionY]
    );

    const rotation =
      this.stateArray[this.stateStrideIndex + BodyOffset.Rotation];

    const velocity = new Vector2D(
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX],
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY]
    );

    const angularVelocity =
      this.stateArray[this.stateStrideIndex + BodyOffset.AngularVelocity];

    const invMass = this.stateArray[this.stateStrideIndex + BodyOffset.InvMass];
    const invInertia =
      this.stateArray[this.stateStrideIndex + BodyOffset.InvMomentOfInertia];

    return {
      position,
      rotation,
      velocity,
      angularVelocity,
      invMass,
      invInertia,
    };
  }

  getFullState(): FullBodyState {
    if (!this.stateArray || !this.infoArray) {
      // console.error("Body not loaded");
      return {
        x: 0,
        y: 0,
        rotation: 0,
        sinRotation: 0,
        cosRotation: 1,
        velocityX: 0,
        velocityY: 0,
        angularVelocity: 0,
        positionLocked: false,
        rotationLocked: false,
        mass: 0,
        momentOfInertia: 0,
        inSubplane: false,
      };
    }

    return {
      x: this.stateArray[this.stateStrideIndex + BodyOffset.PositionX],
      y: this.stateArray[this.stateStrideIndex + BodyOffset.PositionY],
      rotation: this.stateArray[this.stateStrideIndex + BodyOffset.Rotation],
      sinRotation:
        this.stateArray[this.stateStrideIndex + BodyOffset.SinRotation],
      cosRotation:
        this.stateArray[this.stateStrideIndex + BodyOffset.CosRotation],
      velocityX: this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX],
      velocityY: this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY],
      angularVelocity:
        this.stateArray[this.stateStrideIndex + BodyOffset.AngularVelocity],
      mass: this.stateArray[this.stateStrideIndex + BodyOffset.Mass],
      momentOfInertia:
        this.stateArray[this.stateStrideIndex + BodyOffset.MomentOfInertia],
      positionLocked:
        this.infoArray[this.infoStrideIndex + BodyInfoOffset.PositionLocked] ===
        1,
      rotationLocked:
        this.infoArray[this.infoStrideIndex + BodyInfoOffset.RotationLocked] ===
        1,
      inSubplane:
        this.infoArray[this.infoStrideIndex + BodyInfoOffset.InSubplane] === 1,
    };
  }

  getCenter(): Vector2D {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return new Vector2D(0, 0);
    }

    return new Vector2D(
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionX],
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionY]
    );
  }

  getRotation(): {
    angle: number;
    sin: number;
    cos: number;
  } {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return { angle: 0, sin: 0, cos: 1 };
    }
    return {
      angle: this.stateArray[this.stateStrideIndex + BodyOffset.Rotation],
      sin: this.stateArray[this.stateStrideIndex + BodyOffset.SinRotation],
      cos: this.stateArray[this.stateStrideIndex + BodyOffset.CosRotation],
    };
  }

  getClosestPerimeterPoint(
    queryCenter: Vector2D,
    queryRadius: number
  ): {
    distance: number;
    globalPosition: Vector2D;
    perimeterPoint: PerimeterPoint;
  } | null {
    const localQueryCenter = this.getTransform().applyInverse(queryCenter);
    const closest = this.shape.getClosestPerimeterPoint(
      localQueryCenter,
      queryRadius
    );

    if (!closest) {
      return null;
    }

    const globalPosition = this.getTransform().apply(
      closest.perimeterPoint.position
    );

    return { ...closest, globalPosition };
  }

  setPosition(x: number, y: number, rotation: number): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }
    this.stateArray[this.stateStrideIndex + BodyOffset.PositionX] = x;
    this.stateArray[this.stateStrideIndex + BodyOffset.PositionY] = y;
    this.stateArray[this.stateStrideIndex + BodyOffset.Rotation] = rotation;

    const cos = Math.cos(rotation);
    const sin = Math.sin(rotation);

    this.stateArray[this.stateStrideIndex + BodyOffset.CosRotation] = cos;
    this.stateArray[this.stateStrideIndex + BodyOffset.SinRotation] = sin;

    const localCentroidX =
      this.stateArray[this.stateStrideIndex + BodyOffset.LocalCentroidX];
    const localCentroidY =
      this.stateArray[this.stateStrideIndex + BodyOffset.LocalCentroidY];

    const localMecX =
      this.stateArray[this.stateStrideIndex + BodyOffset.LocalMecPositionX];
    const localMecY =
      this.stateArray[this.stateStrideIndex + BodyOffset.LocalMecPositionY];

    const transformOffsetX = -localCentroidX * cos - -localCentroidY * sin + x;
    const transformOffsetY = -localCentroidX * sin + -localCentroidY * cos + y;

    this.stateArray[this.stateStrideIndex + BodyOffset.TransformOffsetX] =
      transformOffsetX;
    this.stateArray[this.stateStrideIndex + BodyOffset.TransformOffsetY] =
      transformOffsetY;

    this.stateArray[this.stateStrideIndex + BodyOffset.MecPositionX] =
      localMecX * cos - localMecY * sin + transformOffsetX;
    this.stateArray[this.stateStrideIndex + BodyOffset.MecPositionY] =
      localMecX * sin + localMecY * cos + transformOffsetY;
  }

  setVelocity(x: number, y: number, angularVelocity: number): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }
    this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX] = x;
    this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY] = y;
    this.stateArray[this.stateStrideIndex + BodyOffset.AngularVelocity] =
      angularVelocity;
  }

  clearCompositeInfo(): void {
    if (!this.stateArray || !this.infoArray || !this.compositeIdArray) {
      return;
    }

    this.composite = null;
    this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalX] = 0;
    this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalY] = 0;
    this.stateArray[
      this.stateStrideIndex + BodyOffset.CompositeLocalRotation
    ] = 0;
    this.infoArray[this.infoStrideIndex + BodyInfoOffset.CompositeIndex] =
      NO_COMPOSITE;
    this.compositeIdArray[this.index] = 0;
  }

  setCompositeInfo(
    composite: Composite,
    localX: number,
    localY: number,
    localRotation: number,
    compositeIndex: number,
    compositeId: number
  ): void {
    if (!this.stateArray || !this.infoArray || !this.compositeIdArray) {
      console.error("Body not loaded");
      return;
    }

    this.composite = composite;

    this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalX] =
      localX;
    this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalY] =
      localY;
    this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalRotation] =
      localRotation;
    this.infoArray[this.infoStrideIndex + BodyInfoOffset.CompositeIndex] =
      compositeIndex;
    this.compositeIdArray[this.index] = compositeId;
  }

  getCompositePosition(): {
    localX: number;
    localY: number;
  } | null {
    if (!this.stateArray || !this.infoArray) {
      return null;
    }

    return {
      localX:
        this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalX],
      localY:
        this.stateArray[this.stateStrideIndex + BodyOffset.CompositeLocalY],
    };
  }

  setRotationLocked(locked: boolean): void {
    if (!this.infoArray) {
      return;
    }
    this.infoArray[this.infoStrideIndex + BodyInfoOffset.RotationLocked] =
      locked ? 1 : 0;
  }

  applyImpulse(impulse: Vector2D, r?: Vector2D): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!impulse.isValid()) {
      console.error(`impulse is invalid: ${impulse.x}, ${impulse.y}`);
      return;
    }

    if (this.composite) {
      this.composite.applyImpulse(impulse, r);
      return;
    }

    const invMass = this.stateArray[this.stateStrideIndex + BodyOffset.InvMass];

    this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX] +=
      impulse.x * invMass;
    this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY] +=
      impulse.y * invMass;

    // Apply angular impulse if r is provided
    if (r) {
      // Check for bad r value
      const mecRadius =
        this.stateArray[this.stateStrideIndex + BodyOffset.MecRadius];
      if (r.length() <= mecRadius * 10) {
        const invInertia =
          this.stateArray[
            this.stateStrideIndex + BodyOffset.InvMomentOfInertia
          ];
        const rCrossImpulse = r.x * impulse.y - r.y * impulse.x;
        this.stateArray[this.stateStrideIndex + BodyOffset.AngularVelocity] +=
          rCrossImpulse * invInertia;
      } else {
        // console.error(`r is too large: ${r.length()}`);
      }
    }
  }

  addPositionCorrection(correction: Vector2D): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!correction.isValid()) {
      console.error(`correction is invalid: ${correction.x}, ${correction.y}`);
      return;
    }

    if (this.composite) {
      this.composite.applyPositionCorrection(correction);
      return;
    }

    this.positionCorrections.push(correction);
  }

  addAngleCorrection(correction: number): void {
    if (!this.stateArray || !this.infoArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!isFinite(correction)) {
      console.error(`addAngleCorrection: correction is invalid: ${correction}`);
      return;
    }

    if (this.composite) {
      this.composite.applyAngleCorrection(correction);
      return;
    }

    const rotationLocked =
      this.infoArray[this.infoStrideIndex + BodyInfoOffset.RotationLocked];

    if (!rotationLocked) {
      this.angleCorrections.push(correction);
    }
  }

  applyPositionCorrections(): void {
    if (!this.stateArray || !this.infoArray) {
      // console.error("Body not loaded");
      return;
    }

    if (this.composite) {
      this.positionCorrections = [];
      return;
    }

    const positionLocked =
      this.infoArray[this.infoStrideIndex + BodyInfoOffset.PositionLocked];

    if (positionLocked) {
      return;
    }

    // Calculate average correction
    if (this.positionCorrections.length > 0) {
      const avgCorrection = this.positionCorrections
        .reduce((sum, correction) => sum.add(correction), new Vector2D(0, 0))
        .scale(1 / this.positionCorrections.length);

      const invMass =
        this.stateArray[this.stateStrideIndex + BodyOffset.InvMass];

      const scaledCorrection = avgCorrection.scale(invMass);

      // Apply the average correction
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionX] +=
        scaledCorrection.x;
      this.stateArray[this.stateStrideIndex + BodyOffset.PositionY] +=
        scaledCorrection.y;

      // Update transform offset
      this.stateArray[this.stateStrideIndex + BodyOffset.TransformOffsetX] +=
        scaledCorrection.x;
      this.stateArray[this.stateStrideIndex + BodyOffset.TransformOffsetY] +=
        scaledCorrection.y;

      // Update mec position
      this.stateArray[this.stateStrideIndex + BodyOffset.MecPositionX] +=
        scaledCorrection.x;
      this.stateArray[this.stateStrideIndex + BodyOffset.MecPositionY] +=
        scaledCorrection.y;
    }

    this.positionCorrections = [];
  }

  applyAngleCorrections(): void {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return;
    }

    if (this.composite) {
      this.angleCorrections = [];
      return;
    }

    if (this.angleCorrections.length > 0) {
      const avgCorrection =
        this.angleCorrections.reduce((sum, correction) => sum + correction, 0) /
        this.angleCorrections.length;

      this.stateArray[this.stateStrideIndex + BodyOffset.Rotation] +=
        avgCorrection;
    }

    this.angleCorrections = [];
  }

  applyForce(
    force: Vector2D,
    r?: Vector2D,
    atBodyCentroid: boolean = false
  ): void {
    if (!this.stateArray || !this.infoArray) {
      // console.error("Body not loaded");
      return;
    }

    if (!force.isValid()) {
      console.error(`force is invalid: ${force.x}, ${force.y}`);
      return;
    }

    if (this.composite) {
      if (atBodyCentroid) {
        const thisBodyCenter = this.getCenter();
        const compositeCenter = this.composite.getCenter();
        r = thisBodyCenter.subtract(compositeCenter);
      }

      this.composite.applyForce(force, r);
      return;
    }

    const invMass = this.stateArray[this.stateStrideIndex + BodyOffset.InvMass];
    const linearAcc = force.scale(invMass);
    this.stateArray[this.stateStrideIndex + BodyOffset.AccelerationX] +=
      linearAcc.x;
    this.stateArray[this.stateStrideIndex + BodyOffset.AccelerationY] +=
      linearAcc.y;

    // Skip if rotation locked or inertia is invalid
    if (r) {
      const invInertia =
        this.stateArray[this.stateStrideIndex + BodyOffset.InvMomentOfInertia];
      const torque = r.x * force.y - r.y * force.x;
      this.stateArray[this.stateStrideIndex + BodyOffset.AngularAcceleration] +=
        torque * invInertia;
    }
  }

  applyTorque(
    torque: number,
    r?: Vector2D,
    atBodyCentroid: boolean = false
  ): void {
    if (!this.stateArray) {
      return;
    }

    if (!isFinite(torque)) {
      console.error(`torque is invalid: ${torque}`);
      return;
    }

    // Handle composite bodies
    if (this.composite) {
      if (atBodyCentroid) {
        const thisBodyCenter = this.getCenter();
        const compositeCenter = this.composite.getCenter();
        r = thisBodyCenter.subtract(compositeCenter);
      }
      this.composite.applyTorque(torque, r);
      return;
    }

    const invInertia =
      this.stateArray[this.stateStrideIndex + BodyOffset.InvMomentOfInertia];
    this.stateArray[this.stateStrideIndex + BodyOffset.AngularAcceleration] +=
      torque * invInertia;

    // If there's an offset and we're not position locked,
    // calculate and apply the equivalent force
    if (r) {
      const rLength = r.length();
      if (rLength > 0) {
        // Calculate force magnitude: τ/|r|
        const forceMagnitude = torque / rLength;

        // Force direction is perpendicular to r
        // In 2D, rotate r by 90 degrees: (x,y) -> (-y,x)
        const forceX = (-r.y / rLength) * forceMagnitude;
        const forceY = (r.x / rLength) * forceMagnitude;

        const invMass =
          this.stateArray[this.stateStrideIndex + BodyOffset.InvMass];

        // Apply the linear acceleration: F/m
        const linearAcc = new Vector2D(forceX, forceY).scale(invMass);
        this.stateArray[this.stateStrideIndex + BodyOffset.AccelerationX] +=
          linearAcc.x;
        this.stateArray[this.stateStrideIndex + BodyOffset.AccelerationY] +=
          linearAcc.y;
      }
    }
  }

  getLinearVelocity(): Vector2D {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return new Vector2D(0, 0);
    }

    return new Vector2D(
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityX],
      this.stateArray[this.stateStrideIndex + BodyOffset.VelocityY]
    );
  }

  getAngularVelocity(): number {
    if (!this.stateArray) {
      // console.error("Body not loaded");
      return 0;
    }

    return this.stateArray[this.stateStrideIndex + BodyOffset.AngularVelocity];
  }

  shouldResolveCollision(collision: CollisionInfo): boolean {
    if (!this.shouldResolveCollisionChecks) {
      return true;
    }

    let shouldResolve = true;

    for (const shouldResolveCheck of this.shouldResolveCollisionChecks.values()) {
      if (!shouldResolveCheck(collision)) {
        shouldResolve = false;
      }
    }
    return shouldResolve;
  }

  addShouldResolveCollisionCheck(check: ShouldResolveCollisionCheck): string {
    if (!this.shouldResolveCollisionChecks) {
      this.shouldResolveCollisionChecks = new Map();
    }

    const id = nanoid();
    this.shouldResolveCollisionChecks.set(id, check);
    return id;
  }

  removeShouldResolveCollisionCheck(id: string) {
    if (!this.shouldResolveCollisionChecks) {
      return;
    }

    this.shouldResolveCollisionChecks.delete(id);
  }

  callCollisionTriggers = () => {
    if (!this.collisionTriggers) {
      return;
    }

    for (const collision of this.collisions) {
      const otherBody =
        collision.body1 === this ? collision.body2 : collision.body1;

      for (const trigger of this.collisionTriggers.values()) {
        trigger(otherBody, collision);
      }
    }

    this.collisions = [];
  };

  addCollision(collision: CollisionInfo): boolean {
    if (!this.collisionTriggers || this.collisionTriggers.size === 0) {
      return false;
    }

    this.collisions.push(collision);
    return true;
  }

  addCollisionTrigger(trigger: CollisionTrigger): string {
    if (!this.collisionTriggers) {
      this.collisionTriggers = new Map();
    }

    const id = nanoid();
    this.collisionTriggers.set(id, trigger);
    return id;
  }

  removeCollisionTrigger(id: string) {
    if (!this.collisionTriggers) {
      return;
    }

    this.collisionTriggers.delete(id);
  }

  storeSurfacePoint({
    surfacePoint,
    segment,
    tOrAngle,
  }: {
    surfacePoint: SurfacePoint;
    segment: Segment;
    tOrAngle: number;
  }): void {
    this.shape.storeSurfacePoint({
      surfacePoint,
      segment,
      tOrAngle,
    });
  }

  addSurfacePoint(surfacePoint: SurfacePoint): void {
    this.shape.addSurfacePoint(surfacePoint);
  }

  queryCircle(circle: Circle): SegmentInfo[] {
    const transform = this.getTransform();

    // Convert circle center to local coordinates
    const localCenter = transform.applyInverse(
      new Vector2D(circle.x, circle.y)
    );

    // Create local circle
    const localCircle: Circle = {
      x: localCenter.x,
      y: localCenter.y,
      radius: circle.radius,
    };

    return this.shape.queryCircle(localCircle);
  }

  // Helper function to attempt a single cut
  private attemptCut(
    cutter: Shape,
    cutterPosition: Vector2D,
    offset: Vector2D,
    transform: Transform
  ): {
    resultShapes: {
      shape: Shape;
      subtractionResultShape: SubtractionResultShape;
      surfacePoints: SurfacePoint[];
    }[];
    destroyedSurfacePoints: SurfacePoint[];
  } | null {
    const offsetPosition = cutterPosition.add(offset);
    const cutterTransform = new Transform(
      offsetPosition.x,
      offsetPosition.y,
      0,
      0,
      1
    );

    const transformInfo = Transform.getRelativeTransformInfo({
      guest: cutterTransform,
      host: transform,
    });

    return this.shape.subtract(cutter, transformInfo);
  }

  cut(
    cutter: Shape,
    cutterPosition: Vector2D
  ): {
    resultBodies: {
      state: BodyState;
      body: Body;
      surfacePoints: SurfacePoint[];
    }[];
    destroyedSurfacePoints: SurfacePoint[];
    serializableCutResult: SerializableCutResult;
  } | null {
    if (!this.stateArray || !this.infoArray) {
      return null;
    }

    const MAX_ATTEMPTS = 10;
    const INITIAL_STEP_SIZE = 0.1; // Start with small perturbations

    // Try cutting with increasingly large perturbations
    const offsetGenerator = spiralOffsets(INITIAL_STEP_SIZE);

    let subtractionResult: {
      resultShapes: {
        shape: Shape;
        subtractionResultShape: SubtractionResultShape;
        surfacePoints: SurfacePoint[];
      }[];
      destroyedSurfacePoints: SurfacePoint[];
    } | null = null;
    let attempts = 0;

    const transform = this.getTransform();

    // First try without offset
    subtractionResult = this.attemptCut(
      cutter,
      cutterPosition,
      new Vector2D(0, 0),
      transform
    );

    // If initial attempt fails, try with offsets
    while (subtractionResult === null && attempts < MAX_ATTEMPTS) {
      const offset = offsetGenerator.next().value;
      subtractionResult = this.attemptCut(
        cutter,
        cutterPosition,
        offset,
        transform
      );

      attempts++;
    }

    if (subtractionResult === null) {
      console.warn(`Cut failed after ${attempts} attempts`);
      return null;
    }

    if (subtractionResult.resultShapes.length === 0) {
      return null;
    }

    // Get the original body's velocities and transform
    const targetVelocity = this.getLinearVelocity();
    const targetAngularVel = this.getAngularVelocity();
    const originalCenter = this.getCenter();

    const bodies: {
      state: BodyState;
      body: Body;
      surfacePoints: SurfacePoint[];
    }[] = [];

    const serializableCutBodies: {
      id: number;
      shape: SubtractionResultShape;
    }[] = [];

    for (const shape of subtractionResult.resultShapes) {
      if (shape.shape.area < 200) {
        continue;
      }

      const bodyId = randomUint32();

      // Create the body first so we have access to its shape properties
      const body = new Body({
        id: bodyId,
        shape: shape.shape,
        material: this.material,
        type: this.type,
        inSubplane: this.inSubplane,
      });

      serializableCutBodies.push({
        id: bodyId,
        shape: shape.subtractionResultShape,
      });

      // The new shape's centroid is in the original shape's local space
      // Transform it to world space
      const globalCentroid = transform.apply(shape.shape.centroid);

      // Calculate the vector from original center to new centroid
      const r = globalCentroid.subtract(originalCenter);

      // Calculate rotational velocity component (v = ω × r)
      const rotationalVel = new Vector2D(
        -targetAngularVel * r.y,
        targetAngularVel * r.x
      );

      const positionLocked =
        this.infoArray[this.infoStrideIndex + BodyInfoOffset.PositionLocked] ===
        1;
      const rotationLocked =
        this.infoArray[this.infoStrideIndex + BodyInfoOffset.RotationLocked] ===
        1;

      bodies.push({
        body,
        state: {
          position: [globalCentroid.x, globalCentroid.y, transform.angle],
          velocity: [
            targetVelocity.x + rotationalVel.x,
            targetVelocity.y + rotationalVel.y,
            targetAngularVel,
          ],
          positionLocked,
          rotationLocked,
        },
        surfacePoints: shape.surfacePoints,
      });
    }

    return {
      resultBodies: bodies,
      destroyedSurfacePoints: subtractionResult.destroyedSurfacePoints,
      serializableCutResult: {
        targetBodyId: this.id,
        resultBodies: serializableCutBodies,
      },
    };
  }

  cutFromSerialized(cut: SerializableCutResult): {
    resultBodies: {
      state: BodyState;
      body: Body;
      surfacePoints: SurfacePoint[];
    }[];
    destroyedSurfacePoints: SurfacePoint[];
  } | null {
    if (!this.stateArray || !this.infoArray) {
      return null;
    }

    const originalSurfacePoints = this.shape.surfacePoints;
    const transform = this.getTransform();

    // Get the original body's velocities and transform
    const targetVelocity = this.getLinearVelocity();
    const targetAngularVel = this.getAngularVelocity();
    const originalCenter = this.getCenter();

    const bodies: {
      state: BodyState;
      body: Body;
      surfacePoints: SurfacePoint[];
    }[] = [];

    for (const resultBody of cut.resultBodies) {
      const resultShape = this.shape.getSubtractionResultFromSerialized(
        resultBody.shape
      );

      const body = new Body({
        id: resultBody.id,
        shape: resultShape.shape,
        material: this.material,
        type: this.type,
        inSubplane: this.inSubplane,
      });

      const globalCentroid = transform.apply(resultShape.shape.centroid);

      // Calculate the vector from original center to new centroid
      const r = globalCentroid.subtract(originalCenter);

      // Calculate rotational velocity component (v = ω × r)
      const rotationalVel = new Vector2D(
        -targetAngularVel * r.y,
        targetAngularVel * r.x
      );

      const positionLocked =
        this.infoArray[this.infoStrideIndex + BodyInfoOffset.PositionLocked] ===
        1;
      const rotationLocked =
        this.infoArray[this.infoStrideIndex + BodyInfoOffset.RotationLocked] ===
        1;

      bodies.push({
        body,
        state: {
          position: [globalCentroid.x, globalCentroid.y, transform.angle],
          velocity: [
            targetVelocity.x + rotationalVel.x,
            targetVelocity.y + rotationalVel.y,
            targetAngularVel,
          ],
          positionLocked,
          rotationLocked,
        },
        surfacePoints: resultShape.surfacePoints,
      });

      if (!originalSurfacePoints) {
        continue;
      }

      for (const surfacePoint of resultShape.surfacePoints) {
        originalSurfacePoints.delete(surfacePoint);
      }
    }

    let destroyedSurfacePoints: SurfacePoint[] = [];

    if (originalSurfacePoints && originalSurfacePoints.size > 0) {
      destroyedSurfacePoints = Array.from(originalSurfacePoints);
    }

    return {
      resultBodies: bodies,
      destroyedSurfacePoints,
    };
  }

  // getTextureInfo(): {
  //   texture: ImageBitmap;
  //   textureScale: number;
  //   originalBounds: AABB;
  // } | null {
  //   if (!this.infoArray) {
  //     return null;
  //   }

  //   const inSubplane =
  //     this.infoArray[this.infoStrideIndex + BodyInfoOffset.InSubplane] === 1;
  //   if (!this.textureInfo) {
  //     this.textureInfo = createTextureInfo({
  //       aabb: this.getLocalAABB(),
  //       color: this.material.color,
  //       inSubplane,
  //     });
  //   }

  //   return this.textureInfo!;
  // }

  removeFromQuadtree(): void {
    if (this.quadtreeNode) {
      this.quadtreeNode.remove(this);
    }
  }

  updateQuadtree(mec: Circle, quadtreeCheckType: QuadtreeCheckType): void {
    if (!this.quadtreeNode) {
      throw new Error("Body has no quadtree node");
    }

    this.quadtreeNode.update(this, mec, quadtreeCheckType);
  }

  setQuadtreeNode(
    node: BodyQuadtreeNode | null,
    quadtreeCheckType: QuadtreeCheckType
  ): void {
    if (!this.stateArray || !this.infoArray) {
      return;
    }

    this.quadtreeNode = node;

    if (node === null) {
      this.stateArray[this.stateStrideIndex + BodyOffset.QuadtreeNodeX] = 0;
      this.stateArray[this.stateStrideIndex + BodyOffset.QuadtreeNodeY] = 0;
      this.stateArray[this.stateStrideIndex + BodyOffset.QuadtreeNodeSize] = 0;
      this.infoArray[this.infoStrideIndex + BodyInfoOffset.QuadtreeCheckType] =
        QuadtreeCheckType.None;
      return;
    }

    this.stateArray[this.stateStrideIndex + BodyOffset.QuadtreeNodeX] = node.x;
    this.stateArray[this.stateStrideIndex + BodyOffset.QuadtreeNodeY] = node.y;
    this.stateArray[this.stateStrideIndex + BodyOffset.QuadtreeNodeSize] =
      node.size;
    this.infoArray[this.infoStrideIndex + BodyInfoOffset.QuadtreeCheckType] =
      quadtreeCheckType;
  }
}

// Helper function to generate points in a spiral pattern
function* spiralOffsets(stepSize: number): Generator<Vector2D> {
  let angle = 0;
  let radius = stepSize;
  while (true) {
    // Convert polar coordinates to Cartesian
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    yield new Vector2D(x, y);

    // Increment angle by golden ratio for better distribution
    angle += 2.4; // Approximately golden angle in radians
    radius += stepSize / 6; // Gradually increase radius
  }
}
