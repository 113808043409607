import { nanoid } from "nanoid";
import { Vector2D } from "../math/vector2D.ts";
import { Actuator } from "../plane/components/actuator.ts";
import type { Plane } from "../plane/plane.ts";
import {
  BodyType,
  ButtonEvent,
  type PlayerEntityIds,
  type PointOnBodySurface,
} from "../models.ts";

import { Body } from "../plane/body.ts";
import { VertexShapeBuilder } from "../shapes/builders/vertexShapeBuilder.ts";
import { Color } from "../rendering/color.ts";
import { CentroidTether } from "../plane/components/centroidTether.ts";
import { Thruster } from "../plane/components/thruster.ts";
import type { Action, ActionUpdateInfo } from "./action.ts";
import { Cut } from "./actions/cut.ts";
import { Grab } from "./actions/grab.ts";
import { Mark } from "./actions/mark.ts";
import { PlaceSpring, PlacePin, PlaceTether } from "./actions/place.ts";
import { Shoot } from "./actions/shoot.ts";

export class Player {
  plane: Plane;
  id: string;
  abdomen: Body;
  limb: Body;
  actuator: Actuator;
  tether: CentroidTether;
  thruster: Thruster;
  targetPosition: Vector2D = new Vector2D(0, 0);
  globalTargetPosition: Vector2D = new Vector2D(0, 0);
  thrustDirection: Vector2D = new Vector2D(0, 0);
  armControlVector: Vector2D = new Vector2D(0, 0);

  actionRequested: boolean = false;
  actionStartTime: number = 0;
  lastActionTime: number = 0;
  actionInProgress: boolean = false;

  grabRequested: boolean = false;
  grabInProgress: boolean = false;

  thrustMagnitude: number = 0;
  maxThrustMagnitude: number = 3000000;
  minThrustMagnitude: number = 500000;
  thrustRampUpSpeed: number = 200000;
  thrustRampDownSpeed: number = 10000000;
  requestedBodyIds: Set<number> = new Set();
  requestedPlayerIds: Set<string> = new Set();
  actions: Action[];
  private currentActionIndex: number = 0;
  grabAction: Action;

  constructor(id: string, plane: Plane, entityIds: PlayerEntityIds) {
    this.id = id;
    this.plane = plane;
    const { abdomen, limb, actuator, tether, thruster } =
      this.createEntities(entityIds);

    this.abdomen = abdomen;
    this.limb = limb;
    this.actuator = actuator;
    this.tether = tether;
    this.thruster = thruster;
    this.actions = [
      new Shoot({
        player: this,
        cooldown: 100,
        shots: 1000,
      }),
      new Cut({
        player: this,
        cutRadius: 200,
        cutDuration: 500,
      }),
      new Cut({
        player: this,
        cutRadius: 50,
        cutDuration: 50,
      }),
      new PlaceSpring(this),
      new PlacePin(this),
      new PlaceTether(this),
      new Mark(this),
    ];
    this.currentActionIndex = 0;
    this.grabAction = new Grab(this, this.limb);
  }

  getEntityIds(): PlayerEntityIds {
    return {
      abdomenId: this.abdomen.id,
      limbId: this.limb.id,
      actuatorId: this.actuator.id,
      tetherId: this.tether.id,
      thrusterId: this.thruster.id,
    };
  }

  updateArmControlVector(x: number, y: number): void {
    this.armControlVector.x = x;
    this.armControlVector.y = y;
  }

  private updateGlobalTargetPosition(dt: number): void {
    // Get positions in world space
    const abdomenPosition = this.getAbdomenPosition();

    // Convert body2 position to body1-relative space
    const relativeLimbPosition =
      this.getLimbPosition().subtract(abdomenPosition);

    // Calculate distance and direction from arm to target in relative space
    const toTarget = this.targetPosition.subtract(relativeLimbPosition);
    const distance = toTarget.magnitude();

    // Spring force pulling target towards arm (stronger when further away)
    const SPRING_CONSTANT = 3;
    const springForce = toTarget.scale(-SPRING_CONSTANT);

    // Control force (already in relative space)
    const controlForce = this.armControlVector.scale(1000);

    // Reset stick vector after using it
    this.armControlVector = new Vector2D(0, 0);

    // Combine forces and update position
    const totalForce = springForce.add(controlForce);
    const positionDelta = totalForce.scale(dt);

    // Update target position (keeping in body1-relative space)
    this.targetPosition = this.targetPosition.add(positionDelta);

    // Constrain maximum distance from arm in relative space
    const MAX_DISTANCE = 10000;
    if (distance > MAX_DISTANCE) {
      // Scale the vector back to maximum length (in relative space)
      this.targetPosition = relativeLimbPosition.add(
        toTarget.normalize().scale(MAX_DISTANCE)
      );
    }

    // Return the global position without modifying this.targetPosition
    this.globalTargetPosition = this.targetPosition.add(abdomenPosition);
  }

  update(dt: number, now: number): void {
    if (!this.abdomen.isLoaded()) {
      console.error("Player body not loaded");
      return;
    }

    this.updateGlobalTargetPosition(dt);
    this.actuator.updateTargetPos(this.globalTargetPosition);

    this.updateThrust(dt);

    this.updateGrab(dt, now);
    this.updateCurrentAction(dt, now);
  }

  handleButtonEvent(buttonEvent: ButtonEvent): void {
    if (!this.abdomen.isLoaded()) {
      return;
    }

    switch (buttonEvent) {
      case ButtonEvent.StartGrab:
        this.grabRequested = true;
        break;
      case ButtonEvent.StopGrab:
        this.grabRequested = false;
        break;
      case ButtonEvent.StartAction:
        this.actionRequested = true;
        break;
      case ButtonEvent.StopAction:
        this.actionRequested = false;
        break;
      case ButtonEvent.PreviousAction:
        this.switchToPreviousAction();
        break;
      case ButtonEvent.NextAction:
        this.switchToNextAction();
        break;
      default:
        break;
    }
  }

  updateThrustDirection(direction: Vector2D): void {
    this.thrustDirection = direction;
  }

  private updateThrust(dt: number): void {
    let thrustDirection = this.thrustDirection;

    const thrustMagnitude = thrustDirection.magnitude();

    if (thrustMagnitude > 1) {
      thrustDirection = thrustDirection.normalize();
    }

    if (thrustMagnitude < 0.01) {
      // Stop thrust
      this.thruster.updateForce(new Vector2D(0, 0));
      // Ramp down thrust magnitude
      this.thrustMagnitude = Math.max(
        this.minThrustMagnitude,
        this.thrustMagnitude - this.thrustRampDownSpeed * dt
      );
      return;
    }

    // Ramp up thrust magnitude
    this.thrustMagnitude = Math.min(
      this.maxThrustMagnitude,
      this.thrustMagnitude + this.thrustRampUpSpeed * dt
    );

    // Update thrust force
    this.thruster.updateForce(thrustDirection.scale(this.thrustMagnitude));
  }

  getCurrentAction(): Action {
    return this.actions[this.currentActionIndex];
  }

  private switchToPreviousAction(): void {
    this.cancelAction();

    const newIndex =
      (this.currentActionIndex - 1 + this.actions.length) % this.actions.length;
    this.currentActionIndex = newIndex;
  }

  private switchToNextAction(): void {
    this.cancelAction();

    const newIndex = (this.currentActionIndex + 1) % this.actions.length;
    this.currentActionIndex = newIndex;
  }

  private cancelAction(): void {
    this.getCurrentAction().cancel();
    this.actionInProgress = false;
    this.actionStartTime = 0;
    this.lastActionTime = 0;
  }

  updateCurrentAction(dt: number, now: number): void {
    const action = this.getCurrentAction();

    // Release item if not using it
    if (!this.actionRequested) {
      if (this.actionInProgress) {
        action.release(this.getActionUpdateInfo(action, now, dt));
        this.actionInProgress = false;
        this.actionStartTime = 0;
      }
      return;
    }

    // Hold case
    if (this.actionInProgress) {
      const result = action.hold(this.getActionUpdateInfo(action, now, dt));

      if (!result.ongoing) {
        this.actionInProgress = false;
        this.actionStartTime = 0;
      }
    }
    // Start case
    else {
      // Check cooldown
      if (action.cooldown && action.cooldown > now - this.lastActionTime) {
        return;
      }

      const result = action.activate(this.getActionUpdateInfo(action, now, dt));

      if (result.success) {
        this.lastActionTime = now;
      }

      if (result.ongoing) {
        this.actionInProgress = true;
        this.actionStartTime = now;
      }
    }
  }

  updateGrab(dt: number, now: number): void {
    const action = this.grabAction;

    // Release item if not using it
    if (!this.grabRequested) {
      if (this.grabInProgress) {
        action.release(this.getActionUpdateInfo(action, now, dt));
        this.grabInProgress = false;
      }
      return;
    }

    // Hold case
    if (this.grabInProgress) {
      const result = action.hold(this.getActionUpdateInfo(action, now, dt));

      if (!result.ongoing) {
        this.grabInProgress = false;
      }
    }
    // Start case
    else {
      const result = action.activate(this.getActionUpdateInfo(action, now, dt));

      if (result.ongoing) {
        this.grabInProgress = true;
      }
    }
  }

  getActionUpdateInfo(
    action: Action,
    now: number,
    dt: number
  ): ActionUpdateInfo {
    let selectPoint: {
      distanceFromLimb: number;
      pointOnSurface: PointOnBodySurface;
    } | null = null;

    if (action.selectPointConfig) {
      const closestPoint = this.plane.bodyStore.getClosestPointOnBody(
        this.getLimbPosition(),
        action.selectPointConfig.reach,
        action.selectPointConfig.includeAbdomen
          ? [this.limb]
          : [this.limb, this.abdomen],
        action.selectPointConfig.eligibilityCheck
      );

      if (closestPoint) {
        selectPoint = {
          distanceFromLimb: closestPoint.distance,
          pointOnSurface: closestPoint.pointOnSurface,
        };
      }
    }

    const abdomenPosition = this.getAbdomenPosition();
    const limbPosition = this.getLimbPosition();
    const limbDirection = abdomenPosition.subtract(limbPosition).normalize();

    return {
      dt,
      now,
      abdomenPosition: abdomenPosition,
      abdomenVelocity: this.getAbdomenVelocity(),
      limbPosition: limbPosition,
      limbVelocity: this.getLimbVelocity(),
      limbDirection: limbDirection,
      selectPoint,
      holdTime: now - this.actionStartTime,
    };
  }

  createEntities(entityIds: PlayerEntityIds): {
    abdomen: Body;
    limb: Body;
    actuator: Actuator;
    tether: CentroidTether;
    thruster: Thruster;
  } {
    const abdomen = new Body({
      id: entityIds.abdomenId,
      shape: VertexShapeBuilder.regularPolygon(150, 6),
      material: {
        density: 0.5,
        restitution: 0.1,
        staticFriction: 0.9,
        dynamicFriction: 0.9,
        color: new Color(50, 100, 50),
        destructible: false,
      },
      type: BodyType.PlayerBody,
      light: {
        radius: 5000,
        glowRadius: 150,
        brightnessStops: [
          { position: 0, opacity: 1 },
          { position: 1, opacity: 0 },
        ],
        colorStops: [
          { position: 0, opacity: 0.1 },
          { position: 1, opacity: 0 },
        ],
        color: Color.fromHex("#e3bb64"),
      },
      inSubplane: false,
    });

    const limb = new Body({
      id: entityIds.limbId,
      shape: VertexShapeBuilder.circle(40),
      // shape: VertexShapeBuilder.regularPolygon(40, 6),
      // shape: VertexShapeBuilder.capsule({
      //   width: 40,
      //   height: 40,
      //   widthSagittaFactor: 0.8,
      //   heightSagittaFactor: 0.8,
      // }),
      material: {
        density: 0.3,
        restitution: 0.2,
        staticFriction: 6,
        dynamicFriction: 6,
        color: new Color(50, 100, 50),
        destructible: false,
      },
      type: BodyType.PlayerArm,
      light: {
        radius: 1500,
        glowRadius: 150,
        brightnessStops: [
          { position: 0, opacity: 1 },
          { position: 1, opacity: 0 },
        ],
        colorStops: [
          { position: 0, opacity: 0.1 },
          { position: 1, opacity: 0 },
        ],
        color: Color.fromHex("#e3bb64"),
      },
      inSubplane: false,
    });

    const actuator = new Actuator(
      entityIds.actuatorId,
      abdomen,
      limb,
      {
        maxForce: 1000000,
        kp: 40,
        ki: 2,
        kd: 8,
        windupLimit: 1000,
      },
      2000,
      new Color(50, 100, 50)
    );

    const tether = new CentroidTether(entityIds.tetherId, abdomen, limb, {
      maxLength: 2000,
      baumgarteScale: 0.7,
      slop: 0.1,
      restitution: 0.1,
    });

    const thruster = new Thruster(entityIds.thrusterId, abdomen);

    return { abdomen, limb, actuator, tether, thruster };
  }

  getAbdomenPosition(): Vector2D {
    return this.abdomen.getCenter();
  }

  getLimbPosition(): Vector2D {
    return this.limb.getCenter();
  }

  getAbdomenVelocity(): Vector2D {
    const velocity = this.abdomen.getVelocity();
    return new Vector2D(velocity[0], velocity[1]);
  }

  getLimbVelocity(): Vector2D {
    const velocity = this.limb.getVelocity();
    return new Vector2D(velocity[0], velocity[1]);
  }
}
