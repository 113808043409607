import type { AABB, Circle } from "../models.ts";
import { Body } from "./body.ts";

export interface BodyQuadtreeEntry {
  index: number;
  body: Body;
  circle: Circle;
}

export interface BodyQuadtreePair {
  a: BodyQuadtreeEntry;
  b: BodyQuadtreeEntry;
}

export enum QuadtreeCheckType {
  None = 0,
  IntersectedBounds = 1,
  StoppedIntersectingVerticalMidline = 2,
  StoppedIntersectingHorizontalMidline = 3,
  StoppedIntersectingEitherMidline = 4,
  UpdateMec = 5,
}

export enum QuadtreeNodeState {
  None = 0,
  WithinBounds = 1,
  IntersectingVerticalMidline = 2,
  IntersectingHorizontalMidline = 3,
  IntersectingBothMidlines = 4,
}

const MAX_CAPACITY = 3;
const MAX_DEPTH = 100;

export class BodyQuadtree {
  root: BodyQuadtreeNode;
  bounds: AABB;
  private readonly nodeSizes: number[];

  constructor(size: number) {
    const halfSize = size / 2;
    this.bounds = {
      left: -halfSize,
      right: halfSize,
      top: -halfSize,
      bottom: halfSize,
    };

    this.nodeSizes = [];
    for (let i = 0; i <= MAX_DEPTH; i++) {
      this.nodeSizes.push(halfSize / Math.pow(2, i));
    }

    this.root = new BodyQuadtreeNode(0, 0, 0, null, this.nodeSizes);
  }

  insert(entry: BodyQuadtreeEntry): void {
    // If the circle doesn't intersect this node's bounds, don't insert
    if (!circleIntersectsAABB(entry.circle, this.bounds)) {
      // console.error("Circle does not intersect root bounds, inserting anyway");
      this.root.insert(entry);
      return;
    }

    this.root.insert(entry);
  }

  queryCircle(circle: Circle): BodyQuadtreeEntry[] {
    if (!circleIntersectsAABB(circle, this.bounds)) {
      return [];
    }

    let results: BodyQuadtreeEntry[] = [];
    this.root.queryCircle(circle, results);
    return results;
  }

  queryAABB(aabb: AABB): BodyQuadtreeEntry[] {
    if (!aabbsIntersect(aabb, this.bounds)) {
      return [];
    }

    let results: BodyQuadtreeEntry[] = [];
    this.root.queryAABB(aabb, results);
    return results;
  }

  getPossiblePairs(): BodyQuadtreePair[] {
    return this.root.getPossiblePairs([]);
  }
}

export class BodyQuadtreeNode {
  x: number;
  y: number;
  bounds: AABB;
  nodeSizes: number[];
  size: number;
  entries: BodyQuadtreeEntry[] = [];
  parent: BodyQuadtreeNode | null = null;
  children: {
    nw: BodyQuadtreeNode;
    ne: BodyQuadtreeNode;
    sw: BodyQuadtreeNode;
    se: BodyQuadtreeNode;
  } | null = null;
  private depth: number;

  constructor(
    x: number,
    y: number,
    depth: number,
    parent: BodyQuadtreeNode | null,
    nodeSizes: number[]
  ) {
    this.x = x;
    this.y = y;
    this.depth = depth;
    this.nodeSizes = nodeSizes;
    this.size = nodeSizes[depth];
    this.bounds = {
      left: x - this.size,
      right: x + this.size,
      top: y - this.size,
      bottom: y + this.size,
    };
    this.parent = parent;
  }

  getPositionAndSize(): { x: number; y: number; size: number } {
    return { x: this.x, y: this.y, size: this.size };
  }

  insert(entry: BodyQuadtreeEntry): void {
    if (this.children) {
      const result = this.checkChildIntersection(entry.circle);
      if (typeof result !== "number") {
        result.insert(entry);
        return;
      }
      this.entries.push(entry);
      entry.body.setQuadtreeNode(this, result);
      return;
    }

    // Not divided yet - check if we need to subdivide
    if (this.entries.length >= MAX_CAPACITY && this.depth < MAX_DEPTH) {
      // Need to subdivide
      this.subdivide();

      // Redistribute existing entries
      const existingEntries: BodyQuadtreeEntry[] = [];
      for (const entry of this.entries) {
        // Update the circle to the new mec
        entry.circle = entry.body.getMec();
        existingEntries.push(entry);
      }
      this.entries = []; // Clear the entries array

      // Reinsert all entries (including the new one)
      for (const existingEntry of existingEntries) {
        this.insert(existingEntry);
      }

      // Update the circle to the new mec
      entry.circle = entry.body.getMec();

      // Insert the new entry
      this.insert(entry);

      return;
    }

    // Either we're at max depth or under capacity, so store here
    this.entries.push(entry);
    entry.body.setQuadtreeNode(this, QuadtreeCheckType.IntersectedBounds);
  }

  updateMec(body: Body, updatedCircle: Circle): void {
    const updatedEntry = this.entries.find((entry) => entry.body === body);
    if (!updatedEntry) {
      console.warn("Body not found in quadtree node");
      return;
    }
    updatedEntry.circle = updatedCircle;
  }

  update(
    body: Body,
    updatedCircle: Circle,
    updateType: QuadtreeCheckType
  ): void {
    if (body.quadtreeNode !== this) {
      console.warn("Attempting to update body in wrong quadtree node");
      return;
    }

    const updatedEntry = this.entries.find((entry) => entry.body === body);
    if (!updatedEntry) {
      console.warn("Body not found in quadtree node");
      return;
    }

    if (updateType === QuadtreeCheckType.UpdateMec) {
      this.updateMec(body, updatedCircle);
      return;
    }

    this.entries = this.entries.filter((entry) => entry !== updatedEntry);

    updatedEntry.circle = updatedCircle;

    updatedEntry.body.setQuadtreeNode(null, QuadtreeCheckType.None);

    switch (updateType) {
      case QuadtreeCheckType.StoppedIntersectingHorizontalMidline:
      case QuadtreeCheckType.StoppedIntersectingVerticalMidline:
      case QuadtreeCheckType.StoppedIntersectingEitherMidline:
        this.insert(updatedEntry);
        return;
      case QuadtreeCheckType.IntersectedBounds:
        this.reinsert(updatedEntry);
        return;
      default:
        console.warn("Unknown update type: ", updateType);
        throw new Error("stop");
    }
  }

  reinsert(entry: BodyQuadtreeEntry): void {
    const circle = entry.circle;
    let containingNode: BodyQuadtreeNode | null = null;
    const originalNode = this;

    // Recurse up the tree
    let currentParent: BodyQuadtreeNode | null = this;
    while (currentParent) {
      // Check if the circle is contained in the current node's bounds
      if (currentParent !== originalNode) {
        if (aabbContainsCircle(currentParent.bounds, circle)) {
          containingNode = currentParent;
          break; // We found our node, no need to continue climbing
        }
      }

      // Store the current parent before moving up
      const lastParent = currentParent;
      currentParent = currentParent.parent;

      // If we've reached the root, check if it can contain the circle
      if (!currentParent && !containingNode) {
        containingNode = lastParent;
      }
    }

    // If we found a containing node, merge from our original node up to it
    if (containingNode) {
      currentParent = this;
      while (currentParent && currentParent !== containingNode) {
        currentParent.merge();
        currentParent = currentParent.parent;
      }
      containingNode.insert(entry);
    } else {
      console.warn("Circle not contained in any node");
    }
  }

  remove(body: Body): void {
    if (body.quadtreeNode !== this) {
      console.warn("Attempting to remove body from wrong quadtree node");
      return;
    }

    const currentSize = this.entries.length;
    this.entries = this.entries.filter((entry) => entry.body !== body);
    body.setQuadtreeNode(null, QuadtreeCheckType.None);

    if (this.entries.length === currentSize) {
      return;
    }

    // Recurse up the tree checking if we need to merge
    let currentParent: BodyQuadtreeNode | null = this;
    let merged = true; // Start as true to enter the loop
    while (currentParent && merged) {
      merged = currentParent.merge();
      currentParent = currentParent.parent;
    }
  }

  private merge(): boolean {
    if (!this.children) {
      return false;
    }

    const entriesInChildren: BodyQuadtreeEntry[] = [];

    for (const child of Object.values(this.children)) {
      if (child.hasChildren()) {
        return false;
      }
      entriesInChildren.push(...child.entries);
    }

    if (entriesInChildren.length + this.entries.length <= MAX_CAPACITY) {
      for (const entry of entriesInChildren) {
        entry.body.setQuadtreeNode(this, QuadtreeCheckType.IntersectedBounds);
      }

      for (const child of Object.values(this.children)) {
        child.entries = [];
        child.parent = null;
      }

      this.entries.push(...entriesInChildren);
      this.children = null;
      return true;
    }

    return false;
  }

  checkChildIntersection(circle: Circle): BodyQuadtreeNode | QuadtreeCheckType {
    if (!this.children) {
      return QuadtreeCheckType.IntersectedBounds;
    }

    const circleMaxX = circle.x + circle.radius;
    const circleMinX = circle.x - circle.radius;
    const circleMaxY = circle.y + circle.radius;
    const circleMinY = circle.y - circle.radius;

    let leftOfMid: boolean = false;
    let aboveMid: boolean = false;
    let intersectingVerticalMidline: boolean = false;
    let intersectingHorizontalMidline: boolean = false;

    if (this.x < circleMinX) {
      leftOfMid = false; // Circle is entirely to the right of midpoint
    } else if (this.x > circleMaxX) {
      leftOfMid = true; // Circle is entirely to the left of midpoint
    } else {
      intersectingVerticalMidline = true; // Circle spans the vertical midline
    }

    if (this.y < circleMinY) {
      aboveMid = false; // Circle is entirely below midpoint
    } else if (this.y > circleMaxY) {
      aboveMid = true; // Circle is entirely above midpoint
    } else {
      intersectingHorizontalMidline = true; // Circle spans the horizontal midline
    }

    if (intersectingVerticalMidline) {
      if (intersectingHorizontalMidline) {
        return QuadtreeCheckType.StoppedIntersectingEitherMidline;
      } else {
        return QuadtreeCheckType.StoppedIntersectingVerticalMidline;
      }
    } else {
      if (intersectingHorizontalMidline) {
        return QuadtreeCheckType.StoppedIntersectingHorizontalMidline;
      }
    }

    if (leftOfMid && aboveMid) {
      return this.children.nw;
    } else if (leftOfMid && !aboveMid) {
      return this.children.sw;
    } else if (!leftOfMid && aboveMid) {
      return this.children.ne;
    } else {
      return this.children.se;
    }
  }

  getPossiblePairs(entriesFromAbove: BodyQuadtreeEntry[]): BodyQuadtreePair[] {
    const pairs: BodyQuadtreePair[] = [];

    // Check entries at this level against each other
    for (let i = 0; i < this.entries.length; i++) {
      // Check against other entries at this level
      for (let j = i + 1; j < this.entries.length; j++) {
        pairs.push({ a: this.entries[i], b: this.entries[j] });
      }

      // Check against entries from above
      for (const aboveEntry of entriesFromAbove) {
        pairs.push({ a: this.entries[i], b: aboveEntry });
      }
    }

    // If we're divided, use optimized distribution to children
    if (this.children) {
      const midX = this.x;
      const midY = this.y;

      // Arrays for horizontal splits
      const leftEntries: BodyQuadtreeEntry[] = [];
      const rightEntries: BodyQuadtreeEntry[] = [];
      const horizontalSpanningEntries: BodyQuadtreeEntry[] = [];

      // // Update all circles at this level
      // for (const entry of this.entries) {
      //   entry.circle = entry.body.getMec();
      // }

      // First split all entries horizontally
      const allEntries = [...entriesFromAbove, ...this.entries];
      for (const entry of allEntries) {
        const circle = entry.circle;
        const leftBound = circle.x - circle.radius;
        const rightBound = circle.x + circle.radius;

        if (leftBound <= midX && rightBound >= midX) {
          horizontalSpanningEntries.push(entry);
        } else if (rightBound < midX) {
          leftEntries.push(entry);
        } else if (leftBound > midX) {
          rightEntries.push(entry);
        }
      }

      // Function to split entries vertically
      const splitVertically = (entries: BodyQuadtreeEntry[]) => {
        const top: BodyQuadtreeEntry[] = [];
        const bottom: BodyQuadtreeEntry[] = [];
        const spanning: BodyQuadtreeEntry[] = [];

        for (const entry of entries) {
          const circle = entry.circle;
          const topBound = circle.y - circle.radius;
          const bottomBound = circle.y + circle.radius;

          if (topBound <= midY && bottomBound >= midY) {
            spanning.push(entry);
          } else if (bottomBound < midY) {
            top.push(entry);
          } else if (topBound > midY) {
            bottom.push(entry);
          }
        }

        return { top, bottom, spanning };
      };

      // Split each horizontal group vertically
      const leftSplit = splitVertically(leftEntries);
      const rightSplit = splitVertically(rightEntries);
      const spanningSplit = splitVertically(horizontalSpanningEntries);

      // Distribute to children (clockwise from northwest)
      const children = this.children;

      // Northwest (top-left)
      pairs.push(
        ...children.nw.getPossiblePairs([
          ...leftSplit.top,
          ...leftSplit.spanning,
          ...spanningSplit.top,
          ...spanningSplit.spanning,
        ])
      );

      // Northeast (top-right)
      pairs.push(
        ...children.ne.getPossiblePairs([
          ...rightSplit.top,
          ...rightSplit.spanning,
          ...spanningSplit.top,
          ...spanningSplit.spanning,
        ])
      );

      // Southwest (bottom-left)
      pairs.push(
        ...children.sw.getPossiblePairs([
          ...leftSplit.bottom,
          ...leftSplit.spanning,
          ...spanningSplit.bottom,
          ...spanningSplit.spanning,
        ])
      );

      // Southeast (bottom-right)
      pairs.push(
        ...children.se.getPossiblePairs([
          ...rightSplit.bottom,
          ...rightSplit.spanning,
          ...spanningSplit.bottom,
          ...spanningSplit.spanning,
        ])
      );
    }

    return pairs;
  }

  private subdivide() {
    const childDepth = this.depth + 1;
    const sizes = this.nodeSizes;
    const childSize = sizes[childDepth];

    const x = this.x;
    const y = this.y;

    this.children = {
      nw: new BodyQuadtreeNode(
        x - childSize,
        y - childSize,
        childDepth,
        this,
        sizes
      ),
      ne: new BodyQuadtreeNode(
        x + childSize,
        y - childSize,
        childDepth,
        this,
        sizes
      ),
      sw: new BodyQuadtreeNode(
        x - childSize,
        y + childSize,
        childDepth,
        this,
        sizes
      ),
      se: new BodyQuadtreeNode(
        x + childSize,
        y + childSize,
        childDepth,
        this,
        sizes
      ),
    };
  }

  queryCircle(circle: Circle, results: BodyQuadtreeEntry[]) {
    if (!circleIntersectsAABB(circle, this.bounds)) {
      return;
    }

    // Check entries in this node
    for (let entry of this.entries) {
      if (circlesIntersect(circle, entry.circle)) {
        results.push(entry);
      }
    }

    // Recurse into children
    if (this.children) {
      for (const child of Object.values(this.children)) {
        child.queryCircle(circle, results);
      }
    }
  }

  queryAABB(aabb: AABB, results: BodyQuadtreeEntry[]) {
    // If the search rectangle does not intersect this node, return
    if (!aabbsIntersect(aabb, this.bounds)) {
      return;
    }

    // Check entries in this node
    for (let entry of this.entries) {
      if (circleIntersectsAABB(entry.circle, aabb)) {
        results.push(entry);
      }
    }

    // Recurse into children
    if (this.children) {
      for (let child of Object.values(this.children)) {
        child.queryAABB(aabb, results);
      }
    }
  }

  hasChildren(): boolean {
    return this.children !== null;
  }
}

export function circlesIntersect(a: Circle, b: Circle): boolean {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  const distanceSq = dx * dx + dy * dy;
  const radiusSum = a.radius + b.radius;
  return distanceSq <= radiusSum * radiusSum;
}

function aabbsIntersect(a: AABB, b: AABB): boolean {
  return !(
    a.left > b.right ||
    a.right < b.left ||
    a.top > b.bottom ||
    a.bottom < b.top
  );
}

function circleIntersectsAABB(circle: Circle, aabb: AABB): boolean {
  const closestX = clamp(circle.x, aabb.left, aabb.right);
  const closestY = clamp(circle.y, aabb.top, aabb.bottom);
  const dx = circle.x - closestX;
  const dy = circle.y - closestY;
  return dx * dx + dy * dy <= circle.radius * circle.radius;
}

function aabbContainsCircle(aabb: AABB, circle: Circle): boolean {
  return (
    circle.x + circle.radius <= aabb.right && // Circle doesn't extend past right edge
    circle.x - circle.radius >= aabb.left && // Circle doesn't extend past left edge
    circle.y + circle.radius <= aabb.bottom && // Circle doesn't extend past bottom edge
    circle.y - circle.radius >= aabb.top // Circle doesn't extend past top edge
  );
}

/**
 * Helper function to clamp a value between min and max.
 */
function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value));
}

// getChildrenIntersectingCircle(circle: Circle): BodyQuadtreeNode[] {
//   if (!this.children) {
//     return [];
//   }

//   const circleMaxX = circle.x + circle.radius;
//   const circleMinX = circle.x - circle.radius;
//   const circleMaxY = circle.y + circle.radius;
//   const circleMinY = circle.y - circle.radius;

//   let centerInBounds: boolean = false;
//   // Check if circle center is in bounds
//   if (
//     circle.x > this.x - this.size &&
//     circle.x < this.x + this.size &&
//     circle.y > this.y - this.size &&
//     circle.y < this.y + this.size
//   ) {
//     centerInBounds = true;
//   }

//   let xPosition: QuadrantIntersection;
//   if (this.x < circleMinX) {
//     xPosition = QuadrantIntersection.East; // Circle is in the east half
//   } else if (this.x > circleMaxX) {
//     xPosition = QuadrantIntersection.West; // Circle is in the west half
//   } else {
//     xPosition = QuadrantIntersection.Intersects; // Circle intersects node's vertical line
//   }

//   let yPosition: QuadrantIntersection;
//   if (this.y < circleMinY) {
//     yPosition = QuadrantIntersection.North; // Circle is in the north half
//   } else if (this.y > circleMaxY) {
//     yPosition = QuadrantIntersection.South; // Circle is in the south half
//   } else {
//     yPosition = QuadrantIntersection.Intersects; // Circle intersects node's horizontal line
//   }

//   if (xPosition === QuadrantIntersection.West) {
//     if (yPosition === QuadrantIntersection.North) {
//       return [this.children.nw];
//     } else if (yPosition === QuadrantIntersection.South) {
//       return [this.children.sw];
//     } else {
//       return [this.children.nw, this.children.sw];
//     }
//   } else if (xPosition === QuadrantIntersection.East) {
//     if (yPosition === QuadrantIntersection.North) {
//       return [this.children.ne];
//     } else if (yPosition === QuadrantIntersection.South) {
//       return [this.children.se];
//     } else {
//       return [this.children.ne, this.children.se];
//     }
//   } else {
//     if (yPosition === QuadrantIntersection.North) {
//       return [this.children.nw, this.children.ne];
//     } else if (yPosition === QuadrantIntersection.South) {
//       return [this.children.sw, this.children.se];
//     }
//     // Fall through if both are intersects
//   }

//   const dx = this.x - circle.x;
//   const dy = this.y - circle.y;
//   const distanceSquared = dx * dx + dy * dy;
//   const radiusSquared = circle.radius * circle.radius;

//   if (distanceSquared <= radiusSquared) {
//     return [
//       this.children.nw,
//       this.children.ne,
//       this.children.sw,
//       this.children.se,
//     ];
//   }

//   let centerLeftOfMid: boolean;
//   if (dx > 0) {
//     // Center is to the right of circle
//     centerLeftOfMid = false;
//   } else {
//     // Center is to the left of circle
//     centerLeftOfMid = true;
//   }

//   let centerAboveMid: boolean;
//   if (dy > 0) {
//     // Center is above circle
//     centerAboveMid = false;
//   } else {
//     // Center is below circle
//     centerAboveMid = true;
//   }

//   if (centerLeftOfMid && centerAboveMid) {
//     return [this.children.nw, this.children.sw, this.children.ne];
//   } else if (centerLeftOfMid && !centerAboveMid) {
//     return [this.children.nw, this.children.se, this.children.sw];
//   } else if (!centerLeftOfMid && centerAboveMid) {
//     return [this.children.nw, this.children.ne, this.children.se];
//   } else {
//     return [this.children.se, this.children.ne, this.children.sw];
//   }
// }
