import { calculateGeometricProperties, TWO_PI } from "../math/utils.ts";
import { welzl, type WelzlPoint } from "../geometry/welzl.ts";
import { Vector2D } from "../math/vector2D.ts";
import {
  type Segment,
  SegmentType,
  type LineIntersection,
  type IntersectionPoint,
  type Circle,
  type SegmentInfo,
  type PerimeterPoint,
  type RelativeTransformInfo,
  type Span,
  StoredPointType,
  type StoredPoint,
  type SubtractionResultShape,
} from "../models.ts";
import { Transform } from "../math/transform.ts";
import { CircleSegment } from "./segments/circleSegment.ts";
import { LineSegment } from "./segments/lineSegment.ts";
import { ArcSegment } from "./segments/arcSegment.ts";
import { findShapeIntersections } from "../geometry/intersection.ts";
import { buildSegmentTree, SegmentTree } from "./segmentTree.ts";
import { intersectSegmentTrees } from "./intersectSegmentTrees.ts";
import { SurfacePoint } from "../plane/surfacePoint.ts";
import {
  buildShapesFromSpans,
  getIntersectionSpans,
} from "../geometry/buildIntersectionSpans.ts";
import { nanoid } from "nanoid";
import type { BufferWriter } from "../planar/bufferUtil.ts";

export class Shape {
  segments: Segment[];
  area: number;
  mec: {
    center: Vector2D;
    radius: number;
    mecPointIds: string[];
  };
  centroid: Vector2D;
  secondMomentOfArea: number;
  path: Path2D | null = null;
  private segmentTree: SegmentTree | null = null;
  private perimeterPoints: Vector2D[] | null = null;
  surfacePoints: Set<SurfacePoint> | null = null;

  constructor(
    segments: Segment[],
    precomputed?: {
      area: number;
      centroid: Vector2D;
      secondMomentOfArea: number;
      mec?: {
        center: Vector2D;
        radius: number;
        mecPointIds: string[];
      };
    }
  ) {
    this.segments = segments;

    if (precomputed) {
      this.area = precomputed.area;
      this.centroid = precomputed.centroid;
      this.secondMomentOfArea = precomputed.secondMomentOfArea;
      this.mec = precomputed.mec ?? this.calculateMec();
    } else {
      const props = calculateGeometricProperties(this.segments);
      this.area = props.area;
      this.centroid = props.centroid;
      this.secondMomentOfArea = props.secondMomentOfArea;
      this.mec = this.calculateMec();
    }
  }

  getCentroid(transform: Transform): Vector2D {
    return transform.apply(this.centroid);
  }

  getSegmentTree(): SegmentTree {
    if (this.segmentTree) {
      return this.segmentTree;
    }

    this.segmentTree = buildSegmentTree(this.segments);
    return this.segmentTree;
  }

  queryCircle(circle: Circle): SegmentInfo[] {
    const segmentTree = this.getSegmentTree();

    return segmentTree.queryCircle(circle);
  }

  containsPoint(point: Vector2D, transform: Transform): boolean {
    const segmentTree = this.getSegmentTree();

    // Convert point to local coordinates
    const localPoint = transform.applyInverse(point);

    const found = segmentTree.queryHorizontalRay(localPoint);

    let count = 0;

    for (const segmentInfo of found) {
      count += segmentInfo.segment.rayIntersectionCount(localPoint);
    }

    return count % 2 === 1;
  }

  storeSurfacePoint({
    surfacePoint,
    segment,
    tOrAngle,
  }: {
    surfacePoint: SurfacePoint;
    segment: Segment;
    tOrAngle: number;
  }): void {
    if (!this.segments.find((s) => s === segment)) {
      throw new Error("Segment not found in shape");
    }

    segment.storePoint({
      type: StoredPointType.SURFACE_POINT,
      data: surfacePoint,
      t: tOrAngle,
    });

    if (!this.surfacePoints) {
      this.surfacePoints = new Set();
    }

    this.surfacePoints.add(surfacePoint);
  }

  addSurfacePoint(surfacePoint: SurfacePoint): void {
    if (!this.surfacePoints) {
      this.surfacePoints = new Set();
    }

    this.surfacePoints.add(surfacePoint);
  }

  getClosestPerimeterPoint(
    queryCenter: Vector2D,
    queryRadius: number
  ): {
    distance: number;
    perimeterPoint: PerimeterPoint;
  } | null {
    // Step 2: Define the search circle in local coordinates
    const searchCircle: Circle = {
      x: queryCenter.x,
      y: queryCenter.y,
      radius: queryRadius,
    };

    const segmentTree = this.getSegmentTree();

    // Step 3: Query the segment quadtree for segments intersecting the search circle
    const possibleSegments = segmentTree.queryCircle(searchCircle);

    // If no segments are found within the query radius, return null
    if (possibleSegments.length === 0) {
      return null;
    }

    let closestPoint: PerimeterPoint | null = null;
    let minDistanceSquared = queryRadius * queryRadius;

    // Step 4: Iterate through each possible segment to find the closest point
    for (const segmentInfo of possibleSegments) {
      // Find the closest point on the segment to the localPoint
      const candidatePoint = segmentInfo.segment.getClosestPoint(queryCenter);

      if (!candidatePoint) {
        continue;
      }

      const { point, tOrAngle } = candidatePoint;

      // Calculate the squared distance between localPoint and candidatePoint
      const dx = point.x - queryCenter.x;
      const dy = point.y - queryCenter.y;
      const distanceSquared = dx * dx + dy * dy;

      // If this point is closer than the current closest, update closestPoint
      if (distanceSquared < minDistanceSquared) {
        minDistanceSquared = distanceSquared;
        closestPoint = {
          segment: segmentInfo.segment,
          segmentIndex: segmentInfo.index,
          position: point.clone(),
          tOrAngle,
        };

        // Early exit if the closest possible point is found
        if (distanceSquared === 0) {
          break;
        }
      }
    }

    if (closestPoint) {
      const closestDistance = Math.sqrt(minDistanceSquared);
      if (closestDistance <= queryRadius) {
        return {
          distance: closestDistance,
          perimeterPoint: closestPoint,
        };
      }
    }

    // If no valid closest point is found within the query radius, return null
    return null;
  }

  storePoint({
    surfacePoint,
    segment,
    tOrAngle,
  }: {
    surfacePoint: SurfacePoint;
    segment: Segment;
    tOrAngle: number;
  }): void {}

  getPointOnPerimeter(transform: Transform): Vector2D {
    if (this.segments[0].type !== SegmentType.CIRCLE) {
      return transform.apply((this.segments[0] as LineSegment).start);
    }
    return transform.apply(
      new Vector2D(
        (this.segments[0] as CircleSegment).center.x +
          (this.segments[0] as CircleSegment).radius,
        (this.segments[0] as CircleSegment).center.y
      )
    );
  }

  recenter(): void {
    this.mec.center = this.mec.center.subtract(this.centroid);
    for (const segment of this.segments) {
      segment.translate(-this.centroid.x, -this.centroid.y);
    }
    this.centroid = new Vector2D(0, 0);
  }

  calculateMec(): {
    center: Vector2D;
    radius: number;
    mecPointIds: string[];
  } {
    const points: WelzlPoint[] = [];
    for (const segment of this.segments) {
      if (segment.type === SegmentType.LINE) {
        const line = segment as LineSegment;
        line.clearStoredMecPoints();
        points.push({
          x: line.start.x,
          y: line.start.y,
          segment: line,
          t: 0,
        });
      } else if (segment.type === SegmentType.ARC) {
        const arc = segment as ArcSegment;
        arc.clearStoredMecPoints();
        points.push({
          x: arc.start.x,
          y: arc.start.y,
          segment: arc,
          t: 0,
        });
        if (!arc.clockwise) {
          points.push(...arc.getMecPoints());
        }
      } else if (segment.type === SegmentType.CIRCLE) {
        const circle = segment as CircleSegment;
        return {
          center: circle.center,
          radius: circle.radius,
          mecPointIds: [],
        };
      }
    }

    const mec = welzl(points);
    if (mec === null) {
      throw new Error("Minimum enclosing circle not found");
    }

    const mecPoints = mec.boundaryPoints;

    const mecPointIds: string[] = [];
    for (const point of mecPoints) {
      const segment = point.segment;
      const t = point.t;
      const id = nanoid(8);
      segment.storePoint({
        type: StoredPointType.MEC_POINT,
        data: id,
        t,
      });
      mecPointIds.push(id);
    }

    return {
      center: new Vector2D(mec.center.x, mec.center.y),
      radius: mec.radius,
      mecPointIds,
    };
  }

  getLineIntersections(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    transform?: Transform
  ): LineIntersection[] {
    const intersections: LineIntersection[] = [];
    const localLineStart = transform?.applyInverse(lineStart) ?? lineStart;
    const localLineEnd = transform?.applyInverse(lineEnd) ?? lineEnd;

    // Find possible segments
    const segmentTree = this.getSegmentTree();
    const possibleSegments = segmentTree.queryLine(
      localLineStart,
      localLineEnd
    );

    for (const segmentInfo of possibleSegments) {
      const segment = segmentInfo.segment;
      if (segment.type === SegmentType.LINE) {
        const intersection = (segment as LineSegment).getLineIntersection(
          localLineStart,
          localLineEnd
        );
        if (intersection) {
          intersections.push(...intersection);
        }
      } else {
        const segmentIntersections = (
          segment as ArcSegment | CircleSegment
        ).getLineIntersection(localLineStart, localLineEnd);
        intersections.push(...segmentIntersections);
      }
    }

    // Sort intersections by t value
    intersections.sort((a, b) => a.t - b.t);

    // Transform intersections back to global coordinates
    for (const intersection of intersections) {
      intersection.point =
        transform?.apply(intersection.point) ?? intersection.point;
    }

    return intersections;
  }

  clone(): Shape {
    const clonedSegments = this.segments.map((segment) => segment.clone(false));

    return new Shape(clonedSegments, {
      area: this.area,
      centroid: this.centroid,
      secondMomentOfArea: this.secondMomentOfArea,
      mec: this.mec,
    });
  }

  inverse(): Shape {
    const clonedSegments = this.segments
      .map((segment) => segment.clone(true))
      .reverse();

    const newShape = new Shape(clonedSegments, {
      area: -this.area,
      centroid: this.centroid,
      secondMomentOfArea: -this.secondMomentOfArea,
      mec: this.mec,
    });

    return newShape;
  }

  subtract(
    other: Shape,
    transformInfo: RelativeTransformInfo
  ): {
    resultShapes: {
      shape: Shape;
      subtractionResultShape: SubtractionResultShape;
      surfacePoints: SurfacePoint[];
    }[];
    destroyedSurfacePoints: SurfacePoint[];
  } | null {
    const otherInverse = other.inverse();

    const segmentPairs = intersectSegmentTrees(
      otherInverse.getSegmentTree(),
      this.getSegmentTree(),
      transformInfo.guestToHost,
      transformInfo.hostToGuest
    );

    if (segmentPairs === null) {
      return null;
    }

    let intersections: {
      intersections: [IntersectionPoint[], IntersectionPoint[]] | null;
      segmentCheckCount: number;
      segmentIntersectionCount: number;
    } | null = null;

    try {
      intersections = findShapeIntersections(
        segmentPairs,
        transformInfo.guestToHost
      );
    } catch (e) {
      return null;
    }

    if (intersections.intersections === null) {
      return null;
    }

    const spanShapes = getIntersectionSpans({
      intersections: intersections.intersections,
      union: false,
    });

    const shapes = buildShapesFromSpans(
      otherInverse,
      this,
      spanShapes,
      transformInfo.guestToHost
    );

    if (shapes.length === 0) {
      return null;
    }

    if (!this.surfacePoints) {
      return {
        resultShapes: shapes,
        destroyedSurfacePoints: [],
      };
    }

    for (const shape of shapes) {
      for (const surfacePoint of shape.surfacePoints) {
        this.surfacePoints.delete(surfacePoint);
      }
    }

    const destroyedSurfacePoints = Array.from(this.surfacePoints);

    return {
      resultShapes: shapes,
      destroyedSurfacePoints,
    };
  }

  getSubtractionResultFromSerialized(
    subtractionResultShape: SubtractionResultShape
  ): {
    shape: Shape;
    surfacePoints: SurfacePoint[];
  } {
    const segments: Segment[] = [];
    const storedPoints: StoredPoint[] = [];

    for (const span of subtractionResultShape.spans) {
      if (Array.isArray(span)) {
        segments.push(...span);
      } else {
        this.addSegmentsInSpan(span, segments, storedPoints);
      }
    }

    const shape = new Shape(segments, subtractionResultShape.shapeProperties);

    const surfacePoints: SurfacePoint[] = [];
    for (const storedPoint of storedPoints) {
      if (storedPoint.type === StoredPointType.SURFACE_POINT) {
        surfacePoints.push(storedPoint.data as SurfacePoint);
      }
    }

    return {
      shape,
      surfacePoints,
    };
  }

  addSegmentsInSpan(
    span: Span,
    segments: Segment[],
    storedPoints: StoredPoint[],
    accumulators?: {
      area: number;
      weightedCentroidX: number;
      weightedCentroidY: number;
      secondMomentOfArea: number;
    },
    transform?: Transform
  ): number {
    // Check if it's wrapped
    let wrapped = false;
    let sameSegment = false;

    if (span.onCircle) {
      sameSegment = true;
    } else if (span.start.segmentIndex === span.end.segmentIndex) {
      if (span.start.t > span.end.t) {
        wrapped = true;
      } else {
        sameSegment = true;
      }
    } else if (span.start.segmentIndex > span.end.segmentIndex) {
      wrapped = true;
    }

    if (sameSegment) {
      const segment = this.segments[span.start.segmentIndex];
      const tValues: [[number, number], [Vector2D | null, Vector2D | null]] = [
        [span.start.t, span.end.t],
        [span.start.point, span.end.point],
      ];
      const partialSegment = transform
        ? segment.globalPartialClone(transform, tValues, false)
        : segment.partialClone(tValues, false);

      segments.push(partialSegment);

      if (accumulators) {
        const segmentProperties = partialSegment.getAllContributions();

        accumulators.area += segmentProperties.area;
        accumulators.weightedCentroidX +=
          segmentProperties.centroid.x * segmentProperties.area;
        accumulators.weightedCentroidY +=
          segmentProperties.centroid.y * segmentProperties.area;
        accumulators.secondMomentOfArea +=
          segmentProperties.secondMomentOfArea +
          segmentProperties.area * segmentProperties.centroid.lengthSquared();
      }

      storedPoints.push(...segment.getStoredPoints());

      return 1;
    }

    let numSegmentsAdded = 0;

    const startSegment = this.segments[span.start.segmentIndex];
    const startTValues: [[number, number], [Vector2D | null, Vector2D | null]] =
      [
        [span.start.t, 1],
        [span.start.point, null],
      ];
    const startPartial = transform
      ? startSegment.globalPartialClone(transform, startTValues, false)
      : startSegment.partialClone(startTValues, false);

    segments.push(startPartial);
    numSegmentsAdded++;

    if (accumulators) {
      const startSegmentProperties = startPartial.getAllContributions();

      accumulators.area += startSegmentProperties.area;
      accumulators.weightedCentroidX +=
        startSegmentProperties.centroid.x * startSegmentProperties.area;
      accumulators.weightedCentroidY +=
        startSegmentProperties.centroid.y * startSegmentProperties.area;
      accumulators.secondMomentOfArea +=
        startSegmentProperties.secondMomentOfArea +
        startSegmentProperties.area *
          startSegmentProperties.centroid.lengthSquared();
    }

    storedPoints.push(...startPartial.getStoredPoints());

    if (wrapped) {
      // To end
      for (let i = span.start.segmentIndex + 1; i < this.segments.length; i++) {
        const segment = transform
          ? this.segments[i].globalClone(transform, false)
          : this.segments[i];
        segments.push(segment);
        numSegmentsAdded++;

        if (accumulators) {
          const segmentProperties = segment.getAllContributions();

          accumulators.area += segmentProperties.area;
          accumulators.weightedCentroidX +=
            segmentProperties.centroid.x * segmentProperties.area;
          accumulators.weightedCentroidY +=
            segmentProperties.centroid.y * segmentProperties.area;
          accumulators.secondMomentOfArea +=
            segmentProperties.secondMomentOfArea +
            segmentProperties.area * segmentProperties.centroid.lengthSquared();
        }

        storedPoints.push(...segment.getStoredPoints());
      }

      // From start
      for (let i = 0; i < span.end.segmentIndex; i++) {
        const segment = transform
          ? this.segments[i].globalClone(transform, false)
          : this.segments[i];
        segments.push(segment);
        numSegmentsAdded++;

        if (accumulators) {
          const segmentProperties = segment.getAllContributions();

          accumulators.area += segmentProperties.area;
          accumulators.weightedCentroidX +=
            segmentProperties.centroid.x * segmentProperties.area;
          accumulators.weightedCentroidY +=
            segmentProperties.centroid.y * segmentProperties.area;
          accumulators.secondMomentOfArea +=
            segmentProperties.secondMomentOfArea +
            segmentProperties.area * segmentProperties.centroid.lengthSquared();
        }

        storedPoints.push(...segment.getStoredPoints());
      }
    } else {
      for (
        let i = span.start.segmentIndex + 1;
        i < span.end.segmentIndex;
        i++
      ) {
        const segment = transform
          ? this.segments[i].globalClone(transform, false)
          : this.segments[i];
        segments.push(segment);
        numSegmentsAdded++;

        if (accumulators) {
          const segmentProperties = segment.getAllContributions();

          accumulators.area += segmentProperties.area;
          accumulators.weightedCentroidX +=
            segmentProperties.centroid.x * segmentProperties.area;
          accumulators.weightedCentroidY +=
            segmentProperties.centroid.y * segmentProperties.area;
          accumulators.secondMomentOfArea +=
            segmentProperties.secondMomentOfArea +
            segmentProperties.area * segmentProperties.centroid.lengthSquared();
        }

        storedPoints.push(...segment.getStoredPoints());
      }
    }

    const endSegment = this.segments[span.end.segmentIndex];
    const endTValues: [[number, number], [Vector2D | null, Vector2D | null]] = [
      [0, span.end.t],
      [null, span.end.point],
    ];
    const endPartial = transform
      ? endSegment.globalPartialClone(transform, endTValues, false)
      : endSegment.partialClone(endTValues, false);

    segments.push(endPartial);
    numSegmentsAdded++;

    if (accumulators) {
      const endSegmentProperties = endPartial.getAllContributions();

      accumulators.area += endSegmentProperties.area;
      accumulators.weightedCentroidX +=
        endSegmentProperties.centroid.x * endSegmentProperties.area;
      accumulators.weightedCentroidY +=
        endSegmentProperties.centroid.y * endSegmentProperties.area;
      accumulators.secondMomentOfArea +=
        endSegmentProperties.secondMomentOfArea +
        endSegmentProperties.area *
          endSegmentProperties.centroid.lengthSquared();
    }

    storedPoints.push(...endPartial.getStoredPoints());

    return numSegmentsAdded;
  }

  getPath(): Path2D {
    if (this.path) {
      return this.path;
    }

    const path = new Path2D();
    for (const [index, segment] of this.segments.entries()) {
      if (index === 0) {
        if (
          segment.type === SegmentType.LINE ||
          segment.type === SegmentType.ARC
        ) {
          const lineSegment = segment as LineSegment;
          path.moveTo(lineSegment.start.x, lineSegment.start.y);
        } else if (segment.type === SegmentType.CIRCLE) {
          const circleSegment = segment as CircleSegment;
          path.moveTo(
            circleSegment.center.x + circleSegment.radius,
            circleSegment.center.y
          );
        }
      }

      switch (segment.type) {
        case SegmentType.LINE:
          const lineSegment = segment as LineSegment;
          path.lineTo(lineSegment.end.x, lineSegment.end.y);
          break;
        case SegmentType.CIRCLE:
          const circleSegment = segment as CircleSegment;
          path.arc(
            circleSegment.center.x,
            circleSegment.center.y,
            circleSegment.radius,
            0,
            TWO_PI,
            circleSegment.clockwise
          );
          break;
        case SegmentType.ARC:
          const arcSegment = segment as ArcSegment;
          path.arc(
            arcSegment.center.x,
            arcSegment.center.y,
            arcSegment.radius,
            arcSegment.startAngle,
            arcSegment.endAngle,
            arcSegment.clockwise
          );
          break;
      }
    }
    path.closePath();
    this.path = path;
    return path;
  }

  getPerimeterPoints(distance: number): Vector2D[] {
    if (this.perimeterPoints) {
      return this.perimeterPoints;
    }

    if (distance <= 0) {
      throw new Error("Distance must be positive");
    }

    const points: Vector2D[] = [];
    let leftoverDistance = 0;

    for (const segment of this.segments) {
      let multiplier = 0;

      while (true) {
        const queryDistance = leftoverDistance + distance * multiplier;
        const result = segment.getPointAtDistance(queryDistance);

        // If we got a point, validate it
        if (result instanceof Vector2D) {
          if (isNaN(result.x) || isNaN(result.y)) {
            throw new Error(
              `Invalid point generated: ${JSON.stringify(
                {
                  segmentType: segment.type,
                  queryDistance,
                  point: result,
                  segment,
                },
                null,
                2
              )}`
            );
          }
        }

        if (typeof result === "number") {
          leftoverDistance = result;
          break;
        }

        points.push(result);
        multiplier++;
      }
    }

    this.perimeterPoints = points;
    return points;
  }
}
