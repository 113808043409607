import { Vector2D } from "../math/vector2D";
import type { Joint } from "../models";
import { Body } from "./body";
import { SurfacePointDataStride, SurfacePointOffset } from "./plane";

export type SurfacePointImpulseInfo = {
  position: Vector2D;
  velocity: Vector2D;
  r: Vector2D;
  invMass: number;
  invInertia: number;
};

export class SurfacePoint {
  id: string;
  body: Body | null;
  index: number;
  strideIndex: number;
  stateArray: Float32Array;
  joint: Joint | null = null;
  transferInStore: (newBody: Body) => boolean;
  destroyInStore: (id: string, index: number) => void;

  constructor({
    id,
    body,
    index,
    stateArray,
    transferInStore,
    destroyInStore,
  }: {
    id: string;
    body: Body;
    index: number;
    stateArray: Float32Array;
    transferInStore: (newBody: Body) => boolean;
    destroyInStore: (id: string, index: number) => void;
  }) {
    this.id = id;
    this.body = body;
    this.index = index;
    this.strideIndex = index * SurfacePointDataStride;
    this.stateArray = stateArray;
    this.transferInStore = transferInStore;
    this.destroyInStore = destroyInStore;
  }

  getBody(): Body | null {
    return this.body;
  }

  getPosition(): Vector2D | null {
    if (this.body === null) {
      return null;
    }

    return new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionX],
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionY]
    );
  }

  getPositionAndBody(): { position: Vector2D; body: Body } | null {
    if (this.body === null) {
      return null;
    }

    return {
      position: new Vector2D(
        this.stateArray[this.strideIndex + SurfacePointOffset.PositionX],
        this.stateArray[this.strideIndex + SurfacePointOffset.PositionY]
      ),
      body: this.body,
    };
  }

  getLocalPosition(): Vector2D | null {
    if (this.body === null) {
      return null;
    }

    return new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.LocalX],
      this.stateArray[this.strideIndex + SurfacePointOffset.LocalY]
    );
  }

  getR(): Vector2D | null {
    if (this.body === null) {
      return null;
    }

    return new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.RX],
      this.stateArray[this.strideIndex + SurfacePointOffset.RY]
    );
  }

  getImpulseInfo(): SurfacePointImpulseInfo | null {
    if (this.body === null) {
      return null;
    }

    const position = new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionX],
      this.stateArray[this.strideIndex + SurfacePointOffset.PositionY]
    );
    const velocity = new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.VelocityX],
      this.stateArray[this.strideIndex + SurfacePointOffset.VelocityY]
    );
    const r = new Vector2D(
      this.stateArray[this.strideIndex + SurfacePointOffset.RX],
      this.stateArray[this.strideIndex + SurfacePointOffset.RY]
    );
    const { invMass, invInertia } = this.body.getInvProperties();
    return {
      position,
      velocity,
      r,
      invMass,
      invInertia,
    };
  }

  applyImpulse(impulse: Vector2D): void {
    if (this.body === null) {
      return;
    }

    this.body.applyImpulse(impulse, this.getR()!);
  }

  applyAngularImpulse(angularImpulse: number): void {
    if (this.body === null) {
      return;
    }

    this.body.applyAngularImpulse(angularImpulse);
  }

  addPositionCorrection(correction: Vector2D): void {
    if (this.body === null) {
      return;
    }

    this.body.addPositionCorrection(correction);
  }

  addAngleCorrection(correction: number): void {
    if (this.body === null) {
      return;
    }

    this.body.addAngleCorrection(correction);
  }

  applyForce(force: Vector2D): void {
    if (this.body === null) {
      return;
    }

    this.body.applyForce(force, this.getR()!);
  }

  applyForceToCenter(force: Vector2D): void {
    if (this.body === null) {
      return;
    }

    this.body.applyForce(force, undefined, true);
  }

  applyTorque(torque: number): void {
    if (this.body === null) {
      return;
    }

    this.body.applyTorque(torque, this.getR()!);
  }

  transfer(newBody: Body): void {
    if (this.body === null) {
      return;
    }

    if (this.transferInStore(newBody)) {
      this.joint?.pointTransfer(this, this.body, newBody);
      this.body = newBody;
    } else {
      this.body = null;
    }
  }

  destroy(): void {
    if (this.body === null) {
      return;
    }

    this.joint?.pointDestroy(this);
    this.destroyInStore(this.id, this.index);
    this.body = null;
  }
}
