import type { Vector2D } from "../math/vector2D";
import { ActionType } from "../planar/action";
import type { Plane } from "../plane/plane";
import type { Player } from "../planar/player";
import type { Cut } from "../planar/actions/cut";

export class AudioRenderer {
  private plane: Plane;
  private player: Player;
  private audioContext: AudioContext;
  private audioBuffers: Map<string, AudioBuffer>;
  private volumeBuffers: Map<string, Map<number, AudioBuffer>>; // id -> volume -> buffer
  private activeNodes: Set<AudioBufferSourceNode>;
  private maxSimultaneousSounds: number;
  private lastPlayTime: number;
  private minTimeBetweenSounds: number;
  private recentCollisionPositions: Array<{ position: Vector2D; time: number }>;
  private readonly POSITION_MEMORY_TIME = 500; // How long to remember positions (ms)
  private readonly MIN_DISTANCE_BETWEEN_SOUNDS = 50; // Minimum distance between collision sounds
  private readonly MAX_VOLUME = 0.2; // Maximum volume for any collision sound
  private readonly VOLUME_STEPS = 10; // Number of volume levels to pre-compute
  private readonly MIN_COLLISION_ENERGY = 1000; // Minimum energy to make a sound
  private readonly MAX_COLLISION_ENERGY = 10000000000; // Energy that would make max volume
  private readonly MAX_DISTANCE = 3000; // Distance at which sound becomes inaudible
  private readonly COLLISION_SOUNDS = ["bump-1", "bump-2", "bump-3"];

  private readonly logRejectionReasons = false;

  playingCutSound: boolean = false;
  private activeCutNode: AudioBufferSourceNode | null = null;

  constructor(plane: Plane, player: Player) {
    this.plane = plane;
    this.player = player;
    this.audioContext = new AudioContext();
    this.audioBuffers = new Map();
    this.volumeBuffers = new Map();
    this.activeNodes = new Set();
    this.maxSimultaneousSounds = 10;
    this.lastPlayTime = 0;
    this.minTimeBetweenSounds = 10;
    this.recentCollisionPositions = [];

    // Load all collision sound variants
    this.COLLISION_SOUNDS.forEach((id) => {
      this.loadSoundWithVolumeVariants(id, `${id}.wav`);
    });
    this.loadSound("cuts", "cuts.wav");
  }

  private isCollisionTooClose(position: Vector2D): boolean {
    const now = performance.now();

    // Clean up old positions
    this.recentCollisionPositions = this.recentCollisionPositions.filter(
      (entry) => now - entry.time < this.POSITION_MEMORY_TIME
    );

    // Check if any recent collision is too close
    return this.recentCollisionPositions.some(
      (entry) =>
        entry.position.subtract(position).length() <
        this.MIN_DISTANCE_BETWEEN_SOUNDS
    );
  }

  private async createVolumeBuffers(
    id: string,
    sourceBuffer: AudioBuffer
  ): Promise<void> {
    const volumeMap = new Map<number, AudioBuffer>();

    for (let i = 0; i <= this.VOLUME_STEPS; i++) {
      // Use logarithmic scaling for volume steps
      const logStep = Math.log10(1 + (i / this.VOLUME_STEPS) * 9); // Maps 0->0, VOLUME_STEPS->1
      const volume = logStep * this.MAX_VOLUME;

      // Create a new buffer at this volume
      const newBuffer = this.audioContext.createBuffer(
        sourceBuffer.numberOfChannels,
        sourceBuffer.length,
        sourceBuffer.sampleRate
      );

      // Copy and scale the audio data for each channel
      for (
        let channel = 0;
        channel < sourceBuffer.numberOfChannels;
        channel++
      ) {
        const originalData = sourceBuffer.getChannelData(channel);
        const newData = newBuffer.getChannelData(channel);

        for (let j = 0; j < originalData.length; j++) {
          newData[j] = originalData[j] * volume;
        }
      }

      volumeMap.set(volume, newBuffer);
    }

    this.volumeBuffers.set(id, volumeMap);
  }

  private getRandomCollisionSound(): string {
    const index = Math.floor(Math.random() * this.COLLISION_SOUNDS.length);
    return this.COLLISION_SOUNDS[index];
  }

  render() {
    this.renderCollisions(this.player);
    this.renderCut(this.player);
  }

  private renderCut(player: Player) {
    const currentAction = player.getCurrentAction();
    if (
      currentAction.type === ActionType.Cut &&
      !this.playingCutSound &&
      player.actionInProgress
    ) {
      const cutAction = currentAction as Cut;
      const cutDuration = cutAction.cutDuration;
      const playbackRate = 200 / cutDuration;
      this.activeCutNode = this.playCutSound(playbackRate);
      this.playingCutSound = true;
    } else if (
      currentAction.type !== ActionType.Cut ||
      !player.actionInProgress
    ) {
      this.playingCutSound = false;
      if (this.activeCutNode) {
        this.activeCutNode.stop();
        this.activeCutNode.disconnect();
        this.activeCutNode = null;
      }
    }
  }

  private playCutSound(playbackRate: number): AudioBufferSourceNode | null {
    const buffer = this.audioBuffers.get("cuts");
    if (!buffer) return null;

    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(this.audioContext.destination);
    // Loop it
    source.loop = true;
    source.playbackRate.value = playbackRate;
    source.start();
    return source;
  }

  private renderCollisions(player: Player) {
    const resolvedCollisions = this.plane.resolvedCollisions;
    const playerLocation = player.getAbdomenPosition();

    for (const collision of resolvedCollisions) {
      if (
        !collision.intensity ||
        collision.intensity.collisionEnergy <= this.MIN_COLLISION_ENERGY
      ) {
        if (this.logRejectionReasons) {
          console.log(`Skipping collision: insufficient energy`);
        }
        continue;
      }

      const distance = collision.pointOfContact
        .subtract(playerLocation)
        .length();
      if (distance >= this.MAX_DISTANCE) {
        if (this.logRejectionReasons) {
          console.log(
            `Skipping collision: too far (${distance.toFixed(0)} >= ${
              this.MAX_DISTANCE
            })`
          );
        }
        continue;
      }

      const energyFactor =
        Math.log10(
          collision.intensity.collisionEnergy / this.MIN_COLLISION_ENERGY
        ) / Math.log10(this.MAX_COLLISION_ENERGY / this.MIN_COLLISION_ENERGY);

      const distanceFactor = 1 - distance / this.MAX_DISTANCE;
      const volume = Math.max(
        0,
        Math.min(
          this.MAX_VOLUME,
          energyFactor * distanceFactor * this.MAX_VOLUME
        )
      );

      this.playCollisionSound(volume, collision.pointOfContact);
    }
  }

  private playCollisionSound(volume: number, position: Vector2D): void {
    const soundId = this.getRandomCollisionSound();
    // Early rejection cases
    if (this.activeNodes.size >= this.maxSimultaneousSounds) {
      if (this.logRejectionReasons) {
        console.log(
          `Skipping sound: too many active sounds (${this.activeNodes.size} >= ${this.maxSimultaneousSounds})`
        );
      }
      return;
    }

    const now = performance.now();
    if (now - this.lastPlayTime < this.minTimeBetweenSounds) {
      if (this.logRejectionReasons) {
        console.log(
          `Skipping sound: too soon (${(now - this.lastPlayTime).toFixed(
            1
          )}ms < ${this.minTimeBetweenSounds}ms)`
        );
      }
      return;
    }

    if (this.isCollisionTooClose(position)) {
      if (this.logRejectionReasons) {
        console.log(`Skipping sound: too close to recent collision`);
      }
      return;
    }

    // Get the pre-computed buffer at the closest volume
    const buffer = this.getClosestVolumeBuffer(soundId, volume);
    if (!buffer) {
      if (this.logRejectionReasons) {
        console.log(`Skipping sound: buffer not found for "${soundId}"`);
      }
      return;
    }

    // Create and configure audio nodes
    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;
    // Lower pitch
    source.playbackRate.value = 0.5;
    source.connect(this.audioContext.destination);

    // Set up cleanup
    source.onended = () => {
      this.activeNodes.delete(source);
      source.disconnect();
    };

    // Start playback
    this.activeNodes.add(source);
    source.start();
    this.lastPlayTime = now;
    this.recentCollisionPositions.push({ position, time: now });
  }

  playSound(id: string): void {
    const buffer = this.audioBuffers.get(id);
    if (!buffer) return;

    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(this.audioContext.destination);
  }

  async loadSoundWithVolumeVariants(
    id: string,
    filename: string
  ): Promise<void> {
    const response = await fetch(`/assets/audio/${filename}`);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
    this.audioBuffers.set(id, audioBuffer);

    // Create volume variants
    await this.createVolumeBuffers(id, audioBuffer);
  }

  async loadSound(id: string, filename: string): Promise<void> {
    const response = await fetch(`/assets/audio/${filename}`);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
    this.audioBuffers.set(id, audioBuffer);
  }

  private getClosestVolumeBuffer(
    id: string,
    targetVolume: number
  ): AudioBuffer | undefined {
    const volumeMap = this.volumeBuffers.get(id);
    if (!volumeMap) return undefined;

    // Find the closest pre-computed volume
    const volumes = Array.from(volumeMap.keys());
    const closest = volumes.reduce((prev, curr) =>
      Math.abs(curr - targetVolume) < Math.abs(prev - targetVolume)
        ? curr
        : prev
    );

    return volumeMap.get(closest);
  }
}
