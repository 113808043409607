import type { Composite } from "../composite.ts";
import { SurfacePoint } from "../surfacePoint.ts";
import { Body } from "../body.ts";
import type { Joint } from "../../models.ts";

export class Weld implements Joint {
  parentPoint: SurfacePoint;
  childPoint: SurfacePoint;
  composite: Composite;
  destroyed: boolean = false;

  constructor(
    parentPoint: SurfacePoint,
    childPoint: SurfacePoint,
    composite: Composite
  ) {
    this.parentPoint = parentPoint;
    this.childPoint = childPoint;
    this.composite = composite;

    this.parentPoint.joint = this;
    this.childPoint.joint = this;
  }

  pointTransfer(point: SurfacePoint, oldBody: Body, newBody: Body): void {
    // const oldBodyProps = oldBody.getProps();
    // const propsBefore = this.composite.getProps();
    this.composite.removeBody(oldBody);
    this.composite.addBody(newBody);
    // const propsAfter = this.composite.getProps();
    // const newBodyProps = newBody.getProps();

    // // Calculate relative differences as percentages
    // const massDiff = Math.abs(propsAfter.mass - propsBefore.mass);
    // const massPercentage = (massDiff / propsBefore.mass) * 100;

    // const centroidDiff = Math.sqrt(
    //   Math.pow(propsAfter.centroid.x - propsBefore.centroid.x, 2) +
    //     Math.pow(propsAfter.centroid.y - propsBefore.centroid.y, 2)
    // );
    // const centroidScale = Math.sqrt(
    //   Math.pow(propsBefore.centroid.x, 2) + Math.pow(propsBefore.centroid.y, 2)
    // );
    // const centroidPercentage = (centroidDiff / (centroidScale || 1)) * 100;

    // const moiDiff = Math.abs(
    //   propsAfter.momentOfInertia - propsBefore.momentOfInertia
    // );
    // const moiPercentage = (moiDiff / propsBefore.momentOfInertia) * 100;

    // console.log(
    //   `Properties Change Report:
    //     Removed Body: ${JSON.stringify(oldBodyProps, null, 2)}
    //     Added Body: ${JSON.stringify(newBodyProps, null, 2)}

    //     Composite Before: ${JSON.stringify(propsBefore, null, 2)}
    //     Composite After: ${JSON.stringify(propsAfter, null, 2)}

    //     Differences:
    //     Mass: ${massDiff.toFixed(6)} (${massPercentage.toFixed(2)}%)
    //     Centroid: ${centroidDiff.toFixed(6)} (${centroidPercentage.toFixed(2)}%)
    //     Moment of Inertia: ${moiDiff.toFixed(6)} (${moiPercentage.toFixed(2)}%)`
    // );
  }

  pointDestroy(point: SurfacePoint): void {
    if (this.destroyed) {
      return;
    }

    const childBody = this.childPoint.body;
    if (childBody) {
      this.composite.removeBody(childBody);
    }

    this.destroyed = true;

    if (point === this.childPoint) {
      this.parentPoint.destroy();
    } else if (point === this.parentPoint) {
      this.childPoint.destroy();
    }
  }

  break(): void {
    this.destroyed = true;

    const childBody = this.childPoint.body;
    if (childBody) {
      this.composite.removeBody(childBody);
    } else {
      console.error("child body is null");
    }

    this.parentPoint.destroy();
    this.childPoint.destroy();
  }
}
