import {
  DEFAULT_BUTTON_MAP,
  ButtonEvent,
  type ButtonMap,
  ClientPacketType,
  ServerPacketType,
  InputPacketType,
  type BodyStateUpdate,
  type BodyState,
  type PlayerEntityIds,
  DataRequestType,
  UpdateType,
  type SerializableCutResult,
} from "../models";
import { Player } from "./player";
import { BrowserTiming } from "./timing";
import { Renderer } from "../rendering/renderer";
import { BufferReader, BufferWriter } from "./bufferUtil";
import { PlanarBase } from "./planarBase";
import { TouchTracker } from "./touch";
import { Enemy } from "./enemy";
import { AudioRenderer } from "../rendering/audioRenderer";
import { nanoid } from "nanoid";
import { randomUint32 } from "../math/utils";
import { Vector2D } from "../math/vector2D";

export class PlanarClient extends PlanarBase {
  localPlayer: Player;
  canvas: HTMLCanvasElement;

  enemies: Enemy[] = [];

  renderer: Renderer;
  audioRenderer: AudioRenderer;
  timing: BrowserTiming;
  lastUpdateTime: number = 0;
  animationFrameId: number | null = null;
  buttonMap: ButtonMap = DEFAULT_BUTTON_MAP;
  maxFrameTime: number = 0.25;
  networked: boolean;
  sendToServer: (message: ArrayBuffer) => void;
  gamepads: Map<number, Gamepad> = new Map();
  gamepadLoopId: number | null = null;
  rightTriggerPressed: boolean = false;
  leftTriggerPressed: boolean = false;
  aButtonPressed: boolean = false;
  leftBumperPressed: boolean = false;
  rightBumperPressed: boolean = false;
  dPadLeftPressed: boolean = false;
  dPadRightPressed: boolean = false;
  pointerLocked: boolean = false;
  lightingOn: boolean = true;
  resolveCollisions: boolean = true;
  soundOn: boolean = false;
  substeps: number = 1;
  mouseSensitivity: number = 1;
  gamepadSensitivity: number = 15;
  touchSensitivity: number = 30;
  gamepadThrustVector: Vector2D = new Vector2D(0, 0);
  thrustState: {
    left: boolean;
    right: boolean;
    up: boolean;
    down: boolean;
  } = {
    left: false,
    right: false,
    up: false,
    down: false,
  };

  // Add accumulators for mouse movement
  private accumulatedMovementX: number = 0;
  private accumulatedMovementY: number = 0;

  private touchTracker: TouchTracker = new TouchTracker();
  private armControllerTouchId: number | null = null;
  private actionTouchId: number | null = null;

  // Add constants for flick detection
  private readonly FLICK_VELOCITY_THRESHOLD = 0.5; // Adjust this value based on testing
  private readonly FLICK_DISTANCE_THRESHOLD = 30; // Minimum distance in pixels

  constructor({
    canvas,
    worldSize,
    sendToServer,
    localPlayerIds,
  }: {
    canvas: HTMLCanvasElement;
    worldSize: number;
    sendToServer?: (message: ArrayBuffer) => void;
    localPlayerIds?: {
      id: string;
      entityIds: PlayerEntityIds;
    };
  }) {
    super({
      id: "client",
      maxBodies: 20000,
      worldSize,
      isNetworkedClient: sendToServer !== undefined,
    });
    this.canvas = canvas;
    this.timing = new BrowserTiming();

    if (localPlayerIds) {
      this.localPlayer = this.addNewPlayer(
        localPlayerIds.id,
        localPlayerIds.entityIds
      );
    } else {
      this.localPlayer = this.addNewPlayer(nanoid(), {
        abdomenId: randomUint32(),
        limbId: randomUint32(),
        actuatorId: randomUint32(),
        tetherId: randomUint32(),
        thrusterId: randomUint32(),
      });
    }

    this.renderer = new Renderer(canvas, this.plane, this.localPlayer);
    this.audioRenderer = new AudioRenderer(this.plane, this.localPlayer);

    if (sendToServer) {
      this.networked = true;
      this.sendToServer = sendToServer;
    } else {
      this.networked = false;
      this.sendToServer = () => {};
    }

    // this.setupGamepadListeners();

    // Initial resize using element dimensions
    const container = canvas.parentElement;
    if (container) {
      this.handleResize([container.clientWidth, container.clientHeight]);
    }

    // Resize listener using element dimensions
    window.addEventListener("resize", () => {
      const container = this.canvas.parentElement;
      if (container) {
        this.handleResize([container.clientWidth, container.clientHeight]);
      }
    });
  }

  update(): void {
    this.endMetric("actualFrameTime");
    this.startMetric("actualFrameTime");
    this.startMetric("frameTime");
    const now = this.timing.now();
    const possibleDt = (now - this.lastUpdateTime) / 1000; // Convert to seconds

    // Prevent spiral of death if frame takes too long
    const dt = Math.min(possibleDt, this.maxFrameTime);

    this.startMetric("planeUpdate");
    const numSubsteps = this.substeps;
    this.recordNumericMetric("numSubsteps", numSubsteps);
    const substepDt = dt / numSubsteps;
    for (let i = 0; i < numSubsteps; i++) {
      this.startMetric("substep");
      const substepNow = now + i * substepDt;

      // Scale movement by camera zoom to maintain consistent feel regardless of zoom level
      const zoomScaleFactor = this.renderer.camera.zoom;
      // const zoomScaleFactor = 0.2;
      const armControlVectorX =
        this.accumulatedMovementX / substepDt / 2000 / zoomScaleFactor;
      const armControlVectorY =
        this.accumulatedMovementY / substepDt / 2000 / zoomScaleFactor;

      const thrustDirection = this.calculateThrustDirection();

      if (this.networked) {
        const writer = new BufferWriter();
        writer.writeUint8(ClientPacketType.Input);
        writer.writeUint8(InputPacketType.MovementControl);
        writer.writeFloat32(armControlVectorX);
        writer.writeFloat32(armControlVectorY);
        writer.writeFloat32(thrustDirection.x);
        writer.writeFloat32(thrustDirection.y);
        this.sendToServer(writer.getBuffer());
      }

      this.localPlayer.updateArmControlVector(
        armControlVectorX,
        armControlVectorY
      );
      this.accumulatedMovementX = 0;
      this.accumulatedMovementY = 0;

      this.localPlayer.updateThrustDirection(thrustDirection);

      this.updatePlayers(substepDt, substepNow);
      this.updateEnemies();
      this.plane.update(substepDt, substepNow, this.resolveCollisions);
      this.endMetric("substep");
    }
    this.endMetric("planeUpdate");
    this.startMetric("render");
    this.updateCamera(dt, now);
    this.renderer.render(
      dt,
      now,
      this.getMetricsReport(this.renderer.camera),
      this.lightingOn
    );
    if (this.soundOn) {
      this.audioRenderer.render();
    }
    this.endMetric("render");

    this.lastUpdateTime = now;
    this.endMetric("frameTime");
  }

  start(): void {
    this.setupListeners();
    this.lastUpdateTime = this.timing.now();

    const animate = () => {
      this.update();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    this.animationFrameId = requestAnimationFrame(animate);
  }

  stop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    this.stopGamepadLoop();
  }

  updatePlayers(dt: number, now: number): void {
    for (const player of this.players.values()) {
      player.update(dt, now);
    }
  }

  updateEnemies(): void {
    for (const enemy of this.enemies) {
      enemy.update(this.localPlayer.getAbdomenPosition());
    }
  }

  updateCamera(dt: number, now: number): void {
    const abdomenPosition = this.localPlayer.getAbdomenPosition();

    this.renderer.camera.follow(abdomenPosition, dt);
  }

  setupListeners(): void {
    this.canvas.addEventListener("mousedown", (e) => {
      if (e.button === 2) {
        this.handleButtonDown("rightMouse");
      } else {
        this.handleButtonDown("leftMouse");
      }
    });
    this.canvas.addEventListener("mouseup", (e) => {
      if (e.button === 2) {
        this.handleButtonUp("rightMouse");
      } else {
        this.handleButtonUp("leftMouse");
      }
    });
    this.canvas.addEventListener("mousemove", (e) => {
      if (this.pointerLocked) {
        this.handleMouseMove([e.movementX, e.movementY]);
      }
    });
    this.canvas.addEventListener("wheel", (e) => this.handleWheel(e.deltaY));
    window.addEventListener("keydown", (e) =>
      this.handleButtonDown(e.key.toLowerCase())
    );
    window.addEventListener("keyup", (e) =>
      this.handleButtonUp(e.key.toLowerCase())
    );
    window.addEventListener("resize", () => {
      const container = this.canvas.parentElement;
      if (container) {
        this.handleResize([container.clientWidth, container.clientHeight]);
      }
    });
    globalThis.addEventListener("touchstart", (e) => this.handleTouchStart(e));
    globalThis.addEventListener("touchmove", (e) => this.handleTouchMove(e));
    globalThis.addEventListener("touchend", (e) => this.handleTouchEnd(e));
    globalThis.addEventListener("touchcancel", (e) => this.handleTouchEnd(e));
    this.canvas.addEventListener("contextmenu", (e) => {
      e.preventDefault(); // Prevent the default context menu
    });
    this.canvas.addEventListener("click", () => {
      if (!this.pointerLocked) {
        this.canvas.requestPointerLock();
      }
    });

    document.addEventListener("pointerlockchange", () => {
      this.pointerLocked = document.pointerLockElement === this.canvas;
    });

    // Update touch listeners with options to prevent all default behaviors
    this.canvas.addEventListener(
      "touchstart",
      (e) => this.handleTouchStart(e),
      {
        passive: false,
        capture: true, // Capture phase to prevent other handlers
      }
    );
    this.canvas.addEventListener("touchmove", (e) => this.handleTouchMove(e), {
      passive: false,
      capture: true,
    });
    this.canvas.addEventListener("touchend", (e) => this.handleTouchEnd(e), {
      passive: false,
      capture: true,
    });
    this.canvas.addEventListener("touchcancel", (e) => this.handleTouchEnd(e), {
      passive: false,
      capture: true,
    });

    // Prevent default touch action styles
    this.canvas.style.touchAction = "none";
    // Prevent context menu (long press)
    this.canvas.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    // Prevent selection
    this.canvas.style.userSelect = "none";
  }

  handleMouseMove(point: [number, number]): void {
    this.accumulatedMovementX += point[0] * this.mouseSensitivity;
    this.accumulatedMovementY += point[1] * this.mouseSensitivity;
  }

  handleButtonDown(button: string): void {
    const buttonEvent = this.buttonMap.get(`${button}-down`);
    if (buttonEvent !== undefined) {
      if (this.isThrustButtonEvent(buttonEvent)) {
        this.updateThrustState(buttonEvent);
        return;
      }
      if (this.networked) {
        const writer = new BufferWriter();
        writer.writeUint8(ClientPacketType.Input);
        writer.writeUint8(InputPacketType.ButtonEvent);
        writer.writeUint8(buttonEvent);
        this.sendToServer(writer.getBuffer());
      }
      this.localPlayer.handleButtonEvent(buttonEvent);
    } else if (button === "l") {
      if (this.lightingOn) {
        this.lightingOn = false;
      } else {
        this.lightingOn = true;
      }
    } else if (button === "m") {
      if (this.soundOn) {
        this.soundOn = false;
      } else {
        this.soundOn = true;
      }
    }
  }

  handleButtonUp(button: string): void {
    const buttonEvent = this.buttonMap.get(`${button}-up`);
    if (buttonEvent !== undefined) {
      if (this.isThrustButtonEvent(buttonEvent)) {
        this.updateThrustState(buttonEvent);
        return;
      }
      if (this.networked) {
        const writer = new BufferWriter();
        writer.writeUint8(ClientPacketType.Input);
        writer.writeUint8(InputPacketType.ButtonEvent);
        writer.writeUint8(buttonEvent);
        this.sendToServer(writer.getBuffer());
      }
      this.localPlayer.handleButtonEvent(buttonEvent);
    }
  }

  handleResize(size: [number, number]): void {
    this.renderer.handleResize(size[0], size[1]);
  }

  handleWheel(deltaY: number): void {
    // Normalize the delta based on its magnitude
    // Trackpads typically send smaller values, mice send larger values
    const zoomSpeed = Math.abs(deltaY) < 50 ? 0.001 : 0.003;
    const zoomFactor = Math.pow(2, -deltaY * zoomSpeed);

    const newZoom = this.renderer.camera.zoomBy(zoomFactor);
    // if (this.networked) {
    //   const writer = new BufferWriter();
    //   writer.writeUint32(ClientPacketType.Zoom);
    //   writer.writeString(this.getLocalPlayer().id);
    //   writer.writeFloat32(newZoom);
    //   this.sendToServer(writer.getBuffer());
    // }
  }

  handleTouchStart(e: TouchEvent): void {
    e.preventDefault();
    const time = this.timing.now();

    for (const touch of e.changedTouches) {
      // Calculate which third of the screen the touch is in
      const screenThird = touch.clientX / window.innerWidth;

      this.touchTracker.addTouch(
        touch.identifier,
        touch.clientX,
        touch.clientY,
        time
      );

      if (screenThird <= 0.33) {
        // Left third - action touch
        this.actionTouchId = touch.identifier;
        this.localPlayer.handleButtonEvent(ButtonEvent.StartAction);
      } else {
        // Right two thirds - arm controller
        this.armControllerTouchId = touch.identifier;
      }
    }
  }

  handleTouchMove(e: TouchEvent): void {
    e.preventDefault();
    const time = this.timing.now();

    for (const touch of e.changedTouches) {
      const updatedTouch = this.touchTracker.updateTouch(
        touch.identifier,
        touch.clientX,
        touch.clientY,
        time
      );

      // Only handle movement for the arm controller touch
      if (touch.identifier === this.armControllerTouchId && updatedTouch) {
        this.accumulatedMovementX +=
          updatedTouch.velocityX * this.touchSensitivity;
        this.accumulatedMovementY +=
          updatedTouch.velocityY * this.touchSensitivity;
      }
    }
  }

  handleTouchEnd(e: TouchEvent): void {
    e.preventDefault();

    for (const touch of e.changedTouches) {
      if (touch.identifier === this.actionTouchId) {
        const touchPoint = this.touchTracker.getTouch(touch.identifier);

        if (touchPoint) {
          this.localPlayer.handleButtonEvent(ButtonEvent.StopAction);

          // Check for flick
          const verticalDistance = touchPoint.currentY - touchPoint.startY;
          const verticalVelocity = touchPoint.velocityY;
          const totalDistance = Math.abs(verticalDistance);

          if (
            totalDistance > this.FLICK_DISTANCE_THRESHOLD &&
            Math.abs(verticalVelocity) > this.FLICK_VELOCITY_THRESHOLD
          ) {
            // Flick detected
            if (verticalVelocity < 0) {
              // Flick up
              this.localPlayer.handleButtonEvent(ButtonEvent.NextAction);
            } else {
              // Flick down
              this.localPlayer.handleButtonEvent(ButtonEvent.PreviousAction);
            }
          }
        }

        this.actionTouchId = null;
      } else if (touch.identifier === this.armControllerTouchId) {
        this.armControllerTouchId = null;
      }

      this.touchTracker.removeTouch(touch.identifier);
    }
  }

  isThrustButtonEvent(event: ButtonEvent): boolean {
    return (
      event === ButtonEvent.StartLeftThrust ||
      event === ButtonEvent.StopLeftThrust ||
      event === ButtonEvent.StartRightThrust ||
      event === ButtonEvent.StopRightThrust ||
      event === ButtonEvent.StartUpThrust ||
      event === ButtonEvent.StopUpThrust ||
      event === ButtonEvent.StartDownThrust ||
      event === ButtonEvent.StopDownThrust
    );
  }

  updateThrustState(event: ButtonEvent): void {
    switch (event) {
      case ButtonEvent.StartLeftThrust:
        this.thrustState.left = true;
        break;
      case ButtonEvent.StopLeftThrust:
        this.thrustState.left = false;
        break;
      case ButtonEvent.StartRightThrust:
        this.thrustState.right = true;
        break;
      case ButtonEvent.StopRightThrust:
        this.thrustState.right = false;
        break;
      case ButtonEvent.StartUpThrust:
        this.thrustState.up = true;
        break;
      case ButtonEvent.StopUpThrust:
        this.thrustState.up = false;
        break;
      case ButtonEvent.StartDownThrust:
        this.thrustState.down = true;
        break;
      case ButtonEvent.StopDownThrust:
        this.thrustState.down = false;
        break;
    }
  }

  calculateThrustDirection(): Vector2D {
    const direction = new Vector2D(0, 0);
    if (this.thrustState.left) {
      direction.x -= 1;
    }
    if (this.thrustState.right) {
      direction.x += 1;
    }
    if (this.thrustState.up) {
      direction.y -= 1;
    }
    if (this.thrustState.down) {
      direction.y += 1;
    }

    const buttonDirection = direction.normalize();
    return buttonDirection.add(this.gamepadThrustVector);
  }

  handleMessage(message: ArrayBuffer): void {
    const reader = new BufferReader(message);
    const packetType = reader.readUint8();
    switch (packetType) {
      case ServerPacketType.StateUpdate:
        this.handleStateUpdate(reader);
        break;
      default:
        console.error(`Unknown packet type: ${packetType}`);
    }
  }

  handleStateUpdate(reader: BufferReader): void {
    const numParts = reader.readUint8();

    let playerIdsToRequest: string[] = [];
    let bodyIdsToRequest: Set<number> = new Set();

    for (let i = 0; i < numParts; i++) {
      const updateType = reader.readUint8();
      switch (updateType) {
        case UpdateType.PlayerData:
          const newPlayers = reader.readPlayerData();
          for (const player of newPlayers) {
            if (this.players.has(player.id)) {
              continue;
            }

            this.addNewPlayer(player.id, player.entityIds);
          }
          break;
        case UpdateType.BodyData:
          const bodies = reader.readBodies();
          for (const body of bodies) {
            this.addBody(body);
          }
          break;
        case UpdateType.Cuts:
          const cuts = reader.readCuts();
          for (const cut of cuts) {
            this.cutFromSerialized(cut, bodyIdsToRequest);
          }
          break;
        case UpdateType.RemovedBodyIds:
          const removedBodyIds = reader.readNumberIds();
          for (const bodyId of removedBodyIds) {
            this.deleteBody(bodyId);
          }
          break;
        case UpdateType.PlayerState:
          const playerStates = reader.readPlayerStates();
          for (const playerState of playerStates) {
            const player = this.players.get(playerState.id);
            if (!player) {
              playerIdsToRequest.push(playerState.id);
              continue;
            }

            if (player.id !== this.localPlayer.id) {
              player.armControlVector = playerState.armControlVector;
              player.thrustDirection = playerState.thrustDirection;
            }
          }
          break;
        case UpdateType.BodyState:
          const bodyStates = reader.readAllBodyStates();
          this.applyStateUpdate(bodyStates, bodyIdsToRequest);
          break;
        case UpdateType.RemovedCompositeIds:
          const removedCompositeIds = reader.readNumberIds();
          for (const compositeId of removedCompositeIds) {
            const composite =
              this.plane.bodyStore.idToComposite.get(compositeId);
            if (!composite) {
              console.error("Composite not found", compositeId);
              continue;
            }

            this.plane.bodyStore.removeComposite(composite.index);
          }
          break;
        case UpdateType.CompositeData:
          const compositeStates = reader.readAllCompositeStates();
          this.plane.bodyStore.applyCompositeStateUpdate(compositeStates);
          break;
      }
    }

    this.sendDataRequest(playerIdsToRequest, bodyIdsToRequest);
  }

  sendDataRequest(
    playerIdsToRequest: string[],
    bodyIdsToRequest: Set<number>
  ): void {
    const containsPlayerIdsToRequest = playerIdsToRequest.length > 0;
    const containsBodyIdsToRequest = bodyIdsToRequest.size > 0;

    let numParts = 0;
    if (containsPlayerIdsToRequest) {
      numParts++;
    }
    if (containsBodyIdsToRequest) {
      numParts++;
    }

    if (numParts === 0) {
      return;
    }

    const writer = new BufferWriter();
    writer.writeUint8(ClientPacketType.DataRequest);

    writer.writeUint8(numParts);

    if (containsPlayerIdsToRequest) {
      writer.writeUint8(DataRequestType.PlayerData);
      writer.writeStrings(playerIdsToRequest);
    }

    if (containsBodyIdsToRequest) {
      writer.writeUint8(DataRequestType.BodyData);
      writer.writeNumberIds(Array.from(bodyIdsToRequest));
    }

    this.sendToServer(writer.getBuffer());
  }

  applyStateUpdate(
    bodyStates: Map<number, BodyStateUpdate>,
    bodyIdsToRequest: Set<number>
  ): void {
    // Updates the body store with the new state, and removes bodies that were succusefully updated
    this.plane.bodyStore.applyStateUpdate(bodyStates);

    if (bodyStates.size === 0) {
      return;
    }

    // Check if we have any bodies that are not loaded
    for (const [id, update] of bodyStates.entries()) {
      const body = this.bodies.get(id);
      if (!body) {
        // If we don't have the body, request it
        bodyIdsToRequest.add(id);
        continue;
      }

      // If we have it, load it
      const state: BodyState = {
        position: [update.positionX, update.positionY, update.rotation],
        velocity: [update.velocityX, update.velocityY, update.angularVelocity],
        positionLocked: update.positionLocked,
        rotationLocked: update.rotationLocked,
      };

      this.loadBody({
        body,
        state,
      });

      if (update.compositeInfo) {
        const composite = this.plane.bodyStore.idToComposite.get(
          update.compositeInfo.id
        );

        if (!composite) {
          console.error("Composite not found", update.compositeInfo.id);
          continue;
        }

        if (!body.composite || body.composite !== composite) {
          body.setCompositeInfo(
            composite,
            update.compositeInfo.localX,
            update.compositeInfo.localY,
            update.compositeInfo.localRotation,
            composite.index,
            composite.id
          );
        }
      }
    }
  }

  // Return the id of the target if we don't have it, otherwise null
  cutFromSerialized(
    cut: SerializableCutResult,
    bodyIdsToRequest: Set<number>
  ): void {
    const target = this.plane.idToBody.get(cut.targetBodyId);
    if (!target) {
      console.error("Target body not found", cut.targetBodyId);
      bodyIdsToRequest.add(cut.targetBodyId);
      return;
    }

    const resultBodies = this.plane.bodyStore.cutFromSerialized(cut);

    if (resultBodies === null) {
      // Add all serialized result ids to the request
      console.error("Cut failed, requesting result bodies");
      for (const body of cut.resultBodies) {
        bodyIdsToRequest.add(body.id);
      }

      this.deleteBody(cut.targetBodyId);

      return;
    }

    for (const body of resultBodies) {
      this.addBody(body);
    }

    this.deleteBody(cut.targetBodyId);
  }

  setupGamepadListeners(): void {
    window.addEventListener("gamepadconnected", (e: GamepadEvent) => {
      this.gamepads.set(e.gamepad.index, e.gamepad);

      // Start the gamepad polling loop if it's not already running
      if (this.gamepadLoopId === null) {
        this.startGamepadLoop();
      }
    });

    window.addEventListener("gamepaddisconnected", (e: GamepadEvent) => {
      this.gamepads.delete(e.gamepad.index);

      // Stop the gamepad loop if no gamepads are connected
      if (this.gamepads.size === 0 && this.gamepadLoopId !== null) {
        this.stopGamepadLoop();
      }
    });
  }

  startGamepadLoop(): void {
    const pollGamepads = () => {
      // Get fresh gamepad data
      const gamepads = navigator.getGamepads();

      for (const gamepad of gamepads) {
        if (!gamepad) continue;

        // Handle left stick
        this.handleAxis01(gamepad.axes[0], gamepad.axes[1]);
        // Handle right stick
        this.handleAxis23(gamepad.axes[2], gamepad.axes[3]);

        this.handleLeftTrigger(gamepad.buttons[6].value);
        this.handleRightTrigger(gamepad.buttons[7].value);
        this.handleAButton(gamepad.buttons[0].pressed);
        this.handleLeftBumper(gamepad.buttons[4].pressed);
        this.handleRightBumper(gamepad.buttons[5].pressed);
        this.handleDPadLeft(gamepad.buttons[14].pressed);
        this.handleDPadRight(gamepad.buttons[15].pressed);
      }

      this.gamepadLoopId = requestAnimationFrame(pollGamepads);
    };

    this.gamepadLoopId = requestAnimationFrame(pollGamepads);
  }

  stopGamepadLoop(): void {
    if (this.gamepadLoopId !== null) {
      cancelAnimationFrame(this.gamepadLoopId);
      this.gamepadLoopId = null;
    }
  }

  // Stub methods for handling gamepad axes
  protected handleAxis01(x: number, y: number): void {
    // Left stick
    this.gamepadThrustVector = new Vector2D(x, y);
  }

  protected handleAxis23(x: number, y: number): void {
    // Right stick
    this.accumulatedMovementX += x * this.gamepadSensitivity;
    this.accumulatedMovementY += y * this.gamepadSensitivity;
  }

  protected handleRightTrigger(value: number): void {
    // Trigger values are -1 to 1, normalize to 0 to 1
    const normalizedValue = (value + 1) / 2;
    const isPressed = normalizedValue > 0.5;

    // Only call handleButtonDown if the state has changed
    if (isPressed !== this.rightTriggerPressed) {
      this.rightTriggerPressed = isPressed;
      if (isPressed) {
        this.handleButtonDown("rightTrigger");
      } else {
        this.handleButtonUp("rightTrigger");
      }
    }
  }

  protected handleLeftTrigger(value: number): void {
    // Trigger values are -1 to 1, normalize to 0 to 1
    const normalizedValue = (value + 1) / 2;
    const isPressed = normalizedValue > 0.5;

    // Only call handleButtonDown if the state has changed
    if (isPressed !== this.leftTriggerPressed) {
      this.leftTriggerPressed = isPressed;
      if (isPressed) {
        this.handleButtonDown("leftTrigger");
      } else {
        this.handleButtonUp("leftTrigger");
      }
    }
  }

  protected handleLeftBumper(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.leftBumperPressed) {
      this.leftBumperPressed = value;
      if (value) {
        this.handleButtonDown("leftBumper");
      } else {
        this.handleButtonUp("leftBumper");
      }
    }
  }

  protected handleRightBumper(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.rightBumperPressed) {
      this.rightBumperPressed = value;
      if (value) {
        this.handleButtonDown("rightBumper");
      } else {
        this.handleButtonUp("rightBumper");
      }
    }
  }

  protected handleDPadLeft(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.dPadLeftPressed) {
      this.dPadLeftPressed = value;
      if (value) {
        this.handleButtonDown("dPadLeft");
      } else {
        this.handleButtonUp("dPadLeft");
      }
    }
  }

  protected handleDPadRight(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.dPadRightPressed) {
      this.dPadRightPressed = value;
      if (value) {
        this.handleButtonDown("dPadRight");
      } else {
        this.handleButtonUp("dPadRight");
      }
    }
  }

  protected handleAButton(value: boolean): void {
    // Only call handleButtonDown if the state has changed
    if (value !== this.aButtonPressed) {
      this.aButtonPressed = value;
      if (value) {
        this.handleButtonDown("gamepadA");
      } else {
        this.handleButtonUp("gamepadA");
      }
    }
  }
}
