import { setupLocalPlanar } from "./setupLocalPlanar.ts";
import { setupNetworkedPlanar } from "./setupNetworkedPlanar.ts";

const canvas = document.getElementById("limbleCanvas") as HTMLCanvasElement;
if (!canvas) {
  throw new Error("Canvas element 'limbleCanvas' not found");
}

function getRoomFromUrl(): string | null {
  // Get room from URL search params (e.g., "?room=ayyo")
  const params = new URLSearchParams(window.location.search);
  const room = params.get("room");

  // Return null if there's no room name or it's empty
  if (!room || room.trim() === "") {
    return null;
  }

  // Validate room name (only allow alphanumeric and dashes)
  return /^[a-zA-Z0-9-]+$/.test(room) ? room : null;
}

async function setupPlanar(): Promise<void> {
  try {
    // const room = getRoomFromUrl();
    const room = "ayyo";
    // const room = null;
    const isLocal = !room;

    const planar = isLocal
      ? setupLocalPlanar(canvas)
      : await setupNetworkedPlanar(canvas, room, false);

    planar.start();
  } catch (error) {
    console.error("Failed to initialize Planar:", error);
    throw error;
  }
}

// Start the application
setupPlanar().catch((error) => {
  console.error("Fatal error starting Planar:", error);
});
