import { type Segment } from "../models";
import { Vector2D } from "./vector2D";

export const TWO_PI = Math.PI * 2;

export function normalizeAngle(angle: number): number {
  return angle - TWO_PI * Math.floor(angle / TWO_PI);
}

export function calculateGeometricPropertiesOld(segments: Segment[]): {
  centroid: Vector2D;
  area: number;
  secondMomentOfArea: number;
} {
  // Initialize accumulators
  let totalArea = 0;
  let weightedCentroidSum = new Vector2D(0, 0);
  let totalSecondMomentOfArea = 0;

  // First pass: collect areas and weighted centroids
  const contributions = segments.map((segment) => {
    return segment.getAllContributions();
  });

  for (const { area, centroid } of contributions) {
    totalArea += area;
    weightedCentroidSum = weightedCentroidSum.add(centroid.multiply(area));
  }

  // Calculate final centroid
  const finalCentroid = weightedCentroidSum.divide(totalArea);

  // Second pass: shift each segment's second moment to final centroid
  for (const { area, centroid, secondMomentOfArea } of contributions) {
    // Get distance from segment centroid to shape centroid
    const r = centroid.subtract(finalCentroid);
    const distanceSquared = r.lengthSquared();

    // Apply parallel axis theorem:
    // I_final = I_local + Ad²
    totalSecondMomentOfArea += secondMomentOfArea + area * distanceSquared;
  }

  return {
    centroid: finalCentroid,
    area: totalArea,
    secondMomentOfArea: totalSecondMomentOfArea,
  };
}

export function calculateGeometricProperties(segments: Segment[]): {
  centroid: Vector2D;
  area: number;
  secondMomentOfArea: number;
} {
  // Initialize accumulators
  let totalArea = 0;
  let weightedCentroidSum = new Vector2D(0, 0);
  let totalSecondMomentOfArea = 0;

  const contributions = segments.map((segment) => {
    return segment.getAllContributions();
  });

  for (const { area, centroid, secondMomentOfArea } of contributions) {
    totalArea += area;
    weightedCentroidSum = weightedCentroidSum.add(centroid.multiply(area));

    totalSecondMomentOfArea +=
      secondMomentOfArea + area * centroid.lengthSquared();
  }

  // Calculate final centroid
  const finalCentroid = weightedCentroidSum.divide(totalArea);

  totalSecondMomentOfArea -= totalArea * finalCentroid.lengthSquared();

  return {
    centroid: finalCentroid,
    area: totalArea,
    secondMomentOfArea: totalSecondMomentOfArea,
  };
}

export function randomUint32(): number {
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
}
