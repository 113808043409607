import {
  SegmentType,
  type BodyStateUpdate,
  type CompositeStateUpdate,
  type LightSource,
  type Material,
  type PlayerEntityIds,
  type Segment,
  type SerializableCutResult,
  type Span,
  type SubtractionResultShape,
} from "../models";
import { Color } from "../rendering/color";
import { Body } from "../plane/body";
import { Shape } from "../shapes/shape";
import { LineSegment } from "../shapes/segments/lineSegment";
import { ArcSegment } from "../shapes/segments/arcSegment";
import { CircleSegment } from "../shapes/segments/circleSegment";
import { Vector2D } from "../math/vector2D";
import {
  BodyDataStride,
  BodyInfoOffset,
  BodyInfoStride,
  BodyOffset,
} from "../plane/plane";
import {
  CompositeInfoOffset,
  CompositeInfoStride,
  CompositeStateOffset,
  CompositeStateStride,
  type BodyStore,
} from "../plane/bodyStore";
import type { Player } from "./player";

class BufferWriter {
  private buffer: ArrayBuffer;
  private view: DataView;
  private offset: number = 0;

  constructor(initialSize: number = 1024) {
    this.buffer = new ArrayBuffer(initialSize);
    this.view = new DataView(this.buffer);
  }

  private ensureSpace(bytesNeeded: number) {
    if (this.offset + bytesNeeded > this.buffer.byteLength) {
      // Double the buffer size or increase by bytesNeeded, whichever is larger
      const newSize = Math.max(
        this.buffer.byteLength * 2,
        this.buffer.byteLength + bytesNeeded
      );
      const newBuffer = new ArrayBuffer(newSize);
      new Uint8Array(newBuffer).set(new Uint8Array(this.buffer));
      this.buffer = newBuffer;
      this.view = new DataView(this.buffer);
    }
  }

  writeByte(value: number) {
    this.ensureSpace(1);
    this.view.setUint8(this.offset, value);
    this.offset += 1;
  }

  writeInt32(value: number) {
    this.ensureSpace(4);
    this.view.setInt32(this.offset, value, true);
    this.offset += 4;
  }

  writeString(str: string) {
    const encoder = new TextEncoder();
    const bytes = encoder.encode(str);
    // Write string length first
    this.writeInt32(bytes.length);
    this.ensureSpace(bytes.length);
    new Uint8Array(this.buffer).set(bytes, this.offset);
    this.offset += bytes.length;
  }

  writeFloat32(value: number) {
    this.ensureSpace(4);
    this.view.setFloat32(this.offset, value, true);
    this.offset += 4;
  }

  writeInt16(value: number) {
    this.ensureSpace(2);
    this.view.setInt16(this.offset, value, true);
    this.offset += 2;
  }

  writeUint8(value: number) {
    this.ensureSpace(1);
    this.view.setUint8(this.offset, value);
    this.offset += 1;
  }

  writeUint16(value: number) {
    this.ensureSpace(2);
    this.view.setUint16(this.offset, value, true);
    this.offset += 2;
  }

  writeUint32(value: number) {
    this.ensureSpace(4);
    this.view.setUint32(this.offset, value, true);
    this.offset += 4;
  }

  writeFloat64(value: number) {
    this.ensureSpace(8);
    this.view.setFloat64(this.offset, value, true);
    this.offset += 8;
  }

  writeBoolean(value: boolean) {
    this.writeByte(value ? 1 : 0);
  }

  getBuffer(): ArrayBuffer {
    return this.buffer.slice(0, this.offset);
  }

  writeBodies(bodies: Body[]): void {
    this.writeUint16(bodies.length);
    for (const body of bodies) {
      this.writeBody(body);
    }
  }

  writeBody(body: Body): void {
    this.writeUint32(body.id);
    this.writeMaterial(body.material);
    this.writeUint8(body.type);
    this.writeBoolean(body.inSubplane);
    this.writeShape(body.shape);
    if (body.light) {
      this.writeBoolean(true);
      this.writeLight(body.light);
    } else {
      this.writeBoolean(false);
    }
  }

  writeColor(color: Color): void {
    this.writeUint8(color.r);
    this.writeUint8(color.g);
    this.writeUint8(color.b);
  }

  writeLight(light: LightSource): void {
    this.writeFloat32(light.radius);
    this.writeFloat32(light.glowRadius);
    this.writeUint16(light.brightnessStops.length);
    for (const stop of light.brightnessStops) {
      this.writeFloat32(stop.position);
      this.writeFloat32(stop.opacity);
    }
    this.writeUint16(light.colorStops.length);
    for (const stop of light.colorStops) {
      this.writeFloat32(stop.position);
      this.writeFloat32(stop.opacity);
    }
    this.writeColor(light.color);
  }

  writeMaterial(material: Material): void {
    this.writeColor(material.color);
    this.writeBoolean(material.destructible);
    this.writeFloat32(material.density);
    this.writeFloat32(material.restitution);
    this.writeFloat32(material.staticFriction);
    this.writeFloat32(material.dynamicFriction);
  }

  writeShape(shape: Shape): void {
    this.writeFloat32(shape.area);
    this.writeFloat32(shape.centroid.x);
    this.writeFloat32(shape.centroid.y);
    this.writeFloat32(shape.secondMomentOfArea);
    this.writeFloat32(shape.mec.center.x);
    this.writeFloat32(shape.mec.center.y);
    this.writeFloat32(shape.mec.radius);
    this.writeUint16(shape.segments.length);
    for (const segment of shape.segments) {
      this.writeSegment(segment);
    }
  }

  writeSegment(segment: Segment): void {
    this.writeUint8(segment.type);
    switch (segment.type) {
      case SegmentType.LINE:
        this.writeLineSegment(segment as LineSegment);
        break;
      case SegmentType.ARC:
        this.writeArcSegment(segment as ArcSegment);
        break;
      case SegmentType.CIRCLE:
        this.writeCircleSegment(segment as CircleSegment);
        break;
    }
  }

  writeLineSegment(segment: LineSegment): void {
    this.writeFloat32(segment.start.x);
    this.writeFloat32(segment.start.y);
    this.writeFloat32(segment.end.x);
    this.writeFloat32(segment.end.y);
  }

  writeArcSegment(segment: ArcSegment): void {
    this.writeFloat32(segment.start.x);
    this.writeFloat32(segment.start.y);
    this.writeFloat32(segment.end.x);
    this.writeFloat32(segment.end.y);
    this.writeFloat32(segment.center.x);
    this.writeFloat32(segment.center.y);
    this.writeFloat32(segment.radius);
    this.writeFloat32(segment.startAngle);
    this.writeFloat32(segment.centralAngle);
  }

  writeCircleSegment(segment: CircleSegment): void {
    this.writeFloat32(segment.center.x);
    this.writeFloat32(segment.center.y);
    this.writeFloat32(segment.radius);
  }

  writeNumberIds(ids: number[]): void {
    this.writeUint16(ids.length);
    for (const id of ids) {
      this.writeUint32(id);
    }
  }

  writeStrings(strings: string[]): void {
    this.writeUint16(strings.length);
    for (const str of strings) {
      this.writeString(str);
    }
  }

  writeAllBodyStates(bodyStore: BodyStore): void {
    // Update velocities and positions with dt
    const stateArray = bodyStore.bodyStates;
    const infoArray = bodyStore.bodyInfo;
    const ids = bodyStore.bodyIds;

    const count = bodyStore.count;

    this.writeUint16(count);

    for (let i = 0; i < bodyStore.nextIndex; i++) {
      const stateStride = i * BodyDataStride;
      const infoStride = i * BodyInfoStride;

      if (ids[i] === 0) {
        continue;
      }

      const compositeId = bodyStore.bodyCompositeIds[i];

      this.writeUint32(ids[i]);
      this.writeFloat32(stateArray[stateStride + BodyOffset.PositionX]);
      this.writeFloat32(stateArray[stateStride + BodyOffset.PositionY]);
      this.writeFloat32(stateArray[stateStride + BodyOffset.Rotation]);
      this.writeFloat32(stateArray[stateStride + BodyOffset.VelocityX]);
      this.writeFloat32(stateArray[stateStride + BodyOffset.VelocityY]);
      this.writeFloat32(stateArray[stateStride + BodyOffset.AngularVelocity]);
      this.writeBoolean(
        infoArray[infoStride + BodyInfoOffset.PositionLocked] === 1
      );
      this.writeBoolean(
        infoArray[infoStride + BodyInfoOffset.RotationLocked] === 1
      );

      if (compositeId !== 0) {
        this.writeBoolean(true);
        this.writeUint32(compositeId);
        this.writeFloat32(stateArray[stateStride + BodyOffset.CompositeLocalX]);
        this.writeFloat32(stateArray[stateStride + BodyOffset.CompositeLocalY]);
        this.writeFloat32(
          stateArray[stateStride + BodyOffset.CompositeLocalRotation]
        );
      } else {
        this.writeBoolean(false);
      }
    }
  }

  writeAllCompositeStates(bodyStore: BodyStore): void {
    this.writeUint16(bodyStore.compositeCount);

    const compositeStates = bodyStore.compositeStates;
    const compositeInfo = bodyStore.compositeInfo;
    const compositeIds = bodyStore.compositeIds;

    for (let i = 0; i < bodyStore.nextCompositeIndex; i++) {
      const stateStride = i * CompositeStateStride;
      const infoStride = i * CompositeInfoStride;

      if (compositeIds[i] === 0) {
        continue;
      }

      this.writeUint32(compositeIds[i]);
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.InvMass]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.InvMomentOfInertia]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.LocalCentroidX]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.LocalCentroidY]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.PositionX]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.PositionY]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.Rotation]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.VelocityX]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.VelocityY]
      );
      this.writeFloat32(
        compositeStates[stateStride + CompositeStateOffset.AngularVelocity]
      );
      this.writeBoolean(
        compositeInfo[infoStride + CompositeInfoOffset.PositionLocked] === 1
      );
      this.writeBoolean(
        compositeInfo[infoStride + CompositeInfoOffset.RotationLocked] === 1
      );
    }
  }

  writePlayerData(players: { id: string; entityIds: PlayerEntityIds }[]): void {
    this.writeUint16(players.length);
    for (const player of players) {
      this.writeString(player.id);
      this.writePlayerEntityIds(player.entityIds);
    }
  }

  writePlayerEntityIds(ids: PlayerEntityIds): void {
    this.writeUint32(ids.abdomenId);
    this.writeUint32(ids.limbId);
    this.writeUint32(ids.actuatorId);
    this.writeUint32(ids.tetherId);
    this.writeUint32(ids.thrusterId);
  }

  writePlayerStates(players: Player[]): void {
    this.writeUint16(players.length);
    for (const player of players) {
      this.writeString(player.id);
      this.writeFloat32(player.armControlVector.x);
      this.writeFloat32(player.armControlVector.y);
      this.writeFloat32(player.thrustDirection.x);
      this.writeFloat32(player.thrustDirection.y);
    }
  }

  writeCuts(cuts: SerializableCutResult[]): void {
    this.writeUint16(cuts.length);
    for (const cut of cuts) {
      this.writeCut(cut);
    }
  }

  writeCut(cut: SerializableCutResult): void {
    this.writeUint32(cut.targetBodyId);
    this.writeUint16(cut.resultBodies.length);
    for (const resultBody of cut.resultBodies) {
      this.writeUint32(resultBody.id);
      this.writeSubtractionResultShape(resultBody.shape);
    }
  }

  writeSubtractionResultShape(shape: SubtractionResultShape): void {
    this.writeUint16(shape.spans.length);
    for (const span of shape.spans) {
      this.writeSpan(span);
    }

    const shapeProperties = shape.shapeProperties;

    this.writeFloat32(shapeProperties.area);
    this.writeFloat32(shapeProperties.centroid.x);
    this.writeFloat32(shapeProperties.centroid.y);
    this.writeFloat32(shapeProperties.secondMomentOfArea);
    this.writeFloat32(shapeProperties.mec.center.x);
    this.writeFloat32(shapeProperties.mec.center.y);
    this.writeFloat32(shapeProperties.mec.radius);
  }

  writeSpan(span: Span | Segment[]): void {
    if (Array.isArray(span)) {
      // Boolean flag is false if the span is a list of segments
      this.writeBoolean(false);
      this.writeUint16(span.length);
      for (const segment of span) {
        this.writeSegment(segment);
      }
    } else {
      // Boolean flag is true if the span is a single span (it's on the host shape)
      this.writeBoolean(true);
      this.writeBoolean(span.onCircle);

      const start = span.start;

      this.writeUint16(start.segmentIndex);
      this.writeFloat32(start.t);
      this.writeFloat32(start.point.x);
      this.writeFloat32(start.point.y);

      const end = span.end;

      this.writeUint16(end.segmentIndex);
      this.writeFloat32(end.t);
      this.writeFloat32(end.point.x);
      this.writeFloat32(end.point.y);
    }
  }
}

class BufferReader {
  private view: DataView;
  private offset: number = 0;

  constructor(buffer: ArrayBuffer) {
    this.view = new DataView(buffer);
  }

  readByte(): number {
    const value = this.view.getUint8(this.offset);
    this.offset += 1;
    return value;
  }

  readInt32(): number {
    const value = this.view.getInt32(this.offset, true);
    this.offset += 4;
    return value;
  }

  readString(): string {
    const length = this.readInt32();
    const bytes = new Uint8Array(this.view.buffer, this.offset, length);
    const decoder = new TextDecoder();
    const str = decoder.decode(bytes);
    this.offset += length;
    return str;
  }

  readFloat32(): number {
    const value = this.view.getFloat32(this.offset, true);
    this.offset += 4;
    return value;
  }

  readInt16(): number {
    const value = this.view.getInt16(this.offset, true);
    this.offset += 2;
    return value;
  }

  readUint8(): number {
    const value = this.view.getUint8(this.offset);
    this.offset += 1;
    return value;
  }

  readUint16(): number {
    const value = this.view.getUint16(this.offset, true);
    this.offset += 2;
    return value;
  }

  readUint32(): number {
    const value = this.view.getUint32(this.offset, true);
    this.offset += 4;
    return value;
  }

  readFloat64(): number {
    const value = this.view.getFloat64(this.offset, true);
    this.offset += 8;
    return value;
  }

  readBodies(): Body[] {
    const count = this.readUint16();
    const bodies: Body[] = [];
    for (let i = 0; i < count; i++) {
      bodies.push(this.readBody());
    }
    return bodies;
  }

  readBody(): Body {
    const id = this.readUint32();
    const material = this.readMaterial();
    const type = this.readUint8();
    const inSubplane = this.readBoolean();
    const shape = this.readShape();
    const light = this.readBoolean() ? this.readLight() : undefined;
    return new Body({ id, material, type, shape, inSubplane, light });
  }

  readLight(): LightSource {
    const radius = this.readFloat32();
    const glowRadius = this.readFloat32();
    const numBrightnessStops = this.readUint16();
    const brightnessStops: { position: number; opacity: number }[] = [];
    for (let i = 0; i < numBrightnessStops; i++) {
      const position = this.readFloat32();
      const opacity = this.readFloat32();
      brightnessStops.push({ position, opacity });
    }
    const numColorStops = this.readUint16();
    const colorStops: { position: number; opacity: number }[] = [];
    for (let i = 0; i < numColorStops; i++) {
      const position = this.readFloat32();
      const opacity = this.readFloat32();
      colorStops.push({ position, opacity });
    }
    const color = this.readColor();
    return { radius, glowRadius, brightnessStops, colorStops, color };
  }

  readColor(): Color {
    const r = this.readUint8();
    const g = this.readUint8();
    const b = this.readUint8();
    return new Color(r, g, b);
  }

  readMaterial(): Material {
    const color = this.readColor();
    const destructible = this.readBoolean();
    const density = this.readFloat32();
    const restitution = this.readFloat32();
    const staticFriction = this.readFloat32();
    const dynamicFriction = this.readFloat32();

    return {
      color,
      destructible,
      density,
      restitution,
      staticFriction,
      dynamicFriction,
    };
  }

  readShape(): Shape {
    const area = this.readFloat32();
    const centroidX = this.readFloat32();
    const centroidY = this.readFloat32();
    const secondMomentOfArea = this.readFloat32();
    const mecCenterX = this.readFloat32();
    const mecCenterY = this.readFloat32();
    const mecRadius = this.readFloat32();
    const segmentCount = this.readUint16();

    const segments: Segment[] = [];

    for (let i = 0; i < segmentCount; i++) {
      segments.push(this.readSegment());
    }

    const shape = new Shape(segments, {
      area,
      centroid: new Vector2D(centroidX, centroidY),
      secondMomentOfArea,
      mec: {
        center: new Vector2D(mecCenterX, mecCenterY),
        radius: mecRadius,
        mecPointIds: [],
      },
    });

    return shape;
  }

  readSegment(): Segment {
    const type = this.readUint8();
    switch (type) {
      case SegmentType.LINE:
        return this.readLineSegment();
      case SegmentType.ARC:
        return this.readArcSegment();
      case SegmentType.CIRCLE:
        return this.readCircleSegment();
      default:
        throw new Error(`Unknown segment type: ${type}`);
    }
  }

  readLineSegment(): LineSegment {
    const startX = this.readFloat32();
    const startY = this.readFloat32();
    const endX = this.readFloat32();
    const endY = this.readFloat32();
    return new LineSegment(
      new Vector2D(startX, startY),
      new Vector2D(endX, endY)
    );
  }

  readArcSegment(): ArcSegment {
    const startX = this.readFloat32();
    const startY = this.readFloat32();
    const endX = this.readFloat32();
    const endY = this.readFloat32();
    const centerX = this.readFloat32();
    const centerY = this.readFloat32();
    const radius = this.readFloat32();
    const startAngle = this.readFloat32();
    const centralAngle = this.readFloat32();
    return new ArcSegment(
      new Vector2D(startX, startY),
      new Vector2D(endX, endY),
      {
        center: new Vector2D(centerX, centerY),
        radius,
        startAngle,
        centralAngle,
      }
    );
  }

  readCircleSegment(): CircleSegment {
    const centerX = this.readFloat32();
    const centerY = this.readFloat32();
    const radius = this.readFloat32();
    return new CircleSegment(new Vector2D(centerX, centerY), radius);
  }

  readNumberIds(): number[] {
    const count = this.readUint16();
    const ids: number[] = [];
    for (let i = 0; i < count; i++) {
      ids.push(this.readUint32());
    }
    return ids;
  }

  readStrings(): string[] {
    const count = this.readUint16();
    const strings: string[] = [];
    for (let i = 0; i < count; i++) {
      strings.push(this.readString());
    }
    return strings;
  }

  readAllBodyStates(): Map<number, BodyStateUpdate> {
    const count = this.readUint16();
    const states: Map<number, BodyStateUpdate> = new Map();
    for (let i = 0; i < count; i++) {
      const id = this.readUint32();
      const positionX = this.readFloat32();
      const positionY = this.readFloat32();
      const rotation = this.readFloat32();
      const velocityX = this.readFloat32();
      const velocityY = this.readFloat32();
      const angularVelocity = this.readFloat32();
      const positionLocked = this.readBoolean();
      const rotationLocked = this.readBoolean();

      const inComposite = this.readBoolean();

      let compositeInfo:
        | {
            id: number;
            localX: number;
            localY: number;
            localRotation: number;
          }
        | undefined = undefined;

      if (inComposite) {
        compositeInfo = {
          id: this.readUint32(),
          localX: this.readFloat32(),
          localY: this.readFloat32(),
          localRotation: this.readFloat32(),
        };
      }

      states.set(id, {
        positionX,
        positionY,
        rotation,
        velocityX,
        velocityY,
        angularVelocity,
        positionLocked,
        rotationLocked,
        compositeInfo,
      });
    }
    return states;
  }

  readAllCompositeStates(): CompositeStateUpdate[] {
    const count = this.readUint16();
    const states: CompositeStateUpdate[] = [];
    for (let i = 0; i < count; i++) {
      const id = this.readUint32();
      const invMass = this.readFloat32();
      const invMomentOfInertia = this.readFloat32();
      const localCentroidX = this.readFloat32();
      const localCentroidY = this.readFloat32();
      const positionX = this.readFloat32();
      const positionY = this.readFloat32();
      const rotation = this.readFloat32();
      const velocityX = this.readFloat32();
      const velocityY = this.readFloat32();
      const angularVelocity = this.readFloat32();
      const positionLocked = this.readBoolean();
      const rotationLocked = this.readBoolean();
      states.push({
        id,
        invMass,
        invMomentOfInertia,
        localCentroidX,
        localCentroidY,
        positionX,
        positionY,
        rotation,
        velocityX,
        velocityY,
        angularVelocity,
        positionLocked,
        rotationLocked,
      });
    }
    return states;
  }

  readPlayerData(): { id: string; entityIds: PlayerEntityIds }[] {
    const count = this.readUint16();
    const players: { id: string; entityIds: PlayerEntityIds }[] = [];
    for (let i = 0; i < count; i++) {
      const id = this.readString();
      const entityIds = this.readPlayerEntityIds();
      players.push({ id, entityIds });
    }
    return players;
  }

  readPlayerEntityIds(): PlayerEntityIds {
    const abdomenId = this.readUint32();
    const limbId = this.readUint32();
    const actuatorId = this.readUint32();
    const tetherId = this.readUint32();
    const thrusterId = this.readUint32();
    return {
      abdomenId,
      limbId,
      actuatorId,
      tetherId,
      thrusterId,
    };
  }

  readPlayerStates(): {
    id: string;
    armControlVector: Vector2D;
    thrustDirection: Vector2D;
  }[] {
    const count = this.readUint16();
    const states: {
      id: string;
      armControlVector: Vector2D;
      thrustDirection: Vector2D;
    }[] = [];
    for (let i = 0; i < count; i++) {
      const id = this.readString();
      const armControlVectorX = this.readFloat32();
      const armControlVectorY = this.readFloat32();
      const thrustDirectionX = this.readFloat32();
      const thrustDirectionY = this.readFloat32();
      states.push({
        id,
        armControlVector: new Vector2D(armControlVectorX, armControlVectorY),
        thrustDirection: new Vector2D(thrustDirectionX, thrustDirectionY),
      });
    }
    return states;
  }

  readCuts(): SerializableCutResult[] {
    const count = this.readUint16();
    const cuts: SerializableCutResult[] = [];
    for (let i = 0; i < count; i++) {
      cuts.push(this.readCut());
    }
    return cuts;
  }

  readCut(): SerializableCutResult {
    const targetBodyId = this.readUint32();
    const resultBodyCount = this.readUint16();
    const resultBodies: { id: number; shape: SubtractionResultShape }[] = [];
    for (let i = 0; i < resultBodyCount; i++) {
      const id = this.readUint32();
      const shape = this.readSubtractionResultShape();
      resultBodies.push({ id, shape });
    }
    return { targetBodyId, resultBodies };
  }

  readSubtractionResultShape(): SubtractionResultShape {
    const spanCount = this.readUint16();
    const spans: (Span | Segment[])[] = [];
    for (let i = 0; i < spanCount; i++) {
      spans.push(this.readSpan());
    }

    const area = this.readFloat32();
    const centroidX = this.readFloat32();
    const centroidY = this.readFloat32();
    const secondMomentOfArea = this.readFloat32();
    const mecCenterX = this.readFloat32();
    const mecCenterY = this.readFloat32();
    const mecRadius = this.readFloat32();

    const shapeProperties = {
      area,
      centroid: new Vector2D(centroidX, centroidY),
      secondMomentOfArea,
      mec: {
        center: new Vector2D(mecCenterX, mecCenterY),
        radius: mecRadius,
        mecPointIds: [],
      },
    };

    return {
      spans,
      shapeProperties,
    };
  }

  readSpan(): Span | Segment[] {
    const isSpan = this.readBoolean();
    if (isSpan) {
      const onCircle = this.readBoolean();

      const startSegmentIndex = this.readUint16();
      const startT = this.readFloat32();
      const startPointX = this.readFloat32();
      const startPointY = this.readFloat32();

      const endSegmentIndex = this.readUint16();
      const endT = this.readFloat32();
      const endPointX = this.readFloat32();
      const endPointY = this.readFloat32();

      return {
        onHost: true,
        onCircle,
        start: {
          segmentIndex: startSegmentIndex,
          t: startT,
          point: new Vector2D(startPointX, startPointY),
        },
        end: {
          segmentIndex: endSegmentIndex,
          t: endT,
          point: new Vector2D(endPointX, endPointY),
        },
      };
    } else {
      const segmentCount = this.readUint16();
      const segments: Segment[] = [];
      for (let i = 0; i < segmentCount; i++) {
        segments.push(this.readSegment());
      }
      return segments;
    }
  }

  readBoolean(): boolean {
    return this.readByte() === 1;
  }

  // Utility methods for buffer state
  getRemainingBytes(): number {
    return this.view.byteLength - this.offset;
  }

  isEnd(): boolean {
    return this.offset >= this.view.byteLength;
  }
}

export { BufferWriter, BufferReader };
