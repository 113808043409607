import { nanoid } from "nanoid";
import { BodyType } from "../models";
import { VertexShapeBuilder } from "../shapes/builders/vertexShapeBuilder";
import { Thruster } from "../plane/components/thruster";
import { Body } from "../plane/body";
import type { Vector2D } from "../math/vector2D";
import type { Plane } from "../plane/plane";
import { Color } from "../rendering/color";
import { randomUint32 } from "../math/utils";

export class Enemy {
  plane: Plane;
  body: Body;
  thruster: Thruster;
  cutsLeft: number = 10;

  constructor(plane: Plane) {
    this.plane = plane;
    this.body = new Body({
      id: randomUint32(),
      shape: VertexShapeBuilder.regularPolygon(40, 8),
      material: {
        density: 1,
        restitution: 0.4,
        staticFriction: 0.6,
        dynamicFriction: 0.4,
        color: new Color(255, 0, 0),
        destructible: false,
      },
      type: BodyType.Enemy,
      light: {
        radius: 200,
        glowRadius: 60,
        brightnessStops: [
          { position: 0, opacity: 1 },
          { position: 1, opacity: 0 },
        ],
        colorStops: [
          { position: 0, opacity: 0.3 },
          { position: 1, opacity: 0 },
        ],
        color: new Color(255, 0, 0),
      },
      inSubplane: false,
    });

    this.body.addCollisionTrigger((otherBody, collision) => {
      const cutShape = VertexShapeBuilder.circle(50);

      const result = this.plane.bodyStore.cut(
        otherBody,
        cutShape,
        collision.pointOfContact
      );

      // if (result.length === 0) {
      //   return;
      // }

      // this.cutsLeft--;
      // if (this.cutsLeft > 0) {
      //   return;
      // }

      // if (
      //   otherBody.type !== BodyType.PlayerArm &&
      //   otherBody.type !== BodyType.PlayerBody &&
      //   otherBody.type !== BodyType.Enemy
      // ) {
      //   this.plane.bodyStore.unloadBody(this.body.index);
      //   this.plane.thrusters = this.plane.thrusters.filter(
      //     (t) => t !== this.thruster
      //   );
      // }
    });

    this.thruster = new Thruster(this.body);
  }

  update(playerPosition: Vector2D) {
    const bodyPosition = this.body.getCenter();

    const toPlayer = playerPosition.subtract(bodyPosition);

    this.thruster.updateForce(toPlayer.normalize().multiply(50000));
  }

  getEntities(): { body: Body; thruster: Thruster } {
    return {
      body: this.body,
      thruster: this.thruster,
    };
  }
}
