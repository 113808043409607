import { Pin } from "../../plane/components/pin";
import { Spring } from "../../plane/components/spring";
import { Tether } from "../../plane/components/tether";
import type { Plane } from "../../plane/plane";
import type { SurfacePoint } from "../../plane/surfacePoint";
import {
  type Action,
  ActionType,
  type SelectPointConfig,
  type ActionUpdateInfo,
  type ActionResult,
} from "../action";
import type { Player } from "../player";

export class PlaceSpring implements Action {
  type: ActionType = ActionType.PlaceSpring;
  player: Player;
  plane: Plane;
  storedSurfacePoint: SurfacePoint | null = null;
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 100,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.storedSurfacePoint = surfacePoint;

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Place Spring`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    const surfacePoint = this.storedSurfacePoint;

    if (!surfacePoint || surfacePoint.body === null) {
      return;
    }

    const pointInfo = info.selectPoint;

    if (!pointInfo) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    const newSurfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!newSurfacePoint) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    this.plane.springs.push(new Spring(surfacePoint, newSurfacePoint, 10, 30));

    this.storedSurfacePoint = null;

    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    if (!this.storedSurfacePoint) {
      return true;
    }

    const point = this.storedSurfacePoint.getPosition();
    if (!point) {
      this.storedSurfacePoint = null;
      return true;
    }

    // Render as red circle
    ctx.beginPath();
    ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
    ctx.fillStyle = "red";
    ctx.fill();

    return true;
  }
}

export class PlacePin implements Action {
  type: ActionType = ActionType.PlacePin;
  player: Player;
  plane: Plane;
  storedSurfacePoint: SurfacePoint | null = null;
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 100,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.storedSurfacePoint = surfacePoint;

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Place Pin`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    const surfacePoint = this.storedSurfacePoint;

    if (!surfacePoint || surfacePoint.body === null) {
      return;
    }

    const pointInfo = info.selectPoint;

    if (!pointInfo) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    const newSurfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!newSurfacePoint || newSurfacePoint.body === null) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    this.plane.pins.push(
      new Pin(surfacePoint, newSurfacePoint, {
        desiredLength: surfacePoint
          .getPosition()!
          .distanceTo(newSurfacePoint.getPosition()!),
        restitution: 0.5,
        baumgarteScale: 1000,
        slop: 0.01,
      })
    );

    this.storedSurfacePoint = null;

    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    if (!this.storedSurfacePoint) {
      return true;
    }

    const point = this.storedSurfacePoint.getPosition();
    if (!point) {
      this.storedSurfacePoint = null;
      return true;
    }

    // Render as red circle
    ctx.beginPath();
    ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
    ctx.fillStyle = "red";
    ctx.fill();

    return true;
  }
}

export class PlaceTether implements Action {
  type: ActionType = ActionType.PlaceTether;
  player: Player;
  plane: Plane;
  storedSurfacePoint: SurfacePoint | null = null;
  cooldown: number = 0;
  selectPointConfig: SelectPointConfig = {
    reach: 100,
    includeAbdomen: true,
    eligibilityCheck: () => true,
  };

  constructor(player: Player) {
    this.player = player;
    this.plane = player.plane;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    const pointInfo = info.selectPoint;

    if (!pointInfo) return { success: false, ongoing: false };

    const surfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!surfacePoint) return { success: false, ongoing: false };

    this.storedSurfacePoint = surfacePoint;

    return { success: true, ongoing: true };
  }

  getDisplayText(): string {
    return `Place Tether`;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return { success: false, ongoing: true };
  }

  release(info: ActionUpdateInfo): void {
    const surfacePoint = this.storedSurfacePoint;

    if (!surfacePoint || surfacePoint.body === null) {
      return;
    }

    const pointInfo = info.selectPoint;

    if (!pointInfo) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    const newSurfacePoint = this.plane.surfacePointStore.createSurfacePoint(
      pointInfo.pointOnSurface
    );

    if (!newSurfacePoint || newSurfacePoint.body === null) {
      surfacePoint.destroy();
      this.storedSurfacePoint = null;
      return;
    }

    this.plane.tethers.push(
      new Tether(surfacePoint, newSurfacePoint, {
        maxLength: surfacePoint
          .getPosition()!
          .distanceTo(newSurfacePoint.getPosition()!),
        restitution: 0.1,
        baumgarteScale: 0.7,
        slop: 0.1,
      })
    );

    this.storedSurfacePoint = null;

    return;
  }

  cancel(): void {
    return;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    if (!this.storedSurfacePoint) {
      return true;
    }

    const point = this.storedSurfacePoint.getPosition();
    if (!point) {
      this.storedSurfacePoint = null;
      return true;
    }

    // Render as red circle
    ctx.beginPath();
    ctx.arc(point.x, point.y, 20, 0, Math.PI * 2);
    ctx.fillStyle = "red";
    ctx.fill();

    return true;
  }
}
