import { type Material, BodyType } from "../../models";
import { Body } from "../../plane/body";
import type { Plane } from "../../plane/plane";
import { Color } from "../../rendering/color";
import { VertexShapeBuilder } from "../../shapes/builders/vertexShapeBuilder";
import {
  type Action,
  ActionType,
  type ActionUpdateInfo,
  type ActionResult,
} from "../action";
import type { Player } from "../player";
import { randomUint32 } from "../../math/utils";

export const DEFAULT_PROJECTILE_MATERIAL: Material = {
  density: 0.2,
  restitution: 1,
  staticFriction: 0,
  dynamicFriction: 0,
  color: Color.fromName("red"),
  destructible: true,
};

export class Shoot implements Action {
  type: ActionType = ActionType.Shoot;
  player: Player;
  plane: Plane;
  cooldown: number;
  reach: number = 0;
  projectileMaterial: Material;
  shots: number;

  constructor({
    player,
    projectileMaterial,
    cooldown,
    shots,
  }: {
    player: Player;
    projectileMaterial?: Material;
    cooldown: number;
    shots: number;
  }) {
    this.player = player;
    this.plane = player.plane;
    this.projectileMaterial = projectileMaterial ?? DEFAULT_PROJECTILE_MATERIAL;
    this.cooldown = cooldown;
    this.shots = shots;
  }

  activate(info: ActionUpdateInfo): ActionResult {
    if (this.shots < 1) {
      return { success: false, ongoing: false };
    }

    if (this.plane.isNetworkedClient) {
      return { success: true, ongoing: false };
    }

    this.shots = this.shots - 1;

    const projectileShape = VertexShapeBuilder.circle(50);

    const projectileBody = new Body({
      id: randomUint32(),
      shape: projectileShape,
      material: this.projectileMaterial,
      type: BodyType.Standard,
      light: {
        radius: 300,
        glowRadius: 150,
        brightnessStops: [
          { position: 0, opacity: 1 },
          { position: 0.75, opacity: 1 },
          { position: 1, opacity: 0 },
        ],
        colorStops: [
          { position: 0, opacity: 0.3 },
          { position: 0.75, opacity: 0.3 },
          { position: 1, opacity: 0 },
        ],
        color: Color.fromHex("#e3bb64"),
      },
      inSubplane: false,
    });

    const linearVelocity = info.limbDirection.scale(-10000);

    const position = info.limbPosition.add(info.limbDirection.scale(-20));

    const finalLinearVelocity = info.limbVelocity.add(linearVelocity);

    projectileBody.addCollisionTrigger((otherBody, collision) => {
      if (otherBody === this.player.abdomen || otherBody === this.player.limb) {
        return;
      }

      const cutShape = VertexShapeBuilder.circle(650);

      this.plane.bodyStore.cut(otherBody, cutShape, collision.pointOfContact);

      const impulseMagnitude = 1000;

      this.plane.bodyStore.explosion(
        collision.pointOfContact,
        500,
        impulseMagnitude
      );

      // Delete projectile after it collides
      this.plane.bodyStore.unloadBody(projectileBody.id);
    });

    this.plane.bodyStore.loadBody({
      body: projectileBody,
      state: {
        position: [position.x, position.y, 0],
        velocity: [finalLinearVelocity.x, finalLinearVelocity.y, 0],
        positionLocked: false,
        rotationLocked: false,
      },
    });

    const momentum = linearVelocity.scale(projectileBody.getMass());

    const impulseOnLimbBody = momentum.scale(-1);

    this.player.limb.applyImpulse(impulseOnLimbBody);

    setTimeout(() => {
      if (projectileBody.isLoaded()) {
        this.plane.bodyStore.unloadBody(projectileBody.id);
      }
    }, 10000);

    return { success: true, ongoing: false };
  }

  getDisplayText(): string {
    return `Shoot (shots: ${this.shots})`;
  }

  render(info: ActionUpdateInfo, ctx: CanvasRenderingContext2D): boolean {
    return true;
  }

  hold(info: ActionUpdateInfo): ActionResult {
    return this.activate(info);
  }

  release(info: ActionUpdateInfo): void {
    return;
  }

  cancel(): void {
    return;
  }
}
