import { Transform } from "../math/transform";
import type { AABB, SegmentInfo } from "../models";
import { SegmentTree } from "./segmentTree";

export enum TransformQuadrant {
  TopLeftIsTopBound,
  TopLeftIsRightBound,
  TopLeftIsBottomBound,
  TopLeftIsLeftBound,
}

export function intersectSegmentTrees(
  a: SegmentTree,
  b: SegmentTree,
  aToB: Transform,
  bToA: Transform,
  margin?: number
): [SegmentInfo, SegmentInfo][] | null {
  const aQuadrant = getAngleQuadrant(aToB.sin, aToB.cos);
  const bQuadrant = getAngleQuadrant(bToA.sin, bToA.cos);

  const aTransformed: IncompleteAABB = {
    left: undefined,
    right: undefined,
    top: undefined,
    bottom: undefined,
  };
  const bTransformed: IncompleteAABB = {
    left: undefined,
    right: undefined,
    top: undefined,
    bottom: undefined,
  };

  if (
    !boundsIntersect(
      a.bounds,
      b.bounds,
      aToB,
      bToA,
      aQuadrant,
      bQuadrant,
      aTransformed,
      bTransformed,
      margin
    )
  ) {
    // Null means no intersection plus enclosure is impossible
    return null;
  }

  const results: [SegmentInfo, SegmentInfo][] = [];

  intersectSegmentTreesRecursive(
    {
      tree: a,
      transform: aToB,
      quadrant: aQuadrant,
      transformedBounds: aTransformed,
    },
    {
      tree: b,
      transform: bToA,
      quadrant: bQuadrant,
      transformedBounds: bTransformed,
    },
    results,
    margin
  );

  return results;
}

export function intersectSegmentTreesRecursive(
  a: {
    tree: SegmentTree;
    transform: Transform;
    quadrant: TransformQuadrant;
    transformedBounds: IncompleteAABB;
  },
  b: {
    tree: SegmentTree;
    transform: Transform;
    quadrant: TransformQuadrant;
    transformedBounds: IncompleteAABB;
  },
  results: [SegmentInfo, SegmentInfo][],
  margin?: number
): void {
  const aChildren = a.tree.children;
  const bChildren = b.tree.children;

  if (aChildren.length > 0 && bChildren.length > 0) {
    // Determine which tree has smaller bounds
    const isASmaller = a.tree.boundsArea <= b.tree.boundsArea;
    const [smaller, larger] = isASmaller ? [a, b] : [b, a];
    const largerChildren = isASmaller ? bChildren : aChildren;

    for (let i = 0; i < largerChildren.length; i++) {
      const largerTransformed: IncompleteAABB = {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
      };

      if (
        !boundsIntersect(
          smaller.tree.bounds,
          largerChildren[i].bounds,
          smaller.transform,
          larger.transform,
          smaller.quadrant,
          larger.quadrant,
          smaller.transformedBounds,
          largerTransformed,
          margin
        )
      ) {
        continue;
      }

      // Recurse with the entire smaller tree against this child of the larger tree
      intersectSegmentTreesRecursive(
        isASmaller
          ? smaller
          : {
              tree: largerChildren[i],
              transform: larger.transform,
              quadrant: larger.quadrant,
              transformedBounds: largerTransformed,
            },
        isASmaller
          ? {
              tree: largerChildren[i],
              transform: larger.transform,
              quadrant: larger.quadrant,
              transformedBounds: largerTransformed,
            }
          : smaller,
        results,
        margin
      );
    }
  } else if (bChildren.length > 0) {
    // If a is a leaf, recursively check b's children
    for (const bChild of bChildren) {
      const bTransformed: IncompleteAABB = {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
      };

      if (
        !boundsIntersect(
          a.tree.bounds,
          bChild.bounds,
          a.transform,
          b.transform,
          a.quadrant,
          b.quadrant,
          a.transformedBounds,
          bTransformed,
          margin
        )
      ) {
        continue;
      }

      intersectSegmentTreesRecursive(
        a,
        {
          tree: bChild,
          transform: b.transform,
          quadrant: b.quadrant,
          transformedBounds: bTransformed,
        },
        results,
        margin
      );
    }
  } else if (aChildren.length > 0) {
    // If b is a leaf, recursively check a's children
    for (const aChild of aChildren) {
      const aTransformed: IncompleteAABB = {
        left: undefined,
        right: undefined,
        top: undefined,
        bottom: undefined,
      };

      if (
        !boundsIntersect(
          aChild.bounds,
          b.tree.bounds,
          a.transform,
          b.transform,
          a.quadrant,
          b.quadrant,
          aTransformed,
          b.transformedBounds,
          margin
        )
      ) {
        continue;
      }

      intersectSegmentTreesRecursive(
        {
          tree: aChild,
          transform: a.transform,
          quadrant: a.quadrant,
          transformedBounds: aTransformed,
        },
        b,
        results,
        margin
      );
    }
  } else if (a.tree.segment !== null && b.tree.segment !== null) {
    // If both are leaves, add the segments to results
    results.push([a.tree.segment, b.tree.segment]);
  }
}

type IncompleteAABB = {
  left: number | undefined;
  right: number | undefined;
  top: number | undefined;
  bottom: number | undefined;
};

function boundsIntersect(
  a: AABB,
  b: AABB,
  aToB: Transform,
  bToA: Transform,
  aQuadrant: TransformQuadrant,
  bQuadrant: TransformQuadrant,
  aTransformed: IncompleteAABB,
  bTransformed: IncompleteAABB,
  margin?: number
): boolean {
  let tx = bToA.x;
  let cx = bToA.cos;
  let sx = bToA.sin;

  if (bTransformed.left === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.left = tx + cx * b.left - sx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.left = tx + cx * b.right - sx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.left = tx + cx * b.right - sx * b.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.left = tx + cx * b.left - sx * b.top;
        break;
    }
  }

  if (
    margin ? bTransformed.left > a.right + margin : bTransformed.left > a.right
  )
    return false;

  if (bTransformed.right === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.right = tx + cx * b.right - sx * b.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.right = tx + cx * b.left - sx * b.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.right = tx + cx * b.left - sx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.right = tx + cx * b.right - sx * b.bottom;
        break;
    }
  }

  if (
    margin ? bTransformed.right < a.left - margin : bTransformed.right < a.left
  )
    return false;

  let ty = bToA.y;

  if (bTransformed.top === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.top = ty + sx * b.left + cx * b.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.top = ty + sx * b.left + cx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.top = ty + sx * b.right + cx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.top = ty + sx * b.right + cx * b.top;
        break;
    }
  }

  if (
    margin ? bTransformed.top > a.bottom + margin : bTransformed.top > a.bottom
  )
    return false;

  if (bTransformed.bottom === undefined) {
    switch (bQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        bTransformed.bottom = ty + sx * b.right + cx * b.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        bTransformed.bottom = ty + sx * b.right + cx * b.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        bTransformed.bottom = ty + sx * b.left + cx * b.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        bTransformed.bottom = ty + sx * b.left + cx * b.bottom;
        break;
    }
  }

  if (
    margin ? bTransformed.bottom < a.top - margin : bTransformed.bottom < a.top
  )
    return false;

  tx = aToB.x;
  cx = aToB.cos;
  sx = aToB.sin;

  if (aTransformed.left === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.left = tx + cx * a.left - sx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.left = tx + cx * a.right - sx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.left = tx + cx * a.right - sx * a.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.left = tx + cx * a.left - sx * a.top;
        break;
    }
  }

  if (
    margin ? aTransformed.left > b.right + margin : aTransformed.left > b.right
  )
    return false;

  if (aTransformed.right === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.right = tx + cx * a.right - sx * a.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.right = tx + cx * a.left - sx * a.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.right = tx + cx * a.left - sx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.right = tx + cx * a.right - sx * a.bottom;
        break;
    }
  }

  if (
    margin ? aTransformed.right < b.left - margin : aTransformed.right < b.left
  )
    return false;

  ty = aToB.y;

  if (aTransformed.top === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.top = ty + sx * a.left + cx * a.top;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.top = ty + sx * a.left + cx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.top = ty + sx * a.right + cx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.top = ty + sx * a.right + cx * a.top;
        break;
    }
  }

  if (
    margin ? aTransformed.top > b.bottom + margin : aTransformed.top > b.bottom
  )
    return false;

  if (aTransformed.bottom === undefined) {
    switch (aQuadrant) {
      case TransformQuadrant.TopLeftIsTopBound:
        aTransformed.bottom = ty + sx * a.right + cx * a.bottom;
        break;
      case TransformQuadrant.TopLeftIsRightBound:
        aTransformed.bottom = ty + sx * a.right + cx * a.top;
        break;
      case TransformQuadrant.TopLeftIsBottomBound:
        aTransformed.bottom = ty + sx * a.left + cx * a.top;
        break;
      case TransformQuadrant.TopLeftIsLeftBound:
        aTransformed.bottom = ty + sx * a.left + cx * a.bottom;
        break;
    }
  }

  if (
    margin ? aTransformed.bottom < b.top - margin : aTransformed.bottom < b.top
  )
    return false;

  return true;
}

export function getAngleQuadrant(sin: number, cos: number): TransformQuadrant {
  if (sin >= 0) {
    if (cos >= 0) {
      // Quadrant 1 (0° to 90°)
      return TransformQuadrant.TopLeftIsTopBound;
    } else {
      return TransformQuadrant.TopLeftIsRightBound;

      // Quadrant 2 (90° to 180°)
    }
  } else {
    if (cos <= 0) {
      // Quadrant 3 (180° to 270°)
      return TransformQuadrant.TopLeftIsBottomBound;
    } else {
      // Quadrant 4 (270° to 360°)
      return TransformQuadrant.TopLeftIsLeftBound;
    }
  }
}

export function getTransformedAABB(aabb: AABB, transform: Transform): AABB {
  const quadrant = getAngleQuadrant(transform.sin, transform.cos);

  switch (quadrant) {
    case TransformQuadrant.TopLeftIsTopBound:
      return {
        left:
          transform.x + transform.cos * aabb.left - transform.sin * aabb.bottom,
        right:
          transform.x + transform.cos * aabb.right - transform.sin * aabb.top,
        top: transform.y + transform.sin * aabb.left + transform.cos * aabb.top,
        bottom:
          transform.y +
          transform.sin * aabb.right +
          transform.cos * aabb.bottom,
      };
    case TransformQuadrant.TopLeftIsRightBound:
      return {
        left:
          transform.x +
          transform.cos * aabb.right -
          transform.sin * aabb.bottom,
        right:
          transform.x + transform.cos * aabb.left - transform.sin * aabb.top,
        top:
          transform.y + transform.sin * aabb.left + transform.cos * aabb.bottom,
        bottom:
          transform.y + transform.sin * aabb.right + transform.cos * aabb.top,
      };
    case TransformQuadrant.TopLeftIsBottomBound:
      return {
        left:
          transform.x + transform.cos * aabb.right - transform.sin * aabb.top,
        right:
          transform.x + transform.cos * aabb.left - transform.sin * aabb.bottom,
        top:
          transform.y +
          transform.sin * aabb.right +
          transform.cos * aabb.bottom,
        bottom:
          transform.y + transform.sin * aabb.left + transform.cos * aabb.top,
      };
    case TransformQuadrant.TopLeftIsLeftBound:
      return {
        left:
          transform.x + transform.cos * aabb.left - transform.sin * aabb.top,
        right:
          transform.x +
          transform.cos * aabb.right -
          transform.sin * aabb.bottom,
        top:
          transform.y + transform.sin * aabb.right + transform.cos * aabb.top,
        bottom:
          transform.y + transform.sin * aabb.left + transform.cos * aabb.bottom,
      };
    default:
      throw new Error("Invalid quadrant");
  }
}
