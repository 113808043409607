import type { TetherConfig, Joint } from "../../models.ts";
import { RenderRope } from "../../rendering/renderRope.ts";
import type { Body } from "../body.ts";
import type { SurfacePoint } from "../surfacePoint.ts";

export class Tether implements Joint {
  private surfacePoint1: SurfacePoint;
  private surfacePoint2: SurfacePoint;
  private maxLength: number;
  private restitution: number;
  private baumgarteScale: number;
  private slop: number;
  private renderRope: RenderRope | null = null;
  private destroyed: boolean = false;

  constructor(
    surfacePoint1: SurfacePoint,
    surfacePoint2: SurfacePoint,
    config: TetherConfig
  ) {
    this.surfacePoint1 = surfacePoint1;
    this.surfacePoint2 = surfacePoint2;
    this.maxLength = config.maxLength;
    this.restitution = config.restitution;
    this.baumgarteScale = config.baumgarteScale;
    this.slop = config.slop;

    this.surfacePoint1.joint = this;
    this.surfacePoint2.joint = this;
  }

  apply(): Body[] {
    if (this.destroyed) {
      return [];
    }

    const pos1 = this.surfacePoint1.getPosition();
    const pos2 = this.surfacePoint2.getPosition();

    if (!pos1 || !pos2) return [];

    // Calculate current length and direction
    const displacement = pos2.subtract(pos1);
    const currentLength = displacement.magnitude();

    // Only apply constraint if length exceeds maximum
    if (currentLength > this.maxLength) {
      const impulseInfo1 = this.surfacePoint1.getImpulseInfo();
      const impulseInfo2 = this.surfacePoint2.getImpulseInfo();

      if (impulseInfo1 === null || impulseInfo2 === null) {
        return [];
      }

      const {
        velocity: vel1,
        r: r1,
        invMass: invMass1,
        invInertia: invInertia1,
      } = impulseInfo1;
      const {
        velocity: vel2,
        r: r2,
        invMass: invMass2,
        invInertia: invInertia2,
      } = impulseInfo2;

      // Skip if both objects have infinite mass
      if (invMass1 === 0 && invMass2 === 0) return [];

      const direction = displacement.scale(1 / currentLength); // Normalized direction

      const relativeVelocity = vel2.subtract(vel1);

      // Project relative velocity onto constraint direction
      const normalVelocity = relativeVelocity.dot(direction);

      // Calculate r cross n terms for the denominator
      const r1CrossN = r1.x * direction.y - r1.y * direction.x;
      const r2CrossN = r2.x * direction.y - r2.y * direction.x;

      // Calculate denominator including angular terms
      const denominator =
        invMass1 +
        invMass2 +
        r1CrossN * r1CrossN * invInertia1 +
        r2CrossN * r2CrossN * invInertia2;

      if (denominator === 0) return [];

      // Calculate impulse magnitude
      const penetration = currentLength - this.maxLength;
      const restitutionImpulse =
        normalVelocity > 0 ? -(1 + this.restitution) * normalVelocity : 0;
      const penetrationImpulse = penetration * this.baumgarteScale;
      const totalImpulse =
        (restitutionImpulse + penetrationImpulse) / denominator;

      const impulseVector = direction.scale(-totalImpulse);

      // Apply impulses to both bodies
      if (invMass1 > 0) {
        this.surfacePoint1.applyImpulse(impulseVector);
      }
      if (invMass2 > 0) {
        this.surfacePoint2.applyImpulse(impulseVector.scale(-1));
      }

      let appliedPositionCorrectionTo: Body[] = [];

      // Position correction for large penetrations
      if (penetration > this.slop) {
        const correction = direction.scale(
          ((penetration - this.slop) * this.baumgarteScale) / denominator
        );

        if (invMass1 > 0) {
          this.surfacePoint1.addPositionCorrection(correction);
          appliedPositionCorrectionTo.push(this.surfacePoint1.getBody()!);
        }
        if (invMass2 > 0) {
          this.surfacePoint2.addPositionCorrection(correction.scale(-1));
          appliedPositionCorrectionTo.push(this.surfacePoint2.getBody()!);
        }
      }

      return appliedPositionCorrectionTo;
    }

    return [];
  }

  pointDestroy(point: SurfacePoint): void {
    this.destroyed = true;
  }

  pointTransfer(point: SurfacePoint, oldBody: Body, newBody: Body): void {}

  render(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    dt: number
  ): void {
    const pos1 = this.surfacePoint1.getPosition();
    const pos2 = this.surfacePoint2.getPosition();

    if (!pos1 || !pos2) return;

    if (!this.renderRope) {
      this.renderRope = new RenderRope(pos1, pos2, this.maxLength);
    }

    this.renderRope.render(ctx, pos1, pos2, dt);
  }
}
